import { LocalVisualizationsEventIdObject } from 'jotai/visualizationSettings';

export interface WindyWeatherSettingsObject extends LocalVisualizationsEventIdObject {
  id: string;
  zoom: number;
  layer: WindyLayers;
  pressure: boolean;
  marker: boolean;
  autoRefresh: boolean;
}

export enum WindyLayers {
  WIND = 'wind',
  RADAR = 'radar',
  SATELLITE = 'satellite',
  RAIN = 'rain',
  TEMP = 'temp',
  WETBULBTEMP = 'wetbulbtemp',
  HUMIDITY = 'rh',
  THUNDER = 'thunder',
  VISIBILITY = 'visibility',
  FOG = 'fog',
}
