import { VisualizationType } from 'jotai/visualization';
import { indyDefaultVisualizations } from 'jotai/visualization';

export enum DashboardType {
  CombinedView = 'combinedView',
  IndyCar = 'indyCar',
  TrackTemperature = 'trackTemperature',
  Default = '',
  ImageGallery = 'imageGallery',
  Qualifying = 'qualifying',
  Admin = 'admin',
}

/* DashboardType defines what visualizations can be used */
export const dashboardVisualizationsMap = new Map<DashboardType, Set<VisualizationType>>([
  [DashboardType.IndyCar, new Set<VisualizationType>([...indyDefaultVisualizations])],
]);
