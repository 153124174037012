// source: msuimsgs.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var motorsports$data$common_pb = require('./motorsports-data-common_pb.js');
goog.object.extend(proto, motorsports$data$common_pb);
goog.exportSymbol('proto.AdminSettings', null, global);
goog.exportSymbol('proto.AdminSettingsKey', null, global);
goog.exportSymbol('proto.DataType', null, global);
goog.exportSymbol('proto.DriverVehicleSeriesData', null, global);
goog.exportSymbol('proto.Entry', null, global);
goog.exportSymbol('proto.Event', null, global);
goog.exportSymbol('proto.EventSessions', null, global);
goog.exportSymbol('proto.GapType', null, global);
goog.exportSymbol('proto.IndyWindData', null, global);
goog.exportSymbol('proto.LapData', null, global);
goog.exportSymbol('proto.LapHistData', null, global);
goog.exportSymbol('proto.LatLong', null, global);
goog.exportSymbol('proto.LineCrossing', null, global);
goog.exportSymbol('proto.ManufacturerPoints', null, global);
goog.exportSymbol('proto.ManufacturerPointsList', null, global);
goog.exportSymbol('proto.NascarTrailerIrTemp', null, global);
goog.exportSymbol('proto.NascarTrailerWeather', null, global);
goog.exportSymbol('proto.NascarTrailerWeatherData', null, global);
goog.exportSymbol('proto.NascarTrailerWeatherHistoricalListingRowData', null, global);
goog.exportSymbol('proto.OwnerPoints', null, global);
goog.exportSymbol('proto.OwnerPointsList', null, global);
goog.exportSymbol('proto.PitCycleTimeMetadata', null, global);
goog.exportSymbol('proto.PitRoadAnalysis', null, global);
goog.exportSymbol('proto.PointsCalculation', null, global);
goog.exportSymbol('proto.PointsType', null, global);
goog.exportSymbol('proto.PositionProjection', null, global);
goog.exportSymbol('proto.PreviousLapMetaData', null, global);
goog.exportSymbol('proto.QualifyingLapData', null, global);
goog.exportSymbol('proto.QualifyingMetaData', null, global);
goog.exportSymbol('proto.RaceDataResult', null, global);
goog.exportSymbol('proto.RaceProgressionData', null, global);
goog.exportSymbol('proto.RequestCounter', null, global);
goog.exportSymbol('proto.SanctioningBody', null, global);
goog.exportSymbol('proto.SanctioningBodySeries', null, global);
goog.exportSymbol('proto.SasTokenType', null, global);
goog.exportSymbol('proto.Series', null, global);
goog.exportSymbol('proto.Session', null, global);
goog.exportSymbol('proto.SessionInfo', null, global);
goog.exportSymbol('proto.SessionPitRoadAnalysis', null, global);
goog.exportSymbol('proto.SessionPitRoadSummary', null, global);
goog.exportSymbol('proto.SessionPitSummaryMetaData', null, global);
goog.exportSymbol('proto.SessionRunStatus', null, global);
goog.exportSymbol('proto.SessionTimingScoringData', null, global);
goog.exportSymbol('proto.SessionTimingScoringVehicleMetaData', null, global);
goog.exportSymbol('proto.StreamRequestParams', null, global);
goog.exportSymbol('proto.TeamData', null, global);
goog.exportSymbol('proto.TelemetryData', null, global);
goog.exportSymbol('proto.TimestampMetadata', null, global);
goog.exportSymbol('proto.Track', null, global);
goog.exportSymbol('proto.TrackTemperatureHistoricalListingRowData', null, global);
goog.exportSymbol('proto.TrackTemperatureImageData', null, global);
goog.exportSymbol('proto.TrackTemperatureTabularRowData', null, global);
goog.exportSymbol('proto.VehicleMetaData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Series = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Series.repeatedFields_, null);
};
goog.inherits(proto.Series, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Series.displayName = 'proto.Series';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Track = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Track.repeatedFields_, null);
};
goog.inherits(proto.Track, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Track.displayName = 'proto.Track';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.QualifyingMetaData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.QualifyingMetaData.repeatedFields_, null);
};
goog.inherits(proto.QualifyingMetaData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.QualifyingMetaData.displayName = 'proto.QualifyingMetaData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LineCrossing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LineCrossing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LineCrossing.displayName = 'proto.LineCrossing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Session = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Session, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Session.displayName = 'proto.Session';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Entry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Entry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Entry.displayName = 'proto.Entry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EventSessions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EventSessions.repeatedFields_, null);
};
goog.inherits(proto.EventSessions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EventSessions.displayName = 'proto.EventSessions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Event.displayName = 'proto.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LapData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.LapData.repeatedFields_, null);
};
goog.inherits(proto.LapData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LapData.displayName = 'proto.LapData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SessionRunStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SessionRunStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SessionRunStatus.displayName = 'proto.SessionRunStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaceDataResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RaceDataResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaceDataResult.displayName = 'proto.RaceDataResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TelemetryData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TelemetryData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TelemetryData.displayName = 'proto.TelemetryData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaceProgressionData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RaceProgressionData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaceProgressionData.displayName = 'proto.RaceProgressionData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TimestampMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TimestampMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TimestampMetadata.displayName = 'proto.TimestampMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LapHistData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.LapHistData.repeatedFields_, null);
};
goog.inherits(proto.LapHistData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LapHistData.displayName = 'proto.LapHistData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.VehicleMetaData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.VehicleMetaData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.VehicleMetaData.displayName = 'proto.VehicleMetaData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SessionTimingScoringData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SessionTimingScoringData.repeatedFields_, null);
};
goog.inherits(proto.SessionTimingScoringData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SessionTimingScoringData.displayName = 'proto.SessionTimingScoringData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.QualifyingLapData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.QualifyingLapData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.QualifyingLapData.displayName = 'proto.QualifyingLapData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SessionTimingScoringVehicleMetaData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SessionTimingScoringVehicleMetaData.repeatedFields_, null);
};
goog.inherits(proto.SessionTimingScoringVehicleMetaData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SessionTimingScoringVehicleMetaData.displayName = 'proto.SessionTimingScoringVehicleMetaData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PreviousLapMetaData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PreviousLapMetaData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PreviousLapMetaData.displayName = 'proto.PreviousLapMetaData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DriverVehicleSeriesData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DriverVehicleSeriesData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DriverVehicleSeriesData.displayName = 'proto.DriverVehicleSeriesData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PitRoadAnalysis = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PitRoadAnalysis, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PitRoadAnalysis.displayName = 'proto.PitRoadAnalysis';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SanctioningBody = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SanctioningBody.repeatedFields_, null);
};
goog.inherits(proto.SanctioningBody, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SanctioningBody.displayName = 'proto.SanctioningBody';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SanctioningBodySeries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SanctioningBodySeries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SanctioningBodySeries.displayName = 'proto.SanctioningBodySeries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TrackTemperatureHistoricalListingRowData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TrackTemperatureHistoricalListingRowData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TrackTemperatureHistoricalListingRowData.displayName = 'proto.TrackTemperatureHistoricalListingRowData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TrackTemperatureTabularRowData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TrackTemperatureTabularRowData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TrackTemperatureTabularRowData.displayName = 'proto.TrackTemperatureTabularRowData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TrackTemperatureImageData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TrackTemperatureImageData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TrackTemperatureImageData.displayName = 'proto.TrackTemperatureImageData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RequestCounter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RequestCounter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RequestCounter.displayName = 'proto.RequestCounter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NascarTrailerWeatherHistoricalListingRowData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NascarTrailerWeatherHistoricalListingRowData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NascarTrailerWeatherHistoricalListingRowData.displayName = 'proto.NascarTrailerWeatherHistoricalListingRowData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NascarTrailerIrTemp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NascarTrailerIrTemp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NascarTrailerIrTemp.displayName = 'proto.NascarTrailerIrTemp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SessionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SessionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SessionInfo.displayName = 'proto.SessionInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StreamRequestParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StreamRequestParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StreamRequestParams.displayName = 'proto.StreamRequestParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PitCycleTimeMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PitCycleTimeMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PitCycleTimeMetadata.displayName = 'proto.PitCycleTimeMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NascarTrailerWeatherData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NascarTrailerWeatherData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NascarTrailerWeatherData.displayName = 'proto.NascarTrailerWeatherData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NascarTrailerWeather = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NascarTrailerWeather, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NascarTrailerWeather.displayName = 'proto.NascarTrailerWeather';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IndyWindData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IndyWindData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IndyWindData.displayName = 'proto.IndyWindData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SessionPitRoadSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SessionPitRoadSummary.repeatedFields_, null);
};
goog.inherits(proto.SessionPitRoadSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SessionPitRoadSummary.displayName = 'proto.SessionPitRoadSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SessionPitSummaryMetaData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SessionPitSummaryMetaData.repeatedFields_, null);
};
goog.inherits(proto.SessionPitSummaryMetaData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SessionPitSummaryMetaData.displayName = 'proto.SessionPitSummaryMetaData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SessionPitRoadAnalysis = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SessionPitRoadAnalysis.repeatedFields_, null);
};
goog.inherits(proto.SessionPitRoadAnalysis, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SessionPitRoadAnalysis.displayName = 'proto.SessionPitRoadAnalysis';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LatLong = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LatLong, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LatLong.displayName = 'proto.LatLong';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OwnerPoints = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.OwnerPoints, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.OwnerPoints.displayName = 'proto.OwnerPoints';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OwnerPointsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.OwnerPointsList.repeatedFields_, null);
};
goog.inherits(proto.OwnerPointsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.OwnerPointsList.displayName = 'proto.OwnerPointsList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ManufacturerPoints = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ManufacturerPoints, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ManufacturerPoints.displayName = 'proto.ManufacturerPoints';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ManufacturerPointsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ManufacturerPointsList.repeatedFields_, null);
};
goog.inherits(proto.ManufacturerPointsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ManufacturerPointsList.displayName = 'proto.ManufacturerPointsList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TeamData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TeamData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TeamData.displayName = 'proto.TeamData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AdminSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AdminSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AdminSettings.displayName = 'proto.AdminSettings';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Series.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Series.prototype.toObject = function(opt_includeInstance) {
  return proto.Series.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Series} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Series.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriesId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seriesFullName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    seriesShortName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    seriesAbbreviation: jspb.Message.getFieldWithDefault(msg, 4, ""),
    seasonsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Series}
 */
proto.Series.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Series;
  return proto.Series.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Series} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Series}
 */
proto.Series.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeriesId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeriesFullName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeriesShortName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeriesAbbreviation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addSeasons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Series.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Series.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Series} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Series.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriesId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeriesFullName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSeriesShortName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSeriesAbbreviation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSeasonsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string series_id = 1;
 * @return {string}
 */
proto.Series.prototype.getSeriesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Series} returns this
 */
proto.Series.prototype.setSeriesId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string series_full_name = 2;
 * @return {string}
 */
proto.Series.prototype.getSeriesFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Series} returns this
 */
proto.Series.prototype.setSeriesFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string series_short_name = 3;
 * @return {string}
 */
proto.Series.prototype.getSeriesShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Series} returns this
 */
proto.Series.prototype.setSeriesShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string series_abbreviation = 4;
 * @return {string}
 */
proto.Series.prototype.getSeriesAbbreviation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Series} returns this
 */
proto.Series.prototype.setSeriesAbbreviation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string seasons = 5;
 * @return {!Array<string>}
 */
proto.Series.prototype.getSeasonsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.Series} returns this
 */
proto.Series.prototype.setSeasonsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.Series} returns this
 */
proto.Series.prototype.addSeasons = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Series} returns this
 */
proto.Series.prototype.clearSeasonsList = function() {
  return this.setSeasonsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Track.repeatedFields_ = [10,26];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Track.prototype.toObject = function(opt_includeInstance) {
  return proto.Track.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Track} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Track.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    trackName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    trackCity: jspb.Message.getFieldWithDefault(msg, 3, ""),
    trackState: jspb.Message.getFieldWithDefault(msg, 4, ""),
    trackLatitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    trackLongitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    trackZoom: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    trackBearing: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    trackTimezone: jspb.Message.getFieldWithDefault(msg, 9, ""),
    trackLineCrossingList: jspb.Message.toObjectList(msg.getTrackLineCrossingList(),
    proto.LineCrossing.toObject, includeInstance),
    trackAddress: jspb.Message.getFieldWithDefault(msg, 11, ""),
    trackZip: jspb.Message.getFieldWithDefault(msg, 12, ""),
    trackType: jspb.Message.getFieldWithDefault(msg, 13, ""),
    trackSurface: jspb.Message.getFieldWithDefault(msg, 14, ""),
    trackBanking: jspb.Message.getFieldWithDefault(msg, 15, ""),
    trackLength: jspb.Message.getFieldWithDefault(msg, 16, ""),
    trackDescription: jspb.Message.getFieldWithDefault(msg, 17, ""),
    yearBuilt: jspb.Message.getFieldWithDefault(msg, 18, ""),
    frontstretchLength: jspb.Message.getFieldWithDefault(msg, 19, ""),
    backstretchLength: jspb.Message.getFieldWithDefault(msg, 20, ""),
    cautionCarSpeed: jspb.Message.getFieldWithDefault(msg, 21, ""),
    schStartTsUtc: jspb.Message.getFieldWithDefault(msg, 22, ""),
    schEndTsUtc: jspb.Message.getFieldWithDefault(msg, 23, ""),
    aclStartTsUtc: jspb.Message.getFieldWithDefault(msg, 24, ""),
    aclEndTsUtc: jspb.Message.getFieldWithDefault(msg, 25, ""),
    qualifyingmetadataList: jspb.Message.toObjectList(msg.getQualifyingmetadataList(),
    proto.QualifyingMetaData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Track}
 */
proto.Track.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Track;
  return proto.Track.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Track} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Track}
 */
proto.Track.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackCity(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackState(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTrackLatitude(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTrackLongitude(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTrackZoom(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTrackBearing(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackTimezone(value);
      break;
    case 10:
      var value = new proto.LineCrossing;
      reader.readMessage(value,proto.LineCrossing.deserializeBinaryFromReader);
      msg.addTrackLineCrossing(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackAddress(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackZip(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackSurface(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackBanking(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackLength(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackDescription(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setYearBuilt(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrontstretchLength(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackstretchLength(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setCautionCarSpeed(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchStartTsUtc(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setSchEndTsUtc(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setAclStartTsUtc(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setAclEndTsUtc(value);
      break;
    case 26:
      var value = new proto.QualifyingMetaData;
      reader.readMessage(value,proto.QualifyingMetaData.deserializeBinaryFromReader);
      msg.addQualifyingmetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Track.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Track.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Track} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Track.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTrackName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTrackCity();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTrackState();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTrackLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getTrackLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTrackZoom();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTrackBearing();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getTrackTimezone();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTrackLineCrossingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.LineCrossing.serializeBinaryToWriter
    );
  }
  f = message.getTrackAddress();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTrackZip();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTrackType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTrackSurface();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTrackBanking();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getTrackLength();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getTrackDescription();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getYearBuilt();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getFrontstretchLength();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getBackstretchLength();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getCautionCarSpeed();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getSchStartTsUtc();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getSchEndTsUtc();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getAclStartTsUtc();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getAclEndTsUtc();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getQualifyingmetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      proto.QualifyingMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string track_id = 1;
 * @return {string}
 */
proto.Track.prototype.getTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string track_name = 2;
 * @return {string}
 */
proto.Track.prototype.getTrackName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setTrackName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string track_city = 3;
 * @return {string}
 */
proto.Track.prototype.getTrackCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setTrackCity = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string track_state = 4;
 * @return {string}
 */
proto.Track.prototype.getTrackState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setTrackState = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double track_latitude = 5;
 * @return {number}
 */
proto.Track.prototype.getTrackLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setTrackLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double track_longitude = 6;
 * @return {number}
 */
proto.Track.prototype.getTrackLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setTrackLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double track_zoom = 7;
 * @return {number}
 */
proto.Track.prototype.getTrackZoom = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setTrackZoom = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double track_bearing = 8;
 * @return {number}
 */
proto.Track.prototype.getTrackBearing = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setTrackBearing = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string track_timezone = 9;
 * @return {string}
 */
proto.Track.prototype.getTrackTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setTrackTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated LineCrossing track_line_crossing = 10;
 * @return {!Array<!proto.LineCrossing>}
 */
proto.Track.prototype.getTrackLineCrossingList = function() {
  return /** @type{!Array<!proto.LineCrossing>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.LineCrossing, 10));
};


/**
 * @param {!Array<!proto.LineCrossing>} value
 * @return {!proto.Track} returns this
*/
proto.Track.prototype.setTrackLineCrossingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.LineCrossing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.LineCrossing}
 */
proto.Track.prototype.addTrackLineCrossing = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.LineCrossing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.clearTrackLineCrossingList = function() {
  return this.setTrackLineCrossingList([]);
};


/**
 * optional string track_address = 11;
 * @return {string}
 */
proto.Track.prototype.getTrackAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setTrackAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string track_zip = 12;
 * @return {string}
 */
proto.Track.prototype.getTrackZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setTrackZip = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string track_type = 13;
 * @return {string}
 */
proto.Track.prototype.getTrackType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setTrackType = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string track_surface = 14;
 * @return {string}
 */
proto.Track.prototype.getTrackSurface = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setTrackSurface = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string track_banking = 15;
 * @return {string}
 */
proto.Track.prototype.getTrackBanking = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setTrackBanking = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string track_length = 16;
 * @return {string}
 */
proto.Track.prototype.getTrackLength = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setTrackLength = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string track_description = 17;
 * @return {string}
 */
proto.Track.prototype.getTrackDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setTrackDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string year_built = 18;
 * @return {string}
 */
proto.Track.prototype.getYearBuilt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setYearBuilt = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string frontstretch_length = 19;
 * @return {string}
 */
proto.Track.prototype.getFrontstretchLength = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setFrontstretchLength = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string backstretch_length = 20;
 * @return {string}
 */
proto.Track.prototype.getBackstretchLength = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setBackstretchLength = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string caution_car_speed = 21;
 * @return {string}
 */
proto.Track.prototype.getCautionCarSpeed = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setCautionCarSpeed = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string sch_start_ts_utc = 22;
 * @return {string}
 */
proto.Track.prototype.getSchStartTsUtc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setSchStartTsUtc = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string sch_end_ts_utc = 23;
 * @return {string}
 */
proto.Track.prototype.getSchEndTsUtc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setSchEndTsUtc = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string acl_start_ts_utc = 24;
 * @return {string}
 */
proto.Track.prototype.getAclStartTsUtc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setAclStartTsUtc = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string acl_end_ts_utc = 25;
 * @return {string}
 */
proto.Track.prototype.getAclEndTsUtc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.setAclEndTsUtc = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * repeated QualifyingMetaData qualifyingMetaData = 26;
 * @return {!Array<!proto.QualifyingMetaData>}
 */
proto.Track.prototype.getQualifyingmetadataList = function() {
  return /** @type{!Array<!proto.QualifyingMetaData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.QualifyingMetaData, 26));
};


/**
 * @param {!Array<!proto.QualifyingMetaData>} value
 * @return {!proto.Track} returns this
*/
proto.Track.prototype.setQualifyingmetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.QualifyingMetaData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.QualifyingMetaData}
 */
proto.Track.prototype.addQualifyingmetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.QualifyingMetaData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Track} returns this
 */
proto.Track.prototype.clearQualifyingmetadataList = function() {
  return this.setQualifyingmetadataList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.QualifyingMetaData.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.QualifyingMetaData.prototype.toObject = function(opt_includeInstance) {
  return proto.QualifyingMetaData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.QualifyingMetaData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QualifyingMetaData.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    totalRounds: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lapsPerRound: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isTimedSession: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    seriesId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.QualifyingMetaData}
 */
proto.QualifyingMetaData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.QualifyingMetaData;
  return proto.QualifyingMetaData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.QualifyingMetaData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.QualifyingMetaData}
 */
proto.QualifyingMetaData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroups(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalRounds(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapsPerRound(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTimedSession(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeriesId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.QualifyingMetaData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.QualifyingMetaData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.QualifyingMetaData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QualifyingMetaData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getTotalRounds();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLapsPerRound();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getIsTimedSession();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSeriesId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * repeated string groups = 1;
 * @return {!Array<string>}
 */
proto.QualifyingMetaData.prototype.getGroupsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.QualifyingMetaData} returns this
 */
proto.QualifyingMetaData.prototype.setGroupsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.QualifyingMetaData} returns this
 */
proto.QualifyingMetaData.prototype.addGroups = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.QualifyingMetaData} returns this
 */
proto.QualifyingMetaData.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional int32 total_rounds = 2;
 * @return {number}
 */
proto.QualifyingMetaData.prototype.getTotalRounds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.QualifyingMetaData} returns this
 */
proto.QualifyingMetaData.prototype.setTotalRounds = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 laps_per_round = 3;
 * @return {number}
 */
proto.QualifyingMetaData.prototype.getLapsPerRound = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.QualifyingMetaData} returns this
 */
proto.QualifyingMetaData.prototype.setLapsPerRound = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_timed_session = 4;
 * @return {boolean}
 */
proto.QualifyingMetaData.prototype.getIsTimedSession = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.QualifyingMetaData} returns this
 */
proto.QualifyingMetaData.prototype.setIsTimedSession = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string series_id = 5;
 * @return {string}
 */
proto.QualifyingMetaData.prototype.getSeriesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.QualifyingMetaData} returns this
 */
proto.QualifyingMetaData.prototype.setSeriesId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LineCrossing.prototype.toObject = function(opt_includeInstance) {
  return proto.LineCrossing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LineCrossing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LineCrossing.toObject = function(includeInstance, msg) {
  var f, obj = {
    lineCrossingId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pb_long: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    avgFrac: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    category: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LineCrossing}
 */
proto.LineCrossing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LineCrossing;
  return proto.LineCrossing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LineCrossing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LineCrossing}
 */
proto.LineCrossing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLineCrossingId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLong(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgFrac(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LineCrossing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LineCrossing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LineCrossing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LineCrossing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLineCrossingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLong();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getAvgFrac();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string line_crossing_id = 1;
 * @return {string}
 */
proto.LineCrossing.prototype.getLineCrossingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.LineCrossing} returns this
 */
proto.LineCrossing.prototype.setLineCrossingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double long = 2;
 * @return {number}
 */
proto.LineCrossing.prototype.getLong = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LineCrossing} returns this
 */
proto.LineCrossing.prototype.setLong = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double lat = 3;
 * @return {number}
 */
proto.LineCrossing.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LineCrossing} returns this
 */
proto.LineCrossing.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double avg_frac = 4;
 * @return {number}
 */
proto.LineCrossing.prototype.getAvgFrac = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LineCrossing} returns this
 */
proto.LineCrossing.prototype.setAvgFrac = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string category = 5;
 * @return {string}
 */
proto.LineCrossing.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.LineCrossing} returns this
 */
proto.LineCrossing.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Session.prototype.toObject = function(opt_includeInstance) {
  return proto.Session.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Session} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Session.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sessionStatus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    seriesId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    replayEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Session}
 */
proto.Session.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Session;
  return proto.Session.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Session} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Session}
 */
proto.Session.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = /** @type {!proto.RunType} */ (reader.readEnum());
      msg.setSessionType(value);
      break;
    case 3:
      var value = /** @type {!proto.RunStatus} */ (reader.readEnum());
      msg.setSessionStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeriesId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReplayEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Session.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Session.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Session} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Session.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSessionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSeriesId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getReplayEnabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.Session.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Session} returns this
 */
proto.Session.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RunType session_type = 2;
 * @return {!proto.RunType}
 */
proto.Session.prototype.getSessionType = function() {
  return /** @type {!proto.RunType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.RunType} value
 * @return {!proto.Session} returns this
 */
proto.Session.prototype.setSessionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional RunStatus session_status = 3;
 * @return {!proto.RunStatus}
 */
proto.Session.prototype.getSessionStatus = function() {
  return /** @type {!proto.RunStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.RunStatus} value
 * @return {!proto.Session} returns this
 */
proto.Session.prototype.setSessionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string series_id = 4;
 * @return {string}
 */
proto.Session.prototype.getSeriesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Session} returns this
 */
proto.Session.prototype.setSeriesId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool replay_enabled = 5;
 * @return {boolean}
 */
proto.Session.prototype.getReplayEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Session} returns this
 */
proto.Session.prototype.setReplayEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Entry.prototype.toObject = function(opt_includeInstance) {
  return proto.Entry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Entry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Entry.toObject = function(includeInstance, msg) {
  var f, obj = {
    position: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicleNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    driverFullName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    qualifyingGroupName: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Entry}
 */
proto.Entry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Entry;
  return proto.Entry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Entry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Entry}
 */
proto.Entry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosition(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverFullName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setQualifyingGroupName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Entry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Entry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Entry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Entry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVehicleNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDriverFullName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQualifyingGroupName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 position = 1;
 * @return {number}
 */
proto.Entry.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string vehicle_number = 2;
 * @return {string}
 */
proto.Entry.prototype.getVehicleNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setVehicleNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string driver_full_name = 3;
 * @return {string}
 */
proto.Entry.prototype.getDriverFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setDriverFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string qualifying_group_name = 8;
 * @return {string}
 */
proto.Entry.prototype.getQualifyingGroupName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.Entry} returns this
 */
proto.Entry.prototype.setQualifyingGroupName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EventSessions.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EventSessions.prototype.toObject = function(opt_includeInstance) {
  return proto.EventSessions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EventSessions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventSessions.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    eventName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    eventDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    trackId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    trackName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sessionsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    trackEventId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EventSessions}
 */
proto.EventSessions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EventSessions;
  return proto.EventSessions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EventSessions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EventSessions}
 */
proto.EventSessions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addSessions(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EventSessions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EventSessions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EventSessions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventSessions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEventName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEventDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTrackId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTrackName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSessionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getTrackEventId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.EventSessions.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EventSessions} returns this
 */
proto.EventSessions.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string event_name = 2;
 * @return {string}
 */
proto.EventSessions.prototype.getEventName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.EventSessions} returns this
 */
proto.EventSessions.prototype.setEventName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string event_date = 3;
 * @return {string}
 */
proto.EventSessions.prototype.getEventDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.EventSessions} returns this
 */
proto.EventSessions.prototype.setEventDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string track_id = 4;
 * @return {string}
 */
proto.EventSessions.prototype.getTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.EventSessions} returns this
 */
proto.EventSessions.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string track_name = 5;
 * @return {string}
 */
proto.EventSessions.prototype.getTrackName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.EventSessions} returns this
 */
proto.EventSessions.prototype.setTrackName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string sessions = 6;
 * @return {!Array<string>}
 */
proto.EventSessions.prototype.getSessionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.EventSessions} returns this
 */
proto.EventSessions.prototype.setSessionsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.EventSessions} returns this
 */
proto.EventSessions.prototype.addSessions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EventSessions} returns this
 */
proto.EventSessions.prototype.clearSessionsList = function() {
  return this.setSessionsList([]);
};


/**
 * optional string track_event_id = 7;
 * @return {string}
 */
proto.EventSessions.prototype.getTrackEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.EventSessions} returns this
 */
proto.EventSessions.prototype.setTrackEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    eventName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    eventDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    trackId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    trackEventId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Event}
 */
proto.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Event;
  return proto.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Event}
 */
proto.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEventName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEventDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTrackId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTrackEventId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.Event.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string event_name = 2;
 * @return {string}
 */
proto.Event.prototype.getEventName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setEventName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string event_date = 3;
 * @return {string}
 */
proto.Event.prototype.getEventDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setEventDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string track_id = 4;
 * @return {string}
 */
proto.Event.prototype.getTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string track_event_id = 5;
 * @return {string}
 */
proto.Event.prototype.getTrackEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Event} returns this
 */
proto.Event.prototype.setTrackEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.LapData.repeatedFields_ = [23];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LapData.prototype.toObject = function(opt_includeInstance) {
  return proto.LapData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LapData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapData.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    runningPosition: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lapsCompleted: jspb.Message.getFieldWithDefault(msg, 3, 0),
    driverFullName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deltaToTarget: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    lapsLed: jspb.Message.getFieldWithDefault(msg, 7, 0),
    lastLapTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    bestLapTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    movingAverageLapTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    lapsToTarget: jspb.Message.getFieldWithDefault(msg, 11, 0),
    vehicleStatus: jspb.Message.getFieldWithDefault(msg, 12, ""),
    messageType: jspb.Message.getFieldWithDefault(msg, 13, ""),
    isStartFinish: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    pitCycleTimeMetadata: (f = msg.getPitCycleTimeMetadata()) && proto.PitCycleTimeMetadata.toObject(includeInstance, f),
    timeOfDay: jspb.Message.getFieldWithDefault(msg, 16, 0),
    pitPositionMetadata: (f = msg.getPitPositionMetadata()) && motorsports$data$common_pb.PitPositionMetadata.toObject(includeInstance, f),
    avgLapTime: jspb.Message.getFieldWithDefault(msg, 18, 0),
    fastRank: jspb.Message.getFieldWithDefault(msg, 19, 0),
    avgRank: jspb.Message.getFieldWithDefault(msg, 20, 0),
    runName: jspb.Message.getFieldWithDefault(msg, 22, ""),
    gapmetadataList: jspb.Message.toObjectList(msg.getGapmetadataList(),
    motorsports$data$common_pb.GapMetadata.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LapData}
 */
proto.LapData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LapData;
  return proto.LapData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LapData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LapData}
 */
proto.LapData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRunningPosition(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapsCompleted(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverFullName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeltaToTarget(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapsLed(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastLapTime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestLapTime(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMovingAverageLapTime(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapsToTarget(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleStatus(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageType(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsStartFinish(value);
      break;
    case 15:
      var value = new proto.PitCycleTimeMetadata;
      reader.readMessage(value,proto.PitCycleTimeMetadata.deserializeBinaryFromReader);
      msg.setPitCycleTimeMetadata(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeOfDay(value);
      break;
    case 17:
      var value = new motorsports$data$common_pb.PitPositionMetadata;
      reader.readMessage(value,motorsports$data$common_pb.PitPositionMetadata.deserializeBinaryFromReader);
      msg.setPitPositionMetadata(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAvgLapTime(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFastRank(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgRank(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    case 23:
      var value = new motorsports$data$common_pb.GapMetadata;
      reader.readMessage(value,motorsports$data$common_pb.GapMetadata.deserializeBinaryFromReader);
      msg.addGapmetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LapData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LapData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LapData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRunningPosition();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLapsCompleted();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDriverFullName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeltaToTarget();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getLapsLed();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLastLapTime();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getBestLapTime();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getMovingAverageLapTime();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getLapsToTarget();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getVehicleStatus();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMessageType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getIsStartFinish();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getPitCycleTimeMetadata();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.PitCycleTimeMetadata.serializeBinaryToWriter
    );
  }
  f = message.getTimeOfDay();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getPitPositionMetadata();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      motorsports$data$common_pb.PitPositionMetadata.serializeBinaryToWriter
    );
  }
  f = message.getAvgLapTime();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getFastRank();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getAvgRank();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getGapmetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      motorsports$data$common_pb.GapMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional string vehicle_number = 1;
 * @return {string}
 */
proto.LapData.prototype.getVehicleNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.setVehicleNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 running_position = 2;
 * @return {number}
 */
proto.LapData.prototype.getRunningPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.setRunningPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 laps_completed = 3;
 * @return {number}
 */
proto.LapData.prototype.getLapsCompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.setLapsCompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string driver_full_name = 4;
 * @return {string}
 */
proto.LapData.prototype.getDriverFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.setDriverFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double delta_to_target = 6;
 * @return {number}
 */
proto.LapData.prototype.getDeltaToTarget = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.setDeltaToTarget = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional int32 laps_led = 7;
 * @return {number}
 */
proto.LapData.prototype.getLapsLed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.setLapsLed = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional double last_lap_time = 8;
 * @return {number}
 */
proto.LapData.prototype.getLastLapTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.setLastLapTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double best_lap_time = 9;
 * @return {number}
 */
proto.LapData.prototype.getBestLapTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.setBestLapTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double moving_average_lap_time = 10;
 * @return {number}
 */
proto.LapData.prototype.getMovingAverageLapTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.setMovingAverageLapTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional int32 laps_to_target = 11;
 * @return {number}
 */
proto.LapData.prototype.getLapsToTarget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.setLapsToTarget = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string vehicle_status = 12;
 * @return {string}
 */
proto.LapData.prototype.getVehicleStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.setVehicleStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string message_type = 13;
 * @return {string}
 */
proto.LapData.prototype.getMessageType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.setMessageType = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool is_start_finish = 14;
 * @return {boolean}
 */
proto.LapData.prototype.getIsStartFinish = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.setIsStartFinish = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional PitCycleTimeMetadata pit_cycle_time_metadata = 15;
 * @return {?proto.PitCycleTimeMetadata}
 */
proto.LapData.prototype.getPitCycleTimeMetadata = function() {
  return /** @type{?proto.PitCycleTimeMetadata} */ (
    jspb.Message.getWrapperField(this, proto.PitCycleTimeMetadata, 15));
};


/**
 * @param {?proto.PitCycleTimeMetadata|undefined} value
 * @return {!proto.LapData} returns this
*/
proto.LapData.prototype.setPitCycleTimeMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.clearPitCycleTimeMetadata = function() {
  return this.setPitCycleTimeMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LapData.prototype.hasPitCycleTimeMetadata = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional int64 time_of_day = 16;
 * @return {number}
 */
proto.LapData.prototype.getTimeOfDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.setTimeOfDay = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional PitPositionMetadata pit_position_metadata = 17;
 * @return {?proto.PitPositionMetadata}
 */
proto.LapData.prototype.getPitPositionMetadata = function() {
  return /** @type{?proto.PitPositionMetadata} */ (
    jspb.Message.getWrapperField(this, motorsports$data$common_pb.PitPositionMetadata, 17));
};


/**
 * @param {?proto.PitPositionMetadata|undefined} value
 * @return {!proto.LapData} returns this
*/
proto.LapData.prototype.setPitPositionMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.clearPitPositionMetadata = function() {
  return this.setPitPositionMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LapData.prototype.hasPitPositionMetadata = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional int64 avg_lap_time = 18;
 * @return {number}
 */
proto.LapData.prototype.getAvgLapTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.setAvgLapTime = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int32 fast_rank = 19;
 * @return {number}
 */
proto.LapData.prototype.getFastRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.setFastRank = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 avg_rank = 20;
 * @return {number}
 */
proto.LapData.prototype.getAvgRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.setAvgRank = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string run_name = 22;
 * @return {string}
 */
proto.LapData.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * repeated GapMetadata gapMetadata = 23;
 * @return {!Array<!proto.GapMetadata>}
 */
proto.LapData.prototype.getGapmetadataList = function() {
  return /** @type{!Array<!proto.GapMetadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, motorsports$data$common_pb.GapMetadata, 23));
};


/**
 * @param {!Array<!proto.GapMetadata>} value
 * @return {!proto.LapData} returns this
*/
proto.LapData.prototype.setGapmetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.GapMetadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GapMetadata}
 */
proto.LapData.prototype.addGapmetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.GapMetadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.LapData} returns this
 */
proto.LapData.prototype.clearGapmetadataList = function() {
  return this.setGapmetadataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SessionRunStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.SessionRunStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SessionRunStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionRunStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionStatus: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SessionRunStatus}
 */
proto.SessionRunStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SessionRunStatus;
  return proto.SessionRunStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SessionRunStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SessionRunStatus}
 */
proto.SessionRunStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.RunStatus} */ (reader.readEnum());
      msg.setSessionStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SessionRunStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SessionRunStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SessionRunStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionRunStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional RunStatus session_status = 1;
 * @return {!proto.RunStatus}
 */
proto.SessionRunStatus.prototype.getSessionStatus = function() {
  return /** @type {!proto.RunStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.RunStatus} value
 * @return {!proto.SessionRunStatus} returns this
 */
proto.SessionRunStatus.prototype.setSessionStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaceDataResult.prototype.toObject = function(opt_includeInstance) {
  return proto.RaceDataResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaceDataResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaceDataResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    actualLaps: jspb.Message.getFieldWithDefault(msg, 1, 0),
    scheduledLaps: jspb.Message.getFieldWithDefault(msg, 2, 0),
    stageOneLaps: jspb.Message.getFieldWithDefault(msg, 3, 0),
    stageTwoLaps: jspb.Message.getFieldWithDefault(msg, 4, 0),
    stageThreeLaps: jspb.Message.getFieldWithDefault(msg, 5, 0),
    stageFourLaps: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaceDataResult}
 */
proto.RaceDataResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaceDataResult;
  return proto.RaceDataResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaceDataResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaceDataResult}
 */
proto.RaceDataResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setActualLaps(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setScheduledLaps(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStageOneLaps(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStageTwoLaps(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStageThreeLaps(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStageFourLaps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaceDataResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaceDataResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaceDataResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaceDataResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActualLaps();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getScheduledLaps();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStageOneLaps();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getStageTwoLaps();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getStageThreeLaps();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getStageFourLaps();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 actual_laps = 1;
 * @return {number}
 */
proto.RaceDataResult.prototype.getActualLaps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaceDataResult} returns this
 */
proto.RaceDataResult.prototype.setActualLaps = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 scheduled_laps = 2;
 * @return {number}
 */
proto.RaceDataResult.prototype.getScheduledLaps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaceDataResult} returns this
 */
proto.RaceDataResult.prototype.setScheduledLaps = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 stage_one_laps = 3;
 * @return {number}
 */
proto.RaceDataResult.prototype.getStageOneLaps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaceDataResult} returns this
 */
proto.RaceDataResult.prototype.setStageOneLaps = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 stage_two_laps = 4;
 * @return {number}
 */
proto.RaceDataResult.prototype.getStageTwoLaps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaceDataResult} returns this
 */
proto.RaceDataResult.prototype.setStageTwoLaps = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 stage_three_laps = 5;
 * @return {number}
 */
proto.RaceDataResult.prototype.getStageThreeLaps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaceDataResult} returns this
 */
proto.RaceDataResult.prototype.setStageThreeLaps = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 stage_four_laps = 6;
 * @return {number}
 */
proto.RaceDataResult.prototype.getStageFourLaps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaceDataResult} returns this
 */
proto.RaceDataResult.prototype.setStageFourLaps = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TelemetryData.prototype.toObject = function(opt_includeInstance) {
  return proto.TelemetryData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TelemetryData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TelemetryData.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleManufacturer: jspb.Message.getFieldWithDefault(msg, 2, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    heading: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    position: jspb.Message.getFieldWithDefault(msg, 6, 0),
    onLeadLap: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isLuckyDog: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    gpsQuality: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TelemetryData}
 */
proto.TelemetryData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TelemetryData;
  return proto.TelemetryData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TelemetryData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TelemetryData}
 */
proto.TelemetryData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleManufacturer(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHeading(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosition(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnLeadLap(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLuckyDog(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGpsQuality(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TelemetryData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TelemetryData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TelemetryData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TelemetryData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleManufacturer();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getHeading();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getOnLeadLap();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsLuckyDog();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getGpsQuality();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional string vehicle_number = 1;
 * @return {string}
 */
proto.TelemetryData.prototype.getVehicleNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TelemetryData} returns this
 */
proto.TelemetryData.prototype.setVehicleNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_manufacturer = 2;
 * @return {string}
 */
proto.TelemetryData.prototype.getVehicleManufacturer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TelemetryData} returns this
 */
proto.TelemetryData.prototype.setVehicleManufacturer = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double latitude = 3;
 * @return {number}
 */
proto.TelemetryData.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TelemetryData} returns this
 */
proto.TelemetryData.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double longitude = 4;
 * @return {number}
 */
proto.TelemetryData.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TelemetryData} returns this
 */
proto.TelemetryData.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double heading = 5;
 * @return {number}
 */
proto.TelemetryData.prototype.getHeading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TelemetryData} returns this
 */
proto.TelemetryData.prototype.setHeading = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 position = 6;
 * @return {number}
 */
proto.TelemetryData.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.TelemetryData} returns this
 */
proto.TelemetryData.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool on_lead_lap = 7;
 * @return {boolean}
 */
proto.TelemetryData.prototype.getOnLeadLap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.TelemetryData} returns this
 */
proto.TelemetryData.prototype.setOnLeadLap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_lucky_dog = 8;
 * @return {boolean}
 */
proto.TelemetryData.prototype.getIsLuckyDog = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.TelemetryData} returns this
 */
proto.TelemetryData.prototype.setIsLuckyDog = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 gps_quality = 9;
 * @return {number}
 */
proto.TelemetryData.prototype.getGpsQuality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.TelemetryData} returns this
 */
proto.TelemetryData.prototype.setGpsQuality = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaceProgressionData.prototype.toObject = function(opt_includeInstance) {
  return proto.RaceProgressionData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaceProgressionData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaceProgressionData.toObject = function(includeInstance, msg) {
  var f, obj = {
    lapNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalLaps: jspb.Message.getFieldWithDefault(msg, 2, 0),
    flag: jspb.Message.getFieldWithDefault(msg, 3, 0),
    localTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    timeElapsed: jspb.Message.getFieldWithDefault(msg, 5, 0),
    greenLapsFlagCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    yellowLapsFlagCount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    totalStages: jspb.Message.getFieldWithDefault(msg, 8, 0),
    currentStage: jspb.Message.getFieldWithDefault(msg, 9, 0),
    currentStageCurrentLap: jspb.Message.getFieldWithDefault(msg, 10, 0),
    currentStageTotalLaps: jspb.Message.getFieldWithDefault(msg, 11, 0),
    sessionInfo: (f = msg.getSessionInfo()) && proto.SessionInfo.toObject(includeInstance, f),
    timeRemaining: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaceProgressionData}
 */
proto.RaceProgressionData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaceProgressionData;
  return proto.RaceProgressionData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaceProgressionData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaceProgressionData}
 */
proto.RaceProgressionData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalLaps(value);
      break;
    case 3:
      var value = /** @type {!proto.FlagType} */ (reader.readEnum());
      msg.setFlag(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLocalTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeElapsed(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGreenLapsFlagCount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYellowLapsFlagCount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalStages(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentStage(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentStageCurrentLap(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentStageTotalLaps(value);
      break;
    case 12:
      var value = new proto.SessionInfo;
      reader.readMessage(value,proto.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeRemaining(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaceProgressionData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaceProgressionData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaceProgressionData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaceProgressionData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLapNumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotalLaps();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFlag();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLocalTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTimeElapsed();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getGreenLapsFlagCount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getYellowLapsFlagCount();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getTotalStages();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getCurrentStage();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getCurrentStageCurrentLap();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getCurrentStageTotalLaps();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getTimeRemaining();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
};


/**
 * optional int32 lap_number = 1;
 * @return {number}
 */
proto.RaceProgressionData.prototype.getLapNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaceProgressionData} returns this
 */
proto.RaceProgressionData.prototype.setLapNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total_laps = 2;
 * @return {number}
 */
proto.RaceProgressionData.prototype.getTotalLaps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaceProgressionData} returns this
 */
proto.RaceProgressionData.prototype.setTotalLaps = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional FlagType flag = 3;
 * @return {!proto.FlagType}
 */
proto.RaceProgressionData.prototype.getFlag = function() {
  return /** @type {!proto.FlagType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.FlagType} value
 * @return {!proto.RaceProgressionData} returns this
 */
proto.RaceProgressionData.prototype.setFlag = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 local_time = 4;
 * @return {number}
 */
proto.RaceProgressionData.prototype.getLocalTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaceProgressionData} returns this
 */
proto.RaceProgressionData.prototype.setLocalTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 time_elapsed = 5;
 * @return {number}
 */
proto.RaceProgressionData.prototype.getTimeElapsed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaceProgressionData} returns this
 */
proto.RaceProgressionData.prototype.setTimeElapsed = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 green_laps_flag_count = 6;
 * @return {number}
 */
proto.RaceProgressionData.prototype.getGreenLapsFlagCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaceProgressionData} returns this
 */
proto.RaceProgressionData.prototype.setGreenLapsFlagCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 yellow_laps_flag_count = 7;
 * @return {number}
 */
proto.RaceProgressionData.prototype.getYellowLapsFlagCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaceProgressionData} returns this
 */
proto.RaceProgressionData.prototype.setYellowLapsFlagCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 total_stages = 8;
 * @return {number}
 */
proto.RaceProgressionData.prototype.getTotalStages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaceProgressionData} returns this
 */
proto.RaceProgressionData.prototype.setTotalStages = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 current_stage = 9;
 * @return {number}
 */
proto.RaceProgressionData.prototype.getCurrentStage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaceProgressionData} returns this
 */
proto.RaceProgressionData.prototype.setCurrentStage = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 current_stage_current_lap = 10;
 * @return {number}
 */
proto.RaceProgressionData.prototype.getCurrentStageCurrentLap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaceProgressionData} returns this
 */
proto.RaceProgressionData.prototype.setCurrentStageCurrentLap = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 current_stage_total_laps = 11;
 * @return {number}
 */
proto.RaceProgressionData.prototype.getCurrentStageTotalLaps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaceProgressionData} returns this
 */
proto.RaceProgressionData.prototype.setCurrentStageTotalLaps = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional SessionInfo session_info = 12;
 * @return {?proto.SessionInfo}
 */
proto.RaceProgressionData.prototype.getSessionInfo = function() {
  return /** @type{?proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto.SessionInfo, 12));
};


/**
 * @param {?proto.SessionInfo|undefined} value
 * @return {!proto.RaceProgressionData} returns this
*/
proto.RaceProgressionData.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RaceProgressionData} returns this
 */
proto.RaceProgressionData.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RaceProgressionData.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional int64 time_remaining = 13;
 * @return {number}
 */
proto.RaceProgressionData.prototype.getTimeRemaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaceProgressionData} returns this
 */
proto.RaceProgressionData.prototype.setTimeRemaining = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TimestampMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.TimestampMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TimestampMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimestampMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    earliestTimestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    latestTimestamp: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TimestampMetadata}
 */
proto.TimestampMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TimestampMetadata;
  return proto.TimestampMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TimestampMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TimestampMetadata}
 */
proto.TimestampMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEarliestTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLatestTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TimestampMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TimestampMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TimestampMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimestampMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEarliestTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLatestTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 earliest_timestamp = 1;
 * @return {number}
 */
proto.TimestampMetadata.prototype.getEarliestTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimestampMetadata} returns this
 */
proto.TimestampMetadata.prototype.setEarliestTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 latest_timestamp = 2;
 * @return {number}
 */
proto.TimestampMetadata.prototype.getLatestTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.TimestampMetadata} returns this
 */
proto.TimestampMetadata.prototype.setLatestTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.LapHistData.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LapHistData.prototype.toObject = function(opt_includeInstance) {
  return proto.LapHistData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LapHistData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapHistData.toObject = function(includeInstance, msg) {
  var f, obj = {
    lap: jspb.Message.getFieldWithDefault(msg, 1, 0),
    leaderLapFlag: jspb.Message.getFieldWithDefault(msg, 2, 0),
    vehicleMetaDataList: jspb.Message.toObjectList(msg.getVehicleMetaDataList(),
    proto.VehicleMetaData.toObject, includeInstance),
    sessionInfo: (f = msg.getSessionInfo()) && proto.SessionInfo.toObject(includeInstance, f),
    driverCount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    timestampMetadata: (f = msg.getTimestampMetadata()) && proto.TimestampMetadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LapHistData}
 */
proto.LapHistData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LapHistData;
  return proto.LapHistData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LapHistData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LapHistData}
 */
proto.LapHistData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLap(value);
      break;
    case 2:
      var value = /** @type {!proto.FlagType} */ (reader.readEnum());
      msg.setLeaderLapFlag(value);
      break;
    case 3:
      var value = new proto.VehicleMetaData;
      reader.readMessage(value,proto.VehicleMetaData.deserializeBinaryFromReader);
      msg.addVehicleMetaData(value);
      break;
    case 5:
      var value = new proto.SessionInfo;
      reader.readMessage(value,proto.SessionInfo.deserializeBinaryFromReader);
      msg.setSessionInfo(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDriverCount(value);
      break;
    case 7:
      var value = new proto.TimestampMetadata;
      reader.readMessage(value,proto.TimestampMetadata.deserializeBinaryFromReader);
      msg.setTimestampMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LapHistData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LapHistData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LapHistData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapHistData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLap();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLeaderLapFlag();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getVehicleMetaDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.VehicleMetaData.serializeBinaryToWriter
    );
  }
  f = message.getSessionInfo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.SessionInfo.serializeBinaryToWriter
    );
  }
  f = message.getDriverCount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTimestampMetadata();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.TimestampMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 lap = 1;
 * @return {number}
 */
proto.LapHistData.prototype.getLap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.LapHistData} returns this
 */
proto.LapHistData.prototype.setLap = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional FlagType leader_lap_flag = 2;
 * @return {!proto.FlagType}
 */
proto.LapHistData.prototype.getLeaderLapFlag = function() {
  return /** @type {!proto.FlagType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.FlagType} value
 * @return {!proto.LapHistData} returns this
 */
proto.LapHistData.prototype.setLeaderLapFlag = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated VehicleMetaData vehicle_meta_data = 3;
 * @return {!Array<!proto.VehicleMetaData>}
 */
proto.LapHistData.prototype.getVehicleMetaDataList = function() {
  return /** @type{!Array<!proto.VehicleMetaData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.VehicleMetaData, 3));
};


/**
 * @param {!Array<!proto.VehicleMetaData>} value
 * @return {!proto.LapHistData} returns this
*/
proto.LapHistData.prototype.setVehicleMetaDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.VehicleMetaData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.VehicleMetaData}
 */
proto.LapHistData.prototype.addVehicleMetaData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.VehicleMetaData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.LapHistData} returns this
 */
proto.LapHistData.prototype.clearVehicleMetaDataList = function() {
  return this.setVehicleMetaDataList([]);
};


/**
 * optional SessionInfo session_info = 5;
 * @return {?proto.SessionInfo}
 */
proto.LapHistData.prototype.getSessionInfo = function() {
  return /** @type{?proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, proto.SessionInfo, 5));
};


/**
 * @param {?proto.SessionInfo|undefined} value
 * @return {!proto.LapHistData} returns this
*/
proto.LapHistData.prototype.setSessionInfo = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.LapHistData} returns this
 */
proto.LapHistData.prototype.clearSessionInfo = function() {
  return this.setSessionInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LapHistData.prototype.hasSessionInfo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 driver_count = 6;
 * @return {number}
 */
proto.LapHistData.prototype.getDriverCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.LapHistData} returns this
 */
proto.LapHistData.prototype.setDriverCount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional TimestampMetadata timestamp_metadata = 7;
 * @return {?proto.TimestampMetadata}
 */
proto.LapHistData.prototype.getTimestampMetadata = function() {
  return /** @type{?proto.TimestampMetadata} */ (
    jspb.Message.getWrapperField(this, proto.TimestampMetadata, 7));
};


/**
 * @param {?proto.TimestampMetadata|undefined} value
 * @return {!proto.LapHistData} returns this
*/
proto.LapHistData.prototype.setTimestampMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.LapHistData} returns this
 */
proto.LapHistData.prototype.clearTimestampMetadata = function() {
  return this.setTimestampMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LapHistData.prototype.hasTimestampMetadata = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.VehicleMetaData.prototype.toObject = function(opt_includeInstance) {
  return proto.VehicleMetaData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.VehicleMetaData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.VehicleMetaData.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    runningPosition: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lastLapTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    slowLap: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    pitLap: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    flag: jspb.Message.getFieldWithDefault(msg, 6, 0),
    greenYellowFlag: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    fastestPosition: jspb.Message.getFieldWithDefault(msg, 9, 0),
    driverFullName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    timeOfDay: jspb.Message.getFieldWithDefault(msg, 11, 0),
    avgLapTime: jspb.Message.getFieldWithDefault(msg, 12, 0),
    fastRank: jspb.Message.getFieldWithDefault(msg, 13, 0),
    avgRank: jspb.Message.getFieldWithDefault(msg, 14, 0),
    fastLap: jspb.Message.getFieldWithDefault(msg, 15, 0),
    runName: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.VehicleMetaData}
 */
proto.VehicleMetaData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.VehicleMetaData;
  return proto.VehicleMetaData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.VehicleMetaData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.VehicleMetaData}
 */
proto.VehicleMetaData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRunningPosition(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastLapTime(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSlowLap(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPitLap(value);
      break;
    case 6:
      var value = /** @type {!proto.FlagType} */ (reader.readEnum());
      msg.setFlag(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGreenYellowFlag(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFastestPosition(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverFullName(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeOfDay(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAvgLapTime(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFastRank(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgRank(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFastLap(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.VehicleMetaData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.VehicleMetaData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.VehicleMetaData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.VehicleMetaData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRunningPosition();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLastLapTime();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getSlowLap();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPitLap();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getFlag();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getGreenYellowFlag();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getFastestPosition();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getDriverFullName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTimeOfDay();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getAvgLapTime();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getFastRank();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getAvgRank();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getFastLap();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string vehicle_number = 1;
 * @return {string}
 */
proto.VehicleMetaData.prototype.getVehicleNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.VehicleMetaData} returns this
 */
proto.VehicleMetaData.prototype.setVehicleNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 running_position = 2;
 * @return {number}
 */
proto.VehicleMetaData.prototype.getRunningPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.VehicleMetaData} returns this
 */
proto.VehicleMetaData.prototype.setRunningPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double last_lap_time = 3;
 * @return {number}
 */
proto.VehicleMetaData.prototype.getLastLapTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.VehicleMetaData} returns this
 */
proto.VehicleMetaData.prototype.setLastLapTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional bool slow_lap = 4;
 * @return {boolean}
 */
proto.VehicleMetaData.prototype.getSlowLap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.VehicleMetaData} returns this
 */
proto.VehicleMetaData.prototype.setSlowLap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool pit_lap = 5;
 * @return {boolean}
 */
proto.VehicleMetaData.prototype.getPitLap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.VehicleMetaData} returns this
 */
proto.VehicleMetaData.prototype.setPitLap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional FlagType flag = 6;
 * @return {!proto.FlagType}
 */
proto.VehicleMetaData.prototype.getFlag = function() {
  return /** @type {!proto.FlagType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.FlagType} value
 * @return {!proto.VehicleMetaData} returns this
 */
proto.VehicleMetaData.prototype.setFlag = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool green_yellow_flag = 7;
 * @return {boolean}
 */
proto.VehicleMetaData.prototype.getGreenYellowFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.VehicleMetaData} returns this
 */
proto.VehicleMetaData.prototype.setGreenYellowFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 fastest_position = 9;
 * @return {number}
 */
proto.VehicleMetaData.prototype.getFastestPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.VehicleMetaData} returns this
 */
proto.VehicleMetaData.prototype.setFastestPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string driver_full_name = 10;
 * @return {string}
 */
proto.VehicleMetaData.prototype.getDriverFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.VehicleMetaData} returns this
 */
proto.VehicleMetaData.prototype.setDriverFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 time_of_day = 11;
 * @return {number}
 */
proto.VehicleMetaData.prototype.getTimeOfDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.VehicleMetaData} returns this
 */
proto.VehicleMetaData.prototype.setTimeOfDay = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 avg_lap_time = 12;
 * @return {number}
 */
proto.VehicleMetaData.prototype.getAvgLapTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.VehicleMetaData} returns this
 */
proto.VehicleMetaData.prototype.setAvgLapTime = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 fast_rank = 13;
 * @return {number}
 */
proto.VehicleMetaData.prototype.getFastRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.VehicleMetaData} returns this
 */
proto.VehicleMetaData.prototype.setFastRank = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 avg_rank = 14;
 * @return {number}
 */
proto.VehicleMetaData.prototype.getAvgRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.VehicleMetaData} returns this
 */
proto.VehicleMetaData.prototype.setAvgRank = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 fast_lap = 15;
 * @return {number}
 */
proto.VehicleMetaData.prototype.getFastLap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.VehicleMetaData} returns this
 */
proto.VehicleMetaData.prototype.setFastLap = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string run_name = 16;
 * @return {string}
 */
proto.VehicleMetaData.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.VehicleMetaData} returns this
 */
proto.VehicleMetaData.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SessionTimingScoringData.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SessionTimingScoringData.prototype.toObject = function(opt_includeInstance) {
  return proto.SessionTimingScoringData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SessionTimingScoringData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionTimingScoringData.toObject = function(includeInstance, msg) {
  var f, obj = {
    leaderLap: jspb.Message.getFieldWithDefault(msg, 1, 0),
    leaderLapFlag: jspb.Message.getFieldWithDefault(msg, 2, 0),
    timestampMetadata: (f = msg.getTimestampMetadata()) && proto.TimestampMetadata.toObject(includeInstance, f),
    sessionVehicleMetadataList: jspb.Message.toObjectList(msg.getSessionVehicleMetadataList(),
    proto.SessionTimingScoringVehicleMetaData.toObject, includeInstance),
    driverCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    qualifyingRoundName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SessionTimingScoringData}
 */
proto.SessionTimingScoringData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SessionTimingScoringData;
  return proto.SessionTimingScoringData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SessionTimingScoringData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SessionTimingScoringData}
 */
proto.SessionTimingScoringData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLeaderLap(value);
      break;
    case 2:
      var value = /** @type {!proto.FlagType} */ (reader.readEnum());
      msg.setLeaderLapFlag(value);
      break;
    case 3:
      var value = new proto.TimestampMetadata;
      reader.readMessage(value,proto.TimestampMetadata.deserializeBinaryFromReader);
      msg.setTimestampMetadata(value);
      break;
    case 4:
      var value = new proto.SessionTimingScoringVehicleMetaData;
      reader.readMessage(value,proto.SessionTimingScoringVehicleMetaData.deserializeBinaryFromReader);
      msg.addSessionVehicleMetadata(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDriverCount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setQualifyingRoundName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SessionTimingScoringData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SessionTimingScoringData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SessionTimingScoringData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionTimingScoringData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLeaderLap();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLeaderLapFlag();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTimestampMetadata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.TimestampMetadata.serializeBinaryToWriter
    );
  }
  f = message.getSessionVehicleMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.SessionTimingScoringVehicleMetaData.serializeBinaryToWriter
    );
  }
  f = message.getDriverCount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getQualifyingRoundName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 leader_lap = 1;
 * @return {number}
 */
proto.SessionTimingScoringData.prototype.getLeaderLap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionTimingScoringData} returns this
 */
proto.SessionTimingScoringData.prototype.setLeaderLap = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional FlagType leader_lap_flag = 2;
 * @return {!proto.FlagType}
 */
proto.SessionTimingScoringData.prototype.getLeaderLapFlag = function() {
  return /** @type {!proto.FlagType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.FlagType} value
 * @return {!proto.SessionTimingScoringData} returns this
 */
proto.SessionTimingScoringData.prototype.setLeaderLapFlag = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional TimestampMetadata timestamp_metadata = 3;
 * @return {?proto.TimestampMetadata}
 */
proto.SessionTimingScoringData.prototype.getTimestampMetadata = function() {
  return /** @type{?proto.TimestampMetadata} */ (
    jspb.Message.getWrapperField(this, proto.TimestampMetadata, 3));
};


/**
 * @param {?proto.TimestampMetadata|undefined} value
 * @return {!proto.SessionTimingScoringData} returns this
*/
proto.SessionTimingScoringData.prototype.setTimestampMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SessionTimingScoringData} returns this
 */
proto.SessionTimingScoringData.prototype.clearTimestampMetadata = function() {
  return this.setTimestampMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SessionTimingScoringData.prototype.hasTimestampMetadata = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated SessionTimingScoringVehicleMetaData session_vehicle_metadata = 4;
 * @return {!Array<!proto.SessionTimingScoringVehicleMetaData>}
 */
proto.SessionTimingScoringData.prototype.getSessionVehicleMetadataList = function() {
  return /** @type{!Array<!proto.SessionTimingScoringVehicleMetaData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SessionTimingScoringVehicleMetaData, 4));
};


/**
 * @param {!Array<!proto.SessionTimingScoringVehicleMetaData>} value
 * @return {!proto.SessionTimingScoringData} returns this
*/
proto.SessionTimingScoringData.prototype.setSessionVehicleMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.SessionTimingScoringVehicleMetaData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SessionTimingScoringVehicleMetaData}
 */
proto.SessionTimingScoringData.prototype.addSessionVehicleMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.SessionTimingScoringVehicleMetaData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SessionTimingScoringData} returns this
 */
proto.SessionTimingScoringData.prototype.clearSessionVehicleMetadataList = function() {
  return this.setSessionVehicleMetadataList([]);
};


/**
 * optional int32 driver_count = 5;
 * @return {number}
 */
proto.SessionTimingScoringData.prototype.getDriverCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionTimingScoringData} returns this
 */
proto.SessionTimingScoringData.prototype.setDriverCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string qualifying_round_name = 6;
 * @return {string}
 */
proto.SessionTimingScoringData.prototype.getQualifyingRoundName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionTimingScoringData} returns this
 */
proto.SessionTimingScoringData.prototype.setQualifyingRoundName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.QualifyingLapData.prototype.toObject = function(opt_includeInstance) {
  return proto.QualifyingLapData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.QualifyingLapData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QualifyingLapData.toObject = function(includeInstance, msg) {
  var f, obj = {
    runningPosition: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicleNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    driverFullName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    deltaToTarget: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    lastLapTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    bestLapTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    timeOfDay: jspb.Message.getFieldWithDefault(msg, 7, 0),
    runName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    isDatabaseRecord: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    vehicleStatus: jspb.Message.getFieldWithDefault(msg, 10, ""),
    messageType: jspb.Message.getFieldWithDefault(msg, 11, ""),
    isStartFinish: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    qualifyingPosition: jspb.Message.getFieldWithDefault(msg, 13, 0),
    lapsCompleted: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.QualifyingLapData}
 */
proto.QualifyingLapData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.QualifyingLapData;
  return proto.QualifyingLapData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.QualifyingLapData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.QualifyingLapData}
 */
proto.QualifyingLapData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRunningPosition(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverFullName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeltaToTarget(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastLapTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestLapTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeOfDay(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDatabaseRecord(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleStatus(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageType(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsStartFinish(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQualifyingPosition(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapsCompleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.QualifyingLapData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.QualifyingLapData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.QualifyingLapData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QualifyingLapData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunningPosition();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVehicleNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDriverFullName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDeltaToTarget();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLastLapTime();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getBestLapTime();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTimeOfDay();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIsDatabaseRecord();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getVehicleStatus();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMessageType();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIsStartFinish();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getQualifyingPosition();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getLapsCompleted();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
};


/**
 * optional int32 running_position = 1;
 * @return {number}
 */
proto.QualifyingLapData.prototype.getRunningPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.QualifyingLapData} returns this
 */
proto.QualifyingLapData.prototype.setRunningPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string vehicle_number = 2;
 * @return {string}
 */
proto.QualifyingLapData.prototype.getVehicleNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.QualifyingLapData} returns this
 */
proto.QualifyingLapData.prototype.setVehicleNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string driver_full_name = 3;
 * @return {string}
 */
proto.QualifyingLapData.prototype.getDriverFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.QualifyingLapData} returns this
 */
proto.QualifyingLapData.prototype.setDriverFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double delta_to_target = 4;
 * @return {number}
 */
proto.QualifyingLapData.prototype.getDeltaToTarget = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QualifyingLapData} returns this
 */
proto.QualifyingLapData.prototype.setDeltaToTarget = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double last_lap_time = 5;
 * @return {number}
 */
proto.QualifyingLapData.prototype.getLastLapTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QualifyingLapData} returns this
 */
proto.QualifyingLapData.prototype.setLastLapTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double best_lap_time = 6;
 * @return {number}
 */
proto.QualifyingLapData.prototype.getBestLapTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.QualifyingLapData} returns this
 */
proto.QualifyingLapData.prototype.setBestLapTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional int64 time_of_day = 7;
 * @return {number}
 */
proto.QualifyingLapData.prototype.getTimeOfDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.QualifyingLapData} returns this
 */
proto.QualifyingLapData.prototype.setTimeOfDay = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string run_name = 8;
 * @return {string}
 */
proto.QualifyingLapData.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.QualifyingLapData} returns this
 */
proto.QualifyingLapData.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool is_database_record = 9;
 * @return {boolean}
 */
proto.QualifyingLapData.prototype.getIsDatabaseRecord = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.QualifyingLapData} returns this
 */
proto.QualifyingLapData.prototype.setIsDatabaseRecord = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string vehicle_status = 10;
 * @return {string}
 */
proto.QualifyingLapData.prototype.getVehicleStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.QualifyingLapData} returns this
 */
proto.QualifyingLapData.prototype.setVehicleStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string message_type = 11;
 * @return {string}
 */
proto.QualifyingLapData.prototype.getMessageType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.QualifyingLapData} returns this
 */
proto.QualifyingLapData.prototype.setMessageType = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool is_start_finish = 12;
 * @return {boolean}
 */
proto.QualifyingLapData.prototype.getIsStartFinish = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.QualifyingLapData} returns this
 */
proto.QualifyingLapData.prototype.setIsStartFinish = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional int32 qualifying_position = 13;
 * @return {number}
 */
proto.QualifyingLapData.prototype.getQualifyingPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.QualifyingLapData} returns this
 */
proto.QualifyingLapData.prototype.setQualifyingPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 laps_completed = 14;
 * @return {number}
 */
proto.QualifyingLapData.prototype.getLapsCompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.QualifyingLapData} returns this
 */
proto.QualifyingLapData.prototype.setLapsCompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SessionTimingScoringVehicleMetaData.repeatedFields_ = [27];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.toObject = function(opt_includeInstance) {
  return proto.SessionTimingScoringVehicleMetaData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SessionTimingScoringVehicleMetaData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionTimingScoringVehicleMetaData.toObject = function(includeInstance, msg) {
  var f, obj = {
    lapsCompleted: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicleNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    runningPosition: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastLapTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    slowLap: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    pitLap: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    flag: jspb.Message.getFieldWithDefault(msg, 7, 0),
    greenYellowFlag: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    fastestPosition: jspb.Message.getFieldWithDefault(msg, 9, 0),
    driverFullName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    timeOfDay: jspb.Message.getFieldWithDefault(msg, 11, 0),
    deltaToTarget: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    lapsLed: jspb.Message.getFieldWithDefault(msg, 13, 0),
    bestLapTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    movingAverageLapTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    lapsToTarget: jspb.Message.getFieldWithDefault(msg, 16, 0),
    vehicleStatus: jspb.Message.getFieldWithDefault(msg, 17, ""),
    messageType: jspb.Message.getFieldWithDefault(msg, 18, ""),
    isStartFinish: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    avgLapTime: jspb.Message.getFieldWithDefault(msg, 20, 0),
    fastRank: jspb.Message.getFieldWithDefault(msg, 21, 0),
    avgRank: jspb.Message.getFieldWithDefault(msg, 22, 0),
    runName: jspb.Message.getFieldWithDefault(msg, 24, ""),
    qualifyingPosition: jspb.Message.getFieldWithDefault(msg, 25, 0),
    isDatabaseRecord: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    gapmetadataList: jspb.Message.toObjectList(msg.getGapmetadataList(),
    motorsports$data$common_pb.GapMetadata.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SessionTimingScoringVehicleMetaData}
 */
proto.SessionTimingScoringVehicleMetaData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SessionTimingScoringVehicleMetaData;
  return proto.SessionTimingScoringVehicleMetaData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SessionTimingScoringVehicleMetaData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SessionTimingScoringVehicleMetaData}
 */
proto.SessionTimingScoringVehicleMetaData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapsCompleted(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRunningPosition(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastLapTime(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSlowLap(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPitLap(value);
      break;
    case 7:
      var value = /** @type {!proto.FlagType} */ (reader.readEnum());
      msg.setFlag(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGreenYellowFlag(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFastestPosition(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverFullName(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeOfDay(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeltaToTarget(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapsLed(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestLapTime(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMovingAverageLapTime(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapsToTarget(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleStatus(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageType(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsStartFinish(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAvgLapTime(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFastRank(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgRank(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQualifyingPosition(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDatabaseRecord(value);
      break;
    case 27:
      var value = new motorsports$data$common_pb.GapMetadata;
      reader.readMessage(value,motorsports$data$common_pb.GapMetadata.deserializeBinaryFromReader);
      msg.addGapmetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SessionTimingScoringVehicleMetaData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SessionTimingScoringVehicleMetaData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionTimingScoringVehicleMetaData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLapsCompleted();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVehicleNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRunningPosition();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLastLapTime();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getSlowLap();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPitLap();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getFlag();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getGreenYellowFlag();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getFastestPosition();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getDriverFullName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTimeOfDay();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getDeltaToTarget();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getLapsLed();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getBestLapTime();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getMovingAverageLapTime();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getLapsToTarget();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getVehicleStatus();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getMessageType();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getIsStartFinish();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getAvgLapTime();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getFastRank();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getAvgRank();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getQualifyingPosition();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getIsDatabaseRecord();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getGapmetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      27,
      f,
      motorsports$data$common_pb.GapMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 laps_completed = 1;
 * @return {number}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getLapsCompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setLapsCompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string vehicle_number = 2;
 * @return {string}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getVehicleNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setVehicleNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 running_position = 3;
 * @return {number}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getRunningPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setRunningPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double last_lap_time = 4;
 * @return {number}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getLastLapTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setLastLapTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional bool slow_lap = 5;
 * @return {boolean}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getSlowLap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setSlowLap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool pit_lap = 6;
 * @return {boolean}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getPitLap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setPitLap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional FlagType flag = 7;
 * @return {!proto.FlagType}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getFlag = function() {
  return /** @type {!proto.FlagType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.FlagType} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setFlag = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional bool green_yellow_flag = 8;
 * @return {boolean}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getGreenYellowFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setGreenYellowFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 fastest_position = 9;
 * @return {number}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getFastestPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setFastestPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string driver_full_name = 10;
 * @return {string}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getDriverFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setDriverFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 time_of_day = 11;
 * @return {number}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getTimeOfDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setTimeOfDay = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional double delta_to_target = 12;
 * @return {number}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getDeltaToTarget = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setDeltaToTarget = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional int32 laps_led = 13;
 * @return {number}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getLapsLed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setLapsLed = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional double best_lap_time = 14;
 * @return {number}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getBestLapTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setBestLapTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double moving_average_lap_time = 15;
 * @return {number}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getMovingAverageLapTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setMovingAverageLapTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional int32 laps_to_target = 16;
 * @return {number}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getLapsToTarget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setLapsToTarget = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string vehicle_status = 17;
 * @return {string}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getVehicleStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setVehicleStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string message_type = 18;
 * @return {string}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getMessageType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setMessageType = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional bool is_start_finish = 19;
 * @return {boolean}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getIsStartFinish = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setIsStartFinish = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional int64 avg_lap_time = 20;
 * @return {number}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getAvgLapTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setAvgLapTime = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int32 fast_rank = 21;
 * @return {number}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getFastRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setFastRank = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int32 avg_rank = 22;
 * @return {number}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getAvgRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setAvgRank = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional string run_name = 24;
 * @return {string}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional int32 qualifying_position = 25;
 * @return {number}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getQualifyingPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setQualifyingPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional bool is_database_record = 26;
 * @return {boolean}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getIsDatabaseRecord = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.setIsDatabaseRecord = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * repeated GapMetadata gapMetadata = 27;
 * @return {!Array<!proto.GapMetadata>}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.getGapmetadataList = function() {
  return /** @type{!Array<!proto.GapMetadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, motorsports$data$common_pb.GapMetadata, 27));
};


/**
 * @param {!Array<!proto.GapMetadata>} value
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
*/
proto.SessionTimingScoringVehicleMetaData.prototype.setGapmetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 27, value);
};


/**
 * @param {!proto.GapMetadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GapMetadata}
 */
proto.SessionTimingScoringVehicleMetaData.prototype.addGapmetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 27, opt_value, proto.GapMetadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SessionTimingScoringVehicleMetaData} returns this
 */
proto.SessionTimingScoringVehicleMetaData.prototype.clearGapmetadataList = function() {
  return this.setGapmetadataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PreviousLapMetaData.prototype.toObject = function(opt_includeInstance) {
  return proto.PreviousLapMetaData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PreviousLapMetaData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PreviousLapMetaData.toObject = function(includeInstance, msg) {
  var f, obj = {
    lap: jspb.Message.getFieldWithDefault(msg, 1, 0),
    runningPosition: jspb.Message.getFieldWithDefault(msg, 2, 0),
    flag: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lapTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    slowLap: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    greenYellowFlag: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    pitLap: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PreviousLapMetaData}
 */
proto.PreviousLapMetaData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PreviousLapMetaData;
  return proto.PreviousLapMetaData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PreviousLapMetaData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PreviousLapMetaData}
 */
proto.PreviousLapMetaData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLap(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRunningPosition(value);
      break;
    case 3:
      var value = /** @type {!proto.FlagType} */ (reader.readEnum());
      msg.setFlag(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLapTime(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSlowLap(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGreenYellowFlag(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPitLap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PreviousLapMetaData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PreviousLapMetaData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PreviousLapMetaData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PreviousLapMetaData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLap();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRunningPosition();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFlag();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getLapTime();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getSlowLap();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getGreenYellowFlag();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPitLap();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int32 lap = 1;
 * @return {number}
 */
proto.PreviousLapMetaData.prototype.getLap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.PreviousLapMetaData} returns this
 */
proto.PreviousLapMetaData.prototype.setLap = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 running_position = 2;
 * @return {number}
 */
proto.PreviousLapMetaData.prototype.getRunningPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.PreviousLapMetaData} returns this
 */
proto.PreviousLapMetaData.prototype.setRunningPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional FlagType flag = 3;
 * @return {!proto.FlagType}
 */
proto.PreviousLapMetaData.prototype.getFlag = function() {
  return /** @type {!proto.FlagType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.FlagType} value
 * @return {!proto.PreviousLapMetaData} returns this
 */
proto.PreviousLapMetaData.prototype.setFlag = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional double lap_time = 4;
 * @return {number}
 */
proto.PreviousLapMetaData.prototype.getLapTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PreviousLapMetaData} returns this
 */
proto.PreviousLapMetaData.prototype.setLapTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional bool slow_lap = 5;
 * @return {boolean}
 */
proto.PreviousLapMetaData.prototype.getSlowLap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PreviousLapMetaData} returns this
 */
proto.PreviousLapMetaData.prototype.setSlowLap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool green_yellow_flag = 6;
 * @return {boolean}
 */
proto.PreviousLapMetaData.prototype.getGreenYellowFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PreviousLapMetaData} returns this
 */
proto.PreviousLapMetaData.prototype.setGreenYellowFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool pit_lap = 7;
 * @return {boolean}
 */
proto.PreviousLapMetaData.prototype.getPitLap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PreviousLapMetaData} returns this
 */
proto.PreviousLapMetaData.prototype.setPitLap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DriverVehicleSeriesData.prototype.toObject = function(opt_includeInstance) {
  return proto.DriverVehicleSeriesData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DriverVehicleSeriesData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DriverVehicleSeriesData.toObject = function(includeInstance, msg) {
  var f, obj = {
    driverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vehicleNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    driverName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    manufacturer: jspb.Message.getFieldWithDefault(msg, 4, ""),
    seriesId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DriverVehicleSeriesData}
 */
proto.DriverVehicleSeriesData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DriverVehicleSeriesData;
  return proto.DriverVehicleSeriesData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DriverVehicleSeriesData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DriverVehicleSeriesData}
 */
proto.DriverVehicleSeriesData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setManufacturer(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeriesId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DriverVehicleSeriesData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DriverVehicleSeriesData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DriverVehicleSeriesData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DriverVehicleSeriesData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVehicleNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDriverName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getManufacturer();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSeriesId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string driver_id = 1;
 * @return {string}
 */
proto.DriverVehicleSeriesData.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DriverVehicleSeriesData} returns this
 */
proto.DriverVehicleSeriesData.prototype.setDriverId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string vehicle_number = 2;
 * @return {string}
 */
proto.DriverVehicleSeriesData.prototype.getVehicleNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DriverVehicleSeriesData} returns this
 */
proto.DriverVehicleSeriesData.prototype.setVehicleNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string driver_name = 3;
 * @return {string}
 */
proto.DriverVehicleSeriesData.prototype.getDriverName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.DriverVehicleSeriesData} returns this
 */
proto.DriverVehicleSeriesData.prototype.setDriverName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string manufacturer = 4;
 * @return {string}
 */
proto.DriverVehicleSeriesData.prototype.getManufacturer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.DriverVehicleSeriesData} returns this
 */
proto.DriverVehicleSeriesData.prototype.setManufacturer = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string series_id = 5;
 * @return {string}
 */
proto.DriverVehicleSeriesData.prototype.getSeriesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.DriverVehicleSeriesData} returns this
 */
proto.DriverVehicleSeriesData.prototype.setSeriesId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PitRoadAnalysis.prototype.toObject = function(opt_includeInstance) {
  return proto.PitRoadAnalysis.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PitRoadAnalysis} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitRoadAnalysis.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    driverFullName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bestTotalFourTires: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    bestTotalTwoTires: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    bestStopTwoTires: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    bestStopFourTires: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    avgStopTwoTires: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    avgStopFourTires: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    bestDriverTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    avgDriverTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    bestCrewTwoTires: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    bestCrewFourTires: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    avgCrewTwoTires: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    avgCrewFourTires: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    bestJackLeftTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    bestJackRightTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    avgJackLeftTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    avgJackRightTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    bestJackManTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    avgJackManTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PitRoadAnalysis}
 */
proto.PitRoadAnalysis.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PitRoadAnalysis;
  return proto.PitRoadAnalysis.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PitRoadAnalysis} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PitRoadAnalysis}
 */
proto.PitRoadAnalysis.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverFullName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestTotalFourTires(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestTotalTwoTires(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestStopTwoTires(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestStopFourTires(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgStopTwoTires(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgStopFourTires(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestDriverTime(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgDriverTime(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestCrewTwoTires(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestCrewFourTires(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgCrewTwoTires(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgCrewFourTires(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestJackLeftTime(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestJackRightTime(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgJackLeftTime(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgJackRightTime(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestJackManTime(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgJackManTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PitRoadAnalysis.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PitRoadAnalysis.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PitRoadAnalysis} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitRoadAnalysis.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDriverFullName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBestTotalFourTires();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getBestTotalTwoTires();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getBestStopTwoTires();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getBestStopFourTires();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getAvgStopTwoTires();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getAvgStopFourTires();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getBestDriverTime();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getAvgDriverTime();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getBestCrewTwoTires();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getBestCrewFourTires();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getAvgCrewTwoTires();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getAvgCrewFourTires();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getBestJackLeftTime();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getBestJackRightTime();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getAvgJackLeftTime();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getAvgJackRightTime();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getBestJackManTime();
  if (f !== 0.0) {
    writer.writeDouble(
      19,
      f
    );
  }
  f = message.getAvgJackManTime();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
};


/**
 * optional string vehicle_number = 1;
 * @return {string}
 */
proto.PitRoadAnalysis.prototype.getVehicleNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setVehicleNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string driver_full_name = 2;
 * @return {string}
 */
proto.PitRoadAnalysis.prototype.getDriverFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setDriverFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double best_total_four_tires = 3;
 * @return {number}
 */
proto.PitRoadAnalysis.prototype.getBestTotalFourTires = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setBestTotalFourTires = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double best_total_two_tires = 4;
 * @return {number}
 */
proto.PitRoadAnalysis.prototype.getBestTotalTwoTires = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setBestTotalTwoTires = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double best_stop_two_tires = 5;
 * @return {number}
 */
proto.PitRoadAnalysis.prototype.getBestStopTwoTires = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setBestStopTwoTires = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double best_stop_four_tires = 6;
 * @return {number}
 */
proto.PitRoadAnalysis.prototype.getBestStopFourTires = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setBestStopFourTires = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double avg_stop_two_tires = 7;
 * @return {number}
 */
proto.PitRoadAnalysis.prototype.getAvgStopTwoTires = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setAvgStopTwoTires = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double avg_stop_four_tires = 8;
 * @return {number}
 */
proto.PitRoadAnalysis.prototype.getAvgStopFourTires = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setAvgStopFourTires = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double best_driver_time = 9;
 * @return {number}
 */
proto.PitRoadAnalysis.prototype.getBestDriverTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setBestDriverTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double avg_driver_time = 10;
 * @return {number}
 */
proto.PitRoadAnalysis.prototype.getAvgDriverTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setAvgDriverTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double best_crew_two_tires = 11;
 * @return {number}
 */
proto.PitRoadAnalysis.prototype.getBestCrewTwoTires = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setBestCrewTwoTires = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double best_crew_four_tires = 12;
 * @return {number}
 */
proto.PitRoadAnalysis.prototype.getBestCrewFourTires = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setBestCrewFourTires = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double avg_crew_two_tires = 13;
 * @return {number}
 */
proto.PitRoadAnalysis.prototype.getAvgCrewTwoTires = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setAvgCrewTwoTires = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double avg_crew_four_tires = 14;
 * @return {number}
 */
proto.PitRoadAnalysis.prototype.getAvgCrewFourTires = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setAvgCrewFourTires = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double best_jack_left_time = 15;
 * @return {number}
 */
proto.PitRoadAnalysis.prototype.getBestJackLeftTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setBestJackLeftTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional double best_jack_right_time = 16;
 * @return {number}
 */
proto.PitRoadAnalysis.prototype.getBestJackRightTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setBestJackRightTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional double avg_jack_left_time = 17;
 * @return {number}
 */
proto.PitRoadAnalysis.prototype.getAvgJackLeftTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setAvgJackLeftTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional double avg_jack_right_time = 18;
 * @return {number}
 */
proto.PitRoadAnalysis.prototype.getAvgJackRightTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setAvgJackRightTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional double best_jack_man_time = 19;
 * @return {number}
 */
proto.PitRoadAnalysis.prototype.getBestJackManTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setBestJackManTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional double avg_jack_man_time = 20;
 * @return {number}
 */
proto.PitRoadAnalysis.prototype.getAvgJackManTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitRoadAnalysis} returns this
 */
proto.PitRoadAnalysis.prototype.setAvgJackManTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SanctioningBody.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SanctioningBody.prototype.toObject = function(opt_includeInstance) {
  return proto.SanctioningBody.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SanctioningBody} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SanctioningBody.toObject = function(includeInstance, msg) {
  var f, obj = {
    sanctioningBodyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sanctioningBodyName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sanctioningBodySeriesIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    sourceTypesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SanctioningBody}
 */
proto.SanctioningBody.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SanctioningBody;
  return proto.SanctioningBody.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SanctioningBody} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SanctioningBody}
 */
proto.SanctioningBody.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSanctioningBodyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSanctioningBodyName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addSanctioningBodySeriesIds(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.OriginType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSourceTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SanctioningBody.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SanctioningBody.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SanctioningBody} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SanctioningBody.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSanctioningBodyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSanctioningBodyName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSanctioningBodySeriesIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getSourceTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * optional string sanctioning_body_id = 1;
 * @return {string}
 */
proto.SanctioningBody.prototype.getSanctioningBodyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SanctioningBody} returns this
 */
proto.SanctioningBody.prototype.setSanctioningBodyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sanctioning_body_name = 2;
 * @return {string}
 */
proto.SanctioningBody.prototype.getSanctioningBodyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SanctioningBody} returns this
 */
proto.SanctioningBody.prototype.setSanctioningBodyName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string sanctioning_body_series_ids = 3;
 * @return {!Array<string>}
 */
proto.SanctioningBody.prototype.getSanctioningBodySeriesIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.SanctioningBody} returns this
 */
proto.SanctioningBody.prototype.setSanctioningBodySeriesIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.SanctioningBody} returns this
 */
proto.SanctioningBody.prototype.addSanctioningBodySeriesIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SanctioningBody} returns this
 */
proto.SanctioningBody.prototype.clearSanctioningBodySeriesIdsList = function() {
  return this.setSanctioningBodySeriesIdsList([]);
};


/**
 * repeated OriginType source_types = 4;
 * @return {!Array<!proto.OriginType>}
 */
proto.SanctioningBody.prototype.getSourceTypesList = function() {
  return /** @type {!Array<!proto.OriginType>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.OriginType>} value
 * @return {!proto.SanctioningBody} returns this
 */
proto.SanctioningBody.prototype.setSourceTypesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.OriginType} value
 * @param {number=} opt_index
 * @return {!proto.SanctioningBody} returns this
 */
proto.SanctioningBody.prototype.addSourceTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SanctioningBody} returns this
 */
proto.SanctioningBody.prototype.clearSourceTypesList = function() {
  return this.setSourceTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SanctioningBodySeries.prototype.toObject = function(opt_includeInstance) {
  return proto.SanctioningBodySeries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SanctioningBodySeries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SanctioningBodySeries.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriesId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seriesName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SanctioningBodySeries}
 */
proto.SanctioningBodySeries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SanctioningBodySeries;
  return proto.SanctioningBodySeries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SanctioningBodySeries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SanctioningBodySeries}
 */
proto.SanctioningBodySeries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeriesId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeriesName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SanctioningBodySeries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SanctioningBodySeries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SanctioningBodySeries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SanctioningBodySeries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriesId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeriesName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string series_id = 1;
 * @return {string}
 */
proto.SanctioningBodySeries.prototype.getSeriesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SanctioningBodySeries} returns this
 */
proto.SanctioningBodySeries.prototype.setSeriesId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string series_name = 2;
 * @return {string}
 */
proto.SanctioningBodySeries.prototype.getSeriesName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SanctioningBodySeries} returns this
 */
proto.SanctioningBodySeries.prototype.setSeriesName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TrackTemperatureHistoricalListingRowData.prototype.toObject = function(opt_includeInstance) {
  return proto.TrackTemperatureHistoricalListingRowData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TrackTemperatureHistoricalListingRowData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureHistoricalListingRowData.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordingId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    trackName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    trackTimezone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    recordingDateRange: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TrackTemperatureHistoricalListingRowData}
 */
proto.TrackTemperatureHistoricalListingRowData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TrackTemperatureHistoricalListingRowData;
  return proto.TrackTemperatureHistoricalListingRowData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TrackTemperatureHistoricalListingRowData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TrackTemperatureHistoricalListingRowData}
 */
proto.TrackTemperatureHistoricalListingRowData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecordingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackTimezone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecordingDateRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TrackTemperatureHistoricalListingRowData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TrackTemperatureHistoricalListingRowData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TrackTemperatureHistoricalListingRowData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureHistoricalListingRowData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTrackName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTrackTimezone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRecordingDateRange();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string recording_id = 1;
 * @return {string}
 */
proto.TrackTemperatureHistoricalListingRowData.prototype.getRecordingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureHistoricalListingRowData} returns this
 */
proto.TrackTemperatureHistoricalListingRowData.prototype.setRecordingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string track_name = 2;
 * @return {string}
 */
proto.TrackTemperatureHistoricalListingRowData.prototype.getTrackName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureHistoricalListingRowData} returns this
 */
proto.TrackTemperatureHistoricalListingRowData.prototype.setTrackName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string track_timezone = 3;
 * @return {string}
 */
proto.TrackTemperatureHistoricalListingRowData.prototype.getTrackTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureHistoricalListingRowData} returns this
 */
proto.TrackTemperatureHistoricalListingRowData.prototype.setTrackTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string recording_date_range = 4;
 * @return {string}
 */
proto.TrackTemperatureHistoricalListingRowData.prototype.getRecordingDateRange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureHistoricalListingRowData} returns this
 */
proto.TrackTemperatureHistoricalListingRowData.prototype.setRecordingDateRange = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TrackTemperatureTabularRowData.prototype.toObject = function(opt_includeInstance) {
  return proto.TrackTemperatureTabularRowData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TrackTemperatureTabularRowData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureTabularRowData.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackRecordingName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    trackName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cameraName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cameraLocation: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cameraTakeTimestamp: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lowLineTemperature: jspb.Message.getFieldWithDefault(msg, 6, ""),
    middleLineTemperature: jspb.Message.getFieldWithDefault(msg, 7, ""),
    highLineTemperature: jspb.Message.getFieldWithDefault(msg, 8, ""),
    referenceTemperature: jspb.Message.getFieldWithDefault(msg, 9, ""),
    imageReferenceName: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TrackTemperatureTabularRowData}
 */
proto.TrackTemperatureTabularRowData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TrackTemperatureTabularRowData;
  return proto.TrackTemperatureTabularRowData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TrackTemperatureTabularRowData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TrackTemperatureTabularRowData}
 */
proto.TrackTemperatureTabularRowData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackRecordingName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraLocation(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraTakeTimestamp(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLowLineTemperature(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddleLineTemperature(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setHighLineTemperature(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceTemperature(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageReferenceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TrackTemperatureTabularRowData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TrackTemperatureTabularRowData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TrackTemperatureTabularRowData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureTabularRowData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackRecordingName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTrackName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCameraName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCameraLocation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCameraTakeTimestamp();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLowLineTemperature();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMiddleLineTemperature();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getHighLineTemperature();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getReferenceTemperature();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getImageReferenceName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string track_recording_name = 1;
 * @return {string}
 */
proto.TrackTemperatureTabularRowData.prototype.getTrackRecordingName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureTabularRowData} returns this
 */
proto.TrackTemperatureTabularRowData.prototype.setTrackRecordingName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string track_name = 2;
 * @return {string}
 */
proto.TrackTemperatureTabularRowData.prototype.getTrackName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureTabularRowData} returns this
 */
proto.TrackTemperatureTabularRowData.prototype.setTrackName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string camera_name = 3;
 * @return {string}
 */
proto.TrackTemperatureTabularRowData.prototype.getCameraName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureTabularRowData} returns this
 */
proto.TrackTemperatureTabularRowData.prototype.setCameraName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string camera_location = 4;
 * @return {string}
 */
proto.TrackTemperatureTabularRowData.prototype.getCameraLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureTabularRowData} returns this
 */
proto.TrackTemperatureTabularRowData.prototype.setCameraLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string camera_take_timestamp = 5;
 * @return {string}
 */
proto.TrackTemperatureTabularRowData.prototype.getCameraTakeTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureTabularRowData} returns this
 */
proto.TrackTemperatureTabularRowData.prototype.setCameraTakeTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string low_line_temperature = 6;
 * @return {string}
 */
proto.TrackTemperatureTabularRowData.prototype.getLowLineTemperature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureTabularRowData} returns this
 */
proto.TrackTemperatureTabularRowData.prototype.setLowLineTemperature = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string middle_line_temperature = 7;
 * @return {string}
 */
proto.TrackTemperatureTabularRowData.prototype.getMiddleLineTemperature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureTabularRowData} returns this
 */
proto.TrackTemperatureTabularRowData.prototype.setMiddleLineTemperature = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string high_line_temperature = 8;
 * @return {string}
 */
proto.TrackTemperatureTabularRowData.prototype.getHighLineTemperature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureTabularRowData} returns this
 */
proto.TrackTemperatureTabularRowData.prototype.setHighLineTemperature = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string reference_temperature = 9;
 * @return {string}
 */
proto.TrackTemperatureTabularRowData.prototype.getReferenceTemperature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureTabularRowData} returns this
 */
proto.TrackTemperatureTabularRowData.prototype.setReferenceTemperature = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string image_reference_name = 10;
 * @return {string}
 */
proto.TrackTemperatureTabularRowData.prototype.getImageReferenceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureTabularRowData} returns this
 */
proto.TrackTemperatureTabularRowData.prototype.setImageReferenceName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TrackTemperatureImageData.prototype.toObject = function(opt_includeInstance) {
  return proto.TrackTemperatureImageData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TrackTemperatureImageData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureImageData.toObject = function(includeInstance, msg) {
  var f, obj = {
    cameraName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    base64Image: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TrackTemperatureImageData}
 */
proto.TrackTemperatureImageData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TrackTemperatureImageData;
  return proto.TrackTemperatureImageData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TrackTemperatureImageData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TrackTemperatureImageData}
 */
proto.TrackTemperatureImageData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBase64Image(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TrackTemperatureImageData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TrackTemperatureImageData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TrackTemperatureImageData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureImageData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCameraName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBase64Image();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string camera_name = 1;
 * @return {string}
 */
proto.TrackTemperatureImageData.prototype.getCameraName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureImageData} returns this
 */
proto.TrackTemperatureImageData.prototype.setCameraName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string base64_image = 2;
 * @return {string}
 */
proto.TrackTemperatureImageData.prototype.getBase64Image = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureImageData} returns this
 */
proto.TrackTemperatureImageData.prototype.setBase64Image = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RequestCounter.prototype.toObject = function(opt_includeInstance) {
  return proto.RequestCounter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RequestCounter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequestCounter.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RequestCounter}
 */
proto.RequestCounter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RequestCounter;
  return proto.RequestCounter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RequestCounter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RequestCounter}
 */
proto.RequestCounter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RequestCounter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RequestCounter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RequestCounter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequestCounter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 count = 1;
 * @return {number}
 */
proto.RequestCounter.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RequestCounter} returns this
 */
proto.RequestCounter.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NascarTrailerWeatherHistoricalListingRowData.prototype.toObject = function(opt_includeInstance) {
  return proto.NascarTrailerWeatherHistoricalListingRowData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NascarTrailerWeatherHistoricalListingRowData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerWeatherHistoricalListingRowData.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordingId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    trackName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    trackTimezone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    recordingDateRange: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NascarTrailerWeatherHistoricalListingRowData}
 */
proto.NascarTrailerWeatherHistoricalListingRowData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NascarTrailerWeatherHistoricalListingRowData;
  return proto.NascarTrailerWeatherHistoricalListingRowData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NascarTrailerWeatherHistoricalListingRowData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NascarTrailerWeatherHistoricalListingRowData}
 */
proto.NascarTrailerWeatherHistoricalListingRowData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecordingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackTimezone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecordingDateRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NascarTrailerWeatherHistoricalListingRowData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NascarTrailerWeatherHistoricalListingRowData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NascarTrailerWeatherHistoricalListingRowData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerWeatherHistoricalListingRowData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTrackName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTrackTimezone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRecordingDateRange();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string recording_id = 1;
 * @return {string}
 */
proto.NascarTrailerWeatherHistoricalListingRowData.prototype.getRecordingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.NascarTrailerWeatherHistoricalListingRowData} returns this
 */
proto.NascarTrailerWeatherHistoricalListingRowData.prototype.setRecordingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string track_name = 2;
 * @return {string}
 */
proto.NascarTrailerWeatherHistoricalListingRowData.prototype.getTrackName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.NascarTrailerWeatherHistoricalListingRowData} returns this
 */
proto.NascarTrailerWeatherHistoricalListingRowData.prototype.setTrackName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string track_timezone = 3;
 * @return {string}
 */
proto.NascarTrailerWeatherHistoricalListingRowData.prototype.getTrackTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.NascarTrailerWeatherHistoricalListingRowData} returns this
 */
proto.NascarTrailerWeatherHistoricalListingRowData.prototype.setTrackTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string recording_date_range = 4;
 * @return {string}
 */
proto.NascarTrailerWeatherHistoricalListingRowData.prototype.getRecordingDateRange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.NascarTrailerWeatherHistoricalListingRowData} returns this
 */
proto.NascarTrailerWeatherHistoricalListingRowData.prototype.setRecordingDateRange = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NascarTrailerIrTemp.prototype.toObject = function(opt_includeInstance) {
  return proto.NascarTrailerIrTemp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NascarTrailerIrTemp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerIrTemp.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    summaryhighline: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    summarymidline: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    summarylowline: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    summaryreference: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    pngimagedata: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    eventid: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NascarTrailerIrTemp}
 */
proto.NascarTrailerIrTemp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NascarTrailerIrTemp;
  return proto.NascarTrailerIrTemp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NascarTrailerIrTemp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NascarTrailerIrTemp}
 */
proto.NascarTrailerIrTemp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationname(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSummaryhighline(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSummarymidline(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSummarylowline(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSummaryreference(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPngimagedata(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NascarTrailerIrTemp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NascarTrailerIrTemp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NascarTrailerIrTemp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerIrTemp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getSummaryhighline();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getSummarymidline();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getSummarylowline();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getSummaryreference();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getPngimagedata();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getEventid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string StationName = 1;
 * @return {string}
 */
proto.NascarTrailerIrTemp.prototype.getStationname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.NascarTrailerIrTemp} returns this
 */
proto.NascarTrailerIrTemp.prototype.setStationname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double Timestamp = 2;
 * @return {number}
 */
proto.NascarTrailerIrTemp.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerIrTemp} returns this
 */
proto.NascarTrailerIrTemp.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double SummaryHighLine = 3;
 * @return {number}
 */
proto.NascarTrailerIrTemp.prototype.getSummaryhighline = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerIrTemp} returns this
 */
proto.NascarTrailerIrTemp.prototype.setSummaryhighline = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double SummaryMidLine = 4;
 * @return {number}
 */
proto.NascarTrailerIrTemp.prototype.getSummarymidline = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerIrTemp} returns this
 */
proto.NascarTrailerIrTemp.prototype.setSummarymidline = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double SummaryLowLine = 5;
 * @return {number}
 */
proto.NascarTrailerIrTemp.prototype.getSummarylowline = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerIrTemp} returns this
 */
proto.NascarTrailerIrTemp.prototype.setSummarylowline = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double SummaryReference = 6;
 * @return {number}
 */
proto.NascarTrailerIrTemp.prototype.getSummaryreference = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerIrTemp} returns this
 */
proto.NascarTrailerIrTemp.prototype.setSummaryreference = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double PngImageData = 7;
 * @return {number}
 */
proto.NascarTrailerIrTemp.prototype.getPngimagedata = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerIrTemp} returns this
 */
proto.NascarTrailerIrTemp.prototype.setPngimagedata = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string EventId = 8;
 * @return {string}
 */
proto.NascarTrailerIrTemp.prototype.getEventid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.NascarTrailerIrTemp} returns this
 */
proto.NascarTrailerIrTemp.prototype.setEventid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SessionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.SessionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SessionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    runType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    seriesId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    clientId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sessionId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    eventName: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SessionInfo}
 */
proto.SessionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SessionInfo;
  return proto.SessionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SessionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SessionInfo}
 */
proto.SessionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {!proto.RunType} */ (reader.readEnum());
      msg.setRunType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeriesId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SessionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SessionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SessionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRunType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSeriesId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClientId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEventName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.SessionInfo.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionInfo} returns this
 */
proto.SessionInfo.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RunType run_type = 2;
 * @return {!proto.RunType}
 */
proto.SessionInfo.prototype.getRunType = function() {
  return /** @type {!proto.RunType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.RunType} value
 * @return {!proto.SessionInfo} returns this
 */
proto.SessionInfo.prototype.setRunType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string series_id = 3;
 * @return {string}
 */
proto.SessionInfo.prototype.getSeriesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionInfo} returns this
 */
proto.SessionInfo.prototype.setSeriesId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string client_id = 4;
 * @return {string}
 */
proto.SessionInfo.prototype.getClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionInfo} returns this
 */
proto.SessionInfo.prototype.setClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string session_id = 5;
 * @return {string}
 */
proto.SessionInfo.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionInfo} returns this
 */
proto.SessionInfo.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string event_name = 6;
 * @return {string}
 */
proto.SessionInfo.prototype.getEventName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionInfo} returns this
 */
proto.SessionInfo.prototype.setEventName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StreamRequestParams.prototype.toObject = function(opt_includeInstance) {
  return proto.StreamRequestParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StreamRequestParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StreamRequestParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    paramsSet: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StreamRequestParams}
 */
proto.StreamRequestParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StreamRequestParams;
  return proto.StreamRequestParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StreamRequestParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StreamRequestParams}
 */
proto.StreamRequestParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setParamsSet(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StreamRequestParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StreamRequestParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StreamRequestParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StreamRequestParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParamsSet();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool params_set = 1;
 * @return {boolean}
 */
proto.StreamRequestParams.prototype.getParamsSet = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.StreamRequestParams} returns this
 */
proto.StreamRequestParams.prototype.setParamsSet = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PitCycleTimeMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.PitCycleTimeMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PitCycleTimeMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitCycleTimeMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    bestPitInCycleTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    averagePitInCycleTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    bestPitOutCycleTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    averagePitOutCycleTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    bestFullCycleTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    averageFullCycleTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    bestFourTireFullCycleTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    averageFourTireFullCycleTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    bestTwoTireFullCycleTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    averageTwoTireFullCycleTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    bestFuelOnlyFullCycleTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    averageFuelOnlyFullCycleTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PitCycleTimeMetadata}
 */
proto.PitCycleTimeMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PitCycleTimeMetadata;
  return proto.PitCycleTimeMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PitCycleTimeMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PitCycleTimeMetadata}
 */
proto.PitCycleTimeMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestPitInCycleTime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAveragePitInCycleTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestPitOutCycleTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAveragePitOutCycleTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestFullCycleTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAverageFullCycleTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestFourTireFullCycleTime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAverageFourTireFullCycleTime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestTwoTireFullCycleTime(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAverageTwoTireFullCycleTime(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestFuelOnlyFullCycleTime(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAverageFuelOnlyFullCycleTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PitCycleTimeMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PitCycleTimeMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PitCycleTimeMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitCycleTimeMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBestPitInCycleTime();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getAveragePitInCycleTime();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getBestPitOutCycleTime();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getAveragePitOutCycleTime();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getBestFullCycleTime();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getAverageFullCycleTime();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getBestFourTireFullCycleTime();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getAverageFourTireFullCycleTime();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getBestTwoTireFullCycleTime();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getAverageTwoTireFullCycleTime();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getBestFuelOnlyFullCycleTime();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getAverageFuelOnlyFullCycleTime();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
};


/**
 * optional double best_pit_in_cycle_time = 1;
 * @return {number}
 */
proto.PitCycleTimeMetadata.prototype.getBestPitInCycleTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleTimeMetadata} returns this
 */
proto.PitCycleTimeMetadata.prototype.setBestPitInCycleTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double average_pit_in_cycle_time = 2;
 * @return {number}
 */
proto.PitCycleTimeMetadata.prototype.getAveragePitInCycleTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleTimeMetadata} returns this
 */
proto.PitCycleTimeMetadata.prototype.setAveragePitInCycleTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double best_pit_out_cycle_time = 3;
 * @return {number}
 */
proto.PitCycleTimeMetadata.prototype.getBestPitOutCycleTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleTimeMetadata} returns this
 */
proto.PitCycleTimeMetadata.prototype.setBestPitOutCycleTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double average_pit_out_cycle_time = 4;
 * @return {number}
 */
proto.PitCycleTimeMetadata.prototype.getAveragePitOutCycleTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleTimeMetadata} returns this
 */
proto.PitCycleTimeMetadata.prototype.setAveragePitOutCycleTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double best_full_cycle_time = 5;
 * @return {number}
 */
proto.PitCycleTimeMetadata.prototype.getBestFullCycleTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleTimeMetadata} returns this
 */
proto.PitCycleTimeMetadata.prototype.setBestFullCycleTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double average_full_cycle_time = 6;
 * @return {number}
 */
proto.PitCycleTimeMetadata.prototype.getAverageFullCycleTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleTimeMetadata} returns this
 */
proto.PitCycleTimeMetadata.prototype.setAverageFullCycleTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double best_four_tire_full_cycle_time = 7;
 * @return {number}
 */
proto.PitCycleTimeMetadata.prototype.getBestFourTireFullCycleTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleTimeMetadata} returns this
 */
proto.PitCycleTimeMetadata.prototype.setBestFourTireFullCycleTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double average_four_tire_full_cycle_time = 8;
 * @return {number}
 */
proto.PitCycleTimeMetadata.prototype.getAverageFourTireFullCycleTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleTimeMetadata} returns this
 */
proto.PitCycleTimeMetadata.prototype.setAverageFourTireFullCycleTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double best_two_tire_full_cycle_time = 9;
 * @return {number}
 */
proto.PitCycleTimeMetadata.prototype.getBestTwoTireFullCycleTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleTimeMetadata} returns this
 */
proto.PitCycleTimeMetadata.prototype.setBestTwoTireFullCycleTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double average_two_tire_full_cycle_time = 10;
 * @return {number}
 */
proto.PitCycleTimeMetadata.prototype.getAverageTwoTireFullCycleTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleTimeMetadata} returns this
 */
proto.PitCycleTimeMetadata.prototype.setAverageTwoTireFullCycleTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double best_fuel_only_full_cycle_time = 11;
 * @return {number}
 */
proto.PitCycleTimeMetadata.prototype.getBestFuelOnlyFullCycleTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleTimeMetadata} returns this
 */
proto.PitCycleTimeMetadata.prototype.setBestFuelOnlyFullCycleTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double average_fuel_only_full_cycle_time = 12;
 * @return {number}
 */
proto.PitCycleTimeMetadata.prototype.getAverageFuelOnlyFullCycleTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleTimeMetadata} returns this
 */
proto.PitCycleTimeMetadata.prototype.setAverageFuelOnlyFullCycleTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NascarTrailerWeatherData.prototype.toObject = function(opt_includeInstance) {
  return proto.NascarTrailerWeatherData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NascarTrailerWeatherData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerWeatherData.toObject = function(includeInstance, msg) {
  var f, obj = {
    looHeader: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    barTrend: jspb.Message.getFieldWithDefault(msg, 2, 0),
    packetType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    barometer: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    insideTemperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    outsideTemperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    windSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    windDirection: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    tenMinAvgWindSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    twoMinAvgWindSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    windDirTenMinWindGust: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    barometricSensorRawReading: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    dewPoint: jspb.Message.getFieldWithDefault(msg, 13, 0),
    outsideHumidity: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    heatIndex: jspb.Message.getFieldWithDefault(msg, 15, 0),
    windChill: jspb.Message.getFieldWithDefault(msg, 16, 0),
    thswIndex: jspb.Message.getFieldWithDefault(msg, 17, 0),
    rainRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    uvIndex: jspb.Message.getFieldWithDefault(msg, 19, 0),
    solarRadiation: jspb.Message.getFieldWithDefault(msg, 20, 0),
    stormRain: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    startDateOfStorm: jspb.Message.getFieldWithDefault(msg, 22, ""),
    dailyRain: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    last15MinRain: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    lastHourRain: jspb.Message.getFloatingPointFieldWithDefault(msg, 25, 0.0),
    dailyEt: jspb.Message.getFloatingPointFieldWithDefault(msg, 26, 0.0),
    last24HourRain: jspb.Message.getFloatingPointFieldWithDefault(msg, 27, 0.0),
    barometricReductionMethod: jspb.Message.getFieldWithDefault(msg, 28, 0),
    userBarometricOffset: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0),
    barometricCalibrationNumber: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
    absoluteBarometricPressure: jspb.Message.getFloatingPointFieldWithDefault(msg, 31, 0.0),
    altimeterSetting: jspb.Message.getFloatingPointFieldWithDefault(msg, 32, 0.0),
    adr: jspb.Message.getFloatingPointFieldWithDefault(msg, 33, 0.0),
    tenMinWindGust: jspb.Message.getFloatingPointFieldWithDefault(msg, 34, 0.0),
    insideHumidity: jspb.Message.getFloatingPointFieldWithDefault(msg, 35, 0.0),
    saeCorrectionFactor: jspb.Message.getFloatingPointFieldWithDefault(msg, 36, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NascarTrailerWeatherData}
 */
proto.NascarTrailerWeatherData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NascarTrailerWeatherData;
  return proto.NascarTrailerWeatherData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NascarTrailerWeatherData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NascarTrailerWeatherData}
 */
proto.NascarTrailerWeatherData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLooHeader(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBarTrend(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPacketType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBarometer(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInsideTemperature(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOutsideTemperature(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWindSpeed(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWindDirection(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTenMinAvgWindSpeed(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTwoMinAvgWindSpeed(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWindDirTenMinWindGust(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBarometricSensorRawReading(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDewPoint(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOutsideHumidity(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeatIndex(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWindChill(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setThswIndex(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRainRate(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUvIndex(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSolarRadiation(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setStormRain(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDateOfStorm(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDailyRain(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLast15MinRain(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastHourRain(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDailyEt(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLast24HourRain(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBarometricReductionMethod(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setUserBarometricOffset(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBarometricCalibrationNumber(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAbsoluteBarometricPressure(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAltimeterSetting(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAdr(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTenMinWindGust(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInsideHumidity(value);
      break;
    case 36:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSaeCorrectionFactor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NascarTrailerWeatherData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NascarTrailerWeatherData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NascarTrailerWeatherData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerWeatherData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLooHeader();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getBarTrend();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPacketType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBarometer();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getInsideTemperature();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getOutsideTemperature();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getWindSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getWindDirection();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getTenMinAvgWindSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getTwoMinAvgWindSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getWindDirTenMinWindGust();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getBarometricSensorRawReading();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getDewPoint();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getOutsideHumidity();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getHeatIndex();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getWindChill();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getThswIndex();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getRainRate();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getUvIndex();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getSolarRadiation();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getStormRain();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
  f = message.getStartDateOfStorm();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getDailyRain();
  if (f !== 0.0) {
    writer.writeDouble(
      23,
      f
    );
  }
  f = message.getLast15MinRain();
  if (f !== 0.0) {
    writer.writeDouble(
      24,
      f
    );
  }
  f = message.getLastHourRain();
  if (f !== 0.0) {
    writer.writeDouble(
      25,
      f
    );
  }
  f = message.getDailyEt();
  if (f !== 0.0) {
    writer.writeDouble(
      26,
      f
    );
  }
  f = message.getLast24HourRain();
  if (f !== 0.0) {
    writer.writeDouble(
      27,
      f
    );
  }
  f = message.getBarometricReductionMethod();
  if (f !== 0) {
    writer.writeInt32(
      28,
      f
    );
  }
  f = message.getUserBarometricOffset();
  if (f !== 0.0) {
    writer.writeDouble(
      29,
      f
    );
  }
  f = message.getBarometricCalibrationNumber();
  if (f !== 0.0) {
    writer.writeDouble(
      30,
      f
    );
  }
  f = message.getAbsoluteBarometricPressure();
  if (f !== 0.0) {
    writer.writeDouble(
      31,
      f
    );
  }
  f = message.getAltimeterSetting();
  if (f !== 0.0) {
    writer.writeDouble(
      32,
      f
    );
  }
  f = message.getAdr();
  if (f !== 0.0) {
    writer.writeDouble(
      33,
      f
    );
  }
  f = message.getTenMinWindGust();
  if (f !== 0.0) {
    writer.writeDouble(
      34,
      f
    );
  }
  f = message.getInsideHumidity();
  if (f !== 0.0) {
    writer.writeDouble(
      35,
      f
    );
  }
  f = message.getSaeCorrectionFactor();
  if (f !== 0.0) {
    writer.writeDouble(
      36,
      f
    );
  }
};


/**
 * optional bool loo_header = 1;
 * @return {boolean}
 */
proto.NascarTrailerWeatherData.prototype.getLooHeader = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setLooHeader = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 bar_trend = 2;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getBarTrend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setBarTrend = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string packet_type = 3;
 * @return {string}
 */
proto.NascarTrailerWeatherData.prototype.getPacketType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setPacketType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double barometer = 4;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getBarometer = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setBarometer = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double inside_temperature = 5;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getInsideTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setInsideTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double outside_temperature = 6;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getOutsideTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setOutsideTemperature = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double wind_speed = 7;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getWindSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setWindSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double wind_direction = 8;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getWindDirection = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setWindDirection = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double ten_min_avg_wind_speed = 9;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getTenMinAvgWindSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setTenMinAvgWindSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double two_min_avg_wind_speed = 10;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getTwoMinAvgWindSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setTwoMinAvgWindSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double wind_dir_ten_min_wind_gust = 11;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getWindDirTenMinWindGust = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setWindDirTenMinWindGust = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double barometric_sensor_raw_reading = 12;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getBarometricSensorRawReading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setBarometricSensorRawReading = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional int32 dew_point = 13;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getDewPoint = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setDewPoint = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional double outside_humidity = 14;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getOutsideHumidity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setOutsideHumidity = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional int32 heat_index = 15;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getHeatIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setHeatIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 wind_chill = 16;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getWindChill = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setWindChill = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 thsw_index = 17;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getThswIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setThswIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional double rain_rate = 18;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getRainRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setRainRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional int32 uv_index = 19;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getUvIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setUvIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 solar_radiation = 20;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getSolarRadiation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setSolarRadiation = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional double storm_rain = 21;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getStormRain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setStormRain = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional string start_date_of_storm = 22;
 * @return {string}
 */
proto.NascarTrailerWeatherData.prototype.getStartDateOfStorm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setStartDateOfStorm = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional double daily_rain = 23;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getDailyRain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setDailyRain = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional double last_15_min_rain = 24;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getLast15MinRain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setLast15MinRain = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional double last_hour_rain = 25;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getLastHourRain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 25, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setLastHourRain = function(value) {
  return jspb.Message.setProto3FloatField(this, 25, value);
};


/**
 * optional double daily_et = 26;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getDailyEt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 26, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setDailyEt = function(value) {
  return jspb.Message.setProto3FloatField(this, 26, value);
};


/**
 * optional double last_24_hour_rain = 27;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getLast24HourRain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 27, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setLast24HourRain = function(value) {
  return jspb.Message.setProto3FloatField(this, 27, value);
};


/**
 * optional int32 barometric_reduction_method = 28;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getBarometricReductionMethod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setBarometricReductionMethod = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional double user_barometric_offset = 29;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getUserBarometricOffset = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setUserBarometricOffset = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};


/**
 * optional double barometric_calibration_number = 30;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getBarometricCalibrationNumber = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setBarometricCalibrationNumber = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional double absolute_barometric_pressure = 31;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getAbsoluteBarometricPressure = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 31, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setAbsoluteBarometricPressure = function(value) {
  return jspb.Message.setProto3FloatField(this, 31, value);
};


/**
 * optional double altimeter_setting = 32;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getAltimeterSetting = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 32, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setAltimeterSetting = function(value) {
  return jspb.Message.setProto3FloatField(this, 32, value);
};


/**
 * optional double adr = 33;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getAdr = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 33, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setAdr = function(value) {
  return jspb.Message.setProto3FloatField(this, 33, value);
};


/**
 * optional double ten_min_wind_gust = 34;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getTenMinWindGust = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 34, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setTenMinWindGust = function(value) {
  return jspb.Message.setProto3FloatField(this, 34, value);
};


/**
 * optional double inside_humidity = 35;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getInsideHumidity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 35, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setInsideHumidity = function(value) {
  return jspb.Message.setProto3FloatField(this, 35, value);
};


/**
 * optional double sae_correction_factor = 36;
 * @return {number}
 */
proto.NascarTrailerWeatherData.prototype.getSaeCorrectionFactor = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 36, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherData} returns this
 */
proto.NascarTrailerWeatherData.prototype.setSaeCorrectionFactor = function(value) {
  return jspb.Message.setProto3FloatField(this, 36, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NascarTrailerWeather.prototype.toObject = function(opt_includeInstance) {
  return proto.NascarTrailerWeather.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NascarTrailerWeather} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerWeather.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
    station: jspb.Message.getFieldWithDefault(msg, 4, ""),
    data: (f = msg.getData()) && proto.NascarTrailerWeatherData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NascarTrailerWeather}
 */
proto.NascarTrailerWeather.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NascarTrailerWeather;
  return proto.NascarTrailerWeather.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NascarTrailerWeather} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NascarTrailerWeather}
 */
proto.NascarTrailerWeather.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStation(value);
      break;
    case 5:
      var value = new proto.NascarTrailerWeatherData;
      reader.readMessage(value,proto.NascarTrailerWeatherData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NascarTrailerWeather.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NascarTrailerWeather.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NascarTrailerWeather} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerWeather.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getStation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.NascarTrailerWeatherData.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 _id = 1;
 * @return {number}
 */
proto.NascarTrailerWeather.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeather} returns this
 */
proto.NascarTrailerWeather.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.NascarTrailerWeather.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.NascarTrailerWeather} returns this
 */
proto.NascarTrailerWeather.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 timestamp = 3;
 * @return {number}
 */
proto.NascarTrailerWeather.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeather} returns this
 */
proto.NascarTrailerWeather.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string station = 4;
 * @return {string}
 */
proto.NascarTrailerWeather.prototype.getStation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.NascarTrailerWeather} returns this
 */
proto.NascarTrailerWeather.prototype.setStation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional NascarTrailerWeatherData data = 5;
 * @return {?proto.NascarTrailerWeatherData}
 */
proto.NascarTrailerWeather.prototype.getData = function() {
  return /** @type{?proto.NascarTrailerWeatherData} */ (
    jspb.Message.getWrapperField(this, proto.NascarTrailerWeatherData, 5));
};


/**
 * @param {?proto.NascarTrailerWeatherData|undefined} value
 * @return {!proto.NascarTrailerWeather} returns this
*/
proto.NascarTrailerWeather.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.NascarTrailerWeather} returns this
 */
proto.NascarTrailerWeather.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.NascarTrailerWeather.prototype.hasData = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IndyWindData.prototype.toObject = function(opt_includeInstance) {
  return proto.IndyWindData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IndyWindData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndyWindData.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, 0),
    windSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    adjWindDir: jspb.Message.getFieldWithDefault(msg, 4, 0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IndyWindData}
 */
proto.IndyWindData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IndyWindData;
  return proto.IndyWindData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IndyWindData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IndyWindData}
 */
proto.IndyWindData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWindSpeed(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdjWindDir(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IndyWindData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IndyWindData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IndyWindData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndyWindData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getWindSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getAdjWindDir();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional string station_id = 1;
 * @return {string}
 */
proto.IndyWindData.prototype.getStationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndyWindData} returns this
 */
proto.IndyWindData.prototype.setStationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 timestamp = 2;
 * @return {number}
 */
proto.IndyWindData.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.IndyWindData} returns this
 */
proto.IndyWindData.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double wind_speed = 3;
 * @return {number}
 */
proto.IndyWindData.prototype.getWindSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndyWindData} returns this
 */
proto.IndyWindData.prototype.setWindSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 adj_wind_dir = 4;
 * @return {number}
 */
proto.IndyWindData.prototype.getAdjWindDir = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.IndyWindData} returns this
 */
proto.IndyWindData.prototype.setAdjWindDir = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double latitude = 5;
 * @return {number}
 */
proto.IndyWindData.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndyWindData} returns this
 */
proto.IndyWindData.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double longitude = 6;
 * @return {number}
 */
proto.IndyWindData.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IndyWindData} returns this
 */
proto.IndyWindData.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SessionPitRoadSummary.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SessionPitRoadSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.SessionPitRoadSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SessionPitRoadSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionPitRoadSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    leaderLap: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sessionPitSummeryMetadataList: jspb.Message.toObjectList(msg.getSessionPitSummeryMetadataList(),
    proto.SessionPitSummaryMetaData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SessionPitRoadSummary}
 */
proto.SessionPitRoadSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SessionPitRoadSummary;
  return proto.SessionPitRoadSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SessionPitRoadSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SessionPitRoadSummary}
 */
proto.SessionPitRoadSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLeaderLap(value);
      break;
    case 2:
      var value = new proto.SessionPitSummaryMetaData;
      reader.readMessage(value,proto.SessionPitSummaryMetaData.deserializeBinaryFromReader);
      msg.addSessionPitSummeryMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SessionPitRoadSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SessionPitRoadSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SessionPitRoadSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionPitRoadSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLeaderLap();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSessionPitSummeryMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.SessionPitSummaryMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 leader_lap = 1;
 * @return {number}
 */
proto.SessionPitRoadSummary.prototype.getLeaderLap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionPitRoadSummary} returns this
 */
proto.SessionPitRoadSummary.prototype.setLeaderLap = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated SessionPitSummaryMetaData session_pit_summery_metadata = 2;
 * @return {!Array<!proto.SessionPitSummaryMetaData>}
 */
proto.SessionPitRoadSummary.prototype.getSessionPitSummeryMetadataList = function() {
  return /** @type{!Array<!proto.SessionPitSummaryMetaData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SessionPitSummaryMetaData, 2));
};


/**
 * @param {!Array<!proto.SessionPitSummaryMetaData>} value
 * @return {!proto.SessionPitRoadSummary} returns this
*/
proto.SessionPitRoadSummary.prototype.setSessionPitSummeryMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.SessionPitSummaryMetaData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SessionPitSummaryMetaData}
 */
proto.SessionPitRoadSummary.prototype.addSessionPitSummeryMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.SessionPitSummaryMetaData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SessionPitRoadSummary} returns this
 */
proto.SessionPitRoadSummary.prototype.clearSessionPitSummeryMetadataList = function() {
  return this.setSessionPitSummeryMetadataList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SessionPitSummaryMetaData.repeatedFields_ = [24,25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SessionPitSummaryMetaData.prototype.toObject = function(opt_includeInstance) {
  return proto.SessionPitSummaryMetaData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SessionPitSummaryMetaData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionPitSummaryMetaData.toObject = function(includeInstance, msg) {
  var f, obj = {
    lap: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicleNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    flagIn: jspb.Message.getFieldWithDefault(msg, 3, ""),
    flagOut: jspb.Message.getFieldWithDefault(msg, 4, ""),
    leftTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    rightTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    jackTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    crewTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    driverTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    driverEnter: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    driverGoes: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    vehicleStopToJack: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    jackToVehicleGoes: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    totalTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    tiresChanged: jspb.Message.getFieldWithDefault(msg, 15, 0),
    pitInTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    pitOutTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    missedEvents: jspb.Message.getFieldWithDefault(msg, 18, 0),
    sequence: jspb.Message.getFieldWithDefault(msg, 19, ""),
    leaderLap: jspb.Message.getFieldWithDefault(msg, 20, 0),
    driverFullName: jspb.Message.getFieldWithDefault(msg, 21, ""),
    pitStopType: jspb.Message.getFieldWithDefault(msg, 22, ""),
    pitPositionMetadata: (f = msg.getPitPositionMetadata()) && motorsports$data$common_pb.PitPositionMetadata.toObject(includeInstance, f),
    greenInList: jspb.Message.toObjectList(msg.getGreenInList(),
    motorsports$data$common_pb.GreenCalc.toObject, includeInstance),
    greenOutList: jspb.Message.toObjectList(msg.getGreenOutList(),
    motorsports$data$common_pb.GreenCalc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SessionPitSummaryMetaData}
 */
proto.SessionPitSummaryMetaData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SessionPitSummaryMetaData;
  return proto.SessionPitSummaryMetaData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SessionPitSummaryMetaData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SessionPitSummaryMetaData}
 */
proto.SessionPitSummaryMetaData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLap(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlagIn(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlagOut(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLeftTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRightTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setJackTime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCrewTime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDriverTime(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDriverEnter(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDriverGoes(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVehicleStopToJack(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setJackToVehicleGoes(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalTime(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTiresChanged(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPitInTime(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPitOutTime(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMissedEvents(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setSequence(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLeaderLap(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverFullName(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPitStopType(value);
      break;
    case 23:
      var value = new motorsports$data$common_pb.PitPositionMetadata;
      reader.readMessage(value,motorsports$data$common_pb.PitPositionMetadata.deserializeBinaryFromReader);
      msg.setPitPositionMetadata(value);
      break;
    case 24:
      var value = new motorsports$data$common_pb.GreenCalc;
      reader.readMessage(value,motorsports$data$common_pb.GreenCalc.deserializeBinaryFromReader);
      msg.addGreenIn(value);
      break;
    case 25:
      var value = new motorsports$data$common_pb.GreenCalc;
      reader.readMessage(value,motorsports$data$common_pb.GreenCalc.deserializeBinaryFromReader);
      msg.addGreenOut(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SessionPitSummaryMetaData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SessionPitSummaryMetaData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SessionPitSummaryMetaData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionPitSummaryMetaData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLap();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVehicleNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFlagIn();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFlagOut();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLeftTime();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getRightTime();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getJackTime();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getCrewTime();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getDriverTime();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getDriverEnter();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getDriverGoes();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getVehicleStopToJack();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getJackToVehicleGoes();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getTotalTime();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getTiresChanged();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getPitInTime();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getPitOutTime();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getMissedEvents();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getSequence();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getLeaderLap();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getDriverFullName();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getPitStopType();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getPitPositionMetadata();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      motorsports$data$common_pb.PitPositionMetadata.serializeBinaryToWriter
    );
  }
  f = message.getGreenInList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      24,
      f,
      motorsports$data$common_pb.GreenCalc.serializeBinaryToWriter
    );
  }
  f = message.getGreenOutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      motorsports$data$common_pb.GreenCalc.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 lap = 1;
 * @return {number}
 */
proto.SessionPitSummaryMetaData.prototype.getLap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setLap = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string vehicle_number = 2;
 * @return {string}
 */
proto.SessionPitSummaryMetaData.prototype.getVehicleNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setVehicleNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string flag_in = 3;
 * @return {string}
 */
proto.SessionPitSummaryMetaData.prototype.getFlagIn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setFlagIn = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string flag_out = 4;
 * @return {string}
 */
proto.SessionPitSummaryMetaData.prototype.getFlagOut = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setFlagOut = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double left_time = 5;
 * @return {number}
 */
proto.SessionPitSummaryMetaData.prototype.getLeftTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setLeftTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double right_time = 6;
 * @return {number}
 */
proto.SessionPitSummaryMetaData.prototype.getRightTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setRightTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double jack_time = 7;
 * @return {number}
 */
proto.SessionPitSummaryMetaData.prototype.getJackTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setJackTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double crew_time = 8;
 * @return {number}
 */
proto.SessionPitSummaryMetaData.prototype.getCrewTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setCrewTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double driver_time = 9;
 * @return {number}
 */
proto.SessionPitSummaryMetaData.prototype.getDriverTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setDriverTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double driver_enter = 10;
 * @return {number}
 */
proto.SessionPitSummaryMetaData.prototype.getDriverEnter = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setDriverEnter = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double driver_goes = 11;
 * @return {number}
 */
proto.SessionPitSummaryMetaData.prototype.getDriverGoes = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setDriverGoes = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double vehicle_stop_to_jack = 12;
 * @return {number}
 */
proto.SessionPitSummaryMetaData.prototype.getVehicleStopToJack = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setVehicleStopToJack = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double jack_to_vehicle_goes = 13;
 * @return {number}
 */
proto.SessionPitSummaryMetaData.prototype.getJackToVehicleGoes = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setJackToVehicleGoes = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double total_time = 14;
 * @return {number}
 */
proto.SessionPitSummaryMetaData.prototype.getTotalTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setTotalTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional int32 tires_changed = 15;
 * @return {number}
 */
proto.SessionPitSummaryMetaData.prototype.getTiresChanged = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setTiresChanged = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional double pit_in_time = 16;
 * @return {number}
 */
proto.SessionPitSummaryMetaData.prototype.getPitInTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setPitInTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional double pit_out_time = 17;
 * @return {number}
 */
proto.SessionPitSummaryMetaData.prototype.getPitOutTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setPitOutTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional int32 missed_events = 18;
 * @return {number}
 */
proto.SessionPitSummaryMetaData.prototype.getMissedEvents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setMissedEvents = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string sequence = 19;
 * @return {string}
 */
proto.SessionPitSummaryMetaData.prototype.getSequence = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setSequence = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int32 leader_lap = 20;
 * @return {number}
 */
proto.SessionPitSummaryMetaData.prototype.getLeaderLap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setLeaderLap = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string driver_full_name = 21;
 * @return {string}
 */
proto.SessionPitSummaryMetaData.prototype.getDriverFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setDriverFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string pit_stop_type = 22;
 * @return {string}
 */
proto.SessionPitSummaryMetaData.prototype.getPitStopType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.setPitStopType = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional PitPositionMetadata pit_position_metadata = 23;
 * @return {?proto.PitPositionMetadata}
 */
proto.SessionPitSummaryMetaData.prototype.getPitPositionMetadata = function() {
  return /** @type{?proto.PitPositionMetadata} */ (
    jspb.Message.getWrapperField(this, motorsports$data$common_pb.PitPositionMetadata, 23));
};


/**
 * @param {?proto.PitPositionMetadata|undefined} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
*/
proto.SessionPitSummaryMetaData.prototype.setPitPositionMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.clearPitPositionMetadata = function() {
  return this.setPitPositionMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SessionPitSummaryMetaData.prototype.hasPitPositionMetadata = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * repeated GreenCalc green_in = 24;
 * @return {!Array<!proto.GreenCalc>}
 */
proto.SessionPitSummaryMetaData.prototype.getGreenInList = function() {
  return /** @type{!Array<!proto.GreenCalc>} */ (
    jspb.Message.getRepeatedWrapperField(this, motorsports$data$common_pb.GreenCalc, 24));
};


/**
 * @param {!Array<!proto.GreenCalc>} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
*/
proto.SessionPitSummaryMetaData.prototype.setGreenInList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 24, value);
};


/**
 * @param {!proto.GreenCalc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GreenCalc}
 */
proto.SessionPitSummaryMetaData.prototype.addGreenIn = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 24, opt_value, proto.GreenCalc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.clearGreenInList = function() {
  return this.setGreenInList([]);
};


/**
 * repeated GreenCalc green_out = 25;
 * @return {!Array<!proto.GreenCalc>}
 */
proto.SessionPitSummaryMetaData.prototype.getGreenOutList = function() {
  return /** @type{!Array<!proto.GreenCalc>} */ (
    jspb.Message.getRepeatedWrapperField(this, motorsports$data$common_pb.GreenCalc, 25));
};


/**
 * @param {!Array<!proto.GreenCalc>} value
 * @return {!proto.SessionPitSummaryMetaData} returns this
*/
proto.SessionPitSummaryMetaData.prototype.setGreenOutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.GreenCalc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GreenCalc}
 */
proto.SessionPitSummaryMetaData.prototype.addGreenOut = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.GreenCalc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SessionPitSummaryMetaData} returns this
 */
proto.SessionPitSummaryMetaData.prototype.clearGreenOutList = function() {
  return this.setGreenOutList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SessionPitRoadAnalysis.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SessionPitRoadAnalysis.prototype.toObject = function(opt_includeInstance) {
  return proto.SessionPitRoadAnalysis.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SessionPitRoadAnalysis} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionPitRoadAnalysis.toObject = function(includeInstance, msg) {
  var f, obj = {
    leaderLap: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pitRoadAnalysisList: jspb.Message.toObjectList(msg.getPitRoadAnalysisList(),
    proto.PitRoadAnalysis.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SessionPitRoadAnalysis}
 */
proto.SessionPitRoadAnalysis.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SessionPitRoadAnalysis;
  return proto.SessionPitRoadAnalysis.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SessionPitRoadAnalysis} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SessionPitRoadAnalysis}
 */
proto.SessionPitRoadAnalysis.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLeaderLap(value);
      break;
    case 2:
      var value = new proto.PitRoadAnalysis;
      reader.readMessage(value,proto.PitRoadAnalysis.deserializeBinaryFromReader);
      msg.addPitRoadAnalysis(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SessionPitRoadAnalysis.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SessionPitRoadAnalysis.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SessionPitRoadAnalysis} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionPitRoadAnalysis.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLeaderLap();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPitRoadAnalysisList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.PitRoadAnalysis.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 leader_lap = 1;
 * @return {number}
 */
proto.SessionPitRoadAnalysis.prototype.getLeaderLap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionPitRoadAnalysis} returns this
 */
proto.SessionPitRoadAnalysis.prototype.setLeaderLap = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated PitRoadAnalysis pit_road_analysis = 2;
 * @return {!Array<!proto.PitRoadAnalysis>}
 */
proto.SessionPitRoadAnalysis.prototype.getPitRoadAnalysisList = function() {
  return /** @type{!Array<!proto.PitRoadAnalysis>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.PitRoadAnalysis, 2));
};


/**
 * @param {!Array<!proto.PitRoadAnalysis>} value
 * @return {!proto.SessionPitRoadAnalysis} returns this
*/
proto.SessionPitRoadAnalysis.prototype.setPitRoadAnalysisList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.PitRoadAnalysis=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PitRoadAnalysis}
 */
proto.SessionPitRoadAnalysis.prototype.addPitRoadAnalysis = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.PitRoadAnalysis, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SessionPitRoadAnalysis} returns this
 */
proto.SessionPitRoadAnalysis.prototype.clearPitRoadAnalysisList = function() {
  return this.setPitRoadAnalysisList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LatLong.prototype.toObject = function(opt_includeInstance) {
  return proto.LatLong.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LatLong} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LatLong.toObject = function(includeInstance, msg) {
  var f, obj = {
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LatLong}
 */
proto.LatLong.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LatLong;
  return proto.LatLong.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LatLong} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LatLong}
 */
proto.LatLong.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LatLong.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LatLong.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LatLong} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LatLong.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double latitude = 1;
 * @return {number}
 */
proto.LatLong.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LatLong} returns this
 */
proto.LatLong.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double longitude = 2;
 * @return {number}
 */
proto.LatLong.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LatLong} returns this
 */
proto.LatLong.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.OwnerPoints.prototype.toObject = function(opt_includeInstance) {
  return proto.OwnerPoints.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.OwnerPoints} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OwnerPoints.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    points: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bonusPoints: jspb.Message.getFieldWithDefault(msg, 3, 0),
    starts: jspb.Message.getFieldWithDefault(msg, 4, 0),
    poles: jspb.Message.getFieldWithDefault(msg, 5, 0),
    wins: jspb.Message.getFieldWithDefault(msg, 6, 0),
    top5: jspb.Message.getFieldWithDefault(msg, 7, 0),
    top10: jspb.Message.getFieldWithDefault(msg, 8, 0),
    dnf: jspb.Message.getFieldWithDefault(msg, 9, 0),
    ownerId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    ownerFirstName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    ownerLastName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    vehicleNumber: jspb.Message.getFieldWithDefault(msg, 13, ""),
    rank: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OwnerPoints}
 */
proto.OwnerPoints.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.OwnerPoints;
  return proto.OwnerPoints.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OwnerPoints} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OwnerPoints}
 */
proto.OwnerPoints.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPoints(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBonusPoints(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStarts(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPoles(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWins(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTop5(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTop10(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDnf(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOwnerId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerFirstName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerLastName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleNumber(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRank(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OwnerPoints.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.OwnerPoints.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OwnerPoints} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OwnerPoints.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPoints();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getBonusPoints();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getStarts();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPoles();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getWins();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTop5();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getTop10();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getDnf();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getOwnerFirstName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOwnerLastName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getVehicleNumber();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
};


/**
 * optional string owner_name = 1;
 * @return {string}
 */
proto.OwnerPoints.prototype.getOwnerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.OwnerPoints} returns this
 */
proto.OwnerPoints.prototype.setOwnerName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 points = 2;
 * @return {number}
 */
proto.OwnerPoints.prototype.getPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.OwnerPoints} returns this
 */
proto.OwnerPoints.prototype.setPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 bonus_points = 3;
 * @return {number}
 */
proto.OwnerPoints.prototype.getBonusPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.OwnerPoints} returns this
 */
proto.OwnerPoints.prototype.setBonusPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 starts = 4;
 * @return {number}
 */
proto.OwnerPoints.prototype.getStarts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.OwnerPoints} returns this
 */
proto.OwnerPoints.prototype.setStarts = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 poles = 5;
 * @return {number}
 */
proto.OwnerPoints.prototype.getPoles = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.OwnerPoints} returns this
 */
proto.OwnerPoints.prototype.setPoles = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 wins = 6;
 * @return {number}
 */
proto.OwnerPoints.prototype.getWins = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.OwnerPoints} returns this
 */
proto.OwnerPoints.prototype.setWins = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 top_5 = 7;
 * @return {number}
 */
proto.OwnerPoints.prototype.getTop5 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.OwnerPoints} returns this
 */
proto.OwnerPoints.prototype.setTop5 = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 top_10 = 8;
 * @return {number}
 */
proto.OwnerPoints.prototype.getTop10 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.OwnerPoints} returns this
 */
proto.OwnerPoints.prototype.setTop10 = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 dnf = 9;
 * @return {number}
 */
proto.OwnerPoints.prototype.getDnf = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.OwnerPoints} returns this
 */
proto.OwnerPoints.prototype.setDnf = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 owner_id = 10;
 * @return {number}
 */
proto.OwnerPoints.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.OwnerPoints} returns this
 */
proto.OwnerPoints.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string owner_first_name = 11;
 * @return {string}
 */
proto.OwnerPoints.prototype.getOwnerFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.OwnerPoints} returns this
 */
proto.OwnerPoints.prototype.setOwnerFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string owner_last_name = 12;
 * @return {string}
 */
proto.OwnerPoints.prototype.getOwnerLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.OwnerPoints} returns this
 */
proto.OwnerPoints.prototype.setOwnerLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string vehicle_number = 13;
 * @return {string}
 */
proto.OwnerPoints.prototype.getVehicleNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.OwnerPoints} returns this
 */
proto.OwnerPoints.prototype.setVehicleNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 rank = 14;
 * @return {number}
 */
proto.OwnerPoints.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.OwnerPoints} returns this
 */
proto.OwnerPoints.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.OwnerPointsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.OwnerPointsList.prototype.toObject = function(opt_includeInstance) {
  return proto.OwnerPointsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.OwnerPointsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OwnerPointsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerPointsList: jspb.Message.toObjectList(msg.getOwnerPointsList(),
    proto.OwnerPoints.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OwnerPointsList}
 */
proto.OwnerPointsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.OwnerPointsList;
  return proto.OwnerPointsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OwnerPointsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OwnerPointsList}
 */
proto.OwnerPointsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.OwnerPoints;
      reader.readMessage(value,proto.OwnerPoints.deserializeBinaryFromReader);
      msg.addOwnerPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OwnerPointsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.OwnerPointsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OwnerPointsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OwnerPointsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.OwnerPoints.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OwnerPoints owner_points = 1;
 * @return {!Array<!proto.OwnerPoints>}
 */
proto.OwnerPointsList.prototype.getOwnerPointsList = function() {
  return /** @type{!Array<!proto.OwnerPoints>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.OwnerPoints, 1));
};


/**
 * @param {!Array<!proto.OwnerPoints>} value
 * @return {!proto.OwnerPointsList} returns this
*/
proto.OwnerPointsList.prototype.setOwnerPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.OwnerPoints=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OwnerPoints}
 */
proto.OwnerPointsList.prototype.addOwnerPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.OwnerPoints, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.OwnerPointsList} returns this
 */
proto.OwnerPointsList.prototype.clearOwnerPointsList = function() {
  return this.setOwnerPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ManufacturerPoints.prototype.toObject = function(opt_includeInstance) {
  return proto.ManufacturerPoints.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ManufacturerPoints} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ManufacturerPoints.toObject = function(includeInstance, msg) {
  var f, obj = {
    position: jspb.Message.getFieldWithDefault(msg, 1, 0),
    manufacturer: jspb.Message.getFieldWithDefault(msg, 2, ""),
    points: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ManufacturerPoints}
 */
proto.ManufacturerPoints.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ManufacturerPoints;
  return proto.ManufacturerPoints.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ManufacturerPoints} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ManufacturerPoints}
 */
proto.ManufacturerPoints.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosition(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setManufacturer(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ManufacturerPoints.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ManufacturerPoints.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ManufacturerPoints} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ManufacturerPoints.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getManufacturer();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPoints();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 position = 1;
 * @return {number}
 */
proto.ManufacturerPoints.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ManufacturerPoints} returns this
 */
proto.ManufacturerPoints.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string manufacturer = 2;
 * @return {string}
 */
proto.ManufacturerPoints.prototype.getManufacturer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ManufacturerPoints} returns this
 */
proto.ManufacturerPoints.prototype.setManufacturer = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 points = 3;
 * @return {number}
 */
proto.ManufacturerPoints.prototype.getPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ManufacturerPoints} returns this
 */
proto.ManufacturerPoints.prototype.setPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ManufacturerPointsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ManufacturerPointsList.prototype.toObject = function(opt_includeInstance) {
  return proto.ManufacturerPointsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ManufacturerPointsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ManufacturerPointsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    manufacturerPointsList: jspb.Message.toObjectList(msg.getManufacturerPointsList(),
    proto.ManufacturerPoints.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ManufacturerPointsList}
 */
proto.ManufacturerPointsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ManufacturerPointsList;
  return proto.ManufacturerPointsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ManufacturerPointsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ManufacturerPointsList}
 */
proto.ManufacturerPointsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ManufacturerPoints;
      reader.readMessage(value,proto.ManufacturerPoints.deserializeBinaryFromReader);
      msg.addManufacturerPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ManufacturerPointsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ManufacturerPointsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ManufacturerPointsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ManufacturerPointsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getManufacturerPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ManufacturerPoints.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ManufacturerPoints manufacturer_points = 1;
 * @return {!Array<!proto.ManufacturerPoints>}
 */
proto.ManufacturerPointsList.prototype.getManufacturerPointsList = function() {
  return /** @type{!Array<!proto.ManufacturerPoints>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ManufacturerPoints, 1));
};


/**
 * @param {!Array<!proto.ManufacturerPoints>} value
 * @return {!proto.ManufacturerPointsList} returns this
*/
proto.ManufacturerPointsList.prototype.setManufacturerPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ManufacturerPoints=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ManufacturerPoints}
 */
proto.ManufacturerPointsList.prototype.addManufacturerPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ManufacturerPoints, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ManufacturerPointsList} returns this
 */
proto.ManufacturerPointsList.prototype.clearManufacturerPointsList = function() {
  return this.setManufacturerPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TeamData.prototype.toObject = function(opt_includeInstance) {
  return proto.TeamData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TeamData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TeamData.toObject = function(includeInstance, msg) {
  var f, obj = {
    teamId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    teamName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    domain: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TeamData}
 */
proto.TeamData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TeamData;
  return proto.TeamData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TeamData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TeamData}
 */
proto.TeamData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTeamId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TeamData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TeamData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TeamData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TeamData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTeamName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDomain();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 team_id = 1;
 * @return {number}
 */
proto.TeamData.prototype.getTeamId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.TeamData} returns this
 */
proto.TeamData.prototype.setTeamId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string team_name = 2;
 * @return {string}
 */
proto.TeamData.prototype.getTeamName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TeamData} returns this
 */
proto.TeamData.prototype.setTeamName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string domain = 3;
 * @return {string}
 */
proto.TeamData.prototype.getDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TeamData} returns this
 */
proto.TeamData.prototype.setDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AdminSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.AdminSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AdminSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AdminSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    settingsId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    settings: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AdminSettings}
 */
proto.AdminSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AdminSettings;
  return proto.AdminSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AdminSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AdminSettings}
 */
proto.AdminSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.AdminSettingsKey} */ (reader.readEnum());
      msg.setSettingsId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AdminSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AdminSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AdminSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AdminSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettingsId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSettings();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional AdminSettingsKey settings_id = 1;
 * @return {!proto.AdminSettingsKey}
 */
proto.AdminSettings.prototype.getSettingsId = function() {
  return /** @type {!proto.AdminSettingsKey} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.AdminSettingsKey} value
 * @return {!proto.AdminSettings} returns this
 */
proto.AdminSettings.prototype.setSettingsId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string settings = 2;
 * @return {string}
 */
proto.AdminSettings.prototype.getSettings = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AdminSettings} returns this
 */
proto.AdminSettings.prototype.setSettings = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.DataType = {
  UNKNOWNDATATYPE: 0,
  ALL: 1,
  LINECROSSINGDATA: 2,
  RESULTDATA: 3,
  FLAGDATA: 4,
  TELEMETRYDATA: 5
};

/**
 * @enum {number}
 */
proto.SasTokenType = {
  IMAGEVIEWER: 0,
  AUDIO: 1
};

/**
 * @enum {number}
 */
proto.PointsType = {
  OWNER: 0,
  MANUFACTURER: 1
};

/**
 * @enum {number}
 */
proto.PointsCalculation = {
  ASTHEYRUN: 0,
  PROJECTED: 1
};

/**
 * @enum {number}
 */
proto.PositionProjection = {
  ENDOFSTAGE: 0,
  ENDOFRACE: 1,
  NOW: 2
};

/**
 * @enum {number}
 */
proto.GapType = {
  CAR: 0,
  RANK: 1
};

/**
 * @enum {number}
 */
proto.AdminSettingsKey = {
  ALLADMINSETTINGS: 0,
  AUDIOTRANSCRIPTION: 1,
  DEFAULTWORKSPACE: 2
};

goog.object.extend(exports, proto);
