// package: 
// file: msuiservice.proto

var msuiservice_pb = require("./msuiservice_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var MsUiService = (function () {
  function MsUiService() {}
  MsUiService.serviceName = "MsUiService";
  return MsUiService;
}());

MsUiService.getEventSessionDetailsData = {
  methodName: "getEventSessionDetailsData",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.EventSessionDetailsRequest,
  responseType: msuiservice_pb.EventSessionDetailsResponse
};

MsUiService.getSeries = {
  methodName: "getSeries",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.SeriesRequest,
  responseType: msuiservice_pb.SeriesResponse
};

MsUiService.getCurrentLiveSession = {
  methodName: "getCurrentLiveSession",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.CurrentLiveSessionRequest,
  responseType: msuiservice_pb.CurrentLiveSessionResponse
};

MsUiService.getCurrentLiveSessionStream = {
  methodName: "getCurrentLiveSessionStream",
  service: MsUiService,
  requestStream: false,
  responseStream: true,
  requestType: msuiservice_pb.Empty,
  responseType: msuiservice_pb.CurrentLiveSessionStreamResponse
};

MsUiService.getEventSessionsStatusData = {
  methodName: "getEventSessionsStatusData",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.EventSessionsStatusRequest,
  responseType: msuiservice_pb.EventSessionsStatusResponse
};

MsUiService.getEvents = {
  methodName: "getEvents",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.EventsRequest,
  responseType: msuiservice_pb.EventsResponse
};

MsUiService.getDriverVehicleSeriesData = {
  methodName: "getDriverVehicleSeriesData",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.DriverVehicleSeriesDataRequest,
  responseType: msuiservice_pb.DriverVehicleSeriesDataResponse
};

MsUiService.getVehicleOemBySeriesListRequest = {
  methodName: "getVehicleOemBySeriesListRequest",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.VehicleOemBySeriesListRequest,
  responseType: msuiservice_pb.VehicleOemBySeriesListResponse
};

MsUiService.getRaceProgressionData = {
  methodName: "getRaceProgressionData",
  service: MsUiService,
  requestStream: false,
  responseStream: true,
  requestType: msuiservice_pb.RaceProgressionDataRequest,
  responseType: msuiservice_pb.RaceProgressionDataResponse
};

MsUiService.getTelemetryDataBidi = {
  methodName: "getTelemetryDataBidi",
  service: MsUiService,
  requestStream: true,
  responseStream: true,
  requestType: msuiservice_pb.TelemetryDataRequest,
  responseType: msuiservice_pb.TelemetryDataResponse
};

MsUiService.getLapDataBidiArr = {
  methodName: "getLapDataBidiArr",
  service: MsUiService,
  requestStream: true,
  responseStream: true,
  requestType: msuiservice_pb.LapDataRequest,
  responseType: msuiservice_pb.LapDataResponseArr
};

MsUiService.getLapHistoryDataStream = {
  methodName: "getLapHistoryDataStream",
  service: MsUiService,
  requestStream: true,
  responseStream: true,
  requestType: msuiservice_pb.LapHistoryDataRequest,
  responseType: msuiservice_pb.LapHistoryDataResponse
};

MsUiService.getPitRoadDataArrayStream = {
  methodName: "getPitRoadDataArrayStream",
  service: MsUiService,
  requestStream: true,
  responseStream: true,
  requestType: msuiservice_pb.PitRoadDataRequest,
  responseType: msuiservice_pb.PitRoadDataArrayResponse
};

MsUiService.getPitRoadAnalysisStream = {
  methodName: "getPitRoadAnalysisStream",
  service: MsUiService,
  requestStream: false,
  responseStream: true,
  requestType: msuiservice_pb.PitRoadAnalysisRequest,
  responseType: msuiservice_pb.PitRoadAnalysisResponse
};

MsUiService.getSanctioningBodyData = {
  methodName: "getSanctioningBodyData",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.SanctioningBodyRequest,
  responseType: msuiservice_pb.SanctioningBodyResponse
};

MsUiService.getTrackTemperatureTableData = {
  methodName: "getTrackTemperatureTableData",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.TrackTemperatureTableRequest,
  responseType: msuiservice_pb.TrackTemperatureTableResponse
};

MsUiService.getTrackTemperatureHistoricalTableData = {
  methodName: "getTrackTemperatureHistoricalTableData",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.TrackTemperatureHistoricalTableRequest,
  responseType: msuiservice_pb.TrackTemperatureTableResponse
};

MsUiService.getTrackTemperatureImage = {
  methodName: "getTrackTemperatureImage",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.TrackTemperatureImageRequest,
  responseType: msuiservice_pb.TrackTemperatureImageResponse
};

MsUiService.getTrackTemperatureHistoricalListingData = {
  methodName: "getTrackTemperatureHistoricalListingData",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.TrackTemperatureHistoricalListingRequest,
  responseType: msuiservice_pb.TrackTemperatureHistoricalListingResponse
};

MsUiService.getNascarTrailerWeatherHistoricalTableData = {
  methodName: "getNascarTrailerWeatherHistoricalTableData",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.NascarTrailerWeatherHistoricalTableRequest,
  responseType: msuiservice_pb.NascarTrailerWeatherTableResponse
};

MsUiService.getNascarTrailerWeatherHistoricalListingData = {
  methodName: "getNascarTrailerWeatherHistoricalListingData",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.NascarTrailerWeatherHistoricalListingRequest,
  responseType: msuiservice_pb.NascarTrailerWeatherHistoricalListingResponse
};

MsUiService.getNascarTrailerWeatherTableData = {
  methodName: "getNascarTrailerWeatherTableData",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.NascarTrailerWeatherTableRequest,
  responseType: msuiservice_pb.NascarTrailerWeatherTableResponse
};

MsUiService.getIndyWindData = {
  methodName: "getIndyWindData",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.IndyWindDataRequest,
  responseType: msuiservice_pb.IndyWindDataResponse
};

MsUiService.getIndyWindStationIds = {
  methodName: "getIndyWindStationIds",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.IndyWindStationIdsRequest,
  responseType: msuiservice_pb.IndyWindStationIdsResponse
};

MsUiService.getIndyWeatherTrack = {
  methodName: "getIndyWeatherTrack",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.IndyWeatherTrackRequest,
  responseType: msuiservice_pb.IndyWeatherTrackResponse
};

MsUiService.updateIndyWeatherStationIdAlias = {
  methodName: "updateIndyWeatherStationIdAlias",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.UpdateIndyWindStationIdAliasRequest,
  responseType: msuiservice_pb.GenericResponse
};

MsUiService.getReplaySessionTimingScoringData = {
  methodName: "getReplaySessionTimingScoringData",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.SessionTimingScoringRequest,
  responseType: msuiservice_pb.SessionTimingScoringResponse
};

MsUiService.getReplaySessionProgressionData = {
  methodName: "getReplaySessionProgressionData",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.SessionProgressionRequest,
  responseType: msuiservice_pb.SessionProgressionResponse
};

MsUiService.getAllWorkspaces = {
  methodName: "getAllWorkspaces",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.WorkspaceRequest,
  responseType: msuiservice_pb.WorkspaceResponse
};

MsUiService.getWorkspaceById = {
  methodName: "getWorkspaceById",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.WorkspaceRequest,
  responseType: msuiservice_pb.WorkspaceData
};

MsUiService.upsertWorkspace = {
  methodName: "upsertWorkspace",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.WorkspaceRequest,
  responseType: msuiservice_pb.WorkspaceResponseStatus
};

MsUiService.deleteWorkspace = {
  methodName: "deleteWorkspace",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.WorkspaceRequest,
  responseType: msuiservice_pb.WorkspaceResponseStatus
};

MsUiService.getReplaySessionPitRoadSummaryData = {
  methodName: "getReplaySessionPitRoadSummaryData",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.SessionPitRoadSummaryRequest,
  responseType: msuiservice_pb.SessionPitRoadSummaryResponse
};

MsUiService.getReplaySessionPitRoadAnalysisData = {
  methodName: "getReplaySessionPitRoadAnalysisData",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.SessionPitRoadAnalysisRequest,
  responseType: msuiservice_pb.SessionPitRoadAnalysisResponse
};

MsUiService.getUserPreference = {
  methodName: "getUserPreference",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.Empty,
  responseType: msuiservice_pb.UserPreferenceResponse
};

MsUiService.upsertUserPreference = {
  methodName: "upsertUserPreference",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.UserPreferenceRequest,
  responseType: msuiservice_pb.UserPreferenceResponseStatus
};

MsUiService.getFeedStatusStream = {
  methodName: "getFeedStatusStream",
  service: MsUiService,
  requestStream: false,
  responseStream: true,
  requestType: msuiservice_pb.Empty,
  responseType: msuiservice_pb.FeedStatusResponse
};

MsUiService.getQualifyingLapData = {
  methodName: "getQualifyingLapData",
  service: MsUiService,
  requestStream: false,
  responseStream: true,
  requestType: msuiservice_pb.QualifyingLapDataRequest,
  responseType: msuiservice_pb.QualifyingLapDataResponse
};

MsUiService.getFastData = {
  methodName: "getFastData",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.FastDataRequest,
  responseType: msuiservice_pb.FastDataResponse
};

MsUiService.getRankAnalysisData = {
  methodName: "getRankAnalysisData",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.RankAnalysisRequest,
  responseType: msuiservice_pb.RankAnalysisResponse
};

MsUiService.getAzureBlobSasToken = {
  methodName: "getAzureBlobSasToken",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.AzureBlobSasTokenRequest,
  responseType: msuiservice_pb.AzureBlobSasTokenResponse
};

MsUiService.getPointsData = {
  methodName: "getPointsData",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.PointsRequest,
  responseType: msuiservice_pb.PointsResponse
};

MsUiService.processRaceReplay = {
  methodName: "processRaceReplay",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.ProcessRaceReplayRequest,
  responseType: msuiservice_pb.ProcessRaceReplayResponse
};

MsUiService.getDriverPointsStream = {
  methodName: "getDriverPointsStream",
  service: MsUiService,
  requestStream: false,
  responseStream: true,
  requestType: msuiservice_pb.DriverPointsRequest,
  responseType: msuiservice_pb.DriverPointsResponse
};

MsUiService.getAllTeamsData = {
  methodName: "getAllTeamsData",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.GetAllTeamsDataRequest,
  responseType: msuiservice_pb.GetAllTeamsDataResponse
};

MsUiService.clearCache = {
  methodName: "clearCache",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.ClearCacheRequest,
  responseType: msuiservice_pb.ClearCacheResponse
};

MsUiService.updateAdminSettings = {
  methodName: "updateAdminSettings",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.UpdateAdminSettingsRequest,
  responseType: msuiservice_pb.UpdateAdminSettingsResponse
};

MsUiService.getAdminSettings = {
  methodName: "getAdminSettings",
  service: MsUiService,
  requestStream: false,
  responseStream: false,
  requestType: msuiservice_pb.GetAdminSettingsRequest,
  responseType: msuiservice_pb.GetAdminSettingsResponse
};

exports.MsUiService = MsUiService;

function MsUiServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

MsUiServiceClient.prototype.getEventSessionDetailsData = function getEventSessionDetailsData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getEventSessionDetailsData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getSeries = function getSeries(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getSeries, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getCurrentLiveSession = function getCurrentLiveSession(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getCurrentLiveSession, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getCurrentLiveSessionStream = function getCurrentLiveSessionStream(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MsUiService.getCurrentLiveSessionStream, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getEventSessionsStatusData = function getEventSessionsStatusData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getEventSessionsStatusData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getEvents = function getEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getDriverVehicleSeriesData = function getDriverVehicleSeriesData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getDriverVehicleSeriesData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getVehicleOemBySeriesListRequest = function getVehicleOemBySeriesListRequest(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getVehicleOemBySeriesListRequest, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getRaceProgressionData = function getRaceProgressionData(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MsUiService.getRaceProgressionData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getTelemetryDataBidi = function getTelemetryDataBidi(metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.client(MsUiService.getTelemetryDataBidi, {
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport
  });
  client.onEnd(function (status, statusMessage, trailers) {
    listeners.status.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners.end.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners = null;
  });
  client.onMessage(function (message) {
    listeners.data.forEach(function (handler) {
      handler(message);
    })
  });
  client.start(metadata);
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    write: function (requestMessage) {
      client.send(requestMessage);
      return this;
    },
    end: function () {
      client.finishSend();
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getLapDataBidiArr = function getLapDataBidiArr(metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.client(MsUiService.getLapDataBidiArr, {
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport
  });
  client.onEnd(function (status, statusMessage, trailers) {
    listeners.status.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners.end.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners = null;
  });
  client.onMessage(function (message) {
    listeners.data.forEach(function (handler) {
      handler(message);
    })
  });
  client.start(metadata);
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    write: function (requestMessage) {
      client.send(requestMessage);
      return this;
    },
    end: function () {
      client.finishSend();
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getLapHistoryDataStream = function getLapHistoryDataStream(metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.client(MsUiService.getLapHistoryDataStream, {
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport
  });
  client.onEnd(function (status, statusMessage, trailers) {
    listeners.status.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners.end.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners = null;
  });
  client.onMessage(function (message) {
    listeners.data.forEach(function (handler) {
      handler(message);
    })
  });
  client.start(metadata);
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    write: function (requestMessage) {
      client.send(requestMessage);
      return this;
    },
    end: function () {
      client.finishSend();
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getPitRoadDataArrayStream = function getPitRoadDataArrayStream(metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.client(MsUiService.getPitRoadDataArrayStream, {
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport
  });
  client.onEnd(function (status, statusMessage, trailers) {
    listeners.status.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners.end.forEach(function (handler) {
      handler({ code: status, details: statusMessage, metadata: trailers });
    });
    listeners = null;
  });
  client.onMessage(function (message) {
    listeners.data.forEach(function (handler) {
      handler(message);
    })
  });
  client.start(metadata);
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    write: function (requestMessage) {
      client.send(requestMessage);
      return this;
    },
    end: function () {
      client.finishSend();
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getPitRoadAnalysisStream = function getPitRoadAnalysisStream(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MsUiService.getPitRoadAnalysisStream, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getSanctioningBodyData = function getSanctioningBodyData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getSanctioningBodyData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getTrackTemperatureTableData = function getTrackTemperatureTableData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getTrackTemperatureTableData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getTrackTemperatureHistoricalTableData = function getTrackTemperatureHistoricalTableData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getTrackTemperatureHistoricalTableData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getTrackTemperatureImage = function getTrackTemperatureImage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getTrackTemperatureImage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getTrackTemperatureHistoricalListingData = function getTrackTemperatureHistoricalListingData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getTrackTemperatureHistoricalListingData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getNascarTrailerWeatherHistoricalTableData = function getNascarTrailerWeatherHistoricalTableData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getNascarTrailerWeatherHistoricalTableData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getNascarTrailerWeatherHistoricalListingData = function getNascarTrailerWeatherHistoricalListingData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getNascarTrailerWeatherHistoricalListingData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getNascarTrailerWeatherTableData = function getNascarTrailerWeatherTableData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getNascarTrailerWeatherTableData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getIndyWindData = function getIndyWindData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getIndyWindData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getIndyWindStationIds = function getIndyWindStationIds(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getIndyWindStationIds, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getIndyWeatherTrack = function getIndyWeatherTrack(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getIndyWeatherTrack, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.updateIndyWeatherStationIdAlias = function updateIndyWeatherStationIdAlias(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.updateIndyWeatherStationIdAlias, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getReplaySessionTimingScoringData = function getReplaySessionTimingScoringData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getReplaySessionTimingScoringData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getReplaySessionProgressionData = function getReplaySessionProgressionData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getReplaySessionProgressionData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getAllWorkspaces = function getAllWorkspaces(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getAllWorkspaces, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getWorkspaceById = function getWorkspaceById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getWorkspaceById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.upsertWorkspace = function upsertWorkspace(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.upsertWorkspace, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.deleteWorkspace = function deleteWorkspace(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.deleteWorkspace, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getReplaySessionPitRoadSummaryData = function getReplaySessionPitRoadSummaryData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getReplaySessionPitRoadSummaryData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getReplaySessionPitRoadAnalysisData = function getReplaySessionPitRoadAnalysisData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getReplaySessionPitRoadAnalysisData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getUserPreference = function getUserPreference(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getUserPreference, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.upsertUserPreference = function upsertUserPreference(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.upsertUserPreference, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getFeedStatusStream = function getFeedStatusStream(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MsUiService.getFeedStatusStream, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getQualifyingLapData = function getQualifyingLapData(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MsUiService.getQualifyingLapData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getFastData = function getFastData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getFastData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getRankAnalysisData = function getRankAnalysisData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getRankAnalysisData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getAzureBlobSasToken = function getAzureBlobSasToken(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getAzureBlobSasToken, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getPointsData = function getPointsData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getPointsData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.processRaceReplay = function processRaceReplay(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.processRaceReplay, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getDriverPointsStream = function getDriverPointsStream(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MsUiService.getDriverPointsStream, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getAllTeamsData = function getAllTeamsData(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getAllTeamsData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.clearCache = function clearCache(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.clearCache, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.updateAdminSettings = function updateAdminSettings(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.updateAdminSettings, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MsUiServiceClient.prototype.getAdminSettings = function getAdminSettings(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MsUiService.getAdminSettings, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.MsUiServiceClient = MsUiServiceClient;

