// source: msuiservice.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var msuimsgs_pb = require('./msuimsgs_pb.js');
goog.object.extend(proto, msuimsgs_pb);
var motorsports$data$common_pb = require('./motorsports-data-common_pb.js');
goog.object.extend(proto, motorsports$data$common_pb);
goog.exportSymbol('proto.AzureBlobSasTokenRequest', null, global);
goog.exportSymbol('proto.AzureBlobSasTokenResponse', null, global);
goog.exportSymbol('proto.ClearCacheRequest', null, global);
goog.exportSymbol('proto.ClearCacheResponse', null, global);
goog.exportSymbol('proto.CurrentLiveSessionRequest', null, global);
goog.exportSymbol('proto.CurrentLiveSessionResponse', null, global);
goog.exportSymbol('proto.CurrentLiveSessionStreamResponse', null, global);
goog.exportSymbol('proto.DriverPointsRequest', null, global);
goog.exportSymbol('proto.DriverPointsResponse', null, global);
goog.exportSymbol('proto.DriverVehicleSeriesDataRequest', null, global);
goog.exportSymbol('proto.DriverVehicleSeriesDataResponse', null, global);
goog.exportSymbol('proto.Empty', null, global);
goog.exportSymbol('proto.EventSessionDetailsRequest', null, global);
goog.exportSymbol('proto.EventSessionDetailsRequest.ParameterCase', null, global);
goog.exportSymbol('proto.EventSessionDetailsResponse', null, global);
goog.exportSymbol('proto.EventSessionsStatusRequest', null, global);
goog.exportSymbol('proto.EventSessionsStatusResponse', null, global);
goog.exportSymbol('proto.EventsRequest', null, global);
goog.exportSymbol('proto.EventsResponse', null, global);
goog.exportSymbol('proto.FastDataRequest', null, global);
goog.exportSymbol('proto.FastDataResponse', null, global);
goog.exportSymbol('proto.FeedStatus', null, global);
goog.exportSymbol('proto.FeedStatusResponse', null, global);
goog.exportSymbol('proto.GenericResponse', null, global);
goog.exportSymbol('proto.GetAdminSettingsRequest', null, global);
goog.exportSymbol('proto.GetAdminSettingsResponse', null, global);
goog.exportSymbol('proto.GetAllTeamsDataRequest', null, global);
goog.exportSymbol('proto.GetAllTeamsDataResponse', null, global);
goog.exportSymbol('proto.IndyWeatherTrackRequest', null, global);
goog.exportSymbol('proto.IndyWeatherTrackResponse', null, global);
goog.exportSymbol('proto.IndyWindDataRequest', null, global);
goog.exportSymbol('proto.IndyWindDataResponse', null, global);
goog.exportSymbol('proto.IndyWindStationIdsRequest', null, global);
goog.exportSymbol('proto.IndyWindStationIdsResponse', null, global);
goog.exportSymbol('proto.LapDataRequest', null, global);
goog.exportSymbol('proto.LapDataResponseArr', null, global);
goog.exportSymbol('proto.LapHistoryDataRequest', null, global);
goog.exportSymbol('proto.LapHistoryDataResponse', null, global);
goog.exportSymbol('proto.MovingAverageRequest', null, global);
goog.exportSymbol('proto.NascarTrailerWeatherHistoricalListingRequest', null, global);
goog.exportSymbol('proto.NascarTrailerWeatherHistoricalListingResponse', null, global);
goog.exportSymbol('proto.NascarTrailerWeatherHistoricalTableRequest', null, global);
goog.exportSymbol('proto.NascarTrailerWeatherHistoricalTableResponse', null, global);
goog.exportSymbol('proto.NascarTrailerWeatherTableRequest', null, global);
goog.exportSymbol('proto.NascarTrailerWeatherTableResponse', null, global);
goog.exportSymbol('proto.PitRoadAnalysisRequest', null, global);
goog.exportSymbol('proto.PitRoadAnalysisResponse', null, global);
goog.exportSymbol('proto.PitRoadDataArrayResponse', null, global);
goog.exportSymbol('proto.PitRoadDataRequest', null, global);
goog.exportSymbol('proto.PointsRequest', null, global);
goog.exportSymbol('proto.PointsResponse', null, global);
goog.exportSymbol('proto.PointsResponse.PointsCase', null, global);
goog.exportSymbol('proto.ProcessRaceReplayRequest', null, global);
goog.exportSymbol('proto.ProcessRaceReplayResponse', null, global);
goog.exportSymbol('proto.QualifyingLapDataRequest', null, global);
goog.exportSymbol('proto.QualifyingLapDataResponse', null, global);
goog.exportSymbol('proto.RaceProgressionDataRequest', null, global);
goog.exportSymbol('proto.RaceProgressionDataResponse', null, global);
goog.exportSymbol('proto.RankAnalysisRequest', null, global);
goog.exportSymbol('proto.RankAnalysisResponse', null, global);
goog.exportSymbol('proto.SanctioningBodyRequest', null, global);
goog.exportSymbol('proto.SanctioningBodyResponse', null, global);
goog.exportSymbol('proto.SeriesRequest', null, global);
goog.exportSymbol('proto.SeriesResponse', null, global);
goog.exportSymbol('proto.SessionPitRoadAnalysisRequest', null, global);
goog.exportSymbol('proto.SessionPitRoadAnalysisResponse', null, global);
goog.exportSymbol('proto.SessionPitRoadSummaryRequest', null, global);
goog.exportSymbol('proto.SessionPitRoadSummaryResponse', null, global);
goog.exportSymbol('proto.SessionProgressionRequest', null, global);
goog.exportSymbol('proto.SessionProgressionResponse', null, global);
goog.exportSymbol('proto.SessionTimingScoringRequest', null, global);
goog.exportSymbol('proto.SessionTimingScoringResponse', null, global);
goog.exportSymbol('proto.StationIdAlias', null, global);
goog.exportSymbol('proto.TelemetryDataRequest', null, global);
goog.exportSymbol('proto.TelemetryDataResponse', null, global);
goog.exportSymbol('proto.TrackTemperatureHistoricalListingRequest', null, global);
goog.exportSymbol('proto.TrackTemperatureHistoricalListingResponse', null, global);
goog.exportSymbol('proto.TrackTemperatureHistoricalTableRequest', null, global);
goog.exportSymbol('proto.TrackTemperatureImageRequest', null, global);
goog.exportSymbol('proto.TrackTemperatureImageResponse', null, global);
goog.exportSymbol('proto.TrackTemperatureTableRequest', null, global);
goog.exportSymbol('proto.TrackTemperatureTableResponse', null, global);
goog.exportSymbol('proto.UpdateAdminSettingsRequest', null, global);
goog.exportSymbol('proto.UpdateAdminSettingsResponse', null, global);
goog.exportSymbol('proto.UpdateIndyWindStationIdAliasRequest', null, global);
goog.exportSymbol('proto.UserPreferenceRequest', null, global);
goog.exportSymbol('proto.UserPreferenceResponse', null, global);
goog.exportSymbol('proto.UserPreferenceResponseStatus', null, global);
goog.exportSymbol('proto.VehicleOemBySeriesListRequest', null, global);
goog.exportSymbol('proto.VehicleOemBySeriesListResponse', null, global);
goog.exportSymbol('proto.WorkspaceData', null, global);
goog.exportSymbol('proto.WorkspaceRequest', null, global);
goog.exportSymbol('proto.WorkspaceResponse', null, global);
goog.exportSymbol('proto.WorkspaceResponseStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EventsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EventsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EventsRequest.displayName = 'proto.EventsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EventsResponse.repeatedFields_, null);
};
goog.inherits(proto.EventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EventsResponse.displayName = 'proto.EventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EventSessionDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.EventSessionDetailsRequest.oneofGroups_);
};
goog.inherits(proto.EventSessionDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EventSessionDetailsRequest.displayName = 'proto.EventSessionDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EventSessionDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EventSessionDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EventSessionDetailsResponse.displayName = 'proto.EventSessionDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EventSessionsStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EventSessionsStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EventSessionsStatusRequest.displayName = 'proto.EventSessionsStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EventSessionsStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EventSessionsStatusResponse.repeatedFields_, null);
};
goog.inherits(proto.EventSessionsStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EventSessionsStatusResponse.displayName = 'proto.EventSessionsStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SeriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SeriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SeriesRequest.displayName = 'proto.SeriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SeriesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SeriesResponse.repeatedFields_, null);
};
goog.inherits(proto.SeriesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SeriesResponse.displayName = 'proto.SeriesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CurrentLiveSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CurrentLiveSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CurrentLiveSessionRequest.displayName = 'proto.CurrentLiveSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CurrentLiveSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CurrentLiveSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CurrentLiveSessionResponse.displayName = 'proto.CurrentLiveSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CurrentLiveSessionStreamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CurrentLiveSessionStreamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CurrentLiveSessionStreamResponse.displayName = 'proto.CurrentLiveSessionStreamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LapDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.LapDataRequest.repeatedFields_, null);
};
goog.inherits(proto.LapDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LapDataRequest.displayName = 'proto.LapDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MovingAverageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MovingAverageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MovingAverageRequest.displayName = 'proto.MovingAverageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LapDataResponseArr = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.LapDataResponseArr.repeatedFields_, null);
};
goog.inherits(proto.LapDataResponseArr, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LapDataResponseArr.displayName = 'proto.LapDataResponseArr';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TelemetryDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TelemetryDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TelemetryDataRequest.displayName = 'proto.TelemetryDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TelemetryDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TelemetryDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TelemetryDataResponse.displayName = 'proto.TelemetryDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaceProgressionDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.RaceProgressionDataRequest.repeatedFields_, null);
};
goog.inherits(proto.RaceProgressionDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaceProgressionDataRequest.displayName = 'proto.RaceProgressionDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaceProgressionDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RaceProgressionDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaceProgressionDataResponse.displayName = 'proto.RaceProgressionDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LapHistoryDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.LapHistoryDataRequest.repeatedFields_, null);
};
goog.inherits(proto.LapHistoryDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LapHistoryDataRequest.displayName = 'proto.LapHistoryDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LapHistoryDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.LapHistoryDataResponse.repeatedFields_, null);
};
goog.inherits(proto.LapHistoryDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LapHistoryDataResponse.displayName = 'proto.LapHistoryDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PitRoadDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PitRoadDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PitRoadDataRequest.displayName = 'proto.PitRoadDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PitRoadDataArrayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.PitRoadDataArrayResponse.repeatedFields_, null);
};
goog.inherits(proto.PitRoadDataArrayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PitRoadDataArrayResponse.displayName = 'proto.PitRoadDataArrayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DriverVehicleSeriesDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DriverVehicleSeriesDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DriverVehicleSeriesDataRequest.displayName = 'proto.DriverVehicleSeriesDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DriverVehicleSeriesDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DriverVehicleSeriesDataResponse.repeatedFields_, null);
};
goog.inherits(proto.DriverVehicleSeriesDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DriverVehicleSeriesDataResponse.displayName = 'proto.DriverVehicleSeriesDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.VehicleOemBySeriesListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.VehicleOemBySeriesListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.VehicleOemBySeriesListRequest.displayName = 'proto.VehicleOemBySeriesListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.VehicleOemBySeriesListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.VehicleOemBySeriesListResponse.repeatedFields_, null);
};
goog.inherits(proto.VehicleOemBySeriesListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.VehicleOemBySeriesListResponse.displayName = 'proto.VehicleOemBySeriesListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PitRoadAnalysisRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PitRoadAnalysisRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PitRoadAnalysisRequest.displayName = 'proto.PitRoadAnalysisRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PitRoadAnalysisResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.PitRoadAnalysisResponse.repeatedFields_, null);
};
goog.inherits(proto.PitRoadAnalysisResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PitRoadAnalysisResponse.displayName = 'proto.PitRoadAnalysisResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SanctioningBodyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SanctioningBodyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SanctioningBodyRequest.displayName = 'proto.SanctioningBodyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SanctioningBodyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SanctioningBodyResponse.repeatedFields_, null);
};
goog.inherits(proto.SanctioningBodyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SanctioningBodyResponse.displayName = 'proto.SanctioningBodyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TrackTemperatureTableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TrackTemperatureTableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TrackTemperatureTableRequest.displayName = 'proto.TrackTemperatureTableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TrackTemperatureHistoricalTableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TrackTemperatureHistoricalTableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TrackTemperatureHistoricalTableRequest.displayName = 'proto.TrackTemperatureHistoricalTableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TrackTemperatureTableResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TrackTemperatureTableResponse.repeatedFields_, null);
};
goog.inherits(proto.TrackTemperatureTableResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TrackTemperatureTableResponse.displayName = 'proto.TrackTemperatureTableResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TrackTemperatureImageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TrackTemperatureImageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TrackTemperatureImageRequest.displayName = 'proto.TrackTemperatureImageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TrackTemperatureImageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TrackTemperatureImageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TrackTemperatureImageResponse.displayName = 'proto.TrackTemperatureImageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TrackTemperatureHistoricalListingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TrackTemperatureHistoricalListingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TrackTemperatureHistoricalListingRequest.displayName = 'proto.TrackTemperatureHistoricalListingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TrackTemperatureHistoricalListingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TrackTemperatureHistoricalListingResponse.repeatedFields_, null);
};
goog.inherits(proto.TrackTemperatureHistoricalListingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TrackTemperatureHistoricalListingResponse.displayName = 'proto.TrackTemperatureHistoricalListingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NascarTrailerWeatherHistoricalListingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NascarTrailerWeatherHistoricalListingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NascarTrailerWeatherHistoricalListingRequest.displayName = 'proto.NascarTrailerWeatherHistoricalListingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NascarTrailerWeatherHistoricalListingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.NascarTrailerWeatherHistoricalListingResponse.repeatedFields_, null);
};
goog.inherits(proto.NascarTrailerWeatherHistoricalListingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NascarTrailerWeatherHistoricalListingResponse.displayName = 'proto.NascarTrailerWeatherHistoricalListingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NascarTrailerWeatherHistoricalTableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NascarTrailerWeatherHistoricalTableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NascarTrailerWeatherHistoricalTableRequest.displayName = 'proto.NascarTrailerWeatherHistoricalTableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NascarTrailerWeatherHistoricalTableResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.NascarTrailerWeatherHistoricalTableResponse.repeatedFields_, null);
};
goog.inherits(proto.NascarTrailerWeatherHistoricalTableResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NascarTrailerWeatherHistoricalTableResponse.displayName = 'proto.NascarTrailerWeatherHistoricalTableResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NascarTrailerWeatherTableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NascarTrailerWeatherTableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NascarTrailerWeatherTableRequest.displayName = 'proto.NascarTrailerWeatherTableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.NascarTrailerWeatherTableResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.NascarTrailerWeatherTableResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.NascarTrailerWeatherTableResponse.displayName = 'proto.NascarTrailerWeatherTableResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IndyWindDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IndyWindDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IndyWindDataRequest.displayName = 'proto.IndyWindDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IndyWindDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.IndyWindDataResponse.repeatedFields_, null);
};
goog.inherits(proto.IndyWindDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IndyWindDataResponse.displayName = 'proto.IndyWindDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IndyWindStationIdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IndyWindStationIdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IndyWindStationIdsRequest.displayName = 'proto.IndyWindStationIdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IndyWindStationIdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.IndyWindStationIdsResponse.repeatedFields_, null);
};
goog.inherits(proto.IndyWindStationIdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IndyWindStationIdsResponse.displayName = 'proto.IndyWindStationIdsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StationIdAlias = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StationIdAlias, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StationIdAlias.displayName = 'proto.StationIdAlias';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateIndyWindStationIdAliasRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UpdateIndyWindStationIdAliasRequest.repeatedFields_, null);
};
goog.inherits(proto.UpdateIndyWindStationIdAliasRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateIndyWindStationIdAliasRequest.displayName = 'proto.UpdateIndyWindStationIdAliasRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SessionTimingScoringRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SessionTimingScoringRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SessionTimingScoringRequest.displayName = 'proto.SessionTimingScoringRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SessionTimingScoringResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SessionTimingScoringResponse.repeatedFields_, null);
};
goog.inherits(proto.SessionTimingScoringResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SessionTimingScoringResponse.displayName = 'proto.SessionTimingScoringResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SessionProgressionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SessionProgressionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SessionProgressionRequest.displayName = 'proto.SessionProgressionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SessionProgressionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SessionProgressionResponse.repeatedFields_, null);
};
goog.inherits(proto.SessionProgressionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SessionProgressionResponse.displayName = 'proto.SessionProgressionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.WorkspaceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.WorkspaceRequest.repeatedFields_, null);
};
goog.inherits(proto.WorkspaceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.WorkspaceRequest.displayName = 'proto.WorkspaceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.WorkspaceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.WorkspaceResponse.repeatedFields_, null);
};
goog.inherits(proto.WorkspaceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.WorkspaceResponse.displayName = 'proto.WorkspaceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.WorkspaceResponseStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.WorkspaceResponseStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.WorkspaceResponseStatus.displayName = 'proto.WorkspaceResponseStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.WorkspaceData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.WorkspaceData.repeatedFields_, null);
};
goog.inherits(proto.WorkspaceData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.WorkspaceData.displayName = 'proto.WorkspaceData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IndyWeatherTrackRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IndyWeatherTrackRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IndyWeatherTrackRequest.displayName = 'proto.IndyWeatherTrackRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IndyWeatherTrackResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IndyWeatherTrackResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IndyWeatherTrackResponse.displayName = 'proto.IndyWeatherTrackResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Empty.displayName = 'proto.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GenericResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GenericResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GenericResponse.displayName = 'proto.GenericResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SessionPitRoadSummaryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SessionPitRoadSummaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SessionPitRoadSummaryRequest.displayName = 'proto.SessionPitRoadSummaryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SessionPitRoadSummaryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SessionPitRoadSummaryResponse.repeatedFields_, null);
};
goog.inherits(proto.SessionPitRoadSummaryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SessionPitRoadSummaryResponse.displayName = 'proto.SessionPitRoadSummaryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SessionPitRoadAnalysisRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SessionPitRoadAnalysisRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SessionPitRoadAnalysisRequest.displayName = 'proto.SessionPitRoadAnalysisRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SessionPitRoadAnalysisResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SessionPitRoadAnalysisResponse.repeatedFields_, null);
};
goog.inherits(proto.SessionPitRoadAnalysisResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SessionPitRoadAnalysisResponse.displayName = 'proto.SessionPitRoadAnalysisResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserPreferenceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserPreferenceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserPreferenceResponse.displayName = 'proto.UserPreferenceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserPreferenceResponseStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserPreferenceResponseStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserPreferenceResponseStatus.displayName = 'proto.UserPreferenceResponseStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserPreferenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserPreferenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserPreferenceRequest.displayName = 'proto.UserPreferenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.FeedStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.FeedStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.FeedStatus.displayName = 'proto.FeedStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.FeedStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.FeedStatusResponse.repeatedFields_, null);
};
goog.inherits(proto.FeedStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.FeedStatusResponse.displayName = 'proto.FeedStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.QualifyingLapDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.QualifyingLapDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.QualifyingLapDataRequest.displayName = 'proto.QualifyingLapDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.QualifyingLapDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.QualifyingLapDataResponse.repeatedFields_, null);
};
goog.inherits(proto.QualifyingLapDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.QualifyingLapDataResponse.displayName = 'proto.QualifyingLapDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AzureBlobSasTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AzureBlobSasTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AzureBlobSasTokenRequest.displayName = 'proto.AzureBlobSasTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AzureBlobSasTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AzureBlobSasTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AzureBlobSasTokenResponse.displayName = 'proto.AzureBlobSasTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PointsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PointsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PointsRequest.displayName = 'proto.PointsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PointsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.PointsResponse.oneofGroups_);
};
goog.inherits(proto.PointsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PointsResponse.displayName = 'proto.PointsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.FastDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.FastDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.FastDataRequest.displayName = 'proto.FastDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.FastDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.FastDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.FastDataResponse.displayName = 'proto.FastDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RankAnalysisRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RankAnalysisRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RankAnalysisRequest.displayName = 'proto.RankAnalysisRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RankAnalysisResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RankAnalysisResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RankAnalysisResponse.displayName = 'proto.RankAnalysisResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProcessRaceReplayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ProcessRaceReplayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ProcessRaceReplayRequest.displayName = 'proto.ProcessRaceReplayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProcessRaceReplayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ProcessRaceReplayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ProcessRaceReplayResponse.displayName = 'proto.ProcessRaceReplayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ClearCacheRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ClearCacheRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ClearCacheRequest.displayName = 'proto.ClearCacheRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ClearCacheResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ClearCacheResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ClearCacheResponse.displayName = 'proto.ClearCacheResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DriverPointsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DriverPointsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DriverPointsRequest.displayName = 'proto.DriverPointsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DriverPointsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DriverPointsResponse.repeatedFields_, null);
};
goog.inherits(proto.DriverPointsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DriverPointsResponse.displayName = 'proto.DriverPointsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetAllTeamsDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetAllTeamsDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetAllTeamsDataRequest.displayName = 'proto.GetAllTeamsDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetAllTeamsDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetAllTeamsDataResponse.repeatedFields_, null);
};
goog.inherits(proto.GetAllTeamsDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetAllTeamsDataResponse.displayName = 'proto.GetAllTeamsDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateAdminSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateAdminSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateAdminSettingsRequest.displayName = 'proto.UpdateAdminSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateAdminSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateAdminSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateAdminSettingsResponse.displayName = 'proto.UpdateAdminSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetAdminSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetAdminSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetAdminSettingsRequest.displayName = 'proto.GetAdminSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetAdminSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetAdminSettingsResponse.repeatedFields_, null);
};
goog.inherits(proto.GetAdminSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetAdminSettingsResponse.displayName = 'proto.GetAdminSettingsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EventsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.EventsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EventsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriesId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    season: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EventsRequest}
 */
proto.EventsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EventsRequest;
  return proto.EventsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EventsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EventsRequest}
 */
proto.EventsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeriesId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EventsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EventsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EventsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriesId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string series_id = 1;
 * @return {string}
 */
proto.EventsRequest.prototype.getSeriesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EventsRequest} returns this
 */
proto.EventsRequest.prototype.setSeriesId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string season = 2;
 * @return {string}
 */
proto.EventsRequest.prototype.getSeason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.EventsRequest} returns this
 */
proto.EventsRequest.prototype.setSeason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EventsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.EventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    msuimsgs_pb.EventSessions.toObject, includeInstance),
    sessionsList: jspb.Message.toObjectList(msg.getSessionsList(),
    msuimsgs_pb.Session.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EventsResponse}
 */
proto.EventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EventsResponse;
  return proto.EventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EventsResponse}
 */
proto.EventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.EventSessions;
      reader.readMessage(value,msuimsgs_pb.EventSessions.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    case 2:
      var value = new msuimsgs_pb.Session;
      reader.readMessage(value,msuimsgs_pb.Session.deserializeBinaryFromReader);
      msg.addSessions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.EventSessions.serializeBinaryToWriter
    );
  }
  f = message.getSessionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      msuimsgs_pb.Session.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventSessions events = 1;
 * @return {!Array<!proto.EventSessions>}
 */
proto.EventsResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.EventSessions>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.EventSessions, 1));
};


/**
 * @param {!Array<!proto.EventSessions>} value
 * @return {!proto.EventsResponse} returns this
*/
proto.EventsResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.EventSessions=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EventSessions}
 */
proto.EventsResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.EventSessions, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EventsResponse} returns this
 */
proto.EventsResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * repeated Session sessions = 2;
 * @return {!Array<!proto.Session>}
 */
proto.EventsResponse.prototype.getSessionsList = function() {
  return /** @type{!Array<!proto.Session>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.Session, 2));
};


/**
 * @param {!Array<!proto.Session>} value
 * @return {!proto.EventsResponse} returns this
*/
proto.EventsResponse.prototype.setSessionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.Session=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Session}
 */
proto.EventsResponse.prototype.addSessions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.Session, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EventsResponse} returns this
 */
proto.EventsResponse.prototype.clearSessionsList = function() {
  return this.setSessionsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.EventSessionDetailsRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.EventSessionDetailsRequest.ParameterCase = {
  PARAMETER_NOT_SET: 0,
  SESSION_ID: 1,
  TRACK_EVENT_ID: 2
};

/**
 * @return {proto.EventSessionDetailsRequest.ParameterCase}
 */
proto.EventSessionDetailsRequest.prototype.getParameterCase = function() {
  return /** @type {proto.EventSessionDetailsRequest.ParameterCase} */(jspb.Message.computeOneofCase(this, proto.EventSessionDetailsRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EventSessionDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.EventSessionDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EventSessionDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventSessionDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    trackEventId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EventSessionDetailsRequest}
 */
proto.EventSessionDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EventSessionDetailsRequest;
  return proto.EventSessionDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EventSessionDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EventSessionDetailsRequest}
 */
proto.EventSessionDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EventSessionDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EventSessionDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EventSessionDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventSessionDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.EventSessionDetailsRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EventSessionDetailsRequest} returns this
 */
proto.EventSessionDetailsRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.EventSessionDetailsRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.EventSessionDetailsRequest} returns this
 */
proto.EventSessionDetailsRequest.prototype.clearSessionId = function() {
  return jspb.Message.setOneofField(this, 1, proto.EventSessionDetailsRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EventSessionDetailsRequest.prototype.hasSessionId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string track_event_id = 2;
 * @return {string}
 */
proto.EventSessionDetailsRequest.prototype.getTrackEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.EventSessionDetailsRequest} returns this
 */
proto.EventSessionDetailsRequest.prototype.setTrackEventId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.EventSessionDetailsRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.EventSessionDetailsRequest} returns this
 */
proto.EventSessionDetailsRequest.prototype.clearTrackEventId = function() {
  return jspb.Message.setOneofField(this, 2, proto.EventSessionDetailsRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EventSessionDetailsRequest.prototype.hasTrackEventId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EventSessionDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.EventSessionDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EventSessionDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventSessionDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: (f = msg.getSession()) && msuimsgs_pb.Session.toObject(includeInstance, f),
    event: (f = msg.getEvent()) && msuimsgs_pb.Event.toObject(includeInstance, f),
    track: (f = msg.getTrack()) && msuimsgs_pb.Track.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EventSessionDetailsResponse}
 */
proto.EventSessionDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EventSessionDetailsResponse;
  return proto.EventSessionDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EventSessionDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EventSessionDetailsResponse}
 */
proto.EventSessionDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.Session;
      reader.readMessage(value,msuimsgs_pb.Session.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 2:
      var value = new msuimsgs_pb.Event;
      reader.readMessage(value,msuimsgs_pb.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 3:
      var value = new msuimsgs_pb.Track;
      reader.readMessage(value,msuimsgs_pb.Track.deserializeBinaryFromReader);
      msg.setTrack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EventSessionDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EventSessionDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EventSessionDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventSessionDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msuimsgs_pb.Session.serializeBinaryToWriter
    );
  }
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msuimsgs_pb.Event.serializeBinaryToWriter
    );
  }
  f = message.getTrack();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      msuimsgs_pb.Track.serializeBinaryToWriter
    );
  }
};


/**
 * optional Session session = 1;
 * @return {?proto.Session}
 */
proto.EventSessionDetailsResponse.prototype.getSession = function() {
  return /** @type{?proto.Session} */ (
    jspb.Message.getWrapperField(this, msuimsgs_pb.Session, 1));
};


/**
 * @param {?proto.Session|undefined} value
 * @return {!proto.EventSessionDetailsResponse} returns this
*/
proto.EventSessionDetailsResponse.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EventSessionDetailsResponse} returns this
 */
proto.EventSessionDetailsResponse.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EventSessionDetailsResponse.prototype.hasSession = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Event event = 2;
 * @return {?proto.Event}
 */
proto.EventSessionDetailsResponse.prototype.getEvent = function() {
  return /** @type{?proto.Event} */ (
    jspb.Message.getWrapperField(this, msuimsgs_pb.Event, 2));
};


/**
 * @param {?proto.Event|undefined} value
 * @return {!proto.EventSessionDetailsResponse} returns this
*/
proto.EventSessionDetailsResponse.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EventSessionDetailsResponse} returns this
 */
proto.EventSessionDetailsResponse.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EventSessionDetailsResponse.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Track track = 3;
 * @return {?proto.Track}
 */
proto.EventSessionDetailsResponse.prototype.getTrack = function() {
  return /** @type{?proto.Track} */ (
    jspb.Message.getWrapperField(this, msuimsgs_pb.Track, 3));
};


/**
 * @param {?proto.Track|undefined} value
 * @return {!proto.EventSessionDetailsResponse} returns this
*/
proto.EventSessionDetailsResponse.prototype.setTrack = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EventSessionDetailsResponse} returns this
 */
proto.EventSessionDetailsResponse.prototype.clearTrack = function() {
  return this.setTrack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EventSessionDetailsResponse.prototype.hasTrack = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EventSessionsStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.EventSessionsStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EventSessionsStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventSessionsStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EventSessionsStatusRequest}
 */
proto.EventSessionsStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EventSessionsStatusRequest;
  return proto.EventSessionsStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EventSessionsStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EventSessionsStatusRequest}
 */
proto.EventSessionsStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EventSessionsStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EventSessionsStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EventSessionsStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventSessionsStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.EventSessionsStatusRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EventSessionsStatusRequest} returns this
 */
proto.EventSessionsStatusRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EventSessionsStatusResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EventSessionsStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.EventSessionsStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EventSessionsStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventSessionsStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionList: jspb.Message.toObjectList(msg.getSessionList(),
    msuimsgs_pb.Session.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EventSessionsStatusResponse}
 */
proto.EventSessionsStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EventSessionsStatusResponse;
  return proto.EventSessionsStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EventSessionsStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EventSessionsStatusResponse}
 */
proto.EventSessionsStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = new msuimsgs_pb.Session;
      reader.readMessage(value,msuimsgs_pb.Session.deserializeBinaryFromReader);
      msg.addSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EventSessionsStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EventSessionsStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EventSessionsStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventSessionsStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      msuimsgs_pb.Session.serializeBinaryToWriter
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.EventSessionsStatusResponse.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EventSessionsStatusResponse} returns this
 */
proto.EventSessionsStatusResponse.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Session session = 2;
 * @return {!Array<!proto.Session>}
 */
proto.EventSessionsStatusResponse.prototype.getSessionList = function() {
  return /** @type{!Array<!proto.Session>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.Session, 2));
};


/**
 * @param {!Array<!proto.Session>} value
 * @return {!proto.EventSessionsStatusResponse} returns this
*/
proto.EventSessionsStatusResponse.prototype.setSessionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.Session=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Session}
 */
proto.EventSessionsStatusResponse.prototype.addSession = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.Session, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EventSessionsStatusResponse} returns this
 */
proto.EventSessionsStatusResponse.prototype.clearSessionList = function() {
  return this.setSessionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SeriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SeriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SeriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SeriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SeriesRequest}
 */
proto.SeriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SeriesRequest;
  return proto.SeriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SeriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SeriesRequest}
 */
proto.SeriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SeriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SeriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SeriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SeriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SeriesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SeriesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SeriesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SeriesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SeriesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriesList: jspb.Message.toObjectList(msg.getSeriesList(),
    msuimsgs_pb.Series.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SeriesResponse}
 */
proto.SeriesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SeriesResponse;
  return proto.SeriesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SeriesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SeriesResponse}
 */
proto.SeriesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.Series;
      reader.readMessage(value,msuimsgs_pb.Series.deserializeBinaryFromReader);
      msg.addSeries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SeriesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SeriesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SeriesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SeriesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.Series.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Series series = 1;
 * @return {!Array<!proto.Series>}
 */
proto.SeriesResponse.prototype.getSeriesList = function() {
  return /** @type{!Array<!proto.Series>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.Series, 1));
};


/**
 * @param {!Array<!proto.Series>} value
 * @return {!proto.SeriesResponse} returns this
*/
proto.SeriesResponse.prototype.setSeriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Series=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Series}
 */
proto.SeriesResponse.prototype.addSeries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Series, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SeriesResponse} returns this
 */
proto.SeriesResponse.prototype.clearSeriesList = function() {
  return this.setSeriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CurrentLiveSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CurrentLiveSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CurrentLiveSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CurrentLiveSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CurrentLiveSessionRequest}
 */
proto.CurrentLiveSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CurrentLiveSessionRequest;
  return proto.CurrentLiveSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CurrentLiveSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CurrentLiveSessionRequest}
 */
proto.CurrentLiveSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CurrentLiveSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CurrentLiveSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CurrentLiveSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CurrentLiveSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CurrentLiveSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CurrentLiveSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CurrentLiveSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CurrentLiveSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: (f = msg.getSession()) && msuimsgs_pb.SessionInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CurrentLiveSessionResponse}
 */
proto.CurrentLiveSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CurrentLiveSessionResponse;
  return proto.CurrentLiveSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CurrentLiveSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CurrentLiveSessionResponse}
 */
proto.CurrentLiveSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.SessionInfo;
      reader.readMessage(value,msuimsgs_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CurrentLiveSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CurrentLiveSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CurrentLiveSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CurrentLiveSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msuimsgs_pb.SessionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session = 1;
 * @return {?proto.SessionInfo}
 */
proto.CurrentLiveSessionResponse.prototype.getSession = function() {
  return /** @type{?proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, msuimsgs_pb.SessionInfo, 1));
};


/**
 * @param {?proto.SessionInfo|undefined} value
 * @return {!proto.CurrentLiveSessionResponse} returns this
*/
proto.CurrentLiveSessionResponse.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CurrentLiveSessionResponse} returns this
 */
proto.CurrentLiveSessionResponse.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CurrentLiveSessionResponse.prototype.hasSession = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CurrentLiveSessionStreamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CurrentLiveSessionStreamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CurrentLiveSessionStreamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CurrentLiveSessionStreamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: (f = msg.getSession()) && msuimsgs_pb.SessionInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CurrentLiveSessionStreamResponse}
 */
proto.CurrentLiveSessionStreamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CurrentLiveSessionStreamResponse;
  return proto.CurrentLiveSessionStreamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CurrentLiveSessionStreamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CurrentLiveSessionStreamResponse}
 */
proto.CurrentLiveSessionStreamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.SessionInfo;
      reader.readMessage(value,msuimsgs_pb.SessionInfo.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CurrentLiveSessionStreamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CurrentLiveSessionStreamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CurrentLiveSessionStreamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CurrentLiveSessionStreamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msuimsgs_pb.SessionInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionInfo session = 1;
 * @return {?proto.SessionInfo}
 */
proto.CurrentLiveSessionStreamResponse.prototype.getSession = function() {
  return /** @type{?proto.SessionInfo} */ (
    jspb.Message.getWrapperField(this, msuimsgs_pb.SessionInfo, 1));
};


/**
 * @param {?proto.SessionInfo|undefined} value
 * @return {!proto.CurrentLiveSessionStreamResponse} returns this
*/
proto.CurrentLiveSessionStreamResponse.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CurrentLiveSessionStreamResponse} returns this
 */
proto.CurrentLiveSessionStreamResponse.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CurrentLiveSessionStreamResponse.prototype.hasSession = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.LapDataRequest.repeatedFields_ = [2,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LapDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.LapDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LapDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    filterVehicleList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    movingAverageRequest: (f = msg.getMovingAverageRequest()) && proto.MovingAverageRequest.toObject(includeInstance, f),
    sessionId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sessionType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    seriesIdList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    isContinuousSession: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    dataSourceName: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LapDataRequest}
 */
proto.LapDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LapDataRequest;
  return proto.LapDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LapDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LapDataRequest}
 */
proto.LapDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFilterVehicle(value);
      break;
    case 3:
      var value = new proto.MovingAverageRequest;
      reader.readMessage(value,proto.MovingAverageRequest.deserializeBinaryFromReader);
      msg.setMovingAverageRequest(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 5:
      var value = /** @type {!proto.RunType} */ (reader.readEnum());
      msg.setSessionType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addSeriesId(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsContinuousSession(value);
      break;
    case 8:
      var value = /** @type {!proto.OriginType} */ (reader.readEnum());
      msg.setDataSourceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LapDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LapDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LapDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFilterVehicleList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getMovingAverageRequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.MovingAverageRequest.serializeBinaryToWriter
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSessionType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSeriesIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getIsContinuousSession();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getDataSourceName();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.LapDataRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.LapDataRequest} returns this
 */
proto.LapDataRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string filter_vehicle = 2;
 * @return {!Array<string>}
 */
proto.LapDataRequest.prototype.getFilterVehicleList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.LapDataRequest} returns this
 */
proto.LapDataRequest.prototype.setFilterVehicleList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.LapDataRequest} returns this
 */
proto.LapDataRequest.prototype.addFilterVehicle = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.LapDataRequest} returns this
 */
proto.LapDataRequest.prototype.clearFilterVehicleList = function() {
  return this.setFilterVehicleList([]);
};


/**
 * optional MovingAverageRequest moving_average_request = 3;
 * @return {?proto.MovingAverageRequest}
 */
proto.LapDataRequest.prototype.getMovingAverageRequest = function() {
  return /** @type{?proto.MovingAverageRequest} */ (
    jspb.Message.getWrapperField(this, proto.MovingAverageRequest, 3));
};


/**
 * @param {?proto.MovingAverageRequest|undefined} value
 * @return {!proto.LapDataRequest} returns this
*/
proto.LapDataRequest.prototype.setMovingAverageRequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.LapDataRequest} returns this
 */
proto.LapDataRequest.prototype.clearMovingAverageRequest = function() {
  return this.setMovingAverageRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LapDataRequest.prototype.hasMovingAverageRequest = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string session_id = 4;
 * @return {string}
 */
proto.LapDataRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.LapDataRequest} returns this
 */
proto.LapDataRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional RunType session_type = 5;
 * @return {!proto.RunType}
 */
proto.LapDataRequest.prototype.getSessionType = function() {
  return /** @type {!proto.RunType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.RunType} value
 * @return {!proto.LapDataRequest} returns this
 */
proto.LapDataRequest.prototype.setSessionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * repeated string series_id = 6;
 * @return {!Array<string>}
 */
proto.LapDataRequest.prototype.getSeriesIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.LapDataRequest} returns this
 */
proto.LapDataRequest.prototype.setSeriesIdList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.LapDataRequest} returns this
 */
proto.LapDataRequest.prototype.addSeriesId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.LapDataRequest} returns this
 */
proto.LapDataRequest.prototype.clearSeriesIdList = function() {
  return this.setSeriesIdList([]);
};


/**
 * optional bool is_continuous_session = 7;
 * @return {boolean}
 */
proto.LapDataRequest.prototype.getIsContinuousSession = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.LapDataRequest} returns this
 */
proto.LapDataRequest.prototype.setIsContinuousSession = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional OriginType data_source_name = 8;
 * @return {!proto.OriginType}
 */
proto.LapDataRequest.prototype.getDataSourceName = function() {
  return /** @type {!proto.OriginType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.OriginType} value
 * @return {!proto.LapDataRequest} returns this
 */
proto.LapDataRequest.prototype.setDataSourceName = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MovingAverageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.MovingAverageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MovingAverageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MovingAverageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lapCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    excludeCaution: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    excludeSlowLaps: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MovingAverageRequest}
 */
proto.MovingAverageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MovingAverageRequest;
  return proto.MovingAverageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MovingAverageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MovingAverageRequest}
 */
proto.MovingAverageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapCount(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeCaution(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludeSlowLaps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MovingAverageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MovingAverageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MovingAverageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MovingAverageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLapCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getExcludeCaution();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getExcludeSlowLaps();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 lap_count = 1;
 * @return {number}
 */
proto.MovingAverageRequest.prototype.getLapCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.MovingAverageRequest} returns this
 */
proto.MovingAverageRequest.prototype.setLapCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool exclude_caution = 2;
 * @return {boolean}
 */
proto.MovingAverageRequest.prototype.getExcludeCaution = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.MovingAverageRequest} returns this
 */
proto.MovingAverageRequest.prototype.setExcludeCaution = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool exclude_slow_laps = 3;
 * @return {boolean}
 */
proto.MovingAverageRequest.prototype.getExcludeSlowLaps = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.MovingAverageRequest} returns this
 */
proto.MovingAverageRequest.prototype.setExcludeSlowLaps = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.LapDataResponseArr.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LapDataResponseArr.prototype.toObject = function(opt_includeInstance) {
  return proto.LapDataResponseArr.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LapDataResponseArr} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapDataResponseArr.toObject = function(includeInstance, msg) {
  var f, obj = {
    lapDataList: jspb.Message.toObjectList(msg.getLapDataList(),
    msuimsgs_pb.LapData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LapDataResponseArr}
 */
proto.LapDataResponseArr.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LapDataResponseArr;
  return proto.LapDataResponseArr.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LapDataResponseArr} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LapDataResponseArr}
 */
proto.LapDataResponseArr.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.LapData;
      reader.readMessage(value,msuimsgs_pb.LapData.deserializeBinaryFromReader);
      msg.addLapData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LapDataResponseArr.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LapDataResponseArr.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LapDataResponseArr} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapDataResponseArr.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLapDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.LapData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LapData lap_data = 1;
 * @return {!Array<!proto.LapData>}
 */
proto.LapDataResponseArr.prototype.getLapDataList = function() {
  return /** @type{!Array<!proto.LapData>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.LapData, 1));
};


/**
 * @param {!Array<!proto.LapData>} value
 * @return {!proto.LapDataResponseArr} returns this
*/
proto.LapDataResponseArr.prototype.setLapDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.LapData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.LapData}
 */
proto.LapDataResponseArr.prototype.addLapData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.LapData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.LapDataResponseArr} returns this
 */
proto.LapDataResponseArr.prototype.clearLapDataList = function() {
  return this.setLapDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TelemetryDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TelemetryDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TelemetryDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TelemetryDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sessionType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TelemetryDataRequest}
 */
proto.TelemetryDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TelemetryDataRequest;
  return proto.TelemetryDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TelemetryDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TelemetryDataRequest}
 */
proto.TelemetryDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 3:
      var value = /** @type {!proto.RunType} */ (reader.readEnum());
      msg.setSessionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TelemetryDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TelemetryDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TelemetryDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TelemetryDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSessionType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.TelemetryDataRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TelemetryDataRequest} returns this
 */
proto.TelemetryDataRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string session_id = 2;
 * @return {string}
 */
proto.TelemetryDataRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TelemetryDataRequest} returns this
 */
proto.TelemetryDataRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RunType session_type = 3;
 * @return {!proto.RunType}
 */
proto.TelemetryDataRequest.prototype.getSessionType = function() {
  return /** @type {!proto.RunType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.RunType} value
 * @return {!proto.TelemetryDataRequest} returns this
 */
proto.TelemetryDataRequest.prototype.setSessionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TelemetryDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.TelemetryDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TelemetryDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TelemetryDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    telemetryData: (f = msg.getTelemetryData()) && msuimsgs_pb.TelemetryData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TelemetryDataResponse}
 */
proto.TelemetryDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TelemetryDataResponse;
  return proto.TelemetryDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TelemetryDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TelemetryDataResponse}
 */
proto.TelemetryDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.TelemetryData;
      reader.readMessage(value,msuimsgs_pb.TelemetryData.deserializeBinaryFromReader);
      msg.setTelemetryData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TelemetryDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TelemetryDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TelemetryDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TelemetryDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTelemetryData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msuimsgs_pb.TelemetryData.serializeBinaryToWriter
    );
  }
};


/**
 * optional TelemetryData telemetry_data = 1;
 * @return {?proto.TelemetryData}
 */
proto.TelemetryDataResponse.prototype.getTelemetryData = function() {
  return /** @type{?proto.TelemetryData} */ (
    jspb.Message.getWrapperField(this, msuimsgs_pb.TelemetryData, 1));
};


/**
 * @param {?proto.TelemetryData|undefined} value
 * @return {!proto.TelemetryDataResponse} returns this
*/
proto.TelemetryDataResponse.prototype.setTelemetryData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TelemetryDataResponse} returns this
 */
proto.TelemetryDataResponse.prototype.clearTelemetryData = function() {
  return this.setTelemetryData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TelemetryDataResponse.prototype.hasTelemetryData = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.RaceProgressionDataRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaceProgressionDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.RaceProgressionDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaceProgressionDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaceProgressionDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sessionType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    seriesIdList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    isContinuousSession: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    dataSourceName: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaceProgressionDataRequest}
 */
proto.RaceProgressionDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaceProgressionDataRequest;
  return proto.RaceProgressionDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaceProgressionDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaceProgressionDataRequest}
 */
proto.RaceProgressionDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 3:
      var value = /** @type {!proto.RunType} */ (reader.readEnum());
      msg.setSessionType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSeriesId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsContinuousSession(value);
      break;
    case 6:
      var value = /** @type {!proto.OriginType} */ (reader.readEnum());
      msg.setDataSourceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaceProgressionDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaceProgressionDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaceProgressionDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaceProgressionDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSessionType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSeriesIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getIsContinuousSession();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDataSourceName();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.RaceProgressionDataRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaceProgressionDataRequest} returns this
 */
proto.RaceProgressionDataRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string session_id = 2;
 * @return {string}
 */
proto.RaceProgressionDataRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaceProgressionDataRequest} returns this
 */
proto.RaceProgressionDataRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RunType session_type = 3;
 * @return {!proto.RunType}
 */
proto.RaceProgressionDataRequest.prototype.getSessionType = function() {
  return /** @type {!proto.RunType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.RunType} value
 * @return {!proto.RaceProgressionDataRequest} returns this
 */
proto.RaceProgressionDataRequest.prototype.setSessionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated string series_id = 4;
 * @return {!Array<string>}
 */
proto.RaceProgressionDataRequest.prototype.getSeriesIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.RaceProgressionDataRequest} returns this
 */
proto.RaceProgressionDataRequest.prototype.setSeriesIdList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.RaceProgressionDataRequest} returns this
 */
proto.RaceProgressionDataRequest.prototype.addSeriesId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.RaceProgressionDataRequest} returns this
 */
proto.RaceProgressionDataRequest.prototype.clearSeriesIdList = function() {
  return this.setSeriesIdList([]);
};


/**
 * optional bool is_continuous_session = 5;
 * @return {boolean}
 */
proto.RaceProgressionDataRequest.prototype.getIsContinuousSession = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.RaceProgressionDataRequest} returns this
 */
proto.RaceProgressionDataRequest.prototype.setIsContinuousSession = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional OriginType data_source_name = 6;
 * @return {!proto.OriginType}
 */
proto.RaceProgressionDataRequest.prototype.getDataSourceName = function() {
  return /** @type {!proto.OriginType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.OriginType} value
 * @return {!proto.RaceProgressionDataRequest} returns this
 */
proto.RaceProgressionDataRequest.prototype.setDataSourceName = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaceProgressionDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.RaceProgressionDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaceProgressionDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaceProgressionDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    raceProgressionData: (f = msg.getRaceProgressionData()) && msuimsgs_pb.RaceProgressionData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaceProgressionDataResponse}
 */
proto.RaceProgressionDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaceProgressionDataResponse;
  return proto.RaceProgressionDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaceProgressionDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaceProgressionDataResponse}
 */
proto.RaceProgressionDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.RaceProgressionData;
      reader.readMessage(value,msuimsgs_pb.RaceProgressionData.deserializeBinaryFromReader);
      msg.setRaceProgressionData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaceProgressionDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaceProgressionDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaceProgressionDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaceProgressionDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRaceProgressionData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msuimsgs_pb.RaceProgressionData.serializeBinaryToWriter
    );
  }
};


/**
 * optional RaceProgressionData race_progression_data = 1;
 * @return {?proto.RaceProgressionData}
 */
proto.RaceProgressionDataResponse.prototype.getRaceProgressionData = function() {
  return /** @type{?proto.RaceProgressionData} */ (
    jspb.Message.getWrapperField(this, msuimsgs_pb.RaceProgressionData, 1));
};


/**
 * @param {?proto.RaceProgressionData|undefined} value
 * @return {!proto.RaceProgressionDataResponse} returns this
*/
proto.RaceProgressionDataResponse.prototype.setRaceProgressionData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RaceProgressionDataResponse} returns this
 */
proto.RaceProgressionDataResponse.prototype.clearRaceProgressionData = function() {
  return this.setRaceProgressionData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RaceProgressionDataResponse.prototype.hasRaceProgressionData = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.LapHistoryDataRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LapHistoryDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.LapHistoryDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LapHistoryDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapHistoryDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sessionType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    seriesIdList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    isContinuousSession: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    dataSourceName: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LapHistoryDataRequest}
 */
proto.LapHistoryDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LapHistoryDataRequest;
  return proto.LapHistoryDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LapHistoryDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LapHistoryDataRequest}
 */
proto.LapHistoryDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 3:
      var value = /** @type {!proto.RunType} */ (reader.readEnum());
      msg.setSessionType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSeriesId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsContinuousSession(value);
      break;
    case 6:
      var value = /** @type {!proto.OriginType} */ (reader.readEnum());
      msg.setDataSourceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LapHistoryDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LapHistoryDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LapHistoryDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapHistoryDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSessionType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getSeriesIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getIsContinuousSession();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getDataSourceName();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.LapHistoryDataRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.LapHistoryDataRequest} returns this
 */
proto.LapHistoryDataRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string session_id = 2;
 * @return {string}
 */
proto.LapHistoryDataRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.LapHistoryDataRequest} returns this
 */
proto.LapHistoryDataRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RunType session_type = 3;
 * @return {!proto.RunType}
 */
proto.LapHistoryDataRequest.prototype.getSessionType = function() {
  return /** @type {!proto.RunType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.RunType} value
 * @return {!proto.LapHistoryDataRequest} returns this
 */
proto.LapHistoryDataRequest.prototype.setSessionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated string series_id = 4;
 * @return {!Array<string>}
 */
proto.LapHistoryDataRequest.prototype.getSeriesIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.LapHistoryDataRequest} returns this
 */
proto.LapHistoryDataRequest.prototype.setSeriesIdList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.LapHistoryDataRequest} returns this
 */
proto.LapHistoryDataRequest.prototype.addSeriesId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.LapHistoryDataRequest} returns this
 */
proto.LapHistoryDataRequest.prototype.clearSeriesIdList = function() {
  return this.setSeriesIdList([]);
};


/**
 * optional bool is_continuous_session = 5;
 * @return {boolean}
 */
proto.LapHistoryDataRequest.prototype.getIsContinuousSession = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.LapHistoryDataRequest} returns this
 */
proto.LapHistoryDataRequest.prototype.setIsContinuousSession = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional OriginType data_source_name = 6;
 * @return {!proto.OriginType}
 */
proto.LapHistoryDataRequest.prototype.getDataSourceName = function() {
  return /** @type {!proto.OriginType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.OriginType} value
 * @return {!proto.LapHistoryDataRequest} returns this
 */
proto.LapHistoryDataRequest.prototype.setDataSourceName = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.LapHistoryDataResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LapHistoryDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.LapHistoryDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LapHistoryDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapHistoryDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lapHistDataList: jspb.Message.toObjectList(msg.getLapHistDataList(),
    msuimsgs_pb.LapHistData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LapHistoryDataResponse}
 */
proto.LapHistoryDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LapHistoryDataResponse;
  return proto.LapHistoryDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LapHistoryDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LapHistoryDataResponse}
 */
proto.LapHistoryDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.LapHistData;
      reader.readMessage(value,msuimsgs_pb.LapHistData.deserializeBinaryFromReader);
      msg.addLapHistData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LapHistoryDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LapHistoryDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LapHistoryDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapHistoryDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLapHistDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.LapHistData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LapHistData lap_hist_data = 1;
 * @return {!Array<!proto.LapHistData>}
 */
proto.LapHistoryDataResponse.prototype.getLapHistDataList = function() {
  return /** @type{!Array<!proto.LapHistData>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.LapHistData, 1));
};


/**
 * @param {!Array<!proto.LapHistData>} value
 * @return {!proto.LapHistoryDataResponse} returns this
*/
proto.LapHistoryDataResponse.prototype.setLapHistDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.LapHistData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.LapHistData}
 */
proto.LapHistoryDataResponse.prototype.addLapHistData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.LapHistData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.LapHistoryDataResponse} returns this
 */
proto.LapHistoryDataResponse.prototype.clearLapHistDataList = function() {
  return this.setLapHistDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PitRoadDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.PitRoadDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PitRoadDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitRoadDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sessionType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    returnLatestData: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PitRoadDataRequest}
 */
proto.PitRoadDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PitRoadDataRequest;
  return proto.PitRoadDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PitRoadDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PitRoadDataRequest}
 */
proto.PitRoadDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 3:
      var value = /** @type {!proto.RunType} */ (reader.readEnum());
      msg.setSessionType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnLatestData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PitRoadDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PitRoadDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PitRoadDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitRoadDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSessionType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getReturnLatestData();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.PitRoadDataRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PitRoadDataRequest} returns this
 */
proto.PitRoadDataRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string session_id = 2;
 * @return {string}
 */
proto.PitRoadDataRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PitRoadDataRequest} returns this
 */
proto.PitRoadDataRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RunType session_type = 3;
 * @return {!proto.RunType}
 */
proto.PitRoadDataRequest.prototype.getSessionType = function() {
  return /** @type {!proto.RunType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.RunType} value
 * @return {!proto.PitRoadDataRequest} returns this
 */
proto.PitRoadDataRequest.prototype.setSessionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool return_latest_data = 4;
 * @return {boolean}
 */
proto.PitRoadDataRequest.prototype.getReturnLatestData = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PitRoadDataRequest} returns this
 */
proto.PitRoadDataRequest.prototype.setReturnLatestData = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PitRoadDataArrayResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PitRoadDataArrayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.PitRoadDataArrayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PitRoadDataArrayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitRoadDataArrayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pitRoadDataList: jspb.Message.toObjectList(msg.getPitRoadDataList(),
    msuimsgs_pb.SessionPitSummaryMetaData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PitRoadDataArrayResponse}
 */
proto.PitRoadDataArrayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PitRoadDataArrayResponse;
  return proto.PitRoadDataArrayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PitRoadDataArrayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PitRoadDataArrayResponse}
 */
proto.PitRoadDataArrayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.SessionPitSummaryMetaData;
      reader.readMessage(value,msuimsgs_pb.SessionPitSummaryMetaData.deserializeBinaryFromReader);
      msg.addPitRoadData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PitRoadDataArrayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PitRoadDataArrayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PitRoadDataArrayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitRoadDataArrayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPitRoadDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.SessionPitSummaryMetaData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SessionPitSummaryMetaData pit_road_data = 1;
 * @return {!Array<!proto.SessionPitSummaryMetaData>}
 */
proto.PitRoadDataArrayResponse.prototype.getPitRoadDataList = function() {
  return /** @type{!Array<!proto.SessionPitSummaryMetaData>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.SessionPitSummaryMetaData, 1));
};


/**
 * @param {!Array<!proto.SessionPitSummaryMetaData>} value
 * @return {!proto.PitRoadDataArrayResponse} returns this
*/
proto.PitRoadDataArrayResponse.prototype.setPitRoadDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.SessionPitSummaryMetaData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SessionPitSummaryMetaData}
 */
proto.PitRoadDataArrayResponse.prototype.addPitRoadData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SessionPitSummaryMetaData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PitRoadDataArrayResponse} returns this
 */
proto.PitRoadDataArrayResponse.prototype.clearPitRoadDataList = function() {
  return this.setPitRoadDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DriverVehicleSeriesDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DriverVehicleSeriesDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DriverVehicleSeriesDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DriverVehicleSeriesDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriesId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    year: jspb.Message.getFieldWithDefault(msg, 2, 0),
    eventId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DriverVehicleSeriesDataRequest}
 */
proto.DriverVehicleSeriesDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DriverVehicleSeriesDataRequest;
  return proto.DriverVehicleSeriesDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DriverVehicleSeriesDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DriverVehicleSeriesDataRequest}
 */
proto.DriverVehicleSeriesDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeriesId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DriverVehicleSeriesDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DriverVehicleSeriesDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DriverVehicleSeriesDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DriverVehicleSeriesDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriesId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string series_id = 1;
 * @return {string}
 */
proto.DriverVehicleSeriesDataRequest.prototype.getSeriesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DriverVehicleSeriesDataRequest} returns this
 */
proto.DriverVehicleSeriesDataRequest.prototype.setSeriesId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 year = 2;
 * @return {number}
 */
proto.DriverVehicleSeriesDataRequest.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverVehicleSeriesDataRequest} returns this
 */
proto.DriverVehicleSeriesDataRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string event_id = 3;
 * @return {string}
 */
proto.DriverVehicleSeriesDataRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.DriverVehicleSeriesDataRequest} returns this
 */
proto.DriverVehicleSeriesDataRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DriverVehicleSeriesDataResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DriverVehicleSeriesDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DriverVehicleSeriesDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DriverVehicleSeriesDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DriverVehicleSeriesDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    driverVehicleDataList: jspb.Message.toObjectList(msg.getDriverVehicleDataList(),
    msuimsgs_pb.DriverVehicleSeriesData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DriverVehicleSeriesDataResponse}
 */
proto.DriverVehicleSeriesDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DriverVehicleSeriesDataResponse;
  return proto.DriverVehicleSeriesDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DriverVehicleSeriesDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DriverVehicleSeriesDataResponse}
 */
proto.DriverVehicleSeriesDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.DriverVehicleSeriesData;
      reader.readMessage(value,msuimsgs_pb.DriverVehicleSeriesData.deserializeBinaryFromReader);
      msg.addDriverVehicleData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DriverVehicleSeriesDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DriverVehicleSeriesDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DriverVehicleSeriesDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DriverVehicleSeriesDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDriverVehicleDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.DriverVehicleSeriesData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DriverVehicleSeriesData driver_vehicle_data = 1;
 * @return {!Array<!proto.DriverVehicleSeriesData>}
 */
proto.DriverVehicleSeriesDataResponse.prototype.getDriverVehicleDataList = function() {
  return /** @type{!Array<!proto.DriverVehicleSeriesData>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.DriverVehicleSeriesData, 1));
};


/**
 * @param {!Array<!proto.DriverVehicleSeriesData>} value
 * @return {!proto.DriverVehicleSeriesDataResponse} returns this
*/
proto.DriverVehicleSeriesDataResponse.prototype.setDriverVehicleDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.DriverVehicleSeriesData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DriverVehicleSeriesData}
 */
proto.DriverVehicleSeriesDataResponse.prototype.addDriverVehicleData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.DriverVehicleSeriesData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DriverVehicleSeriesDataResponse} returns this
 */
proto.DriverVehicleSeriesDataResponse.prototype.clearDriverVehicleDataList = function() {
  return this.setDriverVehicleDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.VehicleOemBySeriesListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.VehicleOemBySeriesListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.VehicleOemBySeriesListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.VehicleOemBySeriesListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriesId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.VehicleOemBySeriesListRequest}
 */
proto.VehicleOemBySeriesListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.VehicleOemBySeriesListRequest;
  return proto.VehicleOemBySeriesListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.VehicleOemBySeriesListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.VehicleOemBySeriesListRequest}
 */
proto.VehicleOemBySeriesListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSeriesId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.VehicleOemBySeriesListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.VehicleOemBySeriesListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.VehicleOemBySeriesListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.VehicleOemBySeriesListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriesId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string series_id = 1;
 * @return {string}
 */
proto.VehicleOemBySeriesListRequest.prototype.getSeriesId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.VehicleOemBySeriesListRequest} returns this
 */
proto.VehicleOemBySeriesListRequest.prototype.setSeriesId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.VehicleOemBySeriesListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.VehicleOemBySeriesListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.VehicleOemBySeriesListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.VehicleOemBySeriesListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.VehicleOemBySeriesListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    oemDataList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.VehicleOemBySeriesListResponse}
 */
proto.VehicleOemBySeriesListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.VehicleOemBySeriesListResponse;
  return proto.VehicleOemBySeriesListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.VehicleOemBySeriesListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.VehicleOemBySeriesListResponse}
 */
proto.VehicleOemBySeriesListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addOemData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.VehicleOemBySeriesListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.VehicleOemBySeriesListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.VehicleOemBySeriesListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.VehicleOemBySeriesListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOemDataList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string oem_data = 1;
 * @return {!Array<string>}
 */
proto.VehicleOemBySeriesListResponse.prototype.getOemDataList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.VehicleOemBySeriesListResponse} returns this
 */
proto.VehicleOemBySeriesListResponse.prototype.setOemDataList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.VehicleOemBySeriesListResponse} returns this
 */
proto.VehicleOemBySeriesListResponse.prototype.addOemData = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.VehicleOemBySeriesListResponse} returns this
 */
proto.VehicleOemBySeriesListResponse.prototype.clearOemDataList = function() {
  return this.setOemDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PitRoadAnalysisRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.PitRoadAnalysisRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PitRoadAnalysisRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitRoadAnalysisRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sessionType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PitRoadAnalysisRequest}
 */
proto.PitRoadAnalysisRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PitRoadAnalysisRequest;
  return proto.PitRoadAnalysisRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PitRoadAnalysisRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PitRoadAnalysisRequest}
 */
proto.PitRoadAnalysisRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 3:
      var value = /** @type {!proto.RunType} */ (reader.readEnum());
      msg.setSessionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PitRoadAnalysisRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PitRoadAnalysisRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PitRoadAnalysisRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitRoadAnalysisRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSessionType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.PitRoadAnalysisRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PitRoadAnalysisRequest} returns this
 */
proto.PitRoadAnalysisRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string session_id = 2;
 * @return {string}
 */
proto.PitRoadAnalysisRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PitRoadAnalysisRequest} returns this
 */
proto.PitRoadAnalysisRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional RunType session_type = 3;
 * @return {!proto.RunType}
 */
proto.PitRoadAnalysisRequest.prototype.getSessionType = function() {
  return /** @type {!proto.RunType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.RunType} value
 * @return {!proto.PitRoadAnalysisRequest} returns this
 */
proto.PitRoadAnalysisRequest.prototype.setSessionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PitRoadAnalysisResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PitRoadAnalysisResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.PitRoadAnalysisResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PitRoadAnalysisResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitRoadAnalysisResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pitroadanalysisList: jspb.Message.toObjectList(msg.getPitroadanalysisList(),
    msuimsgs_pb.PitRoadAnalysis.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PitRoadAnalysisResponse}
 */
proto.PitRoadAnalysisResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PitRoadAnalysisResponse;
  return proto.PitRoadAnalysisResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PitRoadAnalysisResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PitRoadAnalysisResponse}
 */
proto.PitRoadAnalysisResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.PitRoadAnalysis;
      reader.readMessage(value,msuimsgs_pb.PitRoadAnalysis.deserializeBinaryFromReader);
      msg.addPitroadanalysis(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PitRoadAnalysisResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PitRoadAnalysisResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PitRoadAnalysisResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitRoadAnalysisResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPitroadanalysisList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.PitRoadAnalysis.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PitRoadAnalysis pitRoadAnalysis = 1;
 * @return {!Array<!proto.PitRoadAnalysis>}
 */
proto.PitRoadAnalysisResponse.prototype.getPitroadanalysisList = function() {
  return /** @type{!Array<!proto.PitRoadAnalysis>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.PitRoadAnalysis, 1));
};


/**
 * @param {!Array<!proto.PitRoadAnalysis>} value
 * @return {!proto.PitRoadAnalysisResponse} returns this
*/
proto.PitRoadAnalysisResponse.prototype.setPitroadanalysisList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.PitRoadAnalysis=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PitRoadAnalysis}
 */
proto.PitRoadAnalysisResponse.prototype.addPitroadanalysis = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.PitRoadAnalysis, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PitRoadAnalysisResponse} returns this
 */
proto.PitRoadAnalysisResponse.prototype.clearPitroadanalysisList = function() {
  return this.setPitroadanalysisList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SanctioningBodyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SanctioningBodyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SanctioningBodyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SanctioningBodyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SanctioningBodyRequest}
 */
proto.SanctioningBodyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SanctioningBodyRequest;
  return proto.SanctioningBodyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SanctioningBodyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SanctioningBodyRequest}
 */
proto.SanctioningBodyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SanctioningBodyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SanctioningBodyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SanctioningBodyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SanctioningBodyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SanctioningBodyResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SanctioningBodyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SanctioningBodyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SanctioningBodyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SanctioningBodyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sanctioningBodiesList: jspb.Message.toObjectList(msg.getSanctioningBodiesList(),
    msuimsgs_pb.SanctioningBody.toObject, includeInstance),
    sanctioningBodySeriesList: jspb.Message.toObjectList(msg.getSanctioningBodySeriesList(),
    msuimsgs_pb.SanctioningBodySeries.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SanctioningBodyResponse}
 */
proto.SanctioningBodyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SanctioningBodyResponse;
  return proto.SanctioningBodyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SanctioningBodyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SanctioningBodyResponse}
 */
proto.SanctioningBodyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.SanctioningBody;
      reader.readMessage(value,msuimsgs_pb.SanctioningBody.deserializeBinaryFromReader);
      msg.addSanctioningBodies(value);
      break;
    case 2:
      var value = new msuimsgs_pb.SanctioningBodySeries;
      reader.readMessage(value,msuimsgs_pb.SanctioningBodySeries.deserializeBinaryFromReader);
      msg.addSanctioningBodySeries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SanctioningBodyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SanctioningBodyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SanctioningBodyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SanctioningBodyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSanctioningBodiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.SanctioningBody.serializeBinaryToWriter
    );
  }
  f = message.getSanctioningBodySeriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      msuimsgs_pb.SanctioningBodySeries.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SanctioningBody sanctioning_bodies = 1;
 * @return {!Array<!proto.SanctioningBody>}
 */
proto.SanctioningBodyResponse.prototype.getSanctioningBodiesList = function() {
  return /** @type{!Array<!proto.SanctioningBody>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.SanctioningBody, 1));
};


/**
 * @param {!Array<!proto.SanctioningBody>} value
 * @return {!proto.SanctioningBodyResponse} returns this
*/
proto.SanctioningBodyResponse.prototype.setSanctioningBodiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.SanctioningBody=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SanctioningBody}
 */
proto.SanctioningBodyResponse.prototype.addSanctioningBodies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SanctioningBody, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SanctioningBodyResponse} returns this
 */
proto.SanctioningBodyResponse.prototype.clearSanctioningBodiesList = function() {
  return this.setSanctioningBodiesList([]);
};


/**
 * repeated SanctioningBodySeries sanctioning_body_series = 2;
 * @return {!Array<!proto.SanctioningBodySeries>}
 */
proto.SanctioningBodyResponse.prototype.getSanctioningBodySeriesList = function() {
  return /** @type{!Array<!proto.SanctioningBodySeries>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.SanctioningBodySeries, 2));
};


/**
 * @param {!Array<!proto.SanctioningBodySeries>} value
 * @return {!proto.SanctioningBodyResponse} returns this
*/
proto.SanctioningBodyResponse.prototype.setSanctioningBodySeriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.SanctioningBodySeries=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SanctioningBodySeries}
 */
proto.SanctioningBodyResponse.prototype.addSanctioningBodySeries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.SanctioningBodySeries, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SanctioningBodyResponse} returns this
 */
proto.SanctioningBodyResponse.prototype.clearSanctioningBodySeriesList = function() {
  return this.setSanctioningBodySeriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TrackTemperatureTableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TrackTemperatureTableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TrackTemperatureTableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureTableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentEventTrackId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currentEventLatestRowTimestamp: jspb.Message.getFieldWithDefault(msg, 2, ""),
    currentEventDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isReplay: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TrackTemperatureTableRequest}
 */
proto.TrackTemperatureTableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TrackTemperatureTableRequest;
  return proto.TrackTemperatureTableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TrackTemperatureTableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TrackTemperatureTableRequest}
 */
proto.TrackTemperatureTableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentEventTrackId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentEventLatestRowTimestamp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentEventDate(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReplay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TrackTemperatureTableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TrackTemperatureTableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TrackTemperatureTableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureTableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentEventTrackId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrentEventLatestRowTimestamp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCurrentEventDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsReplay();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string current_event_track_id = 1;
 * @return {string}
 */
proto.TrackTemperatureTableRequest.prototype.getCurrentEventTrackId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureTableRequest} returns this
 */
proto.TrackTemperatureTableRequest.prototype.setCurrentEventTrackId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string current_event_latest_row_timestamp = 2;
 * @return {string}
 */
proto.TrackTemperatureTableRequest.prototype.getCurrentEventLatestRowTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureTableRequest} returns this
 */
proto.TrackTemperatureTableRequest.prototype.setCurrentEventLatestRowTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string current_event_date = 3;
 * @return {string}
 */
proto.TrackTemperatureTableRequest.prototype.getCurrentEventDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureTableRequest} returns this
 */
proto.TrackTemperatureTableRequest.prototype.setCurrentEventDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_replay = 4;
 * @return {boolean}
 */
proto.TrackTemperatureTableRequest.prototype.getIsReplay = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.TrackTemperatureTableRequest} returns this
 */
proto.TrackTemperatureTableRequest.prototype.setIsReplay = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TrackTemperatureHistoricalTableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TrackTemperatureHistoricalTableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TrackTemperatureHistoricalTableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureHistoricalTableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordingId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TrackTemperatureHistoricalTableRequest}
 */
proto.TrackTemperatureHistoricalTableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TrackTemperatureHistoricalTableRequest;
  return proto.TrackTemperatureHistoricalTableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TrackTemperatureHistoricalTableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TrackTemperatureHistoricalTableRequest}
 */
proto.TrackTemperatureHistoricalTableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecordingId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TrackTemperatureHistoricalTableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TrackTemperatureHistoricalTableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TrackTemperatureHistoricalTableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureHistoricalTableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string recording_id = 1;
 * @return {string}
 */
proto.TrackTemperatureHistoricalTableRequest.prototype.getRecordingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureHistoricalTableRequest} returns this
 */
proto.TrackTemperatureHistoricalTableRequest.prototype.setRecordingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TrackTemperatureTableResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TrackTemperatureTableResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.TrackTemperatureTableResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TrackTemperatureTableResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureTableResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackTemperatureRowList: jspb.Message.toObjectList(msg.getTrackTemperatureRowList(),
    msuimsgs_pb.TrackTemperatureTabularRowData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TrackTemperatureTableResponse}
 */
proto.TrackTemperatureTableResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TrackTemperatureTableResponse;
  return proto.TrackTemperatureTableResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TrackTemperatureTableResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TrackTemperatureTableResponse}
 */
proto.TrackTemperatureTableResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.TrackTemperatureTabularRowData;
      reader.readMessage(value,msuimsgs_pb.TrackTemperatureTabularRowData.deserializeBinaryFromReader);
      msg.addTrackTemperatureRow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TrackTemperatureTableResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TrackTemperatureTableResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TrackTemperatureTableResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureTableResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackTemperatureRowList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.TrackTemperatureTabularRowData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TrackTemperatureTabularRowData track_temperature_row = 1;
 * @return {!Array<!proto.TrackTemperatureTabularRowData>}
 */
proto.TrackTemperatureTableResponse.prototype.getTrackTemperatureRowList = function() {
  return /** @type{!Array<!proto.TrackTemperatureTabularRowData>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.TrackTemperatureTabularRowData, 1));
};


/**
 * @param {!Array<!proto.TrackTemperatureTabularRowData>} value
 * @return {!proto.TrackTemperatureTableResponse} returns this
*/
proto.TrackTemperatureTableResponse.prototype.setTrackTemperatureRowList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.TrackTemperatureTabularRowData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TrackTemperatureTabularRowData}
 */
proto.TrackTemperatureTableResponse.prototype.addTrackTemperatureRow = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.TrackTemperatureTabularRowData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TrackTemperatureTableResponse} returns this
 */
proto.TrackTemperatureTableResponse.prototype.clearTrackTemperatureRowList = function() {
  return this.setTrackTemperatureRowList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TrackTemperatureImageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TrackTemperatureImageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TrackTemperatureImageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureImageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordingId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imageReferenceName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TrackTemperatureImageRequest}
 */
proto.TrackTemperatureImageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TrackTemperatureImageRequest;
  return proto.TrackTemperatureImageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TrackTemperatureImageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TrackTemperatureImageRequest}
 */
proto.TrackTemperatureImageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecordingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageReferenceName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TrackTemperatureImageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TrackTemperatureImageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TrackTemperatureImageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureImageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordingId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageReferenceName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string recording_id = 1;
 * @return {string}
 */
proto.TrackTemperatureImageRequest.prototype.getRecordingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureImageRequest} returns this
 */
proto.TrackTemperatureImageRequest.prototype.setRecordingId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string image_reference_name = 2;
 * @return {string}
 */
proto.TrackTemperatureImageRequest.prototype.getImageReferenceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTemperatureImageRequest} returns this
 */
proto.TrackTemperatureImageRequest.prototype.setImageReferenceName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TrackTemperatureImageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.TrackTemperatureImageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TrackTemperatureImageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureImageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackTemperatureRowImage: (f = msg.getTrackTemperatureRowImage()) && msuimsgs_pb.TrackTemperatureImageData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TrackTemperatureImageResponse}
 */
proto.TrackTemperatureImageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TrackTemperatureImageResponse;
  return proto.TrackTemperatureImageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TrackTemperatureImageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TrackTemperatureImageResponse}
 */
proto.TrackTemperatureImageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.TrackTemperatureImageData;
      reader.readMessage(value,msuimsgs_pb.TrackTemperatureImageData.deserializeBinaryFromReader);
      msg.setTrackTemperatureRowImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TrackTemperatureImageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TrackTemperatureImageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TrackTemperatureImageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureImageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackTemperatureRowImage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msuimsgs_pb.TrackTemperatureImageData.serializeBinaryToWriter
    );
  }
};


/**
 * optional TrackTemperatureImageData track_temperature_row_image = 1;
 * @return {?proto.TrackTemperatureImageData}
 */
proto.TrackTemperatureImageResponse.prototype.getTrackTemperatureRowImage = function() {
  return /** @type{?proto.TrackTemperatureImageData} */ (
    jspb.Message.getWrapperField(this, msuimsgs_pb.TrackTemperatureImageData, 1));
};


/**
 * @param {?proto.TrackTemperatureImageData|undefined} value
 * @return {!proto.TrackTemperatureImageResponse} returns this
*/
proto.TrackTemperatureImageResponse.prototype.setTrackTemperatureRowImage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TrackTemperatureImageResponse} returns this
 */
proto.TrackTemperatureImageResponse.prototype.clearTrackTemperatureRowImage = function() {
  return this.setTrackTemperatureRowImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TrackTemperatureImageResponse.prototype.hasTrackTemperatureRowImage = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TrackTemperatureHistoricalListingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TrackTemperatureHistoricalListingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TrackTemperatureHistoricalListingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureHistoricalListingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TrackTemperatureHistoricalListingRequest}
 */
proto.TrackTemperatureHistoricalListingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TrackTemperatureHistoricalListingRequest;
  return proto.TrackTemperatureHistoricalListingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TrackTemperatureHistoricalListingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TrackTemperatureHistoricalListingRequest}
 */
proto.TrackTemperatureHistoricalListingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TrackTemperatureHistoricalListingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TrackTemperatureHistoricalListingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TrackTemperatureHistoricalListingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureHistoricalListingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TrackTemperatureHistoricalListingResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TrackTemperatureHistoricalListingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.TrackTemperatureHistoricalListingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TrackTemperatureHistoricalListingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureHistoricalListingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordingListingRowList: jspb.Message.toObjectList(msg.getRecordingListingRowList(),
    msuimsgs_pb.TrackTemperatureHistoricalListingRowData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TrackTemperatureHistoricalListingResponse}
 */
proto.TrackTemperatureHistoricalListingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TrackTemperatureHistoricalListingResponse;
  return proto.TrackTemperatureHistoricalListingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TrackTemperatureHistoricalListingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TrackTemperatureHistoricalListingResponse}
 */
proto.TrackTemperatureHistoricalListingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.TrackTemperatureHistoricalListingRowData;
      reader.readMessage(value,msuimsgs_pb.TrackTemperatureHistoricalListingRowData.deserializeBinaryFromReader);
      msg.addRecordingListingRow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TrackTemperatureHistoricalListingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TrackTemperatureHistoricalListingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TrackTemperatureHistoricalListingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemperatureHistoricalListingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordingListingRowList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.TrackTemperatureHistoricalListingRowData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TrackTemperatureHistoricalListingRowData recording_listing_row = 1;
 * @return {!Array<!proto.TrackTemperatureHistoricalListingRowData>}
 */
proto.TrackTemperatureHistoricalListingResponse.prototype.getRecordingListingRowList = function() {
  return /** @type{!Array<!proto.TrackTemperatureHistoricalListingRowData>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.TrackTemperatureHistoricalListingRowData, 1));
};


/**
 * @param {!Array<!proto.TrackTemperatureHistoricalListingRowData>} value
 * @return {!proto.TrackTemperatureHistoricalListingResponse} returns this
*/
proto.TrackTemperatureHistoricalListingResponse.prototype.setRecordingListingRowList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.TrackTemperatureHistoricalListingRowData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TrackTemperatureHistoricalListingRowData}
 */
proto.TrackTemperatureHistoricalListingResponse.prototype.addRecordingListingRow = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.TrackTemperatureHistoricalListingRowData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TrackTemperatureHistoricalListingResponse} returns this
 */
proto.TrackTemperatureHistoricalListingResponse.prototype.clearRecordingListingRowList = function() {
  return this.setRecordingListingRowList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NascarTrailerWeatherHistoricalListingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.NascarTrailerWeatherHistoricalListingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NascarTrailerWeatherHistoricalListingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerWeatherHistoricalListingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NascarTrailerWeatherHistoricalListingRequest}
 */
proto.NascarTrailerWeatherHistoricalListingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NascarTrailerWeatherHistoricalListingRequest;
  return proto.NascarTrailerWeatherHistoricalListingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NascarTrailerWeatherHistoricalListingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NascarTrailerWeatherHistoricalListingRequest}
 */
proto.NascarTrailerWeatherHistoricalListingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NascarTrailerWeatherHistoricalListingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NascarTrailerWeatherHistoricalListingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NascarTrailerWeatherHistoricalListingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerWeatherHistoricalListingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double timestamp = 1;
 * @return {number}
 */
proto.NascarTrailerWeatherHistoricalListingRequest.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherHistoricalListingRequest} returns this
 */
proto.NascarTrailerWeatherHistoricalListingRequest.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.NascarTrailerWeatherHistoricalListingResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NascarTrailerWeatherHistoricalListingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.NascarTrailerWeatherHistoricalListingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NascarTrailerWeatherHistoricalListingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerWeatherHistoricalListingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    weatherList: jspb.Message.toObjectList(msg.getWeatherList(),
    msuimsgs_pb.NascarTrailerWeather.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NascarTrailerWeatherHistoricalListingResponse}
 */
proto.NascarTrailerWeatherHistoricalListingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NascarTrailerWeatherHistoricalListingResponse;
  return proto.NascarTrailerWeatherHistoricalListingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NascarTrailerWeatherHistoricalListingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NascarTrailerWeatherHistoricalListingResponse}
 */
proto.NascarTrailerWeatherHistoricalListingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.NascarTrailerWeather;
      reader.readMessage(value,msuimsgs_pb.NascarTrailerWeather.deserializeBinaryFromReader);
      msg.addWeather(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NascarTrailerWeatherHistoricalListingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NascarTrailerWeatherHistoricalListingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NascarTrailerWeatherHistoricalListingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerWeatherHistoricalListingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWeatherList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.NascarTrailerWeather.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NascarTrailerWeather weather = 1;
 * @return {!Array<!proto.NascarTrailerWeather>}
 */
proto.NascarTrailerWeatherHistoricalListingResponse.prototype.getWeatherList = function() {
  return /** @type{!Array<!proto.NascarTrailerWeather>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.NascarTrailerWeather, 1));
};


/**
 * @param {!Array<!proto.NascarTrailerWeather>} value
 * @return {!proto.NascarTrailerWeatherHistoricalListingResponse} returns this
*/
proto.NascarTrailerWeatherHistoricalListingResponse.prototype.setWeatherList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.NascarTrailerWeather=} opt_value
 * @param {number=} opt_index
 * @return {!proto.NascarTrailerWeather}
 */
proto.NascarTrailerWeatherHistoricalListingResponse.prototype.addWeather = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.NascarTrailerWeather, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.NascarTrailerWeatherHistoricalListingResponse} returns this
 */
proto.NascarTrailerWeatherHistoricalListingResponse.prototype.clearWeatherList = function() {
  return this.setWeatherList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NascarTrailerWeatherHistoricalTableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.NascarTrailerWeatherHistoricalTableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NascarTrailerWeatherHistoricalTableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerWeatherHistoricalTableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NascarTrailerWeatherHistoricalTableRequest}
 */
proto.NascarTrailerWeatherHistoricalTableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NascarTrailerWeatherHistoricalTableRequest;
  return proto.NascarTrailerWeatherHistoricalTableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NascarTrailerWeatherHistoricalTableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NascarTrailerWeatherHistoricalTableRequest}
 */
proto.NascarTrailerWeatherHistoricalTableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NascarTrailerWeatherHistoricalTableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NascarTrailerWeatherHistoricalTableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NascarTrailerWeatherHistoricalTableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerWeatherHistoricalTableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double timestamp = 1;
 * @return {number}
 */
proto.NascarTrailerWeatherHistoricalTableRequest.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherHistoricalTableRequest} returns this
 */
proto.NascarTrailerWeatherHistoricalTableRequest.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.NascarTrailerWeatherHistoricalTableResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NascarTrailerWeatherHistoricalTableResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.NascarTrailerWeatherHistoricalTableResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NascarTrailerWeatherHistoricalTableResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerWeatherHistoricalTableResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    weatherList: jspb.Message.toObjectList(msg.getWeatherList(),
    msuimsgs_pb.NascarTrailerWeather.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NascarTrailerWeatherHistoricalTableResponse}
 */
proto.NascarTrailerWeatherHistoricalTableResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NascarTrailerWeatherHistoricalTableResponse;
  return proto.NascarTrailerWeatherHistoricalTableResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NascarTrailerWeatherHistoricalTableResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NascarTrailerWeatherHistoricalTableResponse}
 */
proto.NascarTrailerWeatherHistoricalTableResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.NascarTrailerWeather;
      reader.readMessage(value,msuimsgs_pb.NascarTrailerWeather.deserializeBinaryFromReader);
      msg.addWeather(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NascarTrailerWeatherHistoricalTableResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NascarTrailerWeatherHistoricalTableResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NascarTrailerWeatherHistoricalTableResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerWeatherHistoricalTableResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWeatherList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.NascarTrailerWeather.serializeBinaryToWriter
    );
  }
};


/**
 * repeated NascarTrailerWeather weather = 1;
 * @return {!Array<!proto.NascarTrailerWeather>}
 */
proto.NascarTrailerWeatherHistoricalTableResponse.prototype.getWeatherList = function() {
  return /** @type{!Array<!proto.NascarTrailerWeather>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.NascarTrailerWeather, 1));
};


/**
 * @param {!Array<!proto.NascarTrailerWeather>} value
 * @return {!proto.NascarTrailerWeatherHistoricalTableResponse} returns this
*/
proto.NascarTrailerWeatherHistoricalTableResponse.prototype.setWeatherList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.NascarTrailerWeather=} opt_value
 * @param {number=} opt_index
 * @return {!proto.NascarTrailerWeather}
 */
proto.NascarTrailerWeatherHistoricalTableResponse.prototype.addWeather = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.NascarTrailerWeather, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.NascarTrailerWeatherHistoricalTableResponse} returns this
 */
proto.NascarTrailerWeatherHistoricalTableResponse.prototype.clearWeatherList = function() {
  return this.setWeatherList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NascarTrailerWeatherTableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.NascarTrailerWeatherTableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NascarTrailerWeatherTableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerWeatherTableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isReplayRequest: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NascarTrailerWeatherTableRequest}
 */
proto.NascarTrailerWeatherTableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NascarTrailerWeatherTableRequest;
  return proto.NascarTrailerWeatherTableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NascarTrailerWeatherTableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NascarTrailerWeatherTableRequest}
 */
proto.NascarTrailerWeatherTableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReplayRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NascarTrailerWeatherTableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NascarTrailerWeatherTableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NascarTrailerWeatherTableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerWeatherTableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getIsReplayRequest();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int64 timestamp = 1;
 * @return {number}
 */
proto.NascarTrailerWeatherTableRequest.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.NascarTrailerWeatherTableRequest} returns this
 */
proto.NascarTrailerWeatherTableRequest.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool is_replay_request = 2;
 * @return {boolean}
 */
proto.NascarTrailerWeatherTableRequest.prototype.getIsReplayRequest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.NascarTrailerWeatherTableRequest} returns this
 */
proto.NascarTrailerWeatherTableRequest.prototype.setIsReplayRequest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.NascarTrailerWeatherTableResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.NascarTrailerWeatherTableResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.NascarTrailerWeatherTableResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerWeatherTableResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    weather: (f = msg.getWeather()) && msuimsgs_pb.NascarTrailerWeather.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.NascarTrailerWeatherTableResponse}
 */
proto.NascarTrailerWeatherTableResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.NascarTrailerWeatherTableResponse;
  return proto.NascarTrailerWeatherTableResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.NascarTrailerWeatherTableResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.NascarTrailerWeatherTableResponse}
 */
proto.NascarTrailerWeatherTableResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.NascarTrailerWeather;
      reader.readMessage(value,msuimsgs_pb.NascarTrailerWeather.deserializeBinaryFromReader);
      msg.setWeather(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.NascarTrailerWeatherTableResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.NascarTrailerWeatherTableResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.NascarTrailerWeatherTableResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.NascarTrailerWeatherTableResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWeather();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msuimsgs_pb.NascarTrailerWeather.serializeBinaryToWriter
    );
  }
};


/**
 * optional NascarTrailerWeather weather = 1;
 * @return {?proto.NascarTrailerWeather}
 */
proto.NascarTrailerWeatherTableResponse.prototype.getWeather = function() {
  return /** @type{?proto.NascarTrailerWeather} */ (
    jspb.Message.getWrapperField(this, msuimsgs_pb.NascarTrailerWeather, 1));
};


/**
 * @param {?proto.NascarTrailerWeather|undefined} value
 * @return {!proto.NascarTrailerWeatherTableResponse} returns this
*/
proto.NascarTrailerWeatherTableResponse.prototype.setWeather = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.NascarTrailerWeatherTableResponse} returns this
 */
proto.NascarTrailerWeatherTableResponse.prototype.clearWeather = function() {
  return this.setWeather(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.NascarTrailerWeatherTableResponse.prototype.hasWeather = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IndyWindDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.IndyWindDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IndyWindDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndyWindDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastEventTimestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stationIdAlias: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IndyWindDataRequest}
 */
proto.IndyWindDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IndyWindDataRequest;
  return proto.IndyWindDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IndyWindDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IndyWindDataRequest}
 */
proto.IndyWindDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastEventTimestamp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationIdAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IndyWindDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IndyWindDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IndyWindDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndyWindDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastEventTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStationIdAlias();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int64 last_event_timestamp = 1;
 * @return {number}
 */
proto.IndyWindDataRequest.prototype.getLastEventTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.IndyWindDataRequest} returns this
 */
proto.IndyWindDataRequest.prototype.setLastEventTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string station_id = 2;
 * @return {string}
 */
proto.IndyWindDataRequest.prototype.getStationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndyWindDataRequest} returns this
 */
proto.IndyWindDataRequest.prototype.setStationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string station_id_alias = 3;
 * @return {string}
 */
proto.IndyWindDataRequest.prototype.getStationIdAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.IndyWindDataRequest} returns this
 */
proto.IndyWindDataRequest.prototype.setStationIdAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.IndyWindDataResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IndyWindDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.IndyWindDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IndyWindDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndyWindDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    msuimsgs_pb.IndyWindData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IndyWindDataResponse}
 */
proto.IndyWindDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IndyWindDataResponse;
  return proto.IndyWindDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IndyWindDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IndyWindDataResponse}
 */
proto.IndyWindDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.IndyWindData;
      reader.readMessage(value,msuimsgs_pb.IndyWindData.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IndyWindDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IndyWindDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IndyWindDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndyWindDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.IndyWindData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IndyWindData data = 1;
 * @return {!Array<!proto.IndyWindData>}
 */
proto.IndyWindDataResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.IndyWindData>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.IndyWindData, 1));
};


/**
 * @param {!Array<!proto.IndyWindData>} value
 * @return {!proto.IndyWindDataResponse} returns this
*/
proto.IndyWindDataResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.IndyWindData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IndyWindData}
 */
proto.IndyWindDataResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.IndyWindData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.IndyWindDataResponse} returns this
 */
proto.IndyWindDataResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IndyWindStationIdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.IndyWindStationIdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IndyWindStationIdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndyWindStationIdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastEventTimestamp: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IndyWindStationIdsRequest}
 */
proto.IndyWindStationIdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IndyWindStationIdsRequest;
  return proto.IndyWindStationIdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IndyWindStationIdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IndyWindStationIdsRequest}
 */
proto.IndyWindStationIdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastEventTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IndyWindStationIdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IndyWindStationIdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IndyWindStationIdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndyWindStationIdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastEventTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 last_event_timestamp = 1;
 * @return {number}
 */
proto.IndyWindStationIdsRequest.prototype.getLastEventTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.IndyWindStationIdsRequest} returns this
 */
proto.IndyWindStationIdsRequest.prototype.setLastEventTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.IndyWindStationIdsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IndyWindStationIdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.IndyWindStationIdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IndyWindStationIdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndyWindStationIdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationIdAliasList: jspb.Message.toObjectList(msg.getStationIdAliasList(),
    proto.StationIdAlias.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IndyWindStationIdsResponse}
 */
proto.IndyWindStationIdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IndyWindStationIdsResponse;
  return proto.IndyWindStationIdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IndyWindStationIdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IndyWindStationIdsResponse}
 */
proto.IndyWindStationIdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.StationIdAlias;
      reader.readMessage(value,proto.StationIdAlias.deserializeBinaryFromReader);
      msg.addStationIdAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IndyWindStationIdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IndyWindStationIdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IndyWindStationIdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndyWindStationIdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationIdAliasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.StationIdAlias.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StationIdAlias station_id_alias = 1;
 * @return {!Array<!proto.StationIdAlias>}
 */
proto.IndyWindStationIdsResponse.prototype.getStationIdAliasList = function() {
  return /** @type{!Array<!proto.StationIdAlias>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.StationIdAlias, 1));
};


/**
 * @param {!Array<!proto.StationIdAlias>} value
 * @return {!proto.IndyWindStationIdsResponse} returns this
*/
proto.IndyWindStationIdsResponse.prototype.setStationIdAliasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.StationIdAlias=} opt_value
 * @param {number=} opt_index
 * @return {!proto.StationIdAlias}
 */
proto.IndyWindStationIdsResponse.prototype.addStationIdAlias = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.StationIdAlias, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.IndyWindStationIdsResponse} returns this
 */
proto.IndyWindStationIdsResponse.prototype.clearStationIdAliasList = function() {
  return this.setStationIdAliasList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StationIdAlias.prototype.toObject = function(opt_includeInstance) {
  return proto.StationIdAlias.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StationIdAlias} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StationIdAlias.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    alias: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StationIdAlias}
 */
proto.StationIdAlias.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StationIdAlias;
  return proto.StationIdAlias.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StationIdAlias} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StationIdAlias}
 */
proto.StationIdAlias.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StationIdAlias.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StationIdAlias.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StationIdAlias} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StationIdAlias.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.StationIdAlias.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.StationIdAlias} returns this
 */
proto.StationIdAlias.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string alias = 2;
 * @return {string}
 */
proto.StationIdAlias.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.StationIdAlias} returns this
 */
proto.StationIdAlias.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.UpdateIndyWindStationIdAliasRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateIndyWindStationIdAliasRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateIndyWindStationIdAliasRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateIndyWindStationIdAliasRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateIndyWindStationIdAliasRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationIdAliasList: jspb.Message.toObjectList(msg.getStationIdAliasList(),
    proto.StationIdAlias.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateIndyWindStationIdAliasRequest}
 */
proto.UpdateIndyWindStationIdAliasRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateIndyWindStationIdAliasRequest;
  return proto.UpdateIndyWindStationIdAliasRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateIndyWindStationIdAliasRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateIndyWindStationIdAliasRequest}
 */
proto.UpdateIndyWindStationIdAliasRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.StationIdAlias;
      reader.readMessage(value,proto.StationIdAlias.deserializeBinaryFromReader);
      msg.addStationIdAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateIndyWindStationIdAliasRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateIndyWindStationIdAliasRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateIndyWindStationIdAliasRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateIndyWindStationIdAliasRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationIdAliasList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.StationIdAlias.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StationIdAlias station_id_alias = 1;
 * @return {!Array<!proto.StationIdAlias>}
 */
proto.UpdateIndyWindStationIdAliasRequest.prototype.getStationIdAliasList = function() {
  return /** @type{!Array<!proto.StationIdAlias>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.StationIdAlias, 1));
};


/**
 * @param {!Array<!proto.StationIdAlias>} value
 * @return {!proto.UpdateIndyWindStationIdAliasRequest} returns this
*/
proto.UpdateIndyWindStationIdAliasRequest.prototype.setStationIdAliasList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.StationIdAlias=} opt_value
 * @param {number=} opt_index
 * @return {!proto.StationIdAlias}
 */
proto.UpdateIndyWindStationIdAliasRequest.prototype.addStationIdAlias = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.StationIdAlias, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UpdateIndyWindStationIdAliasRequest} returns this
 */
proto.UpdateIndyWindStationIdAliasRequest.prototype.clearStationIdAliasList = function() {
  return this.setStationIdAliasList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SessionTimingScoringRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SessionTimingScoringRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SessionTimingScoringRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionTimingScoringRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SessionTimingScoringRequest}
 */
proto.SessionTimingScoringRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SessionTimingScoringRequest;
  return proto.SessionTimingScoringRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SessionTimingScoringRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SessionTimingScoringRequest}
 */
proto.SessionTimingScoringRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {!proto.RunType} */ (reader.readEnum());
      msg.setSessionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SessionTimingScoringRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SessionTimingScoringRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SessionTimingScoringRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionTimingScoringRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.SessionTimingScoringRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionTimingScoringRequest} returns this
 */
proto.SessionTimingScoringRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RunType session_type = 2;
 * @return {!proto.RunType}
 */
proto.SessionTimingScoringRequest.prototype.getSessionType = function() {
  return /** @type {!proto.RunType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.RunType} value
 * @return {!proto.SessionTimingScoringRequest} returns this
 */
proto.SessionTimingScoringRequest.prototype.setSessionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SessionTimingScoringResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SessionTimingScoringResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SessionTimingScoringResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SessionTimingScoringResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionTimingScoringResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionTimingScoringDataList: jspb.Message.toObjectList(msg.getSessionTimingScoringDataList(),
    msuimsgs_pb.SessionTimingScoringData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SessionTimingScoringResponse}
 */
proto.SessionTimingScoringResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SessionTimingScoringResponse;
  return proto.SessionTimingScoringResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SessionTimingScoringResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SessionTimingScoringResponse}
 */
proto.SessionTimingScoringResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.SessionTimingScoringData;
      reader.readMessage(value,msuimsgs_pb.SessionTimingScoringData.deserializeBinaryFromReader);
      msg.addSessionTimingScoringData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SessionTimingScoringResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SessionTimingScoringResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SessionTimingScoringResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionTimingScoringResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionTimingScoringDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.SessionTimingScoringData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SessionTimingScoringData session_timing_scoring_data = 1;
 * @return {!Array<!proto.SessionTimingScoringData>}
 */
proto.SessionTimingScoringResponse.prototype.getSessionTimingScoringDataList = function() {
  return /** @type{!Array<!proto.SessionTimingScoringData>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.SessionTimingScoringData, 1));
};


/**
 * @param {!Array<!proto.SessionTimingScoringData>} value
 * @return {!proto.SessionTimingScoringResponse} returns this
*/
proto.SessionTimingScoringResponse.prototype.setSessionTimingScoringDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.SessionTimingScoringData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SessionTimingScoringData}
 */
proto.SessionTimingScoringResponse.prototype.addSessionTimingScoringData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SessionTimingScoringData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SessionTimingScoringResponse} returns this
 */
proto.SessionTimingScoringResponse.prototype.clearSessionTimingScoringDataList = function() {
  return this.setSessionTimingScoringDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SessionProgressionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SessionProgressionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SessionProgressionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionProgressionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SessionProgressionRequest}
 */
proto.SessionProgressionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SessionProgressionRequest;
  return proto.SessionProgressionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SessionProgressionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SessionProgressionRequest}
 */
proto.SessionProgressionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {!proto.RunType} */ (reader.readEnum());
      msg.setSessionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SessionProgressionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SessionProgressionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SessionProgressionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionProgressionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.SessionProgressionRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionProgressionRequest} returns this
 */
proto.SessionProgressionRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RunType session_type = 2;
 * @return {!proto.RunType}
 */
proto.SessionProgressionRequest.prototype.getSessionType = function() {
  return /** @type {!proto.RunType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.RunType} value
 * @return {!proto.SessionProgressionRequest} returns this
 */
proto.SessionProgressionRequest.prototype.setSessionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SessionProgressionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SessionProgressionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SessionProgressionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SessionProgressionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionProgressionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    progressionDataList: jspb.Message.toObjectList(msg.getProgressionDataList(),
    msuimsgs_pb.RaceProgressionData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SessionProgressionResponse}
 */
proto.SessionProgressionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SessionProgressionResponse;
  return proto.SessionProgressionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SessionProgressionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SessionProgressionResponse}
 */
proto.SessionProgressionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.RaceProgressionData;
      reader.readMessage(value,msuimsgs_pb.RaceProgressionData.deserializeBinaryFromReader);
      msg.addProgressionData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SessionProgressionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SessionProgressionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SessionProgressionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionProgressionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProgressionDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.RaceProgressionData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RaceProgressionData progression_data = 1;
 * @return {!Array<!proto.RaceProgressionData>}
 */
proto.SessionProgressionResponse.prototype.getProgressionDataList = function() {
  return /** @type{!Array<!proto.RaceProgressionData>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.RaceProgressionData, 1));
};


/**
 * @param {!Array<!proto.RaceProgressionData>} value
 * @return {!proto.SessionProgressionResponse} returns this
*/
proto.SessionProgressionResponse.prototype.setProgressionDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.RaceProgressionData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.RaceProgressionData}
 */
proto.SessionProgressionResponse.prototype.addProgressionData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.RaceProgressionData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SessionProgressionResponse} returns this
 */
proto.SessionProgressionResponse.prototype.clearProgressionDataList = function() {
  return this.setProgressionDataList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.WorkspaceRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.WorkspaceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.WorkspaceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.WorkspaceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.WorkspaceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shared: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    sharedDomainsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.WorkspaceRequest}
 */
proto.WorkspaceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.WorkspaceRequest;
  return proto.WorkspaceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.WorkspaceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.WorkspaceRequest}
 */
proto.WorkspaceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShared(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSharedDomains(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.WorkspaceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.WorkspaceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.WorkspaceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.WorkspaceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShared();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSharedDomainsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.WorkspaceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.WorkspaceRequest} returns this
 */
proto.WorkspaceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.WorkspaceRequest.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.WorkspaceRequest} returns this
 */
proto.WorkspaceRequest.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool shared = 3;
 * @return {boolean}
 */
proto.WorkspaceRequest.prototype.getShared = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.WorkspaceRequest} returns this
 */
proto.WorkspaceRequest.prototype.setShared = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated int32 shared_domains = 4;
 * @return {!Array<number>}
 */
proto.WorkspaceRequest.prototype.getSharedDomainsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.WorkspaceRequest} returns this
 */
proto.WorkspaceRequest.prototype.setSharedDomainsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.WorkspaceRequest} returns this
 */
proto.WorkspaceRequest.prototype.addSharedDomains = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.WorkspaceRequest} returns this
 */
proto.WorkspaceRequest.prototype.clearSharedDomainsList = function() {
  return this.setSharedDomainsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.WorkspaceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.WorkspaceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.WorkspaceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.WorkspaceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.WorkspaceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    workspaceList: jspb.Message.toObjectList(msg.getWorkspaceList(),
    proto.WorkspaceData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.WorkspaceResponse}
 */
proto.WorkspaceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.WorkspaceResponse;
  return proto.WorkspaceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.WorkspaceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.WorkspaceResponse}
 */
proto.WorkspaceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.WorkspaceData;
      reader.readMessage(value,proto.WorkspaceData.deserializeBinaryFromReader);
      msg.addWorkspace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.WorkspaceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.WorkspaceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.WorkspaceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.WorkspaceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWorkspaceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.WorkspaceData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated WorkspaceData workspace = 1;
 * @return {!Array<!proto.WorkspaceData>}
 */
proto.WorkspaceResponse.prototype.getWorkspaceList = function() {
  return /** @type{!Array<!proto.WorkspaceData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.WorkspaceData, 1));
};


/**
 * @param {!Array<!proto.WorkspaceData>} value
 * @return {!proto.WorkspaceResponse} returns this
*/
proto.WorkspaceResponse.prototype.setWorkspaceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.WorkspaceData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.WorkspaceData}
 */
proto.WorkspaceResponse.prototype.addWorkspace = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.WorkspaceData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.WorkspaceResponse} returns this
 */
proto.WorkspaceResponse.prototype.clearWorkspaceList = function() {
  return this.setWorkspaceList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.WorkspaceResponseStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.WorkspaceResponseStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.WorkspaceResponseStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.WorkspaceResponseStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.WorkspaceResponseStatus}
 */
proto.WorkspaceResponseStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.WorkspaceResponseStatus;
  return proto.WorkspaceResponseStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.WorkspaceResponseStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.WorkspaceResponseStatus}
 */
proto.WorkspaceResponseStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.WorkspaceResponseStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.WorkspaceResponseStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.WorkspaceResponseStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.WorkspaceResponseStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.WorkspaceResponseStatus.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.WorkspaceResponseStatus} returns this
 */
proto.WorkspaceResponseStatus.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.WorkspaceData.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.WorkspaceData.prototype.toObject = function(opt_includeInstance) {
  return proto.WorkspaceData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.WorkspaceData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.WorkspaceData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    data: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ownedBy: jspb.Message.getFieldWithDefault(msg, 3, ""),
    shared: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    createdBy: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdTimestamp: jspb.Message.getFieldWithDefault(msg, 6, ""),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 7, ""),
    updatedTimestamp: jspb.Message.getFieldWithDefault(msg, 8, ""),
    sharedDomainsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    ownedByDomain: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.WorkspaceData}
 */
proto.WorkspaceData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.WorkspaceData;
  return proto.WorkspaceData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.WorkspaceData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.WorkspaceData}
 */
proto.WorkspaceData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnedBy(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShared(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedTimestamp(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedTimestamp(value);
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSharedDomains(values[i]);
      }
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnedByDomain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.WorkspaceData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.WorkspaceData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.WorkspaceData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.WorkspaceData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwnedBy();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getShared();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedTimestamp();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUpdatedTimestamp();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSharedDomainsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      9,
      f
    );
  }
  f = message.getOwnedByDomain();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.WorkspaceData.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.WorkspaceData} returns this
 */
proto.WorkspaceData.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.WorkspaceData.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.WorkspaceData} returns this
 */
proto.WorkspaceData.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string owned_by = 3;
 * @return {string}
 */
proto.WorkspaceData.prototype.getOwnedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.WorkspaceData} returns this
 */
proto.WorkspaceData.prototype.setOwnedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool shared = 4;
 * @return {boolean}
 */
proto.WorkspaceData.prototype.getShared = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.WorkspaceData} returns this
 */
proto.WorkspaceData.prototype.setShared = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string created_by = 5;
 * @return {string}
 */
proto.WorkspaceData.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.WorkspaceData} returns this
 */
proto.WorkspaceData.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string created_timestamp = 6;
 * @return {string}
 */
proto.WorkspaceData.prototype.getCreatedTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.WorkspaceData} returns this
 */
proto.WorkspaceData.prototype.setCreatedTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string updated_by = 7;
 * @return {string}
 */
proto.WorkspaceData.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.WorkspaceData} returns this
 */
proto.WorkspaceData.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string updated_timestamp = 8;
 * @return {string}
 */
proto.WorkspaceData.prototype.getUpdatedTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.WorkspaceData} returns this
 */
proto.WorkspaceData.prototype.setUpdatedTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated int32 shared_domains = 9;
 * @return {!Array<number>}
 */
proto.WorkspaceData.prototype.getSharedDomainsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.WorkspaceData} returns this
 */
proto.WorkspaceData.prototype.setSharedDomainsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.WorkspaceData} returns this
 */
proto.WorkspaceData.prototype.addSharedDomains = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.WorkspaceData} returns this
 */
proto.WorkspaceData.prototype.clearSharedDomainsList = function() {
  return this.setSharedDomainsList([]);
};


/**
 * optional string owned_by_domain = 10;
 * @return {string}
 */
proto.WorkspaceData.prototype.getOwnedByDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.WorkspaceData} returns this
 */
proto.WorkspaceData.prototype.setOwnedByDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IndyWeatherTrackRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.IndyWeatherTrackRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IndyWeatherTrackRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndyWeatherTrackRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IndyWeatherTrackRequest}
 */
proto.IndyWeatherTrackRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IndyWeatherTrackRequest;
  return proto.IndyWeatherTrackRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IndyWeatherTrackRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IndyWeatherTrackRequest}
 */
proto.IndyWeatherTrackRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IndyWeatherTrackRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IndyWeatherTrackRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IndyWeatherTrackRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndyWeatherTrackRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IndyWeatherTrackResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.IndyWeatherTrackResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IndyWeatherTrackResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndyWeatherTrackResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    track: (f = msg.getTrack()) && msuimsgs_pb.Track.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IndyWeatherTrackResponse}
 */
proto.IndyWeatherTrackResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IndyWeatherTrackResponse;
  return proto.IndyWeatherTrackResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IndyWeatherTrackResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IndyWeatherTrackResponse}
 */
proto.IndyWeatherTrackResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.Track;
      reader.readMessage(value,msuimsgs_pb.Track.deserializeBinaryFromReader);
      msg.setTrack(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IndyWeatherTrackResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IndyWeatherTrackResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IndyWeatherTrackResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IndyWeatherTrackResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrack();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msuimsgs_pb.Track.serializeBinaryToWriter
    );
  }
};


/**
 * optional Track track = 1;
 * @return {?proto.Track}
 */
proto.IndyWeatherTrackResponse.prototype.getTrack = function() {
  return /** @type{?proto.Track} */ (
    jspb.Message.getWrapperField(this, msuimsgs_pb.Track, 1));
};


/**
 * @param {?proto.Track|undefined} value
 * @return {!proto.IndyWeatherTrackResponse} returns this
*/
proto.IndyWeatherTrackResponse.prototype.setTrack = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.IndyWeatherTrackResponse} returns this
 */
proto.IndyWeatherTrackResponse.prototype.clearTrack = function() {
  return this.setTrack(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.IndyWeatherTrackResponse.prototype.hasTrack = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Empty}
 */
proto.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Empty;
  return proto.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Empty}
 */
proto.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GenericResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GenericResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GenericResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GenericResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GenericResponse}
 */
proto.GenericResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GenericResponse;
  return proto.GenericResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GenericResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GenericResponse}
 */
proto.GenericResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GenericResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GenericResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GenericResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GenericResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.GenericResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.GenericResponse} returns this
 */
proto.GenericResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SessionPitRoadSummaryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SessionPitRoadSummaryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SessionPitRoadSummaryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionPitRoadSummaryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SessionPitRoadSummaryRequest}
 */
proto.SessionPitRoadSummaryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SessionPitRoadSummaryRequest;
  return proto.SessionPitRoadSummaryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SessionPitRoadSummaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SessionPitRoadSummaryRequest}
 */
proto.SessionPitRoadSummaryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {!proto.RunType} */ (reader.readEnum());
      msg.setSessionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SessionPitRoadSummaryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SessionPitRoadSummaryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SessionPitRoadSummaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionPitRoadSummaryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.SessionPitRoadSummaryRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionPitRoadSummaryRequest} returns this
 */
proto.SessionPitRoadSummaryRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RunType session_type = 2;
 * @return {!proto.RunType}
 */
proto.SessionPitRoadSummaryRequest.prototype.getSessionType = function() {
  return /** @type {!proto.RunType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.RunType} value
 * @return {!proto.SessionPitRoadSummaryRequest} returns this
 */
proto.SessionPitRoadSummaryRequest.prototype.setSessionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SessionPitRoadSummaryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SessionPitRoadSummaryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SessionPitRoadSummaryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SessionPitRoadSummaryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionPitRoadSummaryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pitRoadSummaryList: jspb.Message.toObjectList(msg.getPitRoadSummaryList(),
    msuimsgs_pb.SessionPitRoadSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SessionPitRoadSummaryResponse}
 */
proto.SessionPitRoadSummaryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SessionPitRoadSummaryResponse;
  return proto.SessionPitRoadSummaryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SessionPitRoadSummaryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SessionPitRoadSummaryResponse}
 */
proto.SessionPitRoadSummaryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.SessionPitRoadSummary;
      reader.readMessage(value,msuimsgs_pb.SessionPitRoadSummary.deserializeBinaryFromReader);
      msg.addPitRoadSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SessionPitRoadSummaryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SessionPitRoadSummaryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SessionPitRoadSummaryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionPitRoadSummaryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPitRoadSummaryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.SessionPitRoadSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SessionPitRoadSummary pit_road_summary = 1;
 * @return {!Array<!proto.SessionPitRoadSummary>}
 */
proto.SessionPitRoadSummaryResponse.prototype.getPitRoadSummaryList = function() {
  return /** @type{!Array<!proto.SessionPitRoadSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.SessionPitRoadSummary, 1));
};


/**
 * @param {!Array<!proto.SessionPitRoadSummary>} value
 * @return {!proto.SessionPitRoadSummaryResponse} returns this
*/
proto.SessionPitRoadSummaryResponse.prototype.setPitRoadSummaryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.SessionPitRoadSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SessionPitRoadSummary}
 */
proto.SessionPitRoadSummaryResponse.prototype.addPitRoadSummary = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SessionPitRoadSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SessionPitRoadSummaryResponse} returns this
 */
proto.SessionPitRoadSummaryResponse.prototype.clearPitRoadSummaryList = function() {
  return this.setPitRoadSummaryList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SessionPitRoadAnalysisRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SessionPitRoadAnalysisRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SessionPitRoadAnalysisRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionPitRoadAnalysisRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SessionPitRoadAnalysisRequest}
 */
proto.SessionPitRoadAnalysisRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SessionPitRoadAnalysisRequest;
  return proto.SessionPitRoadAnalysisRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SessionPitRoadAnalysisRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SessionPitRoadAnalysisRequest}
 */
proto.SessionPitRoadAnalysisRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {!proto.RunType} */ (reader.readEnum());
      msg.setSessionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SessionPitRoadAnalysisRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SessionPitRoadAnalysisRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SessionPitRoadAnalysisRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionPitRoadAnalysisRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.SessionPitRoadAnalysisRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionPitRoadAnalysisRequest} returns this
 */
proto.SessionPitRoadAnalysisRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RunType session_type = 2;
 * @return {!proto.RunType}
 */
proto.SessionPitRoadAnalysisRequest.prototype.getSessionType = function() {
  return /** @type {!proto.RunType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.RunType} value
 * @return {!proto.SessionPitRoadAnalysisRequest} returns this
 */
proto.SessionPitRoadAnalysisRequest.prototype.setSessionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SessionPitRoadAnalysisResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SessionPitRoadAnalysisResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SessionPitRoadAnalysisResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SessionPitRoadAnalysisResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionPitRoadAnalysisResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pitRoadAnalysisList: jspb.Message.toObjectList(msg.getPitRoadAnalysisList(),
    msuimsgs_pb.SessionPitRoadAnalysis.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SessionPitRoadAnalysisResponse}
 */
proto.SessionPitRoadAnalysisResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SessionPitRoadAnalysisResponse;
  return proto.SessionPitRoadAnalysisResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SessionPitRoadAnalysisResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SessionPitRoadAnalysisResponse}
 */
proto.SessionPitRoadAnalysisResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.SessionPitRoadAnalysis;
      reader.readMessage(value,msuimsgs_pb.SessionPitRoadAnalysis.deserializeBinaryFromReader);
      msg.addPitRoadAnalysis(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SessionPitRoadAnalysisResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SessionPitRoadAnalysisResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SessionPitRoadAnalysisResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionPitRoadAnalysisResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPitRoadAnalysisList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.SessionPitRoadAnalysis.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SessionPitRoadAnalysis pit_road_analysis = 1;
 * @return {!Array<!proto.SessionPitRoadAnalysis>}
 */
proto.SessionPitRoadAnalysisResponse.prototype.getPitRoadAnalysisList = function() {
  return /** @type{!Array<!proto.SessionPitRoadAnalysis>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.SessionPitRoadAnalysis, 1));
};


/**
 * @param {!Array<!proto.SessionPitRoadAnalysis>} value
 * @return {!proto.SessionPitRoadAnalysisResponse} returns this
*/
proto.SessionPitRoadAnalysisResponse.prototype.setPitRoadAnalysisList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.SessionPitRoadAnalysis=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SessionPitRoadAnalysis}
 */
proto.SessionPitRoadAnalysisResponse.prototype.addPitRoadAnalysis = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SessionPitRoadAnalysis, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SessionPitRoadAnalysisResponse} returns this
 */
proto.SessionPitRoadAnalysisResponse.prototype.clearPitRoadAnalysisList = function() {
  return this.setPitRoadAnalysisList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserPreferenceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UserPreferenceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserPreferenceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserPreferenceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdTimestamp: jspb.Message.getFieldWithDefault(msg, 3, ""),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 4, ""),
    updatedTimestamp: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserPreferenceResponse}
 */
proto.UserPreferenceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserPreferenceResponse;
  return proto.UserPreferenceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserPreferenceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserPreferenceResponse}
 */
proto.UserPreferenceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedTimestamp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserPreferenceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserPreferenceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserPreferenceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserPreferenceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedTimestamp();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUpdatedTimestamp();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string data = 1;
 * @return {string}
 */
proto.UserPreferenceResponse.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserPreferenceResponse} returns this
 */
proto.UserPreferenceResponse.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string created_by = 2;
 * @return {string}
 */
proto.UserPreferenceResponse.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserPreferenceResponse} returns this
 */
proto.UserPreferenceResponse.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string created_timestamp = 3;
 * @return {string}
 */
proto.UserPreferenceResponse.prototype.getCreatedTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserPreferenceResponse} returns this
 */
proto.UserPreferenceResponse.prototype.setCreatedTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string updated_by = 4;
 * @return {string}
 */
proto.UserPreferenceResponse.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserPreferenceResponse} returns this
 */
proto.UserPreferenceResponse.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string updated_timestamp = 5;
 * @return {string}
 */
proto.UserPreferenceResponse.prototype.getUpdatedTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserPreferenceResponse} returns this
 */
proto.UserPreferenceResponse.prototype.setUpdatedTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserPreferenceResponseStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.UserPreferenceResponseStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserPreferenceResponseStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserPreferenceResponseStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserPreferenceResponseStatus}
 */
proto.UserPreferenceResponseStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserPreferenceResponseStatus;
  return proto.UserPreferenceResponseStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserPreferenceResponseStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserPreferenceResponseStatus}
 */
proto.UserPreferenceResponseStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserPreferenceResponseStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserPreferenceResponseStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserPreferenceResponseStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserPreferenceResponseStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.UserPreferenceResponseStatus.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UserPreferenceResponseStatus} returns this
 */
proto.UserPreferenceResponseStatus.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserPreferenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UserPreferenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserPreferenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserPreferenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserPreferenceRequest}
 */
proto.UserPreferenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserPreferenceRequest;
  return proto.UserPreferenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserPreferenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserPreferenceRequest}
 */
proto.UserPreferenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserPreferenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserPreferenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserPreferenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserPreferenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string data = 1;
 * @return {string}
 */
proto.UserPreferenceRequest.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserPreferenceRequest} returns this
 */
proto.UserPreferenceRequest.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.FeedStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.FeedStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.FeedStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FeedStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    feedName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    statusFlag: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.FeedStatus}
 */
proto.FeedStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.FeedStatus;
  return proto.FeedStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.FeedStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.FeedStatus}
 */
proto.FeedStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeedName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusFlag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.FeedStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.FeedStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.FeedStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FeedStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeedName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatusFlag();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string feed_name = 1;
 * @return {string}
 */
proto.FeedStatus.prototype.getFeedName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.FeedStatus} returns this
 */
proto.FeedStatus.prototype.setFeedName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status_flag = 2;
 * @return {string}
 */
proto.FeedStatus.prototype.getStatusFlag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.FeedStatus} returns this
 */
proto.FeedStatus.prototype.setStatusFlag = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.FeedStatusResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.FeedStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.FeedStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.FeedStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FeedStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    feedStatusList: jspb.Message.toObjectList(msg.getFeedStatusList(),
    proto.FeedStatus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.FeedStatusResponse}
 */
proto.FeedStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.FeedStatusResponse;
  return proto.FeedStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.FeedStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.FeedStatusResponse}
 */
proto.FeedStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.FeedStatus;
      reader.readMessage(value,proto.FeedStatus.deserializeBinaryFromReader);
      msg.addFeedStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.FeedStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.FeedStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.FeedStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FeedStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeedStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.FeedStatus.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FeedStatus feed_status = 1;
 * @return {!Array<!proto.FeedStatus>}
 */
proto.FeedStatusResponse.prototype.getFeedStatusList = function() {
  return /** @type{!Array<!proto.FeedStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.FeedStatus, 1));
};


/**
 * @param {!Array<!proto.FeedStatus>} value
 * @return {!proto.FeedStatusResponse} returns this
*/
proto.FeedStatusResponse.prototype.setFeedStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.FeedStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.FeedStatus}
 */
proto.FeedStatusResponse.prototype.addFeedStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.FeedStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.FeedStatusResponse} returns this
 */
proto.FeedStatusResponse.prototype.clearFeedStatusList = function() {
  return this.setFeedStatusList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.QualifyingLapDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.QualifyingLapDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.QualifyingLapDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QualifyingLapDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    seriesType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    origintype: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.QualifyingLapDataRequest}
 */
proto.QualifyingLapDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.QualifyingLapDataRequest;
  return proto.QualifyingLapDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.QualifyingLapDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.QualifyingLapDataRequest}
 */
proto.QualifyingLapDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {!proto.SeriesType} */ (reader.readEnum());
      msg.setSeriesType(value);
      break;
    case 3:
      var value = /** @type {!proto.OriginType} */ (reader.readEnum());
      msg.setOrigintype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.QualifyingLapDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.QualifyingLapDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.QualifyingLapDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QualifyingLapDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSeriesType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOrigintype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.QualifyingLapDataRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.QualifyingLapDataRequest} returns this
 */
proto.QualifyingLapDataRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SeriesType series_type = 2;
 * @return {!proto.SeriesType}
 */
proto.QualifyingLapDataRequest.prototype.getSeriesType = function() {
  return /** @type {!proto.SeriesType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.SeriesType} value
 * @return {!proto.QualifyingLapDataRequest} returns this
 */
proto.QualifyingLapDataRequest.prototype.setSeriesType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional OriginType OriginType = 3;
 * @return {!proto.OriginType}
 */
proto.QualifyingLapDataRequest.prototype.getOrigintype = function() {
  return /** @type {!proto.OriginType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.OriginType} value
 * @return {!proto.QualifyingLapDataRequest} returns this
 */
proto.QualifyingLapDataRequest.prototype.setOrigintype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.QualifyingLapDataResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.QualifyingLapDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.QualifyingLapDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.QualifyingLapDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QualifyingLapDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    qualifyinglapdataList: jspb.Message.toObjectList(msg.getQualifyinglapdataList(),
    msuimsgs_pb.QualifyingLapData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.QualifyingLapDataResponse}
 */
proto.QualifyingLapDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.QualifyingLapDataResponse;
  return proto.QualifyingLapDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.QualifyingLapDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.QualifyingLapDataResponse}
 */
proto.QualifyingLapDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.QualifyingLapData;
      reader.readMessage(value,msuimsgs_pb.QualifyingLapData.deserializeBinaryFromReader);
      msg.addQualifyinglapdata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.QualifyingLapDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.QualifyingLapDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.QualifyingLapDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QualifyingLapDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQualifyinglapdataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.QualifyingLapData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated QualifyingLapData qualifyingLapData = 1;
 * @return {!Array<!proto.QualifyingLapData>}
 */
proto.QualifyingLapDataResponse.prototype.getQualifyinglapdataList = function() {
  return /** @type{!Array<!proto.QualifyingLapData>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.QualifyingLapData, 1));
};


/**
 * @param {!Array<!proto.QualifyingLapData>} value
 * @return {!proto.QualifyingLapDataResponse} returns this
*/
proto.QualifyingLapDataResponse.prototype.setQualifyinglapdataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.QualifyingLapData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.QualifyingLapData}
 */
proto.QualifyingLapDataResponse.prototype.addQualifyinglapdata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.QualifyingLapData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.QualifyingLapDataResponse} returns this
 */
proto.QualifyingLapDataResponse.prototype.clearQualifyinglapdataList = function() {
  return this.setQualifyinglapdataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AzureBlobSasTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.AzureBlobSasTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AzureBlobSasTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AzureBlobSasTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tokenType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventDate: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AzureBlobSasTokenRequest}
 */
proto.AzureBlobSasTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AzureBlobSasTokenRequest;
  return proto.AzureBlobSasTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AzureBlobSasTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AzureBlobSasTokenRequest}
 */
proto.AzureBlobSasTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.SasTokenType} */ (reader.readEnum());
      msg.setTokenType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AzureBlobSasTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AzureBlobSasTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AzureBlobSasTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AzureBlobSasTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTokenType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getEventDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional SasTokenType token_type = 1;
 * @return {!proto.SasTokenType}
 */
proto.AzureBlobSasTokenRequest.prototype.getTokenType = function() {
  return /** @type {!proto.SasTokenType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.SasTokenType} value
 * @return {!proto.AzureBlobSasTokenRequest} returns this
 */
proto.AzureBlobSasTokenRequest.prototype.setTokenType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string event_date = 2;
 * @return {string}
 */
proto.AzureBlobSasTokenRequest.prototype.getEventDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AzureBlobSasTokenRequest} returns this
 */
proto.AzureBlobSasTokenRequest.prototype.setEventDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AzureBlobSasTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AzureBlobSasTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AzureBlobSasTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AzureBlobSasTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AzureBlobSasTokenResponse}
 */
proto.AzureBlobSasTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AzureBlobSasTokenResponse;
  return proto.AzureBlobSasTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AzureBlobSasTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AzureBlobSasTokenResponse}
 */
proto.AzureBlobSasTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AzureBlobSasTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AzureBlobSasTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AzureBlobSasTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AzureBlobSasTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.AzureBlobSasTokenResponse.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AzureBlobSasTokenResponse} returns this
 */
proto.AzureBlobSasTokenResponse.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PointsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.PointsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PointsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PointsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pointsType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    seriesType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    season: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PointsRequest}
 */
proto.PointsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PointsRequest;
  return proto.PointsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PointsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PointsRequest}
 */
proto.PointsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.PointsType} */ (reader.readEnum());
      msg.setPointsType(value);
      break;
    case 2:
      var value = /** @type {!proto.SeriesType} */ (reader.readEnum());
      msg.setSeriesType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSeason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PointsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PointsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PointsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PointsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointsType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSeriesType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSeason();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional PointsType points_type = 1;
 * @return {!proto.PointsType}
 */
proto.PointsRequest.prototype.getPointsType = function() {
  return /** @type {!proto.PointsType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.PointsType} value
 * @return {!proto.PointsRequest} returns this
 */
proto.PointsRequest.prototype.setPointsType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional SeriesType series_type = 2;
 * @return {!proto.SeriesType}
 */
proto.PointsRequest.prototype.getSeriesType = function() {
  return /** @type {!proto.SeriesType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.SeriesType} value
 * @return {!proto.PointsRequest} returns this
 */
proto.PointsRequest.prototype.setSeriesType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 season = 3;
 * @return {number}
 */
proto.PointsRequest.prototype.getSeason = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.PointsRequest} returns this
 */
proto.PointsRequest.prototype.setSeason = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.PointsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.PointsResponse.PointsCase = {
  POINTS_NOT_SET: 0,
  OWNER_POINTS_LIST: 1,
  MANUFACTURER_POINTS_LIST: 2
};

/**
 * @return {proto.PointsResponse.PointsCase}
 */
proto.PointsResponse.prototype.getPointsCase = function() {
  return /** @type {proto.PointsResponse.PointsCase} */(jspb.Message.computeOneofCase(this, proto.PointsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PointsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.PointsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PointsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PointsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerPointsList: (f = msg.getOwnerPointsList()) && msuimsgs_pb.OwnerPointsList.toObject(includeInstance, f),
    manufacturerPointsList: (f = msg.getManufacturerPointsList()) && msuimsgs_pb.ManufacturerPointsList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PointsResponse}
 */
proto.PointsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PointsResponse;
  return proto.PointsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PointsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PointsResponse}
 */
proto.PointsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.OwnerPointsList;
      reader.readMessage(value,msuimsgs_pb.OwnerPointsList.deserializeBinaryFromReader);
      msg.setOwnerPointsList(value);
      break;
    case 2:
      var value = new msuimsgs_pb.ManufacturerPointsList;
      reader.readMessage(value,msuimsgs_pb.ManufacturerPointsList.deserializeBinaryFromReader);
      msg.setManufacturerPointsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PointsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PointsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PointsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PointsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerPointsList();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      msuimsgs_pb.OwnerPointsList.serializeBinaryToWriter
    );
  }
  f = message.getManufacturerPointsList();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      msuimsgs_pb.ManufacturerPointsList.serializeBinaryToWriter
    );
  }
};


/**
 * optional OwnerPointsList owner_points_list = 1;
 * @return {?proto.OwnerPointsList}
 */
proto.PointsResponse.prototype.getOwnerPointsList = function() {
  return /** @type{?proto.OwnerPointsList} */ (
    jspb.Message.getWrapperField(this, msuimsgs_pb.OwnerPointsList, 1));
};


/**
 * @param {?proto.OwnerPointsList|undefined} value
 * @return {!proto.PointsResponse} returns this
*/
proto.PointsResponse.prototype.setOwnerPointsList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.PointsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PointsResponse} returns this
 */
proto.PointsResponse.prototype.clearOwnerPointsList = function() {
  return this.setOwnerPointsList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PointsResponse.prototype.hasOwnerPointsList = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ManufacturerPointsList manufacturer_points_list = 2;
 * @return {?proto.ManufacturerPointsList}
 */
proto.PointsResponse.prototype.getManufacturerPointsList = function() {
  return /** @type{?proto.ManufacturerPointsList} */ (
    jspb.Message.getWrapperField(this, msuimsgs_pb.ManufacturerPointsList, 2));
};


/**
 * @param {?proto.ManufacturerPointsList|undefined} value
 * @return {!proto.PointsResponse} returns this
*/
proto.PointsResponse.prototype.setManufacturerPointsList = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.PointsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PointsResponse} returns this
 */
proto.PointsResponse.prototype.clearManufacturerPointsList = function() {
  return this.setManufacturerPointsList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PointsResponse.prototype.hasManufacturerPointsList = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.FastDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.FastDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.FastDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FastDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    xLap: jspb.Message.getFieldWithDefault(msg, 1, 0),
    threshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
    eventId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sessionType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    isReplayRequest: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.FastDataRequest}
 */
proto.FastDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.FastDataRequest;
  return proto.FastDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.FastDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.FastDataRequest}
 */
proto.FastDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setXLap(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setThreshold(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 5:
      var value = /** @type {!proto.RunType} */ (reader.readEnum());
      msg.setSessionType(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReplayRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.FastDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.FastDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.FastDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FastDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getXLap();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getThreshold();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSessionType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getIsReplayRequest();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int32 x_lap = 1;
 * @return {number}
 */
proto.FastDataRequest.prototype.getXLap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.FastDataRequest} returns this
 */
proto.FastDataRequest.prototype.setXLap = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double threshold = 2;
 * @return {number}
 */
proto.FastDataRequest.prototype.getThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.FastDataRequest} returns this
 */
proto.FastDataRequest.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int64 timestamp = 3;
 * @return {number}
 */
proto.FastDataRequest.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.FastDataRequest} returns this
 */
proto.FastDataRequest.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string event_id = 4;
 * @return {string}
 */
proto.FastDataRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.FastDataRequest} returns this
 */
proto.FastDataRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional RunType session_type = 5;
 * @return {!proto.RunType}
 */
proto.FastDataRequest.prototype.getSessionType = function() {
  return /** @type {!proto.RunType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.RunType} value
 * @return {!proto.FastDataRequest} returns this
 */
proto.FastDataRequest.prototype.setSessionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool is_replay_request = 6;
 * @return {boolean}
 */
proto.FastDataRequest.prototype.getIsReplayRequest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.FastDataRequest} returns this
 */
proto.FastDataRequest.prototype.setIsReplayRequest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.FastDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.FastDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.FastDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FastDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fastDataMap: (f = msg.getFastDataMap()) ? f.toObject(includeInstance, proto.LapAnalysisData.toObject) : [],
    defaultThreshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.FastDataResponse}
 */
proto.FastDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.FastDataResponse;
  return proto.FastDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.FastDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.FastDataResponse}
 */
proto.FastDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getFastDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.LapAnalysisData.deserializeBinaryFromReader, 0, new proto.LapAnalysisData());
         });
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDefaultThreshold(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.FastDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.FastDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.FastDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FastDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFastDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.LapAnalysisData.serializeBinaryToWriter);
  }
  f = message.getDefaultThreshold();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * map<int32, LapAnalysisData> fast_data = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.LapAnalysisData>}
 */
proto.FastDataResponse.prototype.getFastDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.LapAnalysisData>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.LapAnalysisData));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.FastDataResponse} returns this
 */
proto.FastDataResponse.prototype.clearFastDataMap = function() {
  this.getFastDataMap().clear();
  return this;};


/**
 * optional double default_threshold = 2;
 * @return {number}
 */
proto.FastDataResponse.prototype.getDefaultThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.FastDataResponse} returns this
 */
proto.FastDataResponse.prototype.setDefaultThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RankAnalysisRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.RankAnalysisRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RankAnalysisRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RankAnalysisRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    xLap: jspb.Message.getFieldWithDefault(msg, 1, 0),
    threshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 3, 0),
    eventId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sessionType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    isReplayRequest: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RankAnalysisRequest}
 */
proto.RankAnalysisRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RankAnalysisRequest;
  return proto.RankAnalysisRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RankAnalysisRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RankAnalysisRequest}
 */
proto.RankAnalysisRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setXLap(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setThreshold(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 5:
      var value = /** @type {!proto.RunType} */ (reader.readEnum());
      msg.setSessionType(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsReplayRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RankAnalysisRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RankAnalysisRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RankAnalysisRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RankAnalysisRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getXLap();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getThreshold();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSessionType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getIsReplayRequest();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int32 x_lap = 1;
 * @return {number}
 */
proto.RankAnalysisRequest.prototype.getXLap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RankAnalysisRequest} returns this
 */
proto.RankAnalysisRequest.prototype.setXLap = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double threshold = 2;
 * @return {number}
 */
proto.RankAnalysisRequest.prototype.getThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.RankAnalysisRequest} returns this
 */
proto.RankAnalysisRequest.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int64 timestamp = 3;
 * @return {number}
 */
proto.RankAnalysisRequest.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.RankAnalysisRequest} returns this
 */
proto.RankAnalysisRequest.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string event_id = 4;
 * @return {string}
 */
proto.RankAnalysisRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.RankAnalysisRequest} returns this
 */
proto.RankAnalysisRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional RunType session_type = 5;
 * @return {!proto.RunType}
 */
proto.RankAnalysisRequest.prototype.getSessionType = function() {
  return /** @type {!proto.RunType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.RunType} value
 * @return {!proto.RankAnalysisRequest} returns this
 */
proto.RankAnalysisRequest.prototype.setSessionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool is_replay_request = 6;
 * @return {boolean}
 */
proto.RankAnalysisRequest.prototype.getIsReplayRequest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.RankAnalysisRequest} returns this
 */
proto.RankAnalysisRequest.prototype.setIsReplayRequest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RankAnalysisResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.RankAnalysisResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RankAnalysisResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RankAnalysisResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleToLapMap: (f = msg.getVehicleToLapMap()) && motorsports$data$common_pb.VehicleToLapMap.toObject(includeInstance, f),
    lapAnalysisMapMap: (f = msg.getLapAnalysisMapMap()) ? f.toObject(includeInstance, proto.LapAnalysisData.toObject) : [],
    lapAnalysisAverageMap: (f = msg.getLapAnalysisAverageMap()) && motorsports$data$common_pb.LapAnalysisAvgData.toObject(includeInstance, f),
    defaultLapAnalysisStats: (f = msg.getDefaultLapAnalysisStats()) && motorsports$data$common_pb.LapAnalysisStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RankAnalysisResponse}
 */
proto.RankAnalysisResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RankAnalysisResponse;
  return proto.RankAnalysisResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RankAnalysisResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RankAnalysisResponse}
 */
proto.RankAnalysisResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new motorsports$data$common_pb.VehicleToLapMap;
      reader.readMessage(value,motorsports$data$common_pb.VehicleToLapMap.deserializeBinaryFromReader);
      msg.setVehicleToLapMap(value);
      break;
    case 2:
      var value = msg.getLapAnalysisMapMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readMessage, proto.LapAnalysisData.deserializeBinaryFromReader, 0, new proto.LapAnalysisData());
         });
      break;
    case 3:
      var value = new motorsports$data$common_pb.LapAnalysisAvgData;
      reader.readMessage(value,motorsports$data$common_pb.LapAnalysisAvgData.deserializeBinaryFromReader);
      msg.setLapAnalysisAverageMap(value);
      break;
    case 4:
      var value = new motorsports$data$common_pb.LapAnalysisStats;
      reader.readMessage(value,motorsports$data$common_pb.LapAnalysisStats.deserializeBinaryFromReader);
      msg.setDefaultLapAnalysisStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RankAnalysisResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RankAnalysisResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RankAnalysisResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RankAnalysisResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleToLapMap();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      motorsports$data$common_pb.VehicleToLapMap.serializeBinaryToWriter
    );
  }
  f = message.getLapAnalysisMapMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeMessage, proto.LapAnalysisData.serializeBinaryToWriter);
  }
  f = message.getLapAnalysisAverageMap();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      motorsports$data$common_pb.LapAnalysisAvgData.serializeBinaryToWriter
    );
  }
  f = message.getDefaultLapAnalysisStats();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      motorsports$data$common_pb.LapAnalysisStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional VehicleToLapMap vehicle_to_lap_map = 1;
 * @return {?proto.VehicleToLapMap}
 */
proto.RankAnalysisResponse.prototype.getVehicleToLapMap = function() {
  return /** @type{?proto.VehicleToLapMap} */ (
    jspb.Message.getWrapperField(this, motorsports$data$common_pb.VehicleToLapMap, 1));
};


/**
 * @param {?proto.VehicleToLapMap|undefined} value
 * @return {!proto.RankAnalysisResponse} returns this
*/
proto.RankAnalysisResponse.prototype.setVehicleToLapMap = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RankAnalysisResponse} returns this
 */
proto.RankAnalysisResponse.prototype.clearVehicleToLapMap = function() {
  return this.setVehicleToLapMap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RankAnalysisResponse.prototype.hasVehicleToLapMap = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * map<int32, LapAnalysisData> lap_analysis_map = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.LapAnalysisData>}
 */
proto.RankAnalysisResponse.prototype.getLapAnalysisMapMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.LapAnalysisData>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.LapAnalysisData));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.RankAnalysisResponse} returns this
 */
proto.RankAnalysisResponse.prototype.clearLapAnalysisMapMap = function() {
  this.getLapAnalysisMapMap().clear();
  return this;};


/**
 * optional LapAnalysisAvgData lap_analysis_average_map = 3;
 * @return {?proto.LapAnalysisAvgData}
 */
proto.RankAnalysisResponse.prototype.getLapAnalysisAverageMap = function() {
  return /** @type{?proto.LapAnalysisAvgData} */ (
    jspb.Message.getWrapperField(this, motorsports$data$common_pb.LapAnalysisAvgData, 3));
};


/**
 * @param {?proto.LapAnalysisAvgData|undefined} value
 * @return {!proto.RankAnalysisResponse} returns this
*/
proto.RankAnalysisResponse.prototype.setLapAnalysisAverageMap = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RankAnalysisResponse} returns this
 */
proto.RankAnalysisResponse.prototype.clearLapAnalysisAverageMap = function() {
  return this.setLapAnalysisAverageMap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RankAnalysisResponse.prototype.hasLapAnalysisAverageMap = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LapAnalysisStats default_lap_analysis_stats = 4;
 * @return {?proto.LapAnalysisStats}
 */
proto.RankAnalysisResponse.prototype.getDefaultLapAnalysisStats = function() {
  return /** @type{?proto.LapAnalysisStats} */ (
    jspb.Message.getWrapperField(this, motorsports$data$common_pb.LapAnalysisStats, 4));
};


/**
 * @param {?proto.LapAnalysisStats|undefined} value
 * @return {!proto.RankAnalysisResponse} returns this
*/
proto.RankAnalysisResponse.prototype.setDefaultLapAnalysisStats = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RankAnalysisResponse} returns this
 */
proto.RankAnalysisResponse.prototype.clearDefaultLapAnalysisStats = function() {
  return this.setDefaultLapAnalysisStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RankAnalysisResponse.prototype.hasDefaultLapAnalysisStats = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProcessRaceReplayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ProcessRaceReplayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProcessRaceReplayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProcessRaceReplayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    forceExecute: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProcessRaceReplayRequest}
 */
proto.ProcessRaceReplayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProcessRaceReplayRequest;
  return proto.ProcessRaceReplayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProcessRaceReplayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProcessRaceReplayRequest}
 */
proto.ProcessRaceReplayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventId(value);
      break;
    case 2:
      var value = /** @type {!proto.RunType} */ (reader.readEnum());
      msg.setSessionType(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForceExecute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProcessRaceReplayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProcessRaceReplayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProcessRaceReplayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProcessRaceReplayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getForceExecute();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string event_id = 1;
 * @return {string}
 */
proto.ProcessRaceReplayRequest.prototype.getEventId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProcessRaceReplayRequest} returns this
 */
proto.ProcessRaceReplayRequest.prototype.setEventId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RunType session_type = 2;
 * @return {!proto.RunType}
 */
proto.ProcessRaceReplayRequest.prototype.getSessionType = function() {
  return /** @type {!proto.RunType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.RunType} value
 * @return {!proto.ProcessRaceReplayRequest} returns this
 */
proto.ProcessRaceReplayRequest.prototype.setSessionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool force_execute = 3;
 * @return {boolean}
 */
proto.ProcessRaceReplayRequest.prototype.getForceExecute = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProcessRaceReplayRequest} returns this
 */
proto.ProcessRaceReplayRequest.prototype.setForceExecute = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProcessRaceReplayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ProcessRaceReplayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProcessRaceReplayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProcessRaceReplayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    liveEventRunning: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    azureFunctionError: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProcessRaceReplayResponse}
 */
proto.ProcessRaceReplayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProcessRaceReplayResponse;
  return proto.ProcessRaceReplayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProcessRaceReplayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProcessRaceReplayResponse}
 */
proto.ProcessRaceReplayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLiveEventRunning(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAzureFunctionError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProcessRaceReplayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProcessRaceReplayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProcessRaceReplayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProcessRaceReplayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiveEventRunning();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAzureFunctionError();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool live_event_running = 1;
 * @return {boolean}
 */
proto.ProcessRaceReplayResponse.prototype.getLiveEventRunning = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProcessRaceReplayResponse} returns this
 */
proto.ProcessRaceReplayResponse.prototype.setLiveEventRunning = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.ProcessRaceReplayResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProcessRaceReplayResponse} returns this
 */
proto.ProcessRaceReplayResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool azure_function_error = 3;
 * @return {boolean}
 */
proto.ProcessRaceReplayResponse.prototype.getAzureFunctionError = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ProcessRaceReplayResponse} returns this
 */
proto.ProcessRaceReplayResponse.prototype.setAzureFunctionError = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ClearCacheRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ClearCacheRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ClearCacheRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ClearCacheRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    forceClearCache: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ClearCacheRequest}
 */
proto.ClearCacheRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ClearCacheRequest;
  return proto.ClearCacheRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ClearCacheRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ClearCacheRequest}
 */
proto.ClearCacheRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForceClearCache(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ClearCacheRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ClearCacheRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ClearCacheRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ClearCacheRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForceClearCache();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool force_clear_cache = 1;
 * @return {boolean}
 */
proto.ClearCacheRequest.prototype.getForceClearCache = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ClearCacheRequest} returns this
 */
proto.ClearCacheRequest.prototype.setForceClearCache = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ClearCacheResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ClearCacheResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ClearCacheResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ClearCacheResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    liveEventRunning: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ClearCacheResponse}
 */
proto.ClearCacheResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ClearCacheResponse;
  return proto.ClearCacheResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ClearCacheResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ClearCacheResponse}
 */
proto.ClearCacheResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLiveEventRunning(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ClearCacheResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ClearCacheResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ClearCacheResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ClearCacheResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiveEventRunning();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool live_event_running = 1;
 * @return {boolean}
 */
proto.ClearCacheResponse.prototype.getLiveEventRunning = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ClearCacheResponse} returns this
 */
proto.ClearCacheResponse.prototype.setLiveEventRunning = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DriverPointsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DriverPointsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DriverPointsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DriverPointsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    seriesId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    raceSeason: jspb.Message.getFieldWithDefault(msg, 2, 0),
    raceId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pointsCalculation: jspb.Message.getFieldWithDefault(msg, 4, 0),
    positionProjection: jspb.Message.getFieldWithDefault(msg, 5, 0),
    gapType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    gapValue: jspb.Message.getFieldWithDefault(msg, 7, ""),
    cutoffRank: jspb.Message.getFieldWithDefault(msg, 8, 0),
    lastPlacePlayoffs: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DriverPointsRequest}
 */
proto.DriverPointsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DriverPointsRequest;
  return proto.DriverPointsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DriverPointsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DriverPointsRequest}
 */
proto.DriverPointsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.SeriesType} */ (reader.readEnum());
      msg.setSeriesId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRaceSeason(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRaceId(value);
      break;
    case 4:
      var value = /** @type {!proto.PointsCalculation} */ (reader.readEnum());
      msg.setPointsCalculation(value);
      break;
    case 5:
      var value = /** @type {!proto.PositionProjection} */ (reader.readEnum());
      msg.setPositionProjection(value);
      break;
    case 6:
      var value = /** @type {!proto.GapType} */ (reader.readEnum());
      msg.setGapType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setGapValue(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCutoffRank(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastPlacePlayoffs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DriverPointsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DriverPointsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DriverPointsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DriverPointsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeriesId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getRaceSeason();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRaceId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPointsCalculation();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPositionProjection();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getGapType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getGapValue();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCutoffRank();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLastPlacePlayoffs();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional SeriesType series_id = 1;
 * @return {!proto.SeriesType}
 */
proto.DriverPointsRequest.prototype.getSeriesId = function() {
  return /** @type {!proto.SeriesType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.SeriesType} value
 * @return {!proto.DriverPointsRequest} returns this
 */
proto.DriverPointsRequest.prototype.setSeriesId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 race_season = 2;
 * @return {number}
 */
proto.DriverPointsRequest.prototype.getRaceSeason = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPointsRequest} returns this
 */
proto.DriverPointsRequest.prototype.setRaceSeason = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 race_id = 3;
 * @return {number}
 */
proto.DriverPointsRequest.prototype.getRaceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPointsRequest} returns this
 */
proto.DriverPointsRequest.prototype.setRaceId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional PointsCalculation points_calculation = 4;
 * @return {!proto.PointsCalculation}
 */
proto.DriverPointsRequest.prototype.getPointsCalculation = function() {
  return /** @type {!proto.PointsCalculation} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.PointsCalculation} value
 * @return {!proto.DriverPointsRequest} returns this
 */
proto.DriverPointsRequest.prototype.setPointsCalculation = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional PositionProjection position_projection = 5;
 * @return {!proto.PositionProjection}
 */
proto.DriverPointsRequest.prototype.getPositionProjection = function() {
  return /** @type {!proto.PositionProjection} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.PositionProjection} value
 * @return {!proto.DriverPointsRequest} returns this
 */
proto.DriverPointsRequest.prototype.setPositionProjection = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional GapType gap_type = 6;
 * @return {!proto.GapType}
 */
proto.DriverPointsRequest.prototype.getGapType = function() {
  return /** @type {!proto.GapType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.GapType} value
 * @return {!proto.DriverPointsRequest} returns this
 */
proto.DriverPointsRequest.prototype.setGapType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string gap_value = 7;
 * @return {string}
 */
proto.DriverPointsRequest.prototype.getGapValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.DriverPointsRequest} returns this
 */
proto.DriverPointsRequest.prototype.setGapValue = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 cutoff_rank = 8;
 * @return {number}
 */
proto.DriverPointsRequest.prototype.getCutoffRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPointsRequest} returns this
 */
proto.DriverPointsRequest.prototype.setCutoffRank = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 last_place_playoffs = 9;
 * @return {number}
 */
proto.DriverPointsRequest.prototype.getLastPlacePlayoffs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPointsRequest} returns this
 */
proto.DriverPointsRequest.prototype.setLastPlacePlayoffs = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DriverPointsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DriverPointsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DriverPointsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DriverPointsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DriverPointsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pointsDataList: jspb.Message.toObjectList(msg.getPointsDataList(),
    motorsports$data$common_pb.DriverPoints.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DriverPointsResponse}
 */
proto.DriverPointsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DriverPointsResponse;
  return proto.DriverPointsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DriverPointsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DriverPointsResponse}
 */
proto.DriverPointsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new motorsports$data$common_pb.DriverPoints;
      reader.readMessage(value,motorsports$data$common_pb.DriverPoints.deserializeBinaryFromReader);
      msg.addPointsData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DriverPointsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DriverPointsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DriverPointsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DriverPointsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointsDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      motorsports$data$common_pb.DriverPoints.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DriverPoints points_data = 1;
 * @return {!Array<!proto.DriverPoints>}
 */
proto.DriverPointsResponse.prototype.getPointsDataList = function() {
  return /** @type{!Array<!proto.DriverPoints>} */ (
    jspb.Message.getRepeatedWrapperField(this, motorsports$data$common_pb.DriverPoints, 1));
};


/**
 * @param {!Array<!proto.DriverPoints>} value
 * @return {!proto.DriverPointsResponse} returns this
*/
proto.DriverPointsResponse.prototype.setPointsDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.DriverPoints=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DriverPoints}
 */
proto.DriverPointsResponse.prototype.addPointsData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.DriverPoints, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DriverPointsResponse} returns this
 */
proto.DriverPointsResponse.prototype.clearPointsDataList = function() {
  return this.setPointsDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetAllTeamsDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetAllTeamsDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetAllTeamsDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAllTeamsDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chevroletOnly: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetAllTeamsDataRequest}
 */
proto.GetAllTeamsDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetAllTeamsDataRequest;
  return proto.GetAllTeamsDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetAllTeamsDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetAllTeamsDataRequest}
 */
proto.GetAllTeamsDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setChevroletOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetAllTeamsDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetAllTeamsDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetAllTeamsDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAllTeamsDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChevroletOnly();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool chevrolet_only = 1;
 * @return {boolean}
 */
proto.GetAllTeamsDataRequest.prototype.getChevroletOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.GetAllTeamsDataRequest} returns this
 */
proto.GetAllTeamsDataRequest.prototype.setChevroletOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetAllTeamsDataResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetAllTeamsDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetAllTeamsDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetAllTeamsDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAllTeamsDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    teamsDataList: jspb.Message.toObjectList(msg.getTeamsDataList(),
    msuimsgs_pb.TeamData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetAllTeamsDataResponse}
 */
proto.GetAllTeamsDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetAllTeamsDataResponse;
  return proto.GetAllTeamsDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetAllTeamsDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetAllTeamsDataResponse}
 */
proto.GetAllTeamsDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.TeamData;
      reader.readMessage(value,msuimsgs_pb.TeamData.deserializeBinaryFromReader);
      msg.addTeamsData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetAllTeamsDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetAllTeamsDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetAllTeamsDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAllTeamsDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamsDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.TeamData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TeamData teams_data = 1;
 * @return {!Array<!proto.TeamData>}
 */
proto.GetAllTeamsDataResponse.prototype.getTeamsDataList = function() {
  return /** @type{!Array<!proto.TeamData>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.TeamData, 1));
};


/**
 * @param {!Array<!proto.TeamData>} value
 * @return {!proto.GetAllTeamsDataResponse} returns this
*/
proto.GetAllTeamsDataResponse.prototype.setTeamsDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.TeamData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TeamData}
 */
proto.GetAllTeamsDataResponse.prototype.addTeamsData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.TeamData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetAllTeamsDataResponse} returns this
 */
proto.GetAllTeamsDataResponse.prototype.clearTeamsDataList = function() {
  return this.setTeamsDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateAdminSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateAdminSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateAdminSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateAdminSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    settingsId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    settings: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateAdminSettingsRequest}
 */
proto.UpdateAdminSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateAdminSettingsRequest;
  return proto.UpdateAdminSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateAdminSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateAdminSettingsRequest}
 */
proto.UpdateAdminSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.AdminSettingsKey} */ (reader.readEnum());
      msg.setSettingsId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateAdminSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateAdminSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateAdminSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateAdminSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettingsId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSettings();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional AdminSettingsKey settings_id = 1;
 * @return {!proto.AdminSettingsKey}
 */
proto.UpdateAdminSettingsRequest.prototype.getSettingsId = function() {
  return /** @type {!proto.AdminSettingsKey} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.AdminSettingsKey} value
 * @return {!proto.UpdateAdminSettingsRequest} returns this
 */
proto.UpdateAdminSettingsRequest.prototype.setSettingsId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string settings = 2;
 * @return {string}
 */
proto.UpdateAdminSettingsRequest.prototype.getSettings = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateAdminSettingsRequest} returns this
 */
proto.UpdateAdminSettingsRequest.prototype.setSettings = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateAdminSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateAdminSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateAdminSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateAdminSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateAdminSettingsResponse}
 */
proto.UpdateAdminSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateAdminSettingsResponse;
  return proto.UpdateAdminSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateAdminSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateAdminSettingsResponse}
 */
proto.UpdateAdminSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateAdminSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateAdminSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateAdminSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateAdminSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.UpdateAdminSettingsResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdateAdminSettingsResponse} returns this
 */
proto.UpdateAdminSettingsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetAdminSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetAdminSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetAdminSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAdminSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    settingsId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetAdminSettingsRequest}
 */
proto.GetAdminSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetAdminSettingsRequest;
  return proto.GetAdminSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetAdminSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetAdminSettingsRequest}
 */
proto.GetAdminSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.AdminSettingsKey} */ (reader.readEnum());
      msg.setSettingsId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetAdminSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetAdminSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetAdminSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAdminSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettingsId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional AdminSettingsKey settings_id = 1;
 * @return {!proto.AdminSettingsKey}
 */
proto.GetAdminSettingsRequest.prototype.getSettingsId = function() {
  return /** @type {!proto.AdminSettingsKey} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.AdminSettingsKey} value
 * @return {!proto.GetAdminSettingsRequest} returns this
 */
proto.GetAdminSettingsRequest.prototype.setSettingsId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetAdminSettingsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetAdminSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetAdminSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetAdminSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAdminSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    adminSettingsList: jspb.Message.toObjectList(msg.getAdminSettingsList(),
    msuimsgs_pb.AdminSettings.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetAdminSettingsResponse}
 */
proto.GetAdminSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetAdminSettingsResponse;
  return proto.GetAdminSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetAdminSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetAdminSettingsResponse}
 */
proto.GetAdminSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new msuimsgs_pb.AdminSettings;
      reader.readMessage(value,msuimsgs_pb.AdminSettings.deserializeBinaryFromReader);
      msg.addAdminSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetAdminSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetAdminSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetAdminSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAdminSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdminSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      msuimsgs_pb.AdminSettings.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AdminSettings admin_settings = 1;
 * @return {!Array<!proto.AdminSettings>}
 */
proto.GetAdminSettingsResponse.prototype.getAdminSettingsList = function() {
  return /** @type{!Array<!proto.AdminSettings>} */ (
    jspb.Message.getRepeatedWrapperField(this, msuimsgs_pb.AdminSettings, 1));
};


/**
 * @param {!Array<!proto.AdminSettings>} value
 * @return {!proto.GetAdminSettingsResponse} returns this
*/
proto.GetAdminSettingsResponse.prototype.setAdminSettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.AdminSettings=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AdminSettings}
 */
proto.GetAdminSettingsResponse.prototype.addAdminSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.AdminSettings, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetAdminSettingsResponse} returns this
 */
proto.GetAdminSettingsResponse.prototype.clearAdminSettingsList = function() {
  return this.setAdminSettingsList([]);
};


goog.object.extend(exports, proto);
