// source: motorsports-data-common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.DriverPoints', null, global);
goog.exportSymbol('proto.FlagStatus', null, global);
goog.exportSymbol('proto.FlagType', null, global);
goog.exportSymbol('proto.GapMetadata', null, global);
goog.exportSymbol('proto.GreenCalc', null, global);
goog.exportSymbol('proto.GreenSingle', null, global);
goog.exportSymbol('proto.Lap', null, global);
goog.exportSymbol('proto.LapAnalysisAvgData', null, global);
goog.exportSymbol('proto.LapAnalysisAvgSingle', null, global);
goog.exportSymbol('proto.LapAnalysisData', null, global);
goog.exportSymbol('proto.LapAnalysisSingle', null, global);
goog.exportSymbol('proto.LapAnalysisStats', null, global);
goog.exportSymbol('proto.LapMetadata', null, global);
goog.exportSymbol('proto.LapNumberToTimeMap', null, global);
goog.exportSymbol('proto.MotorsportsData', null, global);
goog.exportSymbol('proto.MotorsportsData.MessagewrapperCase', null, global);
goog.exportSymbol('proto.OriginType', null, global);
goog.exportSymbol('proto.PitCycleMetadata', null, global);
goog.exportSymbol('proto.PitCycleTimeMetric', null, global);
goog.exportSymbol('proto.PitData', null, global);
goog.exportSymbol('proto.PitPositionMetadata', null, global);
goog.exportSymbol('proto.RunStatus', null, global);
goog.exportSymbol('proto.RunType', null, global);
goog.exportSymbol('proto.SeriesType', null, global);
goog.exportSymbol('proto.SessionMetadata', null, global);
goog.exportSymbol('proto.Telemetry', null, global);
goog.exportSymbol('proto.TrackMetaData', null, global);
goog.exportSymbol('proto.TrackTemp', null, global);
goog.exportSymbol('proto.TrackTemp.MessagewrapperCase', null, global);
goog.exportSymbol('proto.TrackTempCsv', null, global);
goog.exportSymbol('proto.TrackTempPng', null, global);
goog.exportSymbol('proto.VehicleToLapMap', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SessionMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SessionMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SessionMetadata.displayName = 'proto.SessionMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MotorsportsData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.MotorsportsData.oneofGroups_);
};
goog.inherits(proto.MotorsportsData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MotorsportsData.displayName = 'proto.MotorsportsData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Telemetry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Telemetry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Telemetry.displayName = 'proto.Telemetry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.FlagStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.FlagStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.FlagStatus.displayName = 'proto.FlagStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LapMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LapMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LapMetadata.displayName = 'proto.LapMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PitCycleTimeMetric = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PitCycleTimeMetric, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PitCycleTimeMetric.displayName = 'proto.PitCycleTimeMetric';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PitCycleMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.PitCycleMetadata.repeatedFields_, null);
};
goog.inherits(proto.PitCycleMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PitCycleMetadata.displayName = 'proto.PitCycleMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GapMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GapMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GapMetadata.displayName = 'proto.GapMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Lap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Lap.repeatedFields_, null);
};
goog.inherits(proto.Lap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Lap.displayName = 'proto.Lap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PitData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.PitData.repeatedFields_, null);
};
goog.inherits(proto.PitData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PitData.displayName = 'proto.PitData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TrackTemp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.TrackTemp.oneofGroups_);
};
goog.inherits(proto.TrackTemp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TrackTemp.displayName = 'proto.TrackTemp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TrackMetaData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TrackMetaData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TrackMetaData.displayName = 'proto.TrackMetaData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TrackTempCsv = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TrackTempCsv, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TrackTempCsv.displayName = 'proto.TrackTempCsv';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TrackTempPng = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TrackTempPng, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TrackTempPng.displayName = 'proto.TrackTempPng';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PitPositionMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PitPositionMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PitPositionMetadata.displayName = 'proto.PitPositionMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GreenCalc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GreenCalc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GreenCalc.displayName = 'proto.GreenCalc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GreenSingle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GreenSingle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GreenSingle.displayName = 'proto.GreenSingle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LapNumberToTimeMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LapNumberToTimeMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LapNumberToTimeMap.displayName = 'proto.LapNumberToTimeMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.VehicleToLapMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.VehicleToLapMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.VehicleToLapMap.displayName = 'proto.VehicleToLapMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LapAnalysisData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LapAnalysisData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LapAnalysisData.displayName = 'proto.LapAnalysisData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LapAnalysisSingle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LapAnalysisSingle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LapAnalysisSingle.displayName = 'proto.LapAnalysisSingle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LapAnalysisStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LapAnalysisStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LapAnalysisStats.displayName = 'proto.LapAnalysisStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LapAnalysisAvgData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LapAnalysisAvgData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LapAnalysisAvgData.displayName = 'proto.LapAnalysisAvgData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LapAnalysisAvgSingle = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LapAnalysisAvgSingle, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LapAnalysisAvgSingle.displayName = 'proto.LapAnalysisAvgSingle';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DriverPoints = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DriverPoints, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DriverPoints.displayName = 'proto.DriverPoints';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SessionMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.SessionMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SessionMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    raceid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    runtype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    seriesid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    clientid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    runname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    eventname: jspb.Message.getFieldWithDefault(msg, 6, ""),
    runstarttime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    mlrunname: jspb.Message.getFieldWithDefault(msg, 8, ""),
    smtrunname: jspb.Message.getFieldWithDefault(msg, 9, ""),
    rmonrunname: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SessionMetadata}
 */
proto.SessionMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SessionMetadata;
  return proto.SessionMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SessionMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SessionMetadata}
 */
proto.SessionMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRaceid(value);
      break;
    case 2:
      var value = /** @type {!proto.RunType} */ (reader.readEnum());
      msg.setRuntype(value);
      break;
    case 3:
      var value = /** @type {!proto.SeriesType} */ (reader.readEnum());
      msg.setSeriesid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunname(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventname(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRunstarttime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMlrunname(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSmtrunname(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRmonrunname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SessionMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SessionMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SessionMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SessionMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRaceid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRuntype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getSeriesid();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getClientid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRunname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEventname();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRunstarttime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getMlrunname();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSmtrunname();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRmonrunname();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional int32 raceId = 1;
 * @return {number}
 */
proto.SessionMetadata.prototype.getRaceid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionMetadata} returns this
 */
proto.SessionMetadata.prototype.setRaceid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional RunType runType = 2;
 * @return {!proto.RunType}
 */
proto.SessionMetadata.prototype.getRuntype = function() {
  return /** @type {!proto.RunType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.RunType} value
 * @return {!proto.SessionMetadata} returns this
 */
proto.SessionMetadata.prototype.setRuntype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional SeriesType seriesId = 3;
 * @return {!proto.SeriesType}
 */
proto.SessionMetadata.prototype.getSeriesid = function() {
  return /** @type {!proto.SeriesType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.SeriesType} value
 * @return {!proto.SessionMetadata} returns this
 */
proto.SessionMetadata.prototype.setSeriesid = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string clientId = 4;
 * @return {string}
 */
proto.SessionMetadata.prototype.getClientid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionMetadata} returns this
 */
proto.SessionMetadata.prototype.setClientid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string runName = 5;
 * @return {string}
 */
proto.SessionMetadata.prototype.getRunname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionMetadata} returns this
 */
proto.SessionMetadata.prototype.setRunname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string eventName = 6;
 * @return {string}
 */
proto.SessionMetadata.prototype.getEventname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionMetadata} returns this
 */
proto.SessionMetadata.prototype.setEventname = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 runStartTime = 7;
 * @return {number}
 */
proto.SessionMetadata.prototype.getRunstarttime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.SessionMetadata} returns this
 */
proto.SessionMetadata.prototype.setRunstarttime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string mlRunName = 8;
 * @return {string}
 */
proto.SessionMetadata.prototype.getMlrunname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionMetadata} returns this
 */
proto.SessionMetadata.prototype.setMlrunname = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string smtRunName = 9;
 * @return {string}
 */
proto.SessionMetadata.prototype.getSmtrunname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionMetadata} returns this
 */
proto.SessionMetadata.prototype.setSmtrunname = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string rmonRunName = 10;
 * @return {string}
 */
proto.SessionMetadata.prototype.getRmonrunname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.SessionMetadata} returns this
 */
proto.SessionMetadata.prototype.setRmonrunname = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.MotorsportsData.oneofGroups_ = [[3,4,5,6]];

/**
 * @enum {number}
 */
proto.MotorsportsData.MessagewrapperCase = {
  MESSAGEWRAPPER_NOT_SET: 0,
  TELEMETRY: 3,
  FLAGSTATUS: 4,
  LAP: 5,
  PITDATA: 6
};

/**
 * @return {proto.MotorsportsData.MessagewrapperCase}
 */
proto.MotorsportsData.prototype.getMessagewrapperCase = function() {
  return /** @type {proto.MotorsportsData.MessagewrapperCase} */(jspb.Message.computeOneofCase(this, proto.MotorsportsData.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MotorsportsData.prototype.toObject = function(opt_includeInstance) {
  return proto.MotorsportsData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MotorsportsData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MotorsportsData.toObject = function(includeInstance, msg) {
  var f, obj = {
    origintype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    session: (f = msg.getSession()) && proto.SessionMetadata.toObject(includeInstance, f),
    telemetry: (f = msg.getTelemetry()) && proto.Telemetry.toObject(includeInstance, f),
    flagstatus: (f = msg.getFlagstatus()) && proto.FlagStatus.toObject(includeInstance, f),
    lap: (f = msg.getLap()) && proto.Lap.toObject(includeInstance, f),
    pitdata: (f = msg.getPitdata()) && proto.PitData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MotorsportsData}
 */
proto.MotorsportsData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MotorsportsData;
  return proto.MotorsportsData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MotorsportsData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MotorsportsData}
 */
proto.MotorsportsData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.OriginType} */ (reader.readEnum());
      msg.setOrigintype(value);
      break;
    case 2:
      var value = new proto.SessionMetadata;
      reader.readMessage(value,proto.SessionMetadata.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 3:
      var value = new proto.Telemetry;
      reader.readMessage(value,proto.Telemetry.deserializeBinaryFromReader);
      msg.setTelemetry(value);
      break;
    case 4:
      var value = new proto.FlagStatus;
      reader.readMessage(value,proto.FlagStatus.deserializeBinaryFromReader);
      msg.setFlagstatus(value);
      break;
    case 5:
      var value = new proto.Lap;
      reader.readMessage(value,proto.Lap.deserializeBinaryFromReader);
      msg.setLap(value);
      break;
    case 6:
      var value = new proto.PitData;
      reader.readMessage(value,proto.PitData.deserializeBinaryFromReader);
      msg.setPitdata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MotorsportsData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MotorsportsData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MotorsportsData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MotorsportsData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrigintype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.SessionMetadata.serializeBinaryToWriter
    );
  }
  f = message.getTelemetry();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Telemetry.serializeBinaryToWriter
    );
  }
  f = message.getFlagstatus();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.FlagStatus.serializeBinaryToWriter
    );
  }
  f = message.getLap();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.Lap.serializeBinaryToWriter
    );
  }
  f = message.getPitdata();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.PitData.serializeBinaryToWriter
    );
  }
};


/**
 * optional OriginType originType = 1;
 * @return {!proto.OriginType}
 */
proto.MotorsportsData.prototype.getOrigintype = function() {
  return /** @type {!proto.OriginType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.OriginType} value
 * @return {!proto.MotorsportsData} returns this
 */
proto.MotorsportsData.prototype.setOrigintype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional SessionMetadata session = 2;
 * @return {?proto.SessionMetadata}
 */
proto.MotorsportsData.prototype.getSession = function() {
  return /** @type{?proto.SessionMetadata} */ (
    jspb.Message.getWrapperField(this, proto.SessionMetadata, 2));
};


/**
 * @param {?proto.SessionMetadata|undefined} value
 * @return {!proto.MotorsportsData} returns this
*/
proto.MotorsportsData.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.MotorsportsData} returns this
 */
proto.MotorsportsData.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MotorsportsData.prototype.hasSession = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Telemetry telemetry = 3;
 * @return {?proto.Telemetry}
 */
proto.MotorsportsData.prototype.getTelemetry = function() {
  return /** @type{?proto.Telemetry} */ (
    jspb.Message.getWrapperField(this, proto.Telemetry, 3));
};


/**
 * @param {?proto.Telemetry|undefined} value
 * @return {!proto.MotorsportsData} returns this
*/
proto.MotorsportsData.prototype.setTelemetry = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.MotorsportsData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.MotorsportsData} returns this
 */
proto.MotorsportsData.prototype.clearTelemetry = function() {
  return this.setTelemetry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MotorsportsData.prototype.hasTelemetry = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional FlagStatus flagStatus = 4;
 * @return {?proto.FlagStatus}
 */
proto.MotorsportsData.prototype.getFlagstatus = function() {
  return /** @type{?proto.FlagStatus} */ (
    jspb.Message.getWrapperField(this, proto.FlagStatus, 4));
};


/**
 * @param {?proto.FlagStatus|undefined} value
 * @return {!proto.MotorsportsData} returns this
*/
proto.MotorsportsData.prototype.setFlagstatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.MotorsportsData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.MotorsportsData} returns this
 */
proto.MotorsportsData.prototype.clearFlagstatus = function() {
  return this.setFlagstatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MotorsportsData.prototype.hasFlagstatus = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Lap lap = 5;
 * @return {?proto.Lap}
 */
proto.MotorsportsData.prototype.getLap = function() {
  return /** @type{?proto.Lap} */ (
    jspb.Message.getWrapperField(this, proto.Lap, 5));
};


/**
 * @param {?proto.Lap|undefined} value
 * @return {!proto.MotorsportsData} returns this
*/
proto.MotorsportsData.prototype.setLap = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.MotorsportsData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.MotorsportsData} returns this
 */
proto.MotorsportsData.prototype.clearLap = function() {
  return this.setLap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MotorsportsData.prototype.hasLap = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PitData pitData = 6;
 * @return {?proto.PitData}
 */
proto.MotorsportsData.prototype.getPitdata = function() {
  return /** @type{?proto.PitData} */ (
    jspb.Message.getWrapperField(this, proto.PitData, 6));
};


/**
 * @param {?proto.PitData|undefined} value
 * @return {!proto.MotorsportsData} returns this
*/
proto.MotorsportsData.prototype.setPitdata = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.MotorsportsData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.MotorsportsData} returns this
 */
proto.MotorsportsData.prototype.clearPitdata = function() {
  return this.setPitdata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.MotorsportsData.prototype.hasPitdata = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Telemetry.prototype.toObject = function(opt_includeInstance) {
  return proto.Telemetry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Telemetry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Telemetry.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: (f = msg.getSession()) && proto.SessionMetadata.toObject(includeInstance, f),
    vehiclenumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vehiclemanufacturer: jspb.Message.getFieldWithDefault(msg, 3, ""),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    altitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    positionstandarddeviation: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    gpsquality: jspb.Message.getFieldWithDefault(msg, 8, 0),
    rpm: jspb.Message.getFieldWithDefault(msg, 9, 0),
    speed: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    throttle: jspb.Message.getFieldWithDefault(msg, 11, 0),
    brakepsi: jspb.Message.getFieldWithDefault(msg, 12, 0),
    steering: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    heading: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    lateralg: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    longitudeg: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    lapelapsedfraction: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    laptiming: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    rpmflag: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    throttleflag: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    brakeflag: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    steeringflag: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    messagetype: jspb.Message.getFieldWithDefault(msg, 23, ""),
    position: jspb.Message.getFieldWithDefault(msg, 24, 0),
    onleadlap: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    isluckydog: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    gear: jspb.Message.getFieldWithDefault(msg, 28, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Telemetry}
 */
proto.Telemetry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Telemetry;
  return proto.Telemetry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Telemetry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Telemetry}
 */
proto.Telemetry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SessionMetadata;
      reader.readMessage(value,proto.SessionMetadata.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehiclenumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehiclemanufacturer(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAltitude(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPositionstandarddeviation(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGpsquality(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRpm(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSpeed(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setThrottle(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBrakepsi(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSteering(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHeading(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLateralg(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitudeg(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLapelapsedfraction(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLaptiming(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRpmflag(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setThrottleflag(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBrakeflag(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSteeringflag(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessagetype(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosition(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnleadlap(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsluckydog(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Telemetry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Telemetry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Telemetry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Telemetry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.SessionMetadata.serializeBinaryToWriter
    );
  }
  f = message.getVehiclenumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehiclemanufacturer();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getAltitude();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getPositionstandarddeviation();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getGpsquality();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getRpm();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getThrottle();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getBrakepsi();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getSteering();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getHeading();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getLateralg();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getLongitudeg();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getLapelapsedfraction();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getLaptiming();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getRpmflag();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getThrottleflag();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getBrakeflag();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getSteeringflag();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getMessagetype();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getOnleadlap();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getIsluckydog();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getGear();
  if (f !== 0) {
    writer.writeInt32(
      28,
      f
    );
  }
};


/**
 * optional SessionMetadata session = 1;
 * @return {?proto.SessionMetadata}
 */
proto.Telemetry.prototype.getSession = function() {
  return /** @type{?proto.SessionMetadata} */ (
    jspb.Message.getWrapperField(this, proto.SessionMetadata, 1));
};


/**
 * @param {?proto.SessionMetadata|undefined} value
 * @return {!proto.Telemetry} returns this
*/
proto.Telemetry.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Telemetry.prototype.hasSession = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string vehicleNumber = 2;
 * @return {string}
 */
proto.Telemetry.prototype.getVehiclenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setVehiclenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vehicleManufacturer = 3;
 * @return {string}
 */
proto.Telemetry.prototype.getVehiclemanufacturer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setVehiclemanufacturer = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double latitude = 4;
 * @return {number}
 */
proto.Telemetry.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double longitude = 5;
 * @return {number}
 */
proto.Telemetry.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double altitude = 6;
 * @return {number}
 */
proto.Telemetry.prototype.getAltitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setAltitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double positionStandardDeviation = 7;
 * @return {number}
 */
proto.Telemetry.prototype.getPositionstandarddeviation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setPositionstandarddeviation = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int32 gpsQuality = 8;
 * @return {number}
 */
proto.Telemetry.prototype.getGpsquality = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setGpsquality = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 rpm = 9;
 * @return {number}
 */
proto.Telemetry.prototype.getRpm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setRpm = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional double speed = 10;
 * @return {number}
 */
proto.Telemetry.prototype.getSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional int32 throttle = 11;
 * @return {number}
 */
proto.Telemetry.prototype.getThrottle = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setThrottle = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 brakePSI = 12;
 * @return {number}
 */
proto.Telemetry.prototype.getBrakepsi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setBrakepsi = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional double steering = 13;
 * @return {number}
 */
proto.Telemetry.prototype.getSteering = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setSteering = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double heading = 14;
 * @return {number}
 */
proto.Telemetry.prototype.getHeading = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setHeading = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double lateralG = 15;
 * @return {number}
 */
proto.Telemetry.prototype.getLateralg = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setLateralg = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional double longitudeG = 16;
 * @return {number}
 */
proto.Telemetry.prototype.getLongitudeg = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setLongitudeg = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional double lapElapsedFraction = 17;
 * @return {number}
 */
proto.Telemetry.prototype.getLapelapsedfraction = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setLapelapsedfraction = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional double lapTiming = 18;
 * @return {number}
 */
proto.Telemetry.prototype.getLaptiming = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setLaptiming = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional bool rpmFlag = 19;
 * @return {boolean}
 */
proto.Telemetry.prototype.getRpmflag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setRpmflag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool throttleFlag = 20;
 * @return {boolean}
 */
proto.Telemetry.prototype.getThrottleflag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setThrottleflag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool brakeFlag = 21;
 * @return {boolean}
 */
proto.Telemetry.prototype.getBrakeflag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setBrakeflag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool steeringFlag = 22;
 * @return {boolean}
 */
proto.Telemetry.prototype.getSteeringflag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setSteeringflag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional string messageType = 23;
 * @return {string}
 */
proto.Telemetry.prototype.getMessagetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setMessagetype = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional int32 position = 24;
 * @return {number}
 */
proto.Telemetry.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional bool onLeadLap = 25;
 * @return {boolean}
 */
proto.Telemetry.prototype.getOnleadlap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setOnleadlap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional bool isLuckyDog = 26;
 * @return {boolean}
 */
proto.Telemetry.prototype.getIsluckydog = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setIsluckydog = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional int32 gear = 28;
 * @return {number}
 */
proto.Telemetry.prototype.getGear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.Telemetry} returns this
 */
proto.Telemetry.prototype.setGear = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.FlagStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.FlagStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.FlagStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FlagStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: (f = msg.getSession()) && proto.SessionMetadata.toObject(includeInstance, f),
    lapnumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lapsremaining: jspb.Message.getFieldWithDefault(msg, 3, 0),
    flag: jspb.Message.getFieldWithDefault(msg, 4, 0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 5, 0),
    elapsedracetime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    greenflagcount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    yellowflagcount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    stagesMap: (f = msg.getStagesMap()) ? f.toObject(includeInstance, undefined) : [],
    stagenumber: jspb.Message.getFieldWithDefault(msg, 10, 0),
    stagelapnumber: jspb.Message.getFieldWithDefault(msg, 11, 0),
    messagetype: jspb.Message.getFieldWithDefault(msg, 12, ""),
    timeremaining: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.FlagStatus}
 */
proto.FlagStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.FlagStatus;
  return proto.FlagStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.FlagStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.FlagStatus}
 */
proto.FlagStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SessionMetadata;
      reader.readMessage(value,proto.SessionMetadata.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapnumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapsremaining(value);
      break;
    case 4:
      var value = /** @type {!proto.FlagType} */ (reader.readEnum());
      msg.setFlag(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setElapsedracetime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGreenflagcount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYellowflagcount(value);
      break;
    case 9:
      var value = msg.getStagesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readInt32, null, 0, 0);
         });
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStagenumber(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStagelapnumber(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessagetype(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeremaining(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.FlagStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.FlagStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.FlagStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.FlagStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.SessionMetadata.serializeBinaryToWriter
    );
  }
  f = message.getLapnumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLapsremaining();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFlag();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getElapsedracetime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getGreenflagcount();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getYellowflagcount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getStagesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(9, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeInt32);
  }
  f = message.getStagenumber();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getStagelapnumber();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getMessagetype();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTimeremaining();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
};


/**
 * optional SessionMetadata session = 1;
 * @return {?proto.SessionMetadata}
 */
proto.FlagStatus.prototype.getSession = function() {
  return /** @type{?proto.SessionMetadata} */ (
    jspb.Message.getWrapperField(this, proto.SessionMetadata, 1));
};


/**
 * @param {?proto.SessionMetadata|undefined} value
 * @return {!proto.FlagStatus} returns this
*/
proto.FlagStatus.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.FlagStatus} returns this
 */
proto.FlagStatus.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.FlagStatus.prototype.hasSession = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 lapNumber = 2;
 * @return {number}
 */
proto.FlagStatus.prototype.getLapnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.FlagStatus} returns this
 */
proto.FlagStatus.prototype.setLapnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 lapsRemaining = 3;
 * @return {number}
 */
proto.FlagStatus.prototype.getLapsremaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.FlagStatus} returns this
 */
proto.FlagStatus.prototype.setLapsremaining = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional FlagType flag = 4;
 * @return {!proto.FlagType}
 */
proto.FlagStatus.prototype.getFlag = function() {
  return /** @type {!proto.FlagType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.FlagType} value
 * @return {!proto.FlagStatus} returns this
 */
proto.FlagStatus.prototype.setFlag = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 timestamp = 5;
 * @return {number}
 */
proto.FlagStatus.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.FlagStatus} returns this
 */
proto.FlagStatus.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 elapsedRaceTime = 6;
 * @return {number}
 */
proto.FlagStatus.prototype.getElapsedracetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.FlagStatus} returns this
 */
proto.FlagStatus.prototype.setElapsedracetime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 greenFlagCount = 7;
 * @return {number}
 */
proto.FlagStatus.prototype.getGreenflagcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.FlagStatus} returns this
 */
proto.FlagStatus.prototype.setGreenflagcount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 yellowFlagCount = 8;
 * @return {number}
 */
proto.FlagStatus.prototype.getYellowflagcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.FlagStatus} returns this
 */
proto.FlagStatus.prototype.setYellowflagcount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * map<int32, int32> stages = 9;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.FlagStatus.prototype.getStagesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 9, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.FlagStatus} returns this
 */
proto.FlagStatus.prototype.clearStagesMap = function() {
  this.getStagesMap().clear();
  return this;};


/**
 * optional int32 stageNumber = 10;
 * @return {number}
 */
proto.FlagStatus.prototype.getStagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.FlagStatus} returns this
 */
proto.FlagStatus.prototype.setStagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 stageLapNumber = 11;
 * @return {number}
 */
proto.FlagStatus.prototype.getStagelapnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.FlagStatus} returns this
 */
proto.FlagStatus.prototype.setStagelapnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string messageType = 12;
 * @return {string}
 */
proto.FlagStatus.prototype.getMessagetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.FlagStatus} returns this
 */
proto.FlagStatus.prototype.setMessagetype = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int64 timeRemaining = 13;
 * @return {number}
 */
proto.FlagStatus.prototype.getTimeremaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.FlagStatus} returns this
 */
proto.FlagStatus.prototype.setTimeremaining = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LapMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.LapMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LapMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    flag: jspb.Message.getFieldWithDefault(msg, 1, 0),
    laptime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lapnumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    position: jspb.Message.getFieldWithDefault(msg, 4, 0),
    slowlap: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    greenyellowflag: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    pitlap: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    leaderflag: jspb.Message.getFieldWithDefault(msg, 8, 0),
    timeofday: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LapMetadata}
 */
proto.LapMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LapMetadata;
  return proto.LapMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LapMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LapMetadata}
 */
proto.LapMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.FlagType} */ (reader.readEnum());
      msg.setFlag(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLaptime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapnumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosition(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSlowlap(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGreenyellowflag(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPitlap(value);
      break;
    case 8:
      var value = /** @type {!proto.FlagType} */ (reader.readEnum());
      msg.setLeaderflag(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeofday(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LapMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LapMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LapMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlag();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getLaptime();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLapnumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSlowlap();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getGreenyellowflag();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPitlap();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getLeaderflag();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getTimeofday();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional FlagType flag = 1;
 * @return {!proto.FlagType}
 */
proto.LapMetadata.prototype.getFlag = function() {
  return /** @type {!proto.FlagType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.FlagType} value
 * @return {!proto.LapMetadata} returns this
 */
proto.LapMetadata.prototype.setFlag = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 lapTime = 2;
 * @return {number}
 */
proto.LapMetadata.prototype.getLaptime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.LapMetadata} returns this
 */
proto.LapMetadata.prototype.setLaptime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 lapNumber = 3;
 * @return {number}
 */
proto.LapMetadata.prototype.getLapnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.LapMetadata} returns this
 */
proto.LapMetadata.prototype.setLapnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 position = 4;
 * @return {number}
 */
proto.LapMetadata.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.LapMetadata} returns this
 */
proto.LapMetadata.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool slowLap = 5;
 * @return {boolean}
 */
proto.LapMetadata.prototype.getSlowlap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.LapMetadata} returns this
 */
proto.LapMetadata.prototype.setSlowlap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool greenYellowFlag = 6;
 * @return {boolean}
 */
proto.LapMetadata.prototype.getGreenyellowflag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.LapMetadata} returns this
 */
proto.LapMetadata.prototype.setGreenyellowflag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool pitLap = 7;
 * @return {boolean}
 */
proto.LapMetadata.prototype.getPitlap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.LapMetadata} returns this
 */
proto.LapMetadata.prototype.setPitlap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional FlagType leaderFlag = 8;
 * @return {!proto.FlagType}
 */
proto.LapMetadata.prototype.getLeaderflag = function() {
  return /** @type {!proto.FlagType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.FlagType} value
 * @return {!proto.LapMetadata} returns this
 */
proto.LapMetadata.prototype.setLeaderflag = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int64 timeOfDay = 9;
 * @return {number}
 */
proto.LapMetadata.prototype.getTimeofday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.LapMetadata} returns this
 */
proto.LapMetadata.prototype.setTimeofday = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PitCycleTimeMetric.prototype.toObject = function(opt_includeInstance) {
  return proto.PitCycleTimeMetric.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PitCycleTimeMetric} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitCycleTimeMetric.toObject = function(includeInstance, msg) {
  var f, obj = {
    lapnumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pitin: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pitout: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fullcycle: jspb.Message.getFieldWithDefault(msg, 4, 0),
    tireschanged: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PitCycleTimeMetric}
 */
proto.PitCycleTimeMetric.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PitCycleTimeMetric;
  return proto.PitCycleTimeMetric.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PitCycleTimeMetric} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PitCycleTimeMetric}
 */
proto.PitCycleTimeMetric.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapnumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPitin(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPitout(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFullcycle(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTireschanged(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PitCycleTimeMetric.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PitCycleTimeMetric.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PitCycleTimeMetric} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitCycleTimeMetric.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLapnumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPitin();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPitout();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFullcycle();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTireschanged();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 lapNumber = 1;
 * @return {number}
 */
proto.PitCycleTimeMetric.prototype.getLapnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleTimeMetric} returns this
 */
proto.PitCycleTimeMetric.prototype.setLapnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 pitIn = 2;
 * @return {number}
 */
proto.PitCycleTimeMetric.prototype.getPitin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleTimeMetric} returns this
 */
proto.PitCycleTimeMetric.prototype.setPitin = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 pitOut = 3;
 * @return {number}
 */
proto.PitCycleTimeMetric.prototype.getPitout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleTimeMetric} returns this
 */
proto.PitCycleTimeMetric.prototype.setPitout = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 fullCycle = 4;
 * @return {number}
 */
proto.PitCycleTimeMetric.prototype.getFullcycle = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleTimeMetric} returns this
 */
proto.PitCycleTimeMetric.prototype.setFullcycle = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 tiresChanged = 5;
 * @return {number}
 */
proto.PitCycleTimeMetric.prototype.getTireschanged = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleTimeMetric} returns this
 */
proto.PitCycleTimeMetric.prototype.setTireschanged = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PitCycleMetadata.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PitCycleMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.PitCycleMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PitCycleMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitCycleMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    pitcycletimesList: jspb.Message.toObjectList(msg.getPitcycletimesList(),
    proto.PitCycleTimeMetric.toObject, includeInstance),
    bestpitincycletime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    averagepitincycletime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    bestpitoutcycletime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    averagepitoutcycletime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    bestfullcycletime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    averagefullcycletime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    best4tfullcycletime: jspb.Message.getFieldWithDefault(msg, 8, 0),
    average4tfullcycletime: jspb.Message.getFieldWithDefault(msg, 9, 0),
    best2tfullcycletime: jspb.Message.getFieldWithDefault(msg, 10, 0),
    average2tfullcycletime: jspb.Message.getFieldWithDefault(msg, 11, 0),
    bestfuelonlyfullcycletime: jspb.Message.getFieldWithDefault(msg, 12, 0),
    averagefuelonlyfullcycletime: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PitCycleMetadata}
 */
proto.PitCycleMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PitCycleMetadata;
  return proto.PitCycleMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PitCycleMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PitCycleMetadata}
 */
proto.PitCycleMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.PitCycleTimeMetric;
      reader.readMessage(value,proto.PitCycleTimeMetric.deserializeBinaryFromReader);
      msg.addPitcycletimes(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBestpitincycletime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAveragepitincycletime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBestpitoutcycletime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAveragepitoutcycletime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBestfullcycletime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAveragefullcycletime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBest4tfullcycletime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAverage4tfullcycletime(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBest2tfullcycletime(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAverage2tfullcycletime(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBestfuelonlyfullcycletime(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAveragefuelonlyfullcycletime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PitCycleMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PitCycleMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PitCycleMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitCycleMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPitcycletimesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.PitCycleTimeMetric.serializeBinaryToWriter
    );
  }
  f = message.getBestpitincycletime();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAveragepitincycletime();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getBestpitoutcycletime();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAveragepitoutcycletime();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getBestfullcycletime();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getAveragefullcycletime();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getBest4tfullcycletime();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getAverage4tfullcycletime();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getBest2tfullcycletime();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getAverage2tfullcycletime();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getBestfuelonlyfullcycletime();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getAveragefuelonlyfullcycletime();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
};


/**
 * repeated PitCycleTimeMetric pitCycleTimes = 1;
 * @return {!Array<!proto.PitCycleTimeMetric>}
 */
proto.PitCycleMetadata.prototype.getPitcycletimesList = function() {
  return /** @type{!Array<!proto.PitCycleTimeMetric>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.PitCycleTimeMetric, 1));
};


/**
 * @param {!Array<!proto.PitCycleTimeMetric>} value
 * @return {!proto.PitCycleMetadata} returns this
*/
proto.PitCycleMetadata.prototype.setPitcycletimesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.PitCycleTimeMetric=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PitCycleTimeMetric}
 */
proto.PitCycleMetadata.prototype.addPitcycletimes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.PitCycleTimeMetric, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PitCycleMetadata} returns this
 */
proto.PitCycleMetadata.prototype.clearPitcycletimesList = function() {
  return this.setPitcycletimesList([]);
};


/**
 * optional int32 bestPitInCycleTime = 2;
 * @return {number}
 */
proto.PitCycleMetadata.prototype.getBestpitincycletime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleMetadata} returns this
 */
proto.PitCycleMetadata.prototype.setBestpitincycletime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 averagePitInCycleTime = 3;
 * @return {number}
 */
proto.PitCycleMetadata.prototype.getAveragepitincycletime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleMetadata} returns this
 */
proto.PitCycleMetadata.prototype.setAveragepitincycletime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 bestPitOutCycleTime = 4;
 * @return {number}
 */
proto.PitCycleMetadata.prototype.getBestpitoutcycletime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleMetadata} returns this
 */
proto.PitCycleMetadata.prototype.setBestpitoutcycletime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 averagePitOutCycleTime = 5;
 * @return {number}
 */
proto.PitCycleMetadata.prototype.getAveragepitoutcycletime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleMetadata} returns this
 */
proto.PitCycleMetadata.prototype.setAveragepitoutcycletime = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 bestFullCycleTime = 6;
 * @return {number}
 */
proto.PitCycleMetadata.prototype.getBestfullcycletime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleMetadata} returns this
 */
proto.PitCycleMetadata.prototype.setBestfullcycletime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 averageFullCycleTime = 7;
 * @return {number}
 */
proto.PitCycleMetadata.prototype.getAveragefullcycletime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleMetadata} returns this
 */
proto.PitCycleMetadata.prototype.setAveragefullcycletime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 best4TFullCycleTime = 8;
 * @return {number}
 */
proto.PitCycleMetadata.prototype.getBest4tfullcycletime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleMetadata} returns this
 */
proto.PitCycleMetadata.prototype.setBest4tfullcycletime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 average4TFullCycleTime = 9;
 * @return {number}
 */
proto.PitCycleMetadata.prototype.getAverage4tfullcycletime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleMetadata} returns this
 */
proto.PitCycleMetadata.prototype.setAverage4tfullcycletime = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 best2TFullCycleTime = 10;
 * @return {number}
 */
proto.PitCycleMetadata.prototype.getBest2tfullcycletime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleMetadata} returns this
 */
proto.PitCycleMetadata.prototype.setBest2tfullcycletime = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 average2TFullCycleTime = 11;
 * @return {number}
 */
proto.PitCycleMetadata.prototype.getAverage2tfullcycletime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleMetadata} returns this
 */
proto.PitCycleMetadata.prototype.setAverage2tfullcycletime = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 bestFuelOnlyFullCycleTime = 12;
 * @return {number}
 */
proto.PitCycleMetadata.prototype.getBestfuelonlyfullcycletime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleMetadata} returns this
 */
proto.PitCycleMetadata.prototype.setBestfuelonlyfullcycletime = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 averageFuelOnlyFullCycleTime = 13;
 * @return {number}
 */
proto.PitCycleMetadata.prototype.getAveragefuelonlyfullcycletime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitCycleMetadata} returns this
 */
proto.PitCycleMetadata.prototype.setAveragefuelonlyfullcycletime = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GapMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.GapMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GapMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GapMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gap: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GapMetadata}
 */
proto.GapMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GapMetadata;
  return proto.GapMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GapMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GapMetadata}
 */
proto.GapMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GapMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GapMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GapMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GapMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGap();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string vehicle_number = 1;
 * @return {string}
 */
proto.GapMetadata.prototype.getVehicleNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GapMetadata} returns this
 */
proto.GapMetadata.prototype.setVehicleNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double gap = 2;
 * @return {number}
 */
proto.GapMetadata.prototype.getGap = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GapMetadata} returns this
 */
proto.GapMetadata.prototype.setGap = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Lap.repeatedFields_ = [16,28];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Lap.prototype.toObject = function(opt_includeInstance) {
  return proto.Lap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Lap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Lap.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: (f = msg.getSession()) && proto.SessionMetadata.toObject(includeInstance, f),
    drivername: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vehiclenumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    elapsedtime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    lapnumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    position: jspb.Message.getFieldWithDefault(msg, 6, 0),
    flag: jspb.Message.getFieldWithDefault(msg, 7, 0),
    laptime: jspb.Message.getFieldWithDefault(msg, 8, 0),
    messagetype: jspb.Message.getFieldWithDefault(msg, 9, ""),
    bestlaptime: jspb.Message.getFieldWithDefault(msg, 10, 0),
    gaptoleader: jspb.Message.getFieldWithDefault(msg, 11, 0),
    leadlap: jspb.Message.getFieldWithDefault(msg, 12, 0),
    lapstoleader: jspb.Message.getFieldWithDefault(msg, 13, 0),
    lapsleadcount: jspb.Message.getFieldWithDefault(msg, 14, 0),
    startfinish: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    previouslapsList: jspb.Message.toObjectList(msg.getPreviouslapsList(),
    proto.LapMetadata.toObject, includeInstance),
    status: jspb.Message.getFieldWithDefault(msg, 17, ""),
    pitlap: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    loopname: jspb.Message.getFieldWithDefault(msg, 19, ""),
    pitcyclemetadata: (f = msg.getPitcyclemetadata()) && proto.PitCycleMetadata.toObject(includeInstance, f),
    timeofday: jspb.Message.getFieldWithDefault(msg, 21, 0),
    pitpositionmetadata: (f = msg.getPitpositionmetadata()) && proto.PitPositionMetadata.toObject(includeInstance, f),
    avglaptime: jspb.Message.getFieldWithDefault(msg, 23, 0),
    fastrank: jspb.Message.getFieldWithDefault(msg, 24, 0),
    avgrank: jspb.Message.getFieldWithDefault(msg, 25, 0),
    isLuckyDog: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    runName: jspb.Message.getFieldWithDefault(msg, 27, ""),
    gapmetadataList: jspb.Message.toObjectList(msg.getGapmetadataList(),
    proto.GapMetadata.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Lap}
 */
proto.Lap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Lap;
  return proto.Lap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Lap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Lap}
 */
proto.Lap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SessionMetadata;
      reader.readMessage(value,proto.SessionMetadata.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrivername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehiclenumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setElapsedtime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapnumber(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosition(value);
      break;
    case 7:
      var value = /** @type {!proto.FlagType} */ (reader.readEnum());
      msg.setFlag(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLaptime(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessagetype(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBestlaptime(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGaptoleader(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLeadlap(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapstoleader(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapsleadcount(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStartfinish(value);
      break;
    case 16:
      var value = new proto.LapMetadata;
      reader.readMessage(value,proto.LapMetadata.deserializeBinaryFromReader);
      msg.addPreviouslaps(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPitlap(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoopname(value);
      break;
    case 20:
      var value = new proto.PitCycleMetadata;
      reader.readMessage(value,proto.PitCycleMetadata.deserializeBinaryFromReader);
      msg.setPitcyclemetadata(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeofday(value);
      break;
    case 22:
      var value = new proto.PitPositionMetadata;
      reader.readMessage(value,proto.PitPositionMetadata.deserializeBinaryFromReader);
      msg.setPitpositionmetadata(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAvglaptime(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFastrank(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgrank(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLuckyDog(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunName(value);
      break;
    case 28:
      var value = new proto.GapMetadata;
      reader.readMessage(value,proto.GapMetadata.deserializeBinaryFromReader);
      msg.addGapmetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Lap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Lap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Lap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Lap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.SessionMetadata.serializeBinaryToWriter
    );
  }
  f = message.getDrivername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVehiclenumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getElapsedtime();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLapnumber();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getFlag();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getLaptime();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getMessagetype();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBestlaptime();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getGaptoleader();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getLeadlap();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getLapstoleader();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getLapsleadcount();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getStartfinish();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getPreviouslapsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.LapMetadata.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPitlap();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getLoopname();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getPitcyclemetadata();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.PitCycleMetadata.serializeBinaryToWriter
    );
  }
  f = message.getTimeofday();
  if (f !== 0) {
    writer.writeInt64(
      21,
      f
    );
  }
  f = message.getPitpositionmetadata();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.PitPositionMetadata.serializeBinaryToWriter
    );
  }
  f = message.getAvglaptime();
  if (f !== 0) {
    writer.writeInt64(
      23,
      f
    );
  }
  f = message.getFastrank();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getAvgrank();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getIsLuckyDog();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getRunName();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getGapmetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      28,
      f,
      proto.GapMetadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionMetadata session = 1;
 * @return {?proto.SessionMetadata}
 */
proto.Lap.prototype.getSession = function() {
  return /** @type{?proto.SessionMetadata} */ (
    jspb.Message.getWrapperField(this, proto.SessionMetadata, 1));
};


/**
 * @param {?proto.SessionMetadata|undefined} value
 * @return {!proto.Lap} returns this
*/
proto.Lap.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Lap.prototype.hasSession = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string driverName = 2;
 * @return {string}
 */
proto.Lap.prototype.getDrivername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setDrivername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vehicleNumber = 3;
 * @return {string}
 */
proto.Lap.prototype.getVehiclenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setVehiclenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 elapsedTime = 4;
 * @return {number}
 */
proto.Lap.prototype.getElapsedtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setElapsedtime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 lapNumber = 5;
 * @return {number}
 */
proto.Lap.prototype.getLapnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setLapnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 position = 6;
 * @return {number}
 */
proto.Lap.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional FlagType flag = 7;
 * @return {!proto.FlagType}
 */
proto.Lap.prototype.getFlag = function() {
  return /** @type {!proto.FlagType} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.FlagType} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setFlag = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional int32 lapTime = 8;
 * @return {number}
 */
proto.Lap.prototype.getLaptime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setLaptime = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string messageType = 9;
 * @return {string}
 */
proto.Lap.prototype.getMessagetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setMessagetype = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 bestLapTime = 10;
 * @return {number}
 */
proto.Lap.prototype.getBestlaptime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setBestlaptime = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 gapToLeader = 11;
 * @return {number}
 */
proto.Lap.prototype.getGaptoleader = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setGaptoleader = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 leadLap = 12;
 * @return {number}
 */
proto.Lap.prototype.getLeadlap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setLeadlap = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 lapsToLeader = 13;
 * @return {number}
 */
proto.Lap.prototype.getLapstoleader = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setLapstoleader = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 lapsLeadCount = 14;
 * @return {number}
 */
proto.Lap.prototype.getLapsleadcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setLapsleadcount = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional bool startFinish = 15;
 * @return {boolean}
 */
proto.Lap.prototype.getStartfinish = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setStartfinish = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * repeated LapMetadata previousLaps = 16;
 * @return {!Array<!proto.LapMetadata>}
 */
proto.Lap.prototype.getPreviouslapsList = function() {
  return /** @type{!Array<!proto.LapMetadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.LapMetadata, 16));
};


/**
 * @param {!Array<!proto.LapMetadata>} value
 * @return {!proto.Lap} returns this
*/
proto.Lap.prototype.setPreviouslapsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.LapMetadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.LapMetadata}
 */
proto.Lap.prototype.addPreviouslaps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.LapMetadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.clearPreviouslapsList = function() {
  return this.setPreviouslapsList([]);
};


/**
 * optional string status = 17;
 * @return {string}
 */
proto.Lap.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool pitLap = 18;
 * @return {boolean}
 */
proto.Lap.prototype.getPitlap = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setPitlap = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional string loopName = 19;
 * @return {string}
 */
proto.Lap.prototype.getLoopname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setLoopname = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional PitCycleMetadata pitCycleMetadata = 20;
 * @return {?proto.PitCycleMetadata}
 */
proto.Lap.prototype.getPitcyclemetadata = function() {
  return /** @type{?proto.PitCycleMetadata} */ (
    jspb.Message.getWrapperField(this, proto.PitCycleMetadata, 20));
};


/**
 * @param {?proto.PitCycleMetadata|undefined} value
 * @return {!proto.Lap} returns this
*/
proto.Lap.prototype.setPitcyclemetadata = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.clearPitcyclemetadata = function() {
  return this.setPitcyclemetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Lap.prototype.hasPitcyclemetadata = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional int64 timeOfDay = 21;
 * @return {number}
 */
proto.Lap.prototype.getTimeofday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setTimeofday = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional PitPositionMetadata pitPositionMetadata = 22;
 * @return {?proto.PitPositionMetadata}
 */
proto.Lap.prototype.getPitpositionmetadata = function() {
  return /** @type{?proto.PitPositionMetadata} */ (
    jspb.Message.getWrapperField(this, proto.PitPositionMetadata, 22));
};


/**
 * @param {?proto.PitPositionMetadata|undefined} value
 * @return {!proto.Lap} returns this
*/
proto.Lap.prototype.setPitpositionmetadata = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.clearPitpositionmetadata = function() {
  return this.setPitpositionmetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Lap.prototype.hasPitpositionmetadata = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional int64 avgLapTime = 23;
 * @return {number}
 */
proto.Lap.prototype.getAvglaptime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setAvglaptime = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int32 fastRank = 24;
 * @return {number}
 */
proto.Lap.prototype.getFastrank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setFastrank = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 avgRank = 25;
 * @return {number}
 */
proto.Lap.prototype.getAvgrank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setAvgrank = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional bool is_lucky_dog = 26;
 * @return {boolean}
 */
proto.Lap.prototype.getIsLuckyDog = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setIsLuckyDog = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional string run_name = 27;
 * @return {string}
 */
proto.Lap.prototype.getRunName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.setRunName = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * repeated GapMetadata gapMetadata = 28;
 * @return {!Array<!proto.GapMetadata>}
 */
proto.Lap.prototype.getGapmetadataList = function() {
  return /** @type{!Array<!proto.GapMetadata>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GapMetadata, 28));
};


/**
 * @param {!Array<!proto.GapMetadata>} value
 * @return {!proto.Lap} returns this
*/
proto.Lap.prototype.setGapmetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 28, value);
};


/**
 * @param {!proto.GapMetadata=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GapMetadata}
 */
proto.Lap.prototype.addGapmetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 28, opt_value, proto.GapMetadata, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Lap} returns this
 */
proto.Lap.prototype.clearGapmetadataList = function() {
  return this.setGapmetadataList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PitData.repeatedFields_ = [39,40,41,42,43,44,45,46,47,48,52,53];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PitData.prototype.toObject = function(opt_includeInstance) {
  return proto.PitData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PitData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitData.toObject = function(includeInstance, msg) {
  var f, obj = {
    session: (f = msg.getSession()) && proto.SessionMetadata.toObject(includeInstance, f),
    vehiclenumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lapnumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    flagin: jspb.Message.getFieldWithDefault(msg, 4, ""),
    flagout: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lefttime: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    righttime: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    jacktime: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    crewtime: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    drivertime: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    driverenter: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    drivergoes: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    carstoptojack: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    jacktocargoes: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    totaltime: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    tireschanged: jspb.Message.getFieldWithDefault(msg, 16, 0),
    pitintime: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    pitouttime: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    missedevents: jspb.Message.getFieldWithDefault(msg, 19, 0),
    sequence: jspb.Message.getFieldWithDefault(msg, 20, ""),
    bestdrivertime: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    avgdrivertime: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    besttotal2t: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    besttotal4t: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    beststop2t: jspb.Message.getFloatingPointFieldWithDefault(msg, 25, 0.0),
    beststop4t: jspb.Message.getFloatingPointFieldWithDefault(msg, 26, 0.0),
    avgstop2t: jspb.Message.getFloatingPointFieldWithDefault(msg, 27, 0.0),
    avgstop4t: jspb.Message.getFloatingPointFieldWithDefault(msg, 28, 0.0),
    bestcrew2t: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0),
    bestcrew4t: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
    avgcrew2t: jspb.Message.getFloatingPointFieldWithDefault(msg, 31, 0.0),
    avgcrew4t: jspb.Message.getFloatingPointFieldWithDefault(msg, 32, 0.0),
    bestjacklefttime: jspb.Message.getFloatingPointFieldWithDefault(msg, 33, 0.0),
    bestjackrighttime: jspb.Message.getFloatingPointFieldWithDefault(msg, 34, 0.0),
    avgjacklefttime: jspb.Message.getFloatingPointFieldWithDefault(msg, 35, 0.0),
    avgjackrighttime: jspb.Message.getFloatingPointFieldWithDefault(msg, 36, 0.0),
    bestjackmantime: jspb.Message.getFloatingPointFieldWithDefault(msg, 37, 0.0),
    avgjackmantime: jspb.Message.getFloatingPointFieldWithDefault(msg, 38, 0.0),
    drivertimesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 39)) == null ? undefined : f,
    stoptimes2tList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 40)) == null ? undefined : f,
    stoptimes4tList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 41)) == null ? undefined : f,
    totaltimes2tList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 42)) == null ? undefined : f,
    totaltimes4tList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 43)) == null ? undefined : f,
    crewtimes2tList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 44)) == null ? undefined : f,
    crewtimes4tList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 45)) == null ? undefined : f,
    jacklefttimesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 46)) == null ? undefined : f,
    jackrighttimesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 47)) == null ? undefined : f,
    jackmantimesList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 48)) == null ? undefined : f,
    leaderlap: jspb.Message.getFieldWithDefault(msg, 49, 0),
    drivername: jspb.Message.getFieldWithDefault(msg, 50, ""),
    pitpositionmetadata: (f = msg.getPitpositionmetadata()) && proto.PitPositionMetadata.toObject(includeInstance, f),
    greeninList: jspb.Message.toObjectList(msg.getGreeninList(),
    proto.GreenCalc.toObject, includeInstance),
    greenoutList: jspb.Message.toObjectList(msg.getGreenoutList(),
    proto.GreenCalc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PitData}
 */
proto.PitData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PitData;
  return proto.PitData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PitData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PitData}
 */
proto.PitData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SessionMetadata;
      reader.readMessage(value,proto.SessionMetadata.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehiclenumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapnumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlagin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlagout(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLefttime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRighttime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setJacktime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCrewtime(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDrivertime(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDriverenter(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDrivergoes(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCarstoptojack(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setJacktocargoes(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotaltime(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTireschanged(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPitintime(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPitouttime(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMissedevents(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setSequence(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestdrivertime(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgdrivertime(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBesttotal2t(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBesttotal4t(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBeststop2t(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBeststop4t(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgstop2t(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgstop4t(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestcrew2t(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestcrew4t(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgcrew2t(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgcrew4t(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestjacklefttime(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestjackrighttime(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgjacklefttime(value);
      break;
    case 36:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgjackrighttime(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBestjackmantime(value);
      break;
    case 38:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgjackmantime(value);
      break;
    case 39:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDrivertimes(values[i]);
      }
      break;
    case 40:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStoptimes2t(values[i]);
      }
      break;
    case 41:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStoptimes4t(values[i]);
      }
      break;
    case 42:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTotaltimes2t(values[i]);
      }
      break;
    case 43:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTotaltimes4t(values[i]);
      }
      break;
    case 44:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCrewtimes2t(values[i]);
      }
      break;
    case 45:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCrewtimes4t(values[i]);
      }
      break;
    case 46:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addJacklefttimes(values[i]);
      }
      break;
    case 47:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addJackrighttimes(values[i]);
      }
      break;
    case 48:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addJackmantimes(values[i]);
      }
      break;
    case 49:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLeaderlap(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setDrivername(value);
      break;
    case 51:
      var value = new proto.PitPositionMetadata;
      reader.readMessage(value,proto.PitPositionMetadata.deserializeBinaryFromReader);
      msg.setPitpositionmetadata(value);
      break;
    case 52:
      var value = new proto.GreenCalc;
      reader.readMessage(value,proto.GreenCalc.deserializeBinaryFromReader);
      msg.addGreenin(value);
      break;
    case 53:
      var value = new proto.GreenCalc;
      reader.readMessage(value,proto.GreenCalc.deserializeBinaryFromReader);
      msg.addGreenout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PitData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PitData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PitData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.SessionMetadata.serializeBinaryToWriter
    );
  }
  f = message.getVehiclenumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLapnumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFlagin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFlagout();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLefttime();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getRighttime();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getJacktime();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getCrewtime();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getDrivertime();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getDriverenter();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getDrivergoes();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getCarstoptojack();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getJacktocargoes();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getTotaltime();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getTireschanged();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getPitintime();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getPitouttime();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getMissedevents();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getSequence();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getBestdrivertime();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
  f = message.getAvgdrivertime();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getBesttotal2t();
  if (f !== 0.0) {
    writer.writeDouble(
      23,
      f
    );
  }
  f = message.getBesttotal4t();
  if (f !== 0.0) {
    writer.writeDouble(
      24,
      f
    );
  }
  f = message.getBeststop2t();
  if (f !== 0.0) {
    writer.writeDouble(
      25,
      f
    );
  }
  f = message.getBeststop4t();
  if (f !== 0.0) {
    writer.writeDouble(
      26,
      f
    );
  }
  f = message.getAvgstop2t();
  if (f !== 0.0) {
    writer.writeDouble(
      27,
      f
    );
  }
  f = message.getAvgstop4t();
  if (f !== 0.0) {
    writer.writeDouble(
      28,
      f
    );
  }
  f = message.getBestcrew2t();
  if (f !== 0.0) {
    writer.writeDouble(
      29,
      f
    );
  }
  f = message.getBestcrew4t();
  if (f !== 0.0) {
    writer.writeDouble(
      30,
      f
    );
  }
  f = message.getAvgcrew2t();
  if (f !== 0.0) {
    writer.writeDouble(
      31,
      f
    );
  }
  f = message.getAvgcrew4t();
  if (f !== 0.0) {
    writer.writeDouble(
      32,
      f
    );
  }
  f = message.getBestjacklefttime();
  if (f !== 0.0) {
    writer.writeDouble(
      33,
      f
    );
  }
  f = message.getBestjackrighttime();
  if (f !== 0.0) {
    writer.writeDouble(
      34,
      f
    );
  }
  f = message.getAvgjacklefttime();
  if (f !== 0.0) {
    writer.writeDouble(
      35,
      f
    );
  }
  f = message.getAvgjackrighttime();
  if (f !== 0.0) {
    writer.writeDouble(
      36,
      f
    );
  }
  f = message.getBestjackmantime();
  if (f !== 0.0) {
    writer.writeDouble(
      37,
      f
    );
  }
  f = message.getAvgjackmantime();
  if (f !== 0.0) {
    writer.writeDouble(
      38,
      f
    );
  }
  f = message.getDrivertimesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      39,
      f
    );
  }
  f = message.getStoptimes2tList();
  if (f.length > 0) {
    writer.writePackedDouble(
      40,
      f
    );
  }
  f = message.getStoptimes4tList();
  if (f.length > 0) {
    writer.writePackedDouble(
      41,
      f
    );
  }
  f = message.getTotaltimes2tList();
  if (f.length > 0) {
    writer.writePackedDouble(
      42,
      f
    );
  }
  f = message.getTotaltimes4tList();
  if (f.length > 0) {
    writer.writePackedDouble(
      43,
      f
    );
  }
  f = message.getCrewtimes2tList();
  if (f.length > 0) {
    writer.writePackedDouble(
      44,
      f
    );
  }
  f = message.getCrewtimes4tList();
  if (f.length > 0) {
    writer.writePackedDouble(
      45,
      f
    );
  }
  f = message.getJacklefttimesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      46,
      f
    );
  }
  f = message.getJackrighttimesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      47,
      f
    );
  }
  f = message.getJackmantimesList();
  if (f.length > 0) {
    writer.writePackedDouble(
      48,
      f
    );
  }
  f = message.getLeaderlap();
  if (f !== 0) {
    writer.writeInt32(
      49,
      f
    );
  }
  f = message.getDrivername();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getPitpositionmetadata();
  if (f != null) {
    writer.writeMessage(
      51,
      f,
      proto.PitPositionMetadata.serializeBinaryToWriter
    );
  }
  f = message.getGreeninList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      52,
      f,
      proto.GreenCalc.serializeBinaryToWriter
    );
  }
  f = message.getGreenoutList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      53,
      f,
      proto.GreenCalc.serializeBinaryToWriter
    );
  }
};


/**
 * optional SessionMetadata session = 1;
 * @return {?proto.SessionMetadata}
 */
proto.PitData.prototype.getSession = function() {
  return /** @type{?proto.SessionMetadata} */ (
    jspb.Message.getWrapperField(this, proto.SessionMetadata, 1));
};


/**
 * @param {?proto.SessionMetadata|undefined} value
 * @return {!proto.PitData} returns this
*/
proto.PitData.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PitData.prototype.hasSession = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string vehicleNumber = 2;
 * @return {string}
 */
proto.PitData.prototype.getVehiclenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setVehiclenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 lapNumber = 3;
 * @return {number}
 */
proto.PitData.prototype.getLapnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setLapnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string flagIn = 4;
 * @return {string}
 */
proto.PitData.prototype.getFlagin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setFlagin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string flagOut = 5;
 * @return {string}
 */
proto.PitData.prototype.getFlagout = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setFlagout = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double leftTime = 6;
 * @return {number}
 */
proto.PitData.prototype.getLefttime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setLefttime = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double rightTime = 7;
 * @return {number}
 */
proto.PitData.prototype.getRighttime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setRighttime = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double jackTime = 8;
 * @return {number}
 */
proto.PitData.prototype.getJacktime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setJacktime = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double crewTime = 9;
 * @return {number}
 */
proto.PitData.prototype.getCrewtime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setCrewtime = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double driverTime = 10;
 * @return {number}
 */
proto.PitData.prototype.getDrivertime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setDrivertime = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double driverEnter = 11;
 * @return {number}
 */
proto.PitData.prototype.getDriverenter = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setDriverenter = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double driverGoes = 12;
 * @return {number}
 */
proto.PitData.prototype.getDrivergoes = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setDrivergoes = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double carStopToJack = 13;
 * @return {number}
 */
proto.PitData.prototype.getCarstoptojack = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setCarstoptojack = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double jackToCarGoes = 14;
 * @return {number}
 */
proto.PitData.prototype.getJacktocargoes = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setJacktocargoes = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double totalTime = 15;
 * @return {number}
 */
proto.PitData.prototype.getTotaltime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setTotaltime = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional int32 tiresChanged = 16;
 * @return {number}
 */
proto.PitData.prototype.getTireschanged = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setTireschanged = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional double pitInTime = 17;
 * @return {number}
 */
proto.PitData.prototype.getPitintime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setPitintime = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional double pitOutTime = 18;
 * @return {number}
 */
proto.PitData.prototype.getPitouttime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setPitouttime = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional int32 missedEvents = 19;
 * @return {number}
 */
proto.PitData.prototype.getMissedevents = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setMissedevents = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional string sequence = 20;
 * @return {string}
 */
proto.PitData.prototype.getSequence = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setSequence = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional double bestDriverTime = 21;
 * @return {number}
 */
proto.PitData.prototype.getBestdrivertime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setBestdrivertime = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional double AvgDriverTime = 22;
 * @return {number}
 */
proto.PitData.prototype.getAvgdrivertime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setAvgdrivertime = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional double bestTotal2T = 23;
 * @return {number}
 */
proto.PitData.prototype.getBesttotal2t = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setBesttotal2t = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional double bestTotal4T = 24;
 * @return {number}
 */
proto.PitData.prototype.getBesttotal4t = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setBesttotal4t = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional double bestStop2T = 25;
 * @return {number}
 */
proto.PitData.prototype.getBeststop2t = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 25, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setBeststop2t = function(value) {
  return jspb.Message.setProto3FloatField(this, 25, value);
};


/**
 * optional double bestStop4T = 26;
 * @return {number}
 */
proto.PitData.prototype.getBeststop4t = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 26, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setBeststop4t = function(value) {
  return jspb.Message.setProto3FloatField(this, 26, value);
};


/**
 * optional double avgStop2T = 27;
 * @return {number}
 */
proto.PitData.prototype.getAvgstop2t = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 27, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setAvgstop2t = function(value) {
  return jspb.Message.setProto3FloatField(this, 27, value);
};


/**
 * optional double avgStop4T = 28;
 * @return {number}
 */
proto.PitData.prototype.getAvgstop4t = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 28, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setAvgstop4t = function(value) {
  return jspb.Message.setProto3FloatField(this, 28, value);
};


/**
 * optional double bestCrew2T = 29;
 * @return {number}
 */
proto.PitData.prototype.getBestcrew2t = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setBestcrew2t = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};


/**
 * optional double bestCrew4T = 30;
 * @return {number}
 */
proto.PitData.prototype.getBestcrew4t = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setBestcrew4t = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional double avgCrew2T = 31;
 * @return {number}
 */
proto.PitData.prototype.getAvgcrew2t = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 31, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setAvgcrew2t = function(value) {
  return jspb.Message.setProto3FloatField(this, 31, value);
};


/**
 * optional double avgCrew4T = 32;
 * @return {number}
 */
proto.PitData.prototype.getAvgcrew4t = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 32, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setAvgcrew4t = function(value) {
  return jspb.Message.setProto3FloatField(this, 32, value);
};


/**
 * optional double bestJackLeftTime = 33;
 * @return {number}
 */
proto.PitData.prototype.getBestjacklefttime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 33, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setBestjacklefttime = function(value) {
  return jspb.Message.setProto3FloatField(this, 33, value);
};


/**
 * optional double bestJackRightTime = 34;
 * @return {number}
 */
proto.PitData.prototype.getBestjackrighttime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 34, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setBestjackrighttime = function(value) {
  return jspb.Message.setProto3FloatField(this, 34, value);
};


/**
 * optional double avgJackLeftTime = 35;
 * @return {number}
 */
proto.PitData.prototype.getAvgjacklefttime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 35, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setAvgjacklefttime = function(value) {
  return jspb.Message.setProto3FloatField(this, 35, value);
};


/**
 * optional double avgJackRightTime = 36;
 * @return {number}
 */
proto.PitData.prototype.getAvgjackrighttime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 36, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setAvgjackrighttime = function(value) {
  return jspb.Message.setProto3FloatField(this, 36, value);
};


/**
 * optional double bestJackManTime = 37;
 * @return {number}
 */
proto.PitData.prototype.getBestjackmantime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 37, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setBestjackmantime = function(value) {
  return jspb.Message.setProto3FloatField(this, 37, value);
};


/**
 * optional double avgJackManTime = 38;
 * @return {number}
 */
proto.PitData.prototype.getAvgjackmantime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 38, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setAvgjackmantime = function(value) {
  return jspb.Message.setProto3FloatField(this, 38, value);
};


/**
 * repeated double driverTimes = 39;
 * @return {!Array<number>}
 */
proto.PitData.prototype.getDrivertimesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 39));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setDrivertimesList = function(value) {
  return jspb.Message.setField(this, 39, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.addDrivertimes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 39, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.clearDrivertimesList = function() {
  return this.setDrivertimesList([]);
};


/**
 * repeated double stopTimes2T = 40;
 * @return {!Array<number>}
 */
proto.PitData.prototype.getStoptimes2tList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 40));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setStoptimes2tList = function(value) {
  return jspb.Message.setField(this, 40, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.addStoptimes2t = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 40, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.clearStoptimes2tList = function() {
  return this.setStoptimes2tList([]);
};


/**
 * repeated double stopTimes4T = 41;
 * @return {!Array<number>}
 */
proto.PitData.prototype.getStoptimes4tList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 41));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setStoptimes4tList = function(value) {
  return jspb.Message.setField(this, 41, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.addStoptimes4t = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 41, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.clearStoptimes4tList = function() {
  return this.setStoptimes4tList([]);
};


/**
 * repeated double totalTimes2T = 42;
 * @return {!Array<number>}
 */
proto.PitData.prototype.getTotaltimes2tList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 42));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setTotaltimes2tList = function(value) {
  return jspb.Message.setField(this, 42, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.addTotaltimes2t = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 42, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.clearTotaltimes2tList = function() {
  return this.setTotaltimes2tList([]);
};


/**
 * repeated double totalTimes4T = 43;
 * @return {!Array<number>}
 */
proto.PitData.prototype.getTotaltimes4tList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 43));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setTotaltimes4tList = function(value) {
  return jspb.Message.setField(this, 43, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.addTotaltimes4t = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 43, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.clearTotaltimes4tList = function() {
  return this.setTotaltimes4tList([]);
};


/**
 * repeated double crewTimes2T = 44;
 * @return {!Array<number>}
 */
proto.PitData.prototype.getCrewtimes2tList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 44));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setCrewtimes2tList = function(value) {
  return jspb.Message.setField(this, 44, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.addCrewtimes2t = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 44, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.clearCrewtimes2tList = function() {
  return this.setCrewtimes2tList([]);
};


/**
 * repeated double crewTimes4T = 45;
 * @return {!Array<number>}
 */
proto.PitData.prototype.getCrewtimes4tList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 45));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setCrewtimes4tList = function(value) {
  return jspb.Message.setField(this, 45, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.addCrewtimes4t = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 45, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.clearCrewtimes4tList = function() {
  return this.setCrewtimes4tList([]);
};


/**
 * repeated double jackLeftTimes = 46;
 * @return {!Array<number>}
 */
proto.PitData.prototype.getJacklefttimesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 46));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setJacklefttimesList = function(value) {
  return jspb.Message.setField(this, 46, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.addJacklefttimes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 46, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.clearJacklefttimesList = function() {
  return this.setJacklefttimesList([]);
};


/**
 * repeated double jackRightTimes = 47;
 * @return {!Array<number>}
 */
proto.PitData.prototype.getJackrighttimesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 47));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setJackrighttimesList = function(value) {
  return jspb.Message.setField(this, 47, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.addJackrighttimes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 47, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.clearJackrighttimesList = function() {
  return this.setJackrighttimesList([]);
};


/**
 * repeated double jackManTimes = 48;
 * @return {!Array<number>}
 */
proto.PitData.prototype.getJackmantimesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 48));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setJackmantimesList = function(value) {
  return jspb.Message.setField(this, 48, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.addJackmantimes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 48, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.clearJackmantimesList = function() {
  return this.setJackmantimesList([]);
};


/**
 * optional int32 leaderLap = 49;
 * @return {number}
 */
proto.PitData.prototype.getLeaderlap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 49, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setLeaderlap = function(value) {
  return jspb.Message.setProto3IntField(this, 49, value);
};


/**
 * optional string driverName = 50;
 * @return {string}
 */
proto.PitData.prototype.getDrivername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.setDrivername = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional PitPositionMetadata pitPositionMetadata = 51;
 * @return {?proto.PitPositionMetadata}
 */
proto.PitData.prototype.getPitpositionmetadata = function() {
  return /** @type{?proto.PitPositionMetadata} */ (
    jspb.Message.getWrapperField(this, proto.PitPositionMetadata, 51));
};


/**
 * @param {?proto.PitPositionMetadata|undefined} value
 * @return {!proto.PitData} returns this
*/
proto.PitData.prototype.setPitpositionmetadata = function(value) {
  return jspb.Message.setWrapperField(this, 51, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.clearPitpositionmetadata = function() {
  return this.setPitpositionmetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PitData.prototype.hasPitpositionmetadata = function() {
  return jspb.Message.getField(this, 51) != null;
};


/**
 * repeated GreenCalc greenIn = 52;
 * @return {!Array<!proto.GreenCalc>}
 */
proto.PitData.prototype.getGreeninList = function() {
  return /** @type{!Array<!proto.GreenCalc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GreenCalc, 52));
};


/**
 * @param {!Array<!proto.GreenCalc>} value
 * @return {!proto.PitData} returns this
*/
proto.PitData.prototype.setGreeninList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 52, value);
};


/**
 * @param {!proto.GreenCalc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GreenCalc}
 */
proto.PitData.prototype.addGreenin = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 52, opt_value, proto.GreenCalc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.clearGreeninList = function() {
  return this.setGreeninList([]);
};


/**
 * repeated GreenCalc greenOut = 53;
 * @return {!Array<!proto.GreenCalc>}
 */
proto.PitData.prototype.getGreenoutList = function() {
  return /** @type{!Array<!proto.GreenCalc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GreenCalc, 53));
};


/**
 * @param {!Array<!proto.GreenCalc>} value
 * @return {!proto.PitData} returns this
*/
proto.PitData.prototype.setGreenoutList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 53, value);
};


/**
 * @param {!proto.GreenCalc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GreenCalc}
 */
proto.PitData.prototype.addGreenout = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 53, opt_value, proto.GreenCalc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PitData} returns this
 */
proto.PitData.prototype.clearGreenoutList = function() {
  return this.setGreenoutList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.TrackTemp.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.TrackTemp.MessagewrapperCase = {
  MESSAGEWRAPPER_NOT_SET: 0,
  TRACK_TEMP_CSV: 2,
  TRACK_TEMP_PNG: 3
};

/**
 * @return {proto.TrackTemp.MessagewrapperCase}
 */
proto.TrackTemp.prototype.getMessagewrapperCase = function() {
  return /** @type {proto.TrackTemp.MessagewrapperCase} */(jspb.Message.computeOneofCase(this, proto.TrackTemp.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TrackTemp.prototype.toObject = function(opt_includeInstance) {
  return proto.TrackTemp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TrackTemp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemp.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackMetaData: (f = msg.getTrackMetaData()) && proto.TrackMetaData.toObject(includeInstance, f),
    trackTempCsv: (f = msg.getTrackTempCsv()) && proto.TrackTempCsv.toObject(includeInstance, f),
    trackTempPng: (f = msg.getTrackTempPng()) && proto.TrackTempPng.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TrackTemp}
 */
proto.TrackTemp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TrackTemp;
  return proto.TrackTemp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TrackTemp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TrackTemp}
 */
proto.TrackTemp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TrackMetaData;
      reader.readMessage(value,proto.TrackMetaData.deserializeBinaryFromReader);
      msg.setTrackMetaData(value);
      break;
    case 2:
      var value = new proto.TrackTempCsv;
      reader.readMessage(value,proto.TrackTempCsv.deserializeBinaryFromReader);
      msg.setTrackTempCsv(value);
      break;
    case 3:
      var value = new proto.TrackTempPng;
      reader.readMessage(value,proto.TrackTempPng.deserializeBinaryFromReader);
      msg.setTrackTempPng(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TrackTemp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TrackTemp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TrackTemp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTemp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackMetaData();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.TrackMetaData.serializeBinaryToWriter
    );
  }
  f = message.getTrackTempCsv();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.TrackTempCsv.serializeBinaryToWriter
    );
  }
  f = message.getTrackTempPng();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.TrackTempPng.serializeBinaryToWriter
    );
  }
};


/**
 * optional TrackMetaData track_meta_data = 1;
 * @return {?proto.TrackMetaData}
 */
proto.TrackTemp.prototype.getTrackMetaData = function() {
  return /** @type{?proto.TrackMetaData} */ (
    jspb.Message.getWrapperField(this, proto.TrackMetaData, 1));
};


/**
 * @param {?proto.TrackMetaData|undefined} value
 * @return {!proto.TrackTemp} returns this
*/
proto.TrackTemp.prototype.setTrackMetaData = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TrackTemp} returns this
 */
proto.TrackTemp.prototype.clearTrackMetaData = function() {
  return this.setTrackMetaData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TrackTemp.prototype.hasTrackMetaData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TrackTempCsv track_temp_csv = 2;
 * @return {?proto.TrackTempCsv}
 */
proto.TrackTemp.prototype.getTrackTempCsv = function() {
  return /** @type{?proto.TrackTempCsv} */ (
    jspb.Message.getWrapperField(this, proto.TrackTempCsv, 2));
};


/**
 * @param {?proto.TrackTempCsv|undefined} value
 * @return {!proto.TrackTemp} returns this
*/
proto.TrackTemp.prototype.setTrackTempCsv = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.TrackTemp.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TrackTemp} returns this
 */
proto.TrackTemp.prototype.clearTrackTempCsv = function() {
  return this.setTrackTempCsv(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TrackTemp.prototype.hasTrackTempCsv = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TrackTempPng track_temp_png = 3;
 * @return {?proto.TrackTempPng}
 */
proto.TrackTemp.prototype.getTrackTempPng = function() {
  return /** @type{?proto.TrackTempPng} */ (
    jspb.Message.getWrapperField(this, proto.TrackTempPng, 3));
};


/**
 * @param {?proto.TrackTempPng|undefined} value
 * @return {!proto.TrackTemp} returns this
*/
proto.TrackTemp.prototype.setTrackTempPng = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.TrackTemp.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TrackTemp} returns this
 */
proto.TrackTemp.prototype.clearTrackTempPng = function() {
  return this.setTrackTempPng(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TrackTemp.prototype.hasTrackTempPng = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TrackMetaData.prototype.toObject = function(opt_includeInstance) {
  return proto.TrackMetaData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TrackMetaData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackMetaData.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentFolder: jspb.Message.getFieldWithDefault(msg, 1, ""),
    year: jspb.Message.getFieldWithDefault(msg, 2, ""),
    raceId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    camera: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    modifiedTimestamp: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TrackMetaData}
 */
proto.TrackMetaData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TrackMetaData;
  return proto.TrackMetaData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TrackMetaData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TrackMetaData}
 */
proto.TrackMetaData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentFolder(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRaceId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCamera(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TrackMetaData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TrackMetaData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TrackMetaData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackMetaData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentFolder();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRaceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCamera();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getModifiedTimestamp();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string parent_folder = 1;
 * @return {string}
 */
proto.TrackMetaData.prototype.getParentFolder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackMetaData} returns this
 */
proto.TrackMetaData.prototype.setParentFolder = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string year = 2;
 * @return {string}
 */
proto.TrackMetaData.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackMetaData} returns this
 */
proto.TrackMetaData.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string race_id = 3;
 * @return {string}
 */
proto.TrackMetaData.prototype.getRaceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackMetaData} returns this
 */
proto.TrackMetaData.prototype.setRaceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string camera = 4;
 * @return {string}
 */
proto.TrackMetaData.prototype.getCamera = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackMetaData} returns this
 */
proto.TrackMetaData.prototype.setCamera = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string file_name = 5;
 * @return {string}
 */
proto.TrackMetaData.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackMetaData} returns this
 */
proto.TrackMetaData.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string modified_timestamp = 6;
 * @return {string}
 */
proto.TrackMetaData.prototype.getModifiedTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackMetaData} returns this
 */
proto.TrackMetaData.prototype.setModifiedTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TrackTempCsv.prototype.toObject = function(opt_includeInstance) {
  return proto.TrackTempCsv.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TrackTempCsv} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTempCsv.toObject = function(includeInstance, msg) {
  var f, obj = {
    trackName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    camera: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cameraLocation: jspb.Message.getFieldWithDefault(msg, 3, ""),
    time: jspb.Message.getFieldWithDefault(msg, 4, ""),
    high: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    middle: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    low: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    reference: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    scale: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TrackTempCsv}
 */
proto.TrackTempCsv.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TrackTempCsv;
  return proto.TrackTempCsv.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TrackTempCsv} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TrackTempCsv}
 */
proto.TrackTempCsv.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrackName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCamera(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCameraLocation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHigh(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMiddle(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLow(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setReference(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setScale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TrackTempCsv.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TrackTempCsv.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TrackTempCsv} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTempCsv.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrackName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCamera();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCameraLocation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHigh();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getMiddle();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getLow();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getReference();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getScale();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string track_name = 1;
 * @return {string}
 */
proto.TrackTempCsv.prototype.getTrackName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTempCsv} returns this
 */
proto.TrackTempCsv.prototype.setTrackName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string camera = 2;
 * @return {string}
 */
proto.TrackTempCsv.prototype.getCamera = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTempCsv} returns this
 */
proto.TrackTempCsv.prototype.setCamera = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string camera_location = 3;
 * @return {string}
 */
proto.TrackTempCsv.prototype.getCameraLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTempCsv} returns this
 */
proto.TrackTempCsv.prototype.setCameraLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string time = 4;
 * @return {string}
 */
proto.TrackTempCsv.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTempCsv} returns this
 */
proto.TrackTempCsv.prototype.setTime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double high = 5;
 * @return {number}
 */
proto.TrackTempCsv.prototype.getHigh = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TrackTempCsv} returns this
 */
proto.TrackTempCsv.prototype.setHigh = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double middle = 6;
 * @return {number}
 */
proto.TrackTempCsv.prototype.getMiddle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TrackTempCsv} returns this
 */
proto.TrackTempCsv.prototype.setMiddle = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double low = 7;
 * @return {number}
 */
proto.TrackTempCsv.prototype.getLow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TrackTempCsv} returns this
 */
proto.TrackTempCsv.prototype.setLow = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double reference = 8;
 * @return {number}
 */
proto.TrackTempCsv.prototype.getReference = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.TrackTempCsv} returns this
 */
proto.TrackTempCsv.prototype.setReference = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string scale = 9;
 * @return {string}
 */
proto.TrackTempCsv.prototype.getScale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.TrackTempCsv} returns this
 */
proto.TrackTempCsv.prototype.setScale = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TrackTempPng.prototype.toObject = function(opt_includeInstance) {
  return proto.TrackTempPng.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TrackTempPng} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTempPng.toObject = function(includeInstance, msg) {
  var f, obj = {
    image: msg.getImage_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TrackTempPng}
 */
proto.TrackTempPng.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TrackTempPng;
  return proto.TrackTempPng.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TrackTempPng} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TrackTempPng}
 */
proto.TrackTempPng.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TrackTempPng.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TrackTempPng.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TrackTempPng} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TrackTempPng.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImage_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes image = 1;
 * @return {!(string|Uint8Array)}
 */
proto.TrackTempPng.prototype.getImage = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes image = 1;
 * This is a type-conversion wrapper around `getImage()`
 * @return {string}
 */
proto.TrackTempPng.prototype.getImage_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getImage()));
};


/**
 * optional bytes image = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getImage()`
 * @return {!Uint8Array}
 */
proto.TrackTempPng.prototype.getImage_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getImage()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.TrackTempPng} returns this
 */
proto.TrackTempPng.prototype.setImage = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PitPositionMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.PitPositionMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PitPositionMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitPositionMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    pitInLap: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pitInPosition: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pitOutPosition: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pitPositionDifference: jspb.Message.getFieldWithDefault(msg, 4, 0),
    avgPitInPosition: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    avgPitOutPosition: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    avgPitPositionDifference: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PitPositionMetadata}
 */
proto.PitPositionMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PitPositionMetadata;
  return proto.PitPositionMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PitPositionMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PitPositionMetadata}
 */
proto.PitPositionMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPitInLap(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPitInPosition(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPitOutPosition(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPitPositionDifference(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgPitInPosition(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgPitOutPosition(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgPitPositionDifference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PitPositionMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PitPositionMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PitPositionMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PitPositionMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPitInLap();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPitInPosition();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPitOutPosition();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPitPositionDifference();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAvgPitInPosition();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getAvgPitOutPosition();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getAvgPitPositionDifference();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional int32 pit_in_lap = 1;
 * @return {number}
 */
proto.PitPositionMetadata.prototype.getPitInLap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitPositionMetadata} returns this
 */
proto.PitPositionMetadata.prototype.setPitInLap = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 pit_in_position = 2;
 * @return {number}
 */
proto.PitPositionMetadata.prototype.getPitInPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitPositionMetadata} returns this
 */
proto.PitPositionMetadata.prototype.setPitInPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 pit_out_position = 3;
 * @return {number}
 */
proto.PitPositionMetadata.prototype.getPitOutPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitPositionMetadata} returns this
 */
proto.PitPositionMetadata.prototype.setPitOutPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 pit_position_difference = 4;
 * @return {number}
 */
proto.PitPositionMetadata.prototype.getPitPositionDifference = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.PitPositionMetadata} returns this
 */
proto.PitPositionMetadata.prototype.setPitPositionDifference = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double avg_pit_in_position = 5;
 * @return {number}
 */
proto.PitPositionMetadata.prototype.getAvgPitInPosition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitPositionMetadata} returns this
 */
proto.PitPositionMetadata.prototype.setAvgPitInPosition = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double avg_pit_out_position = 6;
 * @return {number}
 */
proto.PitPositionMetadata.prototype.getAvgPitOutPosition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitPositionMetadata} returns this
 */
proto.PitPositionMetadata.prototype.setAvgPitOutPosition = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double avg_pit_position_difference = 7;
 * @return {number}
 */
proto.PitPositionMetadata.prototype.getAvgPitPositionDifference = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.PitPositionMetadata} returns this
 */
proto.PitPositionMetadata.prototype.setAvgPitPositionDifference = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GreenCalc.prototype.toObject = function(opt_includeInstance) {
  return proto.GreenCalc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GreenCalc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GreenCalc.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lineCrossing: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GreenCalc}
 */
proto.GreenCalc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GreenCalc;
  return proto.GreenCalc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GreenCalc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GreenCalc}
 */
proto.GreenCalc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLineCrossing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GreenCalc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GreenCalc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GreenCalc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GreenCalc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLineCrossing();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 value = 1;
 * @return {number}
 */
proto.GreenCalc.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.GreenCalc} returns this
 */
proto.GreenCalc.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string line_crossing = 2;
 * @return {string}
 */
proto.GreenCalc.prototype.getLineCrossing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GreenCalc} returns this
 */
proto.GreenCalc.prototype.setLineCrossing = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GreenSingle.prototype.toObject = function(opt_includeInstance) {
  return proto.GreenSingle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GreenSingle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GreenSingle.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lapNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    greenCalc: (f = msg.getGreenCalc()) && proto.GreenCalc.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GreenSingle}
 */
proto.GreenSingle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GreenSingle;
  return proto.GreenSingle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GreenSingle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GreenSingle}
 */
proto.GreenSingle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapNumber(value);
      break;
    case 3:
      var value = new proto.GreenCalc;
      reader.readMessage(value,proto.GreenCalc.deserializeBinaryFromReader);
      msg.setGreenCalc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GreenSingle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GreenSingle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GreenSingle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GreenSingle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLapNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getGreenCalc();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.GreenCalc.serializeBinaryToWriter
    );
  }
};


/**
 * optional string vehicle_number = 1;
 * @return {string}
 */
proto.GreenSingle.prototype.getVehicleNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GreenSingle} returns this
 */
proto.GreenSingle.prototype.setVehicleNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 lap_number = 2;
 * @return {number}
 */
proto.GreenSingle.prototype.getLapNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.GreenSingle} returns this
 */
proto.GreenSingle.prototype.setLapNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional GreenCalc green_calc = 3;
 * @return {?proto.GreenCalc}
 */
proto.GreenSingle.prototype.getGreenCalc = function() {
  return /** @type{?proto.GreenCalc} */ (
    jspb.Message.getWrapperField(this, proto.GreenCalc, 3));
};


/**
 * @param {?proto.GreenCalc|undefined} value
 * @return {!proto.GreenSingle} returns this
*/
proto.GreenSingle.prototype.setGreenCalc = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GreenSingle} returns this
 */
proto.GreenSingle.prototype.clearGreenCalc = function() {
  return this.setGreenCalc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GreenSingle.prototype.hasGreenCalc = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LapNumberToTimeMap.prototype.toObject = function(opt_includeInstance) {
  return proto.LapNumberToTimeMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LapNumberToTimeMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapNumberToTimeMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    lapNumberToLapTimeMap: (f = msg.getLapNumberToLapTimeMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LapNumberToTimeMap}
 */
proto.LapNumberToTimeMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LapNumberToTimeMap;
  return proto.LapNumberToTimeMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LapNumberToTimeMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LapNumberToTimeMap}
 */
proto.LapNumberToTimeMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getLapNumberToLapTimeMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readInt32, jspb.BinaryReader.prototype.readDouble, null, 0, 0.0);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LapNumberToTimeMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LapNumberToTimeMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LapNumberToTimeMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapNumberToTimeMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLapNumberToLapTimeMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeInt32, jspb.BinaryWriter.prototype.writeDouble);
  }
};


/**
 * map<int32, double> lap_number_to_lap_time = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */
proto.LapNumberToTimeMap.prototype.getLapNumberToLapTimeMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,number>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.LapNumberToTimeMap} returns this
 */
proto.LapNumberToTimeMap.prototype.clearLapNumberToLapTimeMap = function() {
  this.getLapNumberToLapTimeMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.VehicleToLapMap.prototype.toObject = function(opt_includeInstance) {
  return proto.VehicleToLapMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.VehicleToLapMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.VehicleToLapMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleToLapTimesMap: (f = msg.getVehicleToLapTimesMap()) ? f.toObject(includeInstance, proto.LapNumberToTimeMap.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.VehicleToLapMap}
 */
proto.VehicleToLapMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.VehicleToLapMap;
  return proto.VehicleToLapMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.VehicleToLapMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.VehicleToLapMap}
 */
proto.VehicleToLapMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getVehicleToLapTimesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.LapNumberToTimeMap.deserializeBinaryFromReader, "", new proto.LapNumberToTimeMap());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.VehicleToLapMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.VehicleToLapMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.VehicleToLapMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.VehicleToLapMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleToLapTimesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.LapNumberToTimeMap.serializeBinaryToWriter);
  }
};


/**
 * map<string, LapNumberToTimeMap> vehicle_to_lap_times = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.LapNumberToTimeMap>}
 */
proto.VehicleToLapMap.prototype.getVehicleToLapTimesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.LapNumberToTimeMap>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.LapNumberToTimeMap));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.VehicleToLapMap} returns this
 */
proto.VehicleToLapMap.prototype.clearVehicleToLapTimesMap = function() {
  this.getVehicleToLapTimesMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LapAnalysisData.prototype.toObject = function(opt_includeInstance) {
  return proto.LapAnalysisData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LapAnalysisData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapAnalysisData.toObject = function(includeInstance, msg) {
  var f, obj = {
    numLaps: jspb.Message.getFieldWithDefault(msg, 1, 0),
    threshold: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    lapDataMap: (f = msg.getLapDataMap()) ? f.toObject(includeInstance, proto.LapAnalysisSingle.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LapAnalysisData}
 */
proto.LapAnalysisData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LapAnalysisData;
  return proto.LapAnalysisData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LapAnalysisData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LapAnalysisData}
 */
proto.LapAnalysisData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumLaps(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setThreshold(value);
      break;
    case 3:
      var value = msg.getLapDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.LapAnalysisSingle.deserializeBinaryFromReader, "", new proto.LapAnalysisSingle());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LapAnalysisData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LapAnalysisData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LapAnalysisData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapAnalysisData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumLaps();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getThreshold();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLapDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.LapAnalysisSingle.serializeBinaryToWriter);
  }
};


/**
 * optional int32 num_laps = 1;
 * @return {number}
 */
proto.LapAnalysisData.prototype.getNumLaps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.LapAnalysisData} returns this
 */
proto.LapAnalysisData.prototype.setNumLaps = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double threshold = 2;
 * @return {number}
 */
proto.LapAnalysisData.prototype.getThreshold = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LapAnalysisData} returns this
 */
proto.LapAnalysisData.prototype.setThreshold = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * map<string, LapAnalysisSingle> lap_data = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.LapAnalysisSingle>}
 */
proto.LapAnalysisData.prototype.getLapDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.LapAnalysisSingle>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.LapAnalysisSingle));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.LapAnalysisData} returns this
 */
proto.LapAnalysisData.prototype.clearLapDataMap = function() {
  this.getLapDataMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LapAnalysisSingle.prototype.toObject = function(opt_includeInstance) {
  return proto.LapAnalysisSingle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LapAnalysisSingle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapAnalysisSingle.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lapTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    lapDiff: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    lapRank: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LapAnalysisSingle}
 */
proto.LapAnalysisSingle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LapAnalysisSingle;
  return proto.LapAnalysisSingle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LapAnalysisSingle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LapAnalysisSingle}
 */
proto.LapAnalysisSingle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLapTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLapDiff(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLapRank(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LapAnalysisSingle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LapAnalysisSingle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LapAnalysisSingle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapAnalysisSingle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLapTime();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLapDiff();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getLapRank();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string vehicle_number = 1;
 * @return {string}
 */
proto.LapAnalysisSingle.prototype.getVehicleNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.LapAnalysisSingle} returns this
 */
proto.LapAnalysisSingle.prototype.setVehicleNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double lap_time = 2;
 * @return {number}
 */
proto.LapAnalysisSingle.prototype.getLapTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LapAnalysisSingle} returns this
 */
proto.LapAnalysisSingle.prototype.setLapTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double lap_diff = 3;
 * @return {number}
 */
proto.LapAnalysisSingle.prototype.getLapDiff = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LapAnalysisSingle} returns this
 */
proto.LapAnalysisSingle.prototype.setLapDiff = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 lap_rank = 4;
 * @return {number}
 */
proto.LapAnalysisSingle.prototype.getLapRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.LapAnalysisSingle} returns this
 */
proto.LapAnalysisSingle.prototype.setLapRank = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LapAnalysisStats.prototype.toObject = function(opt_includeInstance) {
  return proto.LapAnalysisStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LapAnalysisStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapAnalysisStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    slow: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    medium: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    fast: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LapAnalysisStats}
 */
proto.LapAnalysisStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LapAnalysisStats;
  return proto.LapAnalysisStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LapAnalysisStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LapAnalysisStats}
 */
proto.LapAnalysisStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSlow(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMedium(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFast(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LapAnalysisStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LapAnalysisStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LapAnalysisStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapAnalysisStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlow();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getMedium();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getFast();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double slow = 1;
 * @return {number}
 */
proto.LapAnalysisStats.prototype.getSlow = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LapAnalysisStats} returns this
 */
proto.LapAnalysisStats.prototype.setSlow = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double medium = 2;
 * @return {number}
 */
proto.LapAnalysisStats.prototype.getMedium = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LapAnalysisStats} returns this
 */
proto.LapAnalysisStats.prototype.setMedium = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double fast = 3;
 * @return {number}
 */
proto.LapAnalysisStats.prototype.getFast = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LapAnalysisStats} returns this
 */
proto.LapAnalysisStats.prototype.setFast = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LapAnalysisAvgData.prototype.toObject = function(opt_includeInstance) {
  return proto.LapAnalysisAvgData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LapAnalysisAvgData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapAnalysisAvgData.toObject = function(includeInstance, msg) {
  var f, obj = {
    avgDataMap: (f = msg.getAvgDataMap()) ? f.toObject(includeInstance, proto.LapAnalysisAvgSingle.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LapAnalysisAvgData}
 */
proto.LapAnalysisAvgData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LapAnalysisAvgData;
  return proto.LapAnalysisAvgData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LapAnalysisAvgData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LapAnalysisAvgData}
 */
proto.LapAnalysisAvgData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getAvgDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.LapAnalysisAvgSingle.deserializeBinaryFromReader, "", new proto.LapAnalysisAvgSingle());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LapAnalysisAvgData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LapAnalysisAvgData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LapAnalysisAvgData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapAnalysisAvgData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvgDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.LapAnalysisAvgSingle.serializeBinaryToWriter);
  }
};


/**
 * map<string, LapAnalysisAvgSingle> avg_data = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.LapAnalysisAvgSingle>}
 */
proto.LapAnalysisAvgData.prototype.getAvgDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.LapAnalysisAvgSingle>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.LapAnalysisAvgSingle));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.LapAnalysisAvgData} returns this
 */
proto.LapAnalysisAvgData.prototype.clearAvgDataMap = function() {
  this.getAvgDataMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LapAnalysisAvgSingle.prototype.toObject = function(opt_includeInstance) {
  return proto.LapAnalysisAvgSingle.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LapAnalysisAvgSingle} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapAnalysisAvgSingle.toObject = function(includeInstance, msg) {
  var f, obj = {
    vehicleNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    avgLapTime: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    avgLapRank: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LapAnalysisAvgSingle}
 */
proto.LapAnalysisAvgSingle.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LapAnalysisAvgSingle;
  return proto.LapAnalysisAvgSingle.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LapAnalysisAvgSingle} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LapAnalysisAvgSingle}
 */
proto.LapAnalysisAvgSingle.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgLapTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgLapRank(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LapAnalysisAvgSingle.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LapAnalysisAvgSingle.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LapAnalysisAvgSingle} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LapAnalysisAvgSingle.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVehicleNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAvgLapTime();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAvgLapRank();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional string vehicle_number = 1;
 * @return {string}
 */
proto.LapAnalysisAvgSingle.prototype.getVehicleNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.LapAnalysisAvgSingle} returns this
 */
proto.LapAnalysisAvgSingle.prototype.setVehicleNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double avg_lap_time = 2;
 * @return {number}
 */
proto.LapAnalysisAvgSingle.prototype.getAvgLapTime = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LapAnalysisAvgSingle} returns this
 */
proto.LapAnalysisAvgSingle.prototype.setAvgLapTime = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double avg_lap_rank = 3;
 * @return {number}
 */
proto.LapAnalysisAvgSingle.prototype.getAvgLapRank = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.LapAnalysisAvgSingle} returns this
 */
proto.LapAnalysisAvgSingle.prototype.setAvgLapRank = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DriverPoints.prototype.toObject = function(opt_includeInstance) {
  return proto.DriverPoints.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DriverPoints} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DriverPoints.toObject = function(includeInstance, msg) {
  var f, obj = {
    position: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vehicleNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    driverName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    laps: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gapToLeader: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    preRaceRank: jspb.Message.getFieldWithDefault(msg, 6, 0),
    preRaceWins: jspb.Message.getFieldWithDefault(msg, 7, 0),
    preRacePoints: jspb.Message.getFieldWithDefault(msg, 8, 0),
    preRacePlayoffPoints: jspb.Message.getFieldWithDefault(msg, 9, 0),
    pointsGained: jspb.Message.getFieldWithDefault(msg, 10, 0),
    currentWins: jspb.Message.getFieldWithDefault(msg, 11, 0),
    currentPoints: jspb.Message.getFieldWithDefault(msg, 12, 0),
    currentRank: jspb.Message.getFieldWithDefault(msg, 13, 0),
    pointsGap: jspb.Message.getFieldWithDefault(msg, 14, 0),
    pointsToClinch: jspb.Message.getFieldWithDefault(msg, 15, 0),
    pointsProjected: jspb.Message.getFieldWithDefault(msg, 16, 0),
    startProjected: jspb.Message.getFieldWithDefault(msg, 17, 0),
    startPointsProjected: jspb.Message.getFieldWithDefault(msg, 18, 0),
    worstCaseWins: jspb.Message.getFieldWithDefault(msg, 19, 0),
    worstCasePoints: jspb.Message.getFieldWithDefault(msg, 20, 0),
    worstCaseRank: jspb.Message.getFieldWithDefault(msg, 21, 0),
    worstCasePosition: jspb.Message.getFieldWithDefault(msg, 22, 0),
    worstCaseGap: jspb.Message.getFieldWithDefault(msg, 23, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DriverPoints}
 */
proto.DriverPoints.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DriverPoints;
  return proto.DriverPoints.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DriverPoints} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DriverPoints}
 */
proto.DriverPoints.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPosition(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLaps(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGapToLeader(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPreRaceRank(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPreRaceWins(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPreRacePoints(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPreRacePlayoffPoints(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPointsGained(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentWins(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentPoints(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentRank(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPointsGap(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPointsToClinch(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPointsProjected(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartProjected(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartPointsProjected(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorstCaseWins(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorstCasePoints(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorstCaseRank(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorstCasePosition(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorstCaseGap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DriverPoints.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DriverPoints.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DriverPoints} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DriverPoints.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPosition();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getVehicleNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDriverName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLaps();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getGapToLeader();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getPreRaceRank();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPreRaceWins();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPreRacePoints();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getPreRacePlayoffPoints();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getPointsGained();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getCurrentWins();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getCurrentPoints();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getCurrentRank();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getPointsGap();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getPointsToClinch();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getPointsProjected();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getStartProjected();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getStartPointsProjected();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getWorstCaseWins();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getWorstCasePoints();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getWorstCaseRank();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getWorstCasePosition();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getWorstCaseGap();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
};


/**
 * optional int32 position = 1;
 * @return {number}
 */
proto.DriverPoints.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string vehicle_number = 2;
 * @return {string}
 */
proto.DriverPoints.prototype.getVehicleNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setVehicleNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string driver_name = 3;
 * @return {string}
 */
proto.DriverPoints.prototype.getDriverName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setDriverName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 laps = 4;
 * @return {number}
 */
proto.DriverPoints.prototype.getLaps = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setLaps = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double gap_to_leader = 5;
 * @return {number}
 */
proto.DriverPoints.prototype.getGapToLeader = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setGapToLeader = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int32 pre_race_rank = 6;
 * @return {number}
 */
proto.DriverPoints.prototype.getPreRaceRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setPreRaceRank = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 pre_race_wins = 7;
 * @return {number}
 */
proto.DriverPoints.prototype.getPreRaceWins = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setPreRaceWins = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 pre_race_points = 8;
 * @return {number}
 */
proto.DriverPoints.prototype.getPreRacePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setPreRacePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 pre_race_playoff_points = 9;
 * @return {number}
 */
proto.DriverPoints.prototype.getPreRacePlayoffPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setPreRacePlayoffPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 points_gained = 10;
 * @return {number}
 */
proto.DriverPoints.prototype.getPointsGained = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setPointsGained = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 current_wins = 11;
 * @return {number}
 */
proto.DriverPoints.prototype.getCurrentWins = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setCurrentWins = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 current_points = 12;
 * @return {number}
 */
proto.DriverPoints.prototype.getCurrentPoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setCurrentPoints = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 current_rank = 13;
 * @return {number}
 */
proto.DriverPoints.prototype.getCurrentRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setCurrentRank = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 points_gap = 14;
 * @return {number}
 */
proto.DriverPoints.prototype.getPointsGap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setPointsGap = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 points_to_clinch = 15;
 * @return {number}
 */
proto.DriverPoints.prototype.getPointsToClinch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setPointsToClinch = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 points_projected = 16;
 * @return {number}
 */
proto.DriverPoints.prototype.getPointsProjected = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setPointsProjected = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 start_projected = 17;
 * @return {number}
 */
proto.DriverPoints.prototype.getStartProjected = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setStartProjected = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 start_points_projected = 18;
 * @return {number}
 */
proto.DriverPoints.prototype.getStartPointsProjected = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setStartPointsProjected = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int32 worst_case_wins = 19;
 * @return {number}
 */
proto.DriverPoints.prototype.getWorstCaseWins = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setWorstCaseWins = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 worst_case_points = 20;
 * @return {number}
 */
proto.DriverPoints.prototype.getWorstCasePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setWorstCasePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int32 worst_case_rank = 21;
 * @return {number}
 */
proto.DriverPoints.prototype.getWorstCaseRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setWorstCaseRank = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int32 worst_case_position = 22;
 * @return {number}
 */
proto.DriverPoints.prototype.getWorstCasePosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setWorstCasePosition = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int32 worst_case_gap = 23;
 * @return {number}
 */
proto.DriverPoints.prototype.getWorstCaseGap = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.DriverPoints} returns this
 */
proto.DriverPoints.prototype.setWorstCaseGap = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * @enum {number}
 */
proto.OriginType = {
  UNKNOWNFEEDTYPE: 0,
  SMT: 1,
  MULTILOOP: 2,
  RMONITOR: 3,
  BOLT6: 4,
  NASCARAPI: 5,
  PROPENALTY: 6
};

/**
 * @enum {number}
 */
proto.FlagType = {
  UNKNOWNFLAGTYPE: 0,
  GREEN: 1,
  YELLOW: 2,
  RED: 3,
  CHECKER: 4,
  WHITE: 5,
  STOP: 6,
  REDBLACKCOMBO: 7,
  WARM: 8,
  UNFLAGGED: 9
};

/**
 * @enum {number}
 */
proto.RunStatus = {
  UNKNOWNRUNSTATUS: 0,
  COMPLETED: 1,
  LIVE: 2,
  UNAVAILABLE: 3,
  DATALOSS: 4,
  CANCELED: 5
};

/**
 * @enum {number}
 */
proto.RunType = {
  UNKNOWNRUNTYPE: 0,
  PRACTICE: 1,
  QUALIFYING: 2,
  RACE: 3,
  HEAT_RACE: 4
};

/**
 * @enum {number}
 */
proto.SeriesType = {
  UNKNOWNSERIESTYPE: 0,
  CUP: 1,
  XFINITY: 2,
  TRUCK: 3,
  INDYCAR: 4,
  INDYLIGHTS: 5,
  INDYPRO2000: 6,
  USF2000: 7,
  MX5CUP: 8
};

goog.object.extend(exports, proto);
