import { OwnerPoints, OriginType, SanctioningBodyEnum } from 'models/viewModels';
import { RSocketBaseService, StreamRequestType } from './RSocketBaseService';
import axiosConfig, { ApiRequestType } from 'axios/axiosConfig';

export class OwnerPointsService extends RSocketBaseService<OwnerPoints[], OwnerPoints> {
  protected async getStreamHistoryRequest(): Promise<OwnerPoints[]> {
    return axiosConfig(ApiRequestType.StreamMetricsApi)
      .post('/rest/backfill/ownerPoints', this.request)
      .then((response) => response.data as OwnerPoints[])
      .catch(() => []);
  }

  protected onDataCallback = async (response: OwnerPoints) => {
    if (this.listeners.size > 0) {
      this.localCache = [...this.localCache.filter((item) => item.vehicleNumber !== response.vehicleNumber), response];
    }
  };

  async start() {
    this.delay = 5000;
    super.start();
  }
}

export const OwnerPointsRSocketService: Map<SanctioningBodyEnum, Map<OriginType, OwnerPointsService>> = new Map(
  (Object.values(SanctioningBodyEnum) as SanctioningBodyEnum[])
    .filter((x) => typeof x === 'number')
    .map((sanctioningBody) => [
      sanctioningBody,
      new Map(
        (Object.values(OriginType) as OriginType[])
          .filter((x) => typeof x === 'number')
          .map((source: OriginType) => [
            source,
            new OwnerPointsService({
              route: 'stream.ownerPoints',
              connectionType: StreamRequestType.MotorsportsStreamApi,
              originType: source,
              sanctioningBody: sanctioningBody,
            }),
          ])
      ),
    ])
);
